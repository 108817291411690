<template>
  <div class="ai-choose-box">
    <div class="flex content-between">
      <div class="category">
        <div class="desc">品类：</div>
        <el-cascader v-model="currentCategory" :options="options" placeholder="请选择品类" :props="props"
          @change="expandChange" clearable v-removeAriaHidden ref="cascader"></el-cascader>
      </div>
      <div class="category">
        <div class="desc">输入零售价：</div>
        <el-input type="number" placeholder="请输入零售价" v-model="price" @keyup.native="proving2($event)"
          @input="handleInput" min="0" clearable>
        </el-input>
      </div>
    </div>

    <div class="desc-tip">可选项</div>
    <div class="category">
      <div class="desc">IP：</div>
      <el-autocomplete class="inline-input" v-model="ipVal" :fetch-suggestions="querySearch" placeholder="请输入内容"
        :trigger-on-focus="true" :value-key="autoName" @select="handleSelect" :clearable="true"
        @clear="ipChange"></el-autocomplete>
    </div>
    <div class="default-btn2 analysis-btn" @click="beginAnalysis">开始打造</div>
    <el-steps v-if="isLoading" :active="active" finish-status="success" class="mt-20">
      <el-step title="问题分析"></el-step>
      <el-step title="资料查询"></el-step>
      <el-step title="整理答案"></el-step>
      <el-step title="完成"></el-step>
    </el-steps>
    <el-skeleton v-if="isLoading" class="mt-20" :rows="6" animated />
  </div>
</template>
<script>
  import {
    getStatisticsCategoryList,
    getStatisticsSearchIp,
    getStatisticsKeywordList,
    getStatisticsDistribution,
  } from "@/api/statistics";
  export default {
    data() {
      return {
        currentCategory: "",
        category: "",
        secondCategory: "",
        ipVal: "",
        ipId: "",
        options: [],
        categoryAllOptions: [],
        categoryName: "",
        restaurants: [],
        autoName: "name",
        price: "",
        isFirst: true,
        active: 1,
        isLoading: false,
        stepInter: null,
      };
    },
    computed: {
      props() {
        let self = this;
        return {
          lazy: true,
          value: "id",
          label: "name",
          children: "children",
          checkStrictly: false,
          expandTrigger: "click",
          lazyLoad(node, resolve) {
            const { level, children } = node;
            // console.log("level", level, "node", node, "currentDate",self);
            if (level == 1) {
              let param = {
                category_id: node.value,
              };
              if (children && children.length > 0) {
                resolve([]);
                return;
              }
              let nodes = [];
              // let firstNode = {
              //   id: "all_" + node.value,
              //   dataId: node.value,
              //   name: "全部" + node.label,
              //   leaf: true,
              //   isAll: true,
              // };
              // nodes.push(firstNode);
              getStatisticsKeywordList(param)
                .then((res) => {
                  let tempList = (res && res.data && res.data.list) || [];
                  if (tempList.length > 1) {
                    let tempNodes = tempList.map((item) => {
                      return {
                        id: item.id,
                        name: item.name,
                        dataName: item.name,
                        leaf: true,
                        isAll: false,
                      };
                    });
                    nodes = nodes.concat(tempNodes);
                    console.log("nodes", nodes);
                    resolve(nodes);
                  } else if (tempList.length == 1) {
                    let firstNode = {
                      id: tempList[0].id,
                      dataId: tempList[0].id,
                      name: "全部" + tempList[0].name,
                      dataName: tempList[0].name,
                      leaf: true,
                      isAll: false,
                    };
                    nodes.push(firstNode);
                    resolve(nodes);
                  } else {
                    resolve(nodes);
                  }
                })
                .catch(() => {
                  resolve(nodes);
                });
            } else if (level > 1) {
              resolve([]);
            }
          },
        };
      },
    },
    created() {
      this.initData();
      this.$bus.$off("initData");
      this.$bus.$on("initData", () => {
        this.initData();
      });
    },
    methods: {
      getBit(value, bit) {
        let str = Number(value);
        str = str.toFixed(bit)
        return str;
      },
      proving2(e) {
        var keynum = window.event ? e.keyCode : e.which;   //获取键盘码
        var keychar = String.fromCharCode(keynum);  //获取键盘码对应的字符
        if (keynum == 189 || keynum == 109) { //禁止输入负数
          this.$message.warning('禁止输入负数')
          e.target.value = 0
        }

      },
      handleInput(value) {
        // 使用正则表达式匹配数字，并更新值
        // this.price = value.replace(/[^\d]/g, '');
      },
      initData() {
        this.getStatisticsCategoryListReq();
      },
      expandChange(e) {
        console.log("expandChange123123", e);
        this.categoryName = "";
        if (e.length == 0) {
          this.currentCategory = "";
          this.category = 0;
          this.secondCategory = "";
          this.$emit("clearSelect");
        } else {
          this.currentCategory = e;
          let nodeData = this.$refs["cascader"].getCheckedNodes()[0].data;
          console.log("nodeData", nodeData);
          this.categoryName = nodeData.dataName || "";
          if (nodeData.isAll) {
            this.category = nodeData.dataId || 0;
            this.secondCategory = "";
          } else {
            this.category = e[0];
            this.secondCategory = e[1];
          }
        }
      },
      handleSelect() { },
      ipChange() {
        this.ipId = "";
        this.ipVal = "";
      },
      querySearch(queryString, cb) {
        console.log("queryString", queryString);
        getStatisticsSearchIp({
          q: queryString,
        })
          .then((res) => {
            if (res && res.error == 0) {
              let restaurants = (res && res.data && res.data.list) || [];
              this.restaurants = restaurants;
              cb(restaurants);
            }
          })
          .catch(() => {
            this.restaurants = [];
          });
      },
      getStatisticsCategoryListReq() {
        getStatisticsCategoryList()
          .then((res) => {
            this.categoryAllOptions = (res && res.data && res.data.list) || [];
            this.options = JSON.parse(JSON.stringify(this.categoryAllOptions));
          })
          .catch(() => {
            this.categoryAllOptions = [];
            this.options = [];
          });
      },
      clearInter() {
        this.stepInter && clearInterval(this.stepInter);
      },
      beginAnalysis() {
        if (
          (!this.category || this.category.length == 0) &&
          !this.secondCategory
        ) {
          this.$message({
            message: "请选择品类",
            type: "error",
          });
          return;
        }
        if (this.price <= 0) {
          this.$message({
            message: "请选择价格",
            type: "error",
          });
          return;
        }
        if (this.ipVal) {
          let isExist = this.restaurants.filter((res) => {
            return res.name == this.ipVal;
          });
          if (isExist && isExist.length > 0) {
            this.ipId = isExist[0].id;
          }
        }
        this.active = 1;
        console.log("nodeData", this.categoryId);
        // if (this.isFirst) {
        //   this.isFirst = false;
        //   this.isLoading = true;
        //   this.stepInter = setInterval(() => {
        //     if (this.active < 4) {
        //       this.active += 1;
        //     }
        //   }, 800);
        // }

        this.isLoading = true;
        this.$emit("loadingStatus", this.isLoading);
        this.stepInter = setInterval(() => {
          if (this.active < 4) {
            this.active += 1;
          }
        }, 800);

        // const loading = this.$loading({
        //   lock: true,
        //   text: '打造中...',
        //   spinner: 'el-icon-loading',
        //   background: 'rgba(0, 0, 0, 0.7)'
        // });
        setTimeout(() => {
          let param = {
            ip_id: this.ipId,
            keyword_id: this.secondCategory || this.category,
            price: this.price,
          };
          getStatisticsDistribution(param)
            .then((res) => {
              this.clearInter();
              this.active = 4;
              this.isLoading = false;
              this.$emit("loadingStatus", this.isLoading);
              if (res) {
                setTimeout(() => {
                  this.$emit("loadingFinish", {
                    categoryName: this.categoryName,
                    ipName: this.ipVal,
                    ipId: this.ipId,
                    data: (res && res.data) || null,
                    keywordId: this.secondCategory,
                    price: this.price,
                    isFirst: this.ipId ? true : false
                  });
                }, 100);
              }

              // loading.close();
            })
            .catch((err) => {
              this.clearInter();
              this.active = 4;
              this.isLoading = false;
              this.$emit("loadingStatus", this.isLoading);
              setTimeout(() => {
                this.$emit("loadingFinish", {
                  categoryName: this.categoryName,
                  ipName: this.ipVal,
                  ipId: this.ipId,
                  data: null,
                  keywordId: this.secondCategory,
                  price: this.price,
                  isFirst: this.ipId ? true : false
                });
              }, 100);
              // loading.close();
              this.$message({
                message: res.msg || "获取数据失败，请稍后重试",
                type: "error",
              });
            });
        }, 3000);
      },
    },
  };
</script>
<style lang="less" scoped>
  .ai-choose-box {
    width: 100%;
    padding: 20px;
    background: #f8f8fa;
    border-radius: 10px;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    display: flex;
    display: flex;
    flex-direction: column;
    position: relative;

    .tips {
      font-size: 16px;
      color: #f56c6c;
      margin-bottom: 10px;
      text-align: left;
    }

    .desc-tip {
      font-size: 16px;
      color: #626a83;
      margin-bottom: 10px;
      text-align: left;
      margin-top: 20px;
    }

    .category {
      display: flex;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      .desc {
        font-weight: 400;
        font-size: 14px;
        color: #626a83;
        line-height: 20px;
        text-align: left;
        width: 110px;
      }

      .el-input {
        width: 160px;
      }
    }

    .analysis-btn {
      position: absolute;
      left: 420px;
      top: 120px;
      cursor: pointer;
    }

    .step-box {
      margin-top: 100px;
    }
  }
</style>
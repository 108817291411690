<template>
    <div class="advice-box">
        <div class="rank-title" v-if="title"><span>{{title}}</span><span class="sub-title" v-if="subTitle">({{subTitle}})</span>
            <el-tooltip v-if="showTopTip" style="margin-left:4px;margin-top:2px;" class="item" effect="dark"
            placement="right">
            <div slot="content">
              <div v-html="calTopTip()"></div>
            </div>
            <i class="el-icon-question"></i>
          </el-tooltip>
        </div>
        <div class="advice-content">
            <div class="advice-desc" v-html="descText"></div>
            <div class="advice-desc" v-if="secondText" v-html="secondText"></div>
        </div>
    </div>
</template>
<script>
import { marked } from 'marked';
export default {
    props: ["title", "subTitle","descText","secondText","showTopTip","currentType"],
    components: {
    },
    methods: {
        markdownToHtml(val) {
            if(val) {
                return marked(val); 
            } else {
                return ""
            }
            
        },
        calTopTip() {
            switch (this.currentType) {
                case "plyxType":
                    return "分析所选品类当前的市场情况和市场空间。"
                    break;
                case "ipyxType":
                    return "分析IP对于所选品类的匹配度和提升度。"
                    break;
            }
        }
    }
}
</script>
<style lang="less" scoped>
@import url("@/assets/css/rankTitle.less");
.advice-box {
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #E7E8ED;
    .advice-content {
        padding: 15px 20px;
        .advice-desc {
            font-weight: 400;
            font-size: 16px;
            color: #626A83;
            line-height: 20px;
            text-align: justify;
        }
    }
}
</style>
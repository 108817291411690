<template>
  <div id="app" v-cloak>
    <transition name="fade-transform" mode="out-in">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </transition>
    <!-- <router-view></router-view> -->
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
};
</script>

<style>
#app {
  /* text-align: center; */
  width: 100%;
  height: 100%;
  position: relative;
}
</style>

<template>
  <div class="rank-box">
    <div class="rank-title">
      <div class="flex-1 text-left">
        <span>{{title}}</span><span
        class="sub-title"
        v-if="subTitle">({{subTitle}})</span>
        <el-tooltip v-if="showTopTip" style="margin-left:4px;margin-top:2px;" class="item" effect="dark"
          placement="right">
          <div slot="content">
            <div v-html="calTopTip()"></div>
          </div>
          <i class="el-icon-question"></i>
        </el-tooltip>
      </div>
      <div class="right-opt">
        <span v-if="showChooseCate">品类细分</span>
        <!-- <el-cascader v-if="showChooseCate"
          v-model="category" :options="categoryOptions"
          :props="{value:'id', label: 'name'}"
          @change="handleChange"
          :placeholder="placeholderDefault ? placeholderDefault : '请选择'"></el-cascader> -->
        <el-select v-if="showChooseCate" v-model="category" clearable :placeholder="placeholderDefault ? placeholderDefault : '请选择'" @change="handleChange">
          <el-option
            v-for="item in categoryOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id" >
          </el-option>
        </el-select>
      </div>
    
    </div>
    <div class="rank-list">
      <div class="rank-item"
        v-for="(item,idx) in currentList" :key="idx">
        <div class="title">{{item.name}}</div>
        <div class="rank-info-list"
          v-for="(subItem,secIdx) in item.rankList"
          :key="secIdx">

          <div class="rank-info">
            <div class="num"><span>{{secIdx + 1}}</span>
            </div>
            <div class="name one-text">{{subItem.name}}
            </div>
            <div class="percent">
              <el-rate :value="Number(subItem.star)"
                :max="5" disabled
                :colors="['#88ACFF', '#88ACFF', '#88ACFF']">
              </el-rate>
            </div>
            <div class="percent-count">{{subItem.star || subItem.index}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  props: [
    "title",
    "subTitle",
    "currentList",
    "bgColor",
    "categoryOptions",
    "showChooseCate",
    "placeholderDefault",
    "currentType",
    "showTopTip"
  ],
  data() {
    return {
      category: "",
      radioVal: "",
    };
  },
  methods: {
    calTopTip() {
        switch (this.currentType) {
          case "categoryRankType":
            // return "分析玩具市场各品类占比和变化趋势，了解市场趋势与消费者偏好，制定营销策略、产品开发计划以及市场定位，从而更有效地满足消费者需求，提升市场竞争力。"
            return "分析玩具市场各品类占比和变化趋势，了解市场趋势与消费者偏好，制定营销策略、产品开发计划以及市场定位。"
            break;
          case "categoryUpRankType":
            return "分析短期市场趋势和消费者偏好，如果品类销售显著增长，可能反映了短期内消费者对该品类玩具的兴趣增加。"
            break;
          case "categoryDownRankType":
            return "分析短期市场趋势和消费者偏好，如果品类销售显著下降，可能表明消费者对该品类玩具的兴趣减弱。"
            break;
          case "distributionRankType":
            return "分析零售价和销售情况，了解市场结构和消费者需求，优化产品组合和定价策略，提升市场竞争力和盈利能力。";
            break;
          case "flowRankType":
            return "分析各玩具品类的搜索热度和媒体热度，更好地了解市场动态和消费者需求，优化产品开发和市场推广策略，提升市场竞争力和品牌影响力。";
            break;
          case "blueOceanIndexType":
            return "分析各玩具品类的市场空间，蓝海指数低则表示现有市场空间竞争激烈，<br>增长有限，蓝海指数越高表示可开发的市场空间更大，可以通过创新和差异化策略，<br>创造新的市场需求，获得高利润和高增长。";
            break;
          case "ipRankType":
            return "分析各IP商品销售数据，了解当前市场非常受欢迎、具有较高品牌知名度和粉丝基础的IP。";
            break;
          case "categoryIpUpRankType":
            return "分析IP商品销售数据，了解市场需求增加或者消费者关注增加的IP。";
            break;
          case "categoryIpNewRankType":
            return "分析IP商品数据，了解新开发的市场非常受欢迎的IP。";
            break;
          case "categoryIpPotentailRankType":
            return "挖掘品牌知名度和粉丝基础较高而且市场开发空间较大的蓝海IP";
            break;

        }
      },
    handleChange() {
      console.log("handleChange", this.category);
      this.$emit("categoryChange",{
        categoryId: this.category || 0,
        currentType: this.currentType
      });
    },
    calPerWidth(rankList, percent) {
      let sum = 0;
      for (let i = 0, j = rankList.length; i < j; i++) {
        sum += Number(rankList[i].percent);
      }
      if (percent == 0) {
        return 0;
      }
      if (sum == 0) {
        return 0;
      } else {
        return parseFloat((Number(percent) / sum) * 100).toFixed(2);
      }
    },
  },
};
</script>
  <style lang="less" scoped>
@import url("@/assets/css/rankTitle.less");
.rank-box {
  display: flex;
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #e7e8ed;
  margin-bottom: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  .rank-title {
    .right-opt {
      margin-right: 10px;
    }
    ::v-deep {
      .el-cascader {
        line-height: 30px;
        margin-left: 10px;
        input {
          height: 30px;
          line-height: 30px;
        }
        .el-input__icon{
          line-height: 30px;
        }
      }
    }
    ::v-deep {
      .el-select {
        line-height: 30px;
        margin-left: 10px;
        input {
          height: 30px;
          line-height: 30px;
        }
        .el-input__icon {
          line-height: 30px;
        }
      }
    }
  }
  .rank-list {
    display: flex;
    width: 100%;
    .rank-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative;
      .title {
        font-weight: 400;
        font-size: 16px;
        color: #626a83;
        line-height: 20px;
        text-align: center;
        padding-top: 8px;
        margin-bottom: 16px;
      }

      .zhanbi {
        font-weight: 400;
        font-size: 10px;
        color: #979db1;
        line-height: 14px;
        position: absolute;
        right: 10px;
        top: 30px;
      }
      .rank-info-list {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
      .rank-info {
        display: flex;
        padding: 0 6px 0 10px;
        align-items: center;
        .num {
          width: 24px;
          height: 24px;
          background: #fbfbfc;
          box-shadow: 0px 2px 4px 0px #e7e8ed;
          border: 1px solid #e7e8ed;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;

          span {
            font-weight: bold;
            font-size: 12px;
            color: #1a232f;
          }
        }
        .name {
          width: 120px;
          font-weight: 400;
          font-size: 16px;
          color: #1a232f;
          text-align: left;
          margin-left: 8px;
        }
        .percent {
          // flex: 1;
        }
        .percent-count {
          width: 36px;
          font-weight: 400;
          font-size: 16px;
          color: #626a83;
          margin-left: 6px;
        }
      }
    }
  }
}
</style>
<template>
    <div class="detail-warp-popup">
        <div class="detail-popup-content">
            <i class="el-icon-circle-close icon-defalut-close cursor" @click="close"></i>
            <div class="default-title">积分加油站</div>
            <div class="point-order-list">
                <div class="point-order-item" v-for="(item,idx) in orderList" :key="idx" @click="goPay(item)">
                    <div class="flex justify-between order-top">
                        <div class="order-left">
                            <div class="name">{{item.name}}</div>
                            <div class="desc">{{item.desc}}
                            </div>
                            <div class="pay-info">
                                <div class="discount"><span class="unit">¥</span>{{item.user_price && item.user_price/100}}</div>
                                <div class="count"><span class="unit">¥</span>{{item.price && item.price/100}}</div>
                                <div class="label">
                                    {{item.user_discount}}折
                                </div>
                            </div>
                        </div>
                        <div class="pay-btn">购买</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { getCoinOrderProducts } from "@/api/coinOrder";
    export default {
        data() {
            return {
                orderList: [
                ],
            }
        },
        created() {
            this.getCoinOrderProductsReq();
        },
        methods: {
            close() {
                this.$emit("closeStation")
            },
            calDiscount(discount, count) {
                return parseFloat((Number(discount) / Number(count)) * 10).toFixed(0) + "折";
            },
            goPay(item) {
                this.$emit("goPay",item)
            },
            getCoinOrderProductsReq() {
                getCoinOrderProducts().then((res) => {
                    if(res && res.error == 0) {
                        this.orderList = res.data && res.data.list || [];
                    } else {
                        this.orderList = [];
                    }
                }).catch(() => {
                    this.orderList = [];
                })
            }
        }
    }
</script>
<style lang="less" scoped>
    .detail-warp-popup {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: transparent;
        z-index: 100001;

        .detail-popup-content {
            width: 380px;
            background: #FFFFFF;
            box-shadow: 0px 4px 8px 0px #E7E8ED;
            border-radius: 20px;
            border: 1px solid #E7E8ED;
            left: 132px;
            bottom: 20px;
            top: 128px;
            position: absolute;
            overflow-y: auto;

            .point-order-list {
                display: flex;
                flex: 1;
                flex-direction: column;
                overflow-y: auto;
                padding: 0 30px;
                margin-top: 20px;

                .point-order-item {
                    display: flex;
                    padding: 20px;
                    margin-bottom: 10px;
                    position: relative;
                    cursor: pointer;
                    background: #F8F8FA;
                    border-radius: 10px;

                    .order-top {
                        width: 100%;
                    }

                    .order-bottom {}

                    .order-left {
                        text-align: left;
                    }

                    .pay-info {
                        display: flex;
                        margin-top: 20px;
                        align-items: center;

                        .unit {
                            font-size: 14px;
                        }

                        .discount {
                            font-weight: bold;
                            font-size: 20px;
                            color: #3672fd;
                            line-height: 20px;
                            text-align: left;
                        }

                        .count {
                            font-weight: 400;
                            font-size: 14px;
                            color: #979db1;
                            line-height: 20px;
                            text-align: left;
                            text-decoration-line: line-through;
                            margin-left: 12px;
                        }

                        .label {
                            padding: 0 8px;
                            height: 16px;
                            background: #FFD8D8;
                            border-radius: 8px 8px 8px 0px;
                            border: 1px solid #FF5959;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-left: 6px;
                            font-size: 12px;
                            color: #FF5959;
                        }
                    }

                    .pay-btn {
                        width: 64px;
                        height: 28px;
                        background: #D9E5FF;
                        box-shadow: 0px 2px 4px 0px #D9E5FF;
                        border-radius: 18px;
                        border: 1px solid #3672FD;
                        position: absolute;
                        bottom: 20px;
                        right: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 400;
                        font-size: 12px;
                        color: #3672FD;
                    }

                    .name {
                        font-weight: bold;
                        font-size: 14px;
                        color: #1a232f;
                        line-height: 20px;
                    }

                    .desc {
                        font-weight: 400;
                        font-size: 12px;
                        color: #626a83;
                        line-height: 17px;
                        margin-top: 5px;
                    }

                    .status {
                        font-weight: 400;
                        font-size: 12px;
                        color: #3672fd;
                        line-height: 17px;
                    }

                    .count {
                        font-weight: bold;
                        font-size: 14px;
                        color: #1a232f;
                        line-height: 20px;

                        .unit {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
</style>
<template>
  <div
    class="my-popup position-fixed top-0 right-0 bottom-0 left-0"
    style="z-index: 999">
    <div
      class="position-absolute top-0 bottom-0 left-0 right-0 bg-layer">
    </div>
    <div
      class="popup-content position-relative flex flex-column align-center">
      <div
        class="font-24 text-primary line-h-34 font-weight-bolder mt-28">
        恭喜您</div>
      <div class="popup-img">
        <img
          src="https://files.sssrise.com/files/uploads/static/reward.png">
      </div>
      <div
        class="font-14 text-primary font-weight-bolder line-h-20">
        您已成功完成注册流程</div>
      <div
        class="font-27 text-default font-weight-bolder line-h-20">
        您将获得300积分作为注册奖励</div>
      <div
        class="font-27 text-default font-weight-bolder line-h-20">
        尽情享受与AI的交互体验吧!</div>
      <div class="my-button mt-20 cursor"
        @click="closeRewardPopup">立即领取</div>
      <div class="popup-close flex justify-center">
        <i class="el-icon-circle-close icon-close cursor" @click="closeRewardPopup"></i>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    closeRewardPopup() {
      this.$emit("closeRewardPopup");
    },
  },
};
</script>
<style lang="less" scoped>
.popup-content {
  width: 460px;
  height: 360px;
  background: linear-gradient(180deg, #d9e5ff 0%, #ffffff 100%);
  border-radius: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .popup-img {
    margin: 8px 0 15px 0;

    img {
      width: 128px;
      height: 128px;
    }
  }

  .popup-close {
    position: absolute;
    bottom: -50px;
    left: 0;
    right: 0;
    .icon-close {
        font-size: 22px;
        color: #fff;
    }
  }
}
</style>
<template>
  <div class="content-detail-box flex-column">
    <div class="flex justify-end">
      <div class="problem-info problem-detail-info">

        <div
          class="problem-right-desc position-relative align-center flex flex-column">
          <div class="strong-right mb-10"
            v-if="problemItem && problemItem.msg && problemItem.msg.text">
            <span class="user-text">{{problemItem.msg.text}}</span>
          </div>
          <div style="padding: 0 10px;"
            v-if="problemItem && problemItem.msg && problemItem.msg.image">
            <el-image style="width: 100px; height: 100px"
              :src="problemItem.msg.image"
              :preview-src-list="[problemItem.msg.image]">
            </el-image>
          </div>
        </div>
      </div>
      <div class="problem-avatar ml-20">
        <img v-if="problemItem && problemItem.avatar"
          :src="problemItem.avatar">
        <img v-else
          src="https://files.sssrise.com/files/uploads/static/default_avatar.png">
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: ["problemItem", "problemIdx"],
  data() {
    return {};
  },
  methods: {
    copyTextToClipboard(text) {
      //   uni.setClipboardData({
      //     data: text,
      //     success: function () {
      //       console.log('复制成功');
      //       // 可以添加用户友好的提示，例如使用uni.showToast提示复制成功
      //       uni.showToast({
      //         title: '复制成功',
      //         icon: 'success',
      //         duration: 2000
      //       });
      //     },
      //     fail: function () {
      //       console.log('复制失败');
      // 	  uni.showToast({
      // 	    title: '复制失败',
      // 	    icon: 'error',
      // 	    duration: 2000
      // 	  });
      //       // 可以添加错误处理或用户友好的提示
      //     }
      //   });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("@/assets/css/contentDetail.less");
</style>

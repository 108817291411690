<template>
  <div class="rank-box">
    <div class="rank-title"><span>{{title}}</span><span
        class="sub-title"
        v-if="subTitle">({{subTitle}})</span>
        <el-tooltip v-if="showTopTip" style="margin-left:4px;margin-top:2px;" class="item" effect="dark"
        placement="right">
        <div slot="content">
          <div v-html="calTopTip()"></div>
        </div>
        <i class="el-icon-question"></i>
      </el-tooltip>  
      </div>
    <div class="rank-list">
      <div class="rank-item"
        v-for="(item,idx) in currentList" :key="idx">
        <div class="title">{{item.name}}</div>
        <div class="rank-info-list"
          v-for="(subItem,secIdx) in item.rankList"
          :key="secIdx">

          <div class="rank-info">
            <div class="name one-text">
              {{subItem.region_name}}
            </div>
            <div class="percent">
              <div class="active"
                :style="{width: calPerWidth(item.rankList,subItem.region_index,idx) + '%',background: bgColor || '#da8ff5'}">
              </div>
            </div>
            <div class="percent-count">
              {{subItem.region_index.toFixed(0)}}

            </div>
            <div class="up-down">
              <i v-if="subItem.region_index_trend > 0"
                class="el-icon-top"
                style="color: #E6182E;"></i>
              <i v-else-if="subItem.region_index_trend < 0" class="el-icon-bottom"
                style="color: #13B918;"></i>
              <span v-else></span>
            </div>
          </div>
        </div>
        <el-empty v-if="item.rankList.length == 0">
        </el-empty>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  props: ["title", "subTitle", "currentList", "bgColor", "hiddenLabel","currentType","sum","showTopTip"],
  methods: {
    calTopTip() {
      switch(this.currentType) {
        case "ipMarket":
          return "分析品类和IP在各社交平台的讨论热度，制定合适的营销策略。";
          break;
        case "ipMarketRegion":
          return "分析品类和IP在各地域的讨论热度，制定合适的营销策略。";
          break;
        case "ipRegion": 
          return "此IP在各社交平台和地域的讨论热度，了解市场动态和消费者需求，优化IP推广策略和市场定位。";
          break;
      }
    },
    calPerWidth(rankList, percent,idx) {
      let sum = this.sum || 100;
      if(this.currentType == "ipRegion") {
        if(idx == 1) {
          sum = 1000;
        }
      }
      // for (let i = 0, j = rankList.length; i < j; i++) {
      //   sum += Number(rankList[i].region_index);
      // }
      if (percent == 0) {
        return 0;
      }
      if (sum == 0) {
        return 0;
      } else {
        return parseFloat((Number(percent) / sum) * 100).toFixed(2);
      }
    },
  },
};
</script>
  <style lang="less" scoped>
@import url("@/assets/css/rankTitle.less");
.rank-box {
  display: flex;
  width: 100%;
  // height: 206px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #e7e8ed;
  margin-bottom: 20px;
  flex-direction: column;
  .rank-list {
    display: flex;
    width: 100%;
    padding-bottom: 20px;
    .rank-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative;
      ::v-deep .el-empty {
        padding: 20px 0;
        .el-empty__image {
          width: 40px;
        }
      }
      .title {
        font-weight: 400;
        font-size: 14px;
        color: #626a83;
        line-height: 20px;
        text-align: center;
        padding-top: 8px;
        margin-bottom: 20px;
      }

      .zhanbi {
        font-weight: 400;
        font-size: 10px;
        color: #979db1;
        line-height: 14px;
        position: absolute;
        right: 10px;
        top: 30px;
      }
      .rank-info-list {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .rank-info {
        display: flex;
        padding: 0 6px 0 10px;
        align-items: center;
        .name {
          width: 80px;
          font-weight: 400;
          font-size: 16px;
          color: #1a232f;
          text-align: left;
          margin-left: 8px;
        }
        .percent {
          flex: 1;
          height: 16px;
          background: #f3f3f8;
          border-radius: 4px;
          margin-left: 14px;
          position: relative;
          .active {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            background: #da8ff5;
            border-radius: 4px;
          }
        }
        .percent-count {
          width: 70px;
          font-weight: 400;
          font-size: 16px;
          color: #626a83;
          margin-left: 6px;
          display: flex;
        }
        .up-down {
          width: 10px;
          display: flex;
          justify-content: center;
          color: #979db1;
        }
      }
    }
  }
}
</style>
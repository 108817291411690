<template>
    <div style="display: flex;flex-direction: column;">
      <div class="problem-box mt-20">

        <div class="problem-avatar">
          <img
            src="https://files.sssrise.com/files/uploads/static/ai_avatar.png">
        </div>
        <div class="problem-info">
          <div class="problem-desc" ref="textRef">
            <div class="dhk-arrow"><img
                style="width: 20px;height: 16px;"
                src="https://files.sssrise.com/files/uploads/static/dhk_arrow.png">
            </div>
            <div class="desc"
              v-if="currentProblemItem && currentProblemItem.name">
              {{currentProblemItem && currentProblemItem.name}}
            </div>
            <div
              class="strong">
              <div class=" position-relative">
                <span>{{defaultText || ""}}</span>
              </div>
            </div>
    
            <div
              v-if="currentProblemItem && currentProblemItem.imageSrc && currentProblemItem.imageSrc.length > 0"
              class="">
              <div
                v-for="(imgItem,imgIdx) in currentProblemItem.imageSrc"
                :key="imgIdx" class="position-relative">
                <ImgCom :item="imgItem"></ImgCom>
              </div>
    
            </div>
          </div>
    
        </div>
      </div>
      <div class="default-btn2"
        @click="analysisDetail" style="    margin: 0 auto;
        margin-top: 20px;cursor: pointer;">详细分析</div>
    </div>
  
  </template>
  
  <script>
  import { messageLike } from "@/api/user";
  import time from "@/utils/time";
  import ImgCom from "./img.vue";
  import { marked } from 'marked';

  export default {
    name: "probleOne",
    props: ["problemItem", "problemIdx", "streamStatus","defaultText"],
    components: {
      ImgCom
    },
    data() {
      return {
        currentProblemItem: null,
        currentDescList: [],
        currentDescItem: {
          defaultDesc: "",
          currentDesc: "",
        },
        loading: true,
        loadingPercentage: 0
      };
    },
    watch: {
      problemItem: {
        handler(newVal) {
          // console.log("newVal", newVal);
          this.currentProblemItem = this.problemItem;
          if (
            this.currentProblemItem &&
            this.currentProblemItem.desc &&
            this.currentProblemItem.desc.length > 0
          ) {
            for (let i = 0, j = this.currentProblemItem.desc.length; i < j; i++) {
              let tempItem = this.currentProblemItem.desc[i];
              let isExist = this.currentDescList.filter((res) => {
                return res.descId == tempItem.descId;
              });
              if (isExist && isExist.length > 0) {
              } else {
                this.currentDescList.push(tempItem);
                this.currentDescItem.defaultDesc += tempItem.info;
                // this.currentDescItem.defaultDesc = this.currentDescItem.defaultDesc.replace(/\n/g, "<br/>")
                // this.currentDescItem.defaultDesc = this.currentDescItem.defaultDesc.replace(/\n\n/g, '<br/><br/>')
                // this.currentDescItem.defaultDesc = this.currentDescItem.defaultDesc.replace(/\\n/g, '<br/>')
                // this.currentDescItem.defaultDesc = this.currentDescItem.defaultDesc.replace(/\\n\\n/g, '<br/><br/>')
                // this.currentDescItem.defaultDesc = this.currentDescItem.defaultDesc.replace(/\r\n/g, '<br/><br/>')
                // if(this.currentDescItem.defaultDesc) {
                //   this.currentDescItem.defaultDesc = marked(this.currentDescItem.defaultDesc, { sanitize: true }); 
                // }
                
                // console.log("this.currentDescItem.defaultDesc",this.currentDescItem.defaultDesc)
                if (this.problemItem.isMessage) {
                  this.currentDescItem["currentDesc"] =
                    this.currentDescItem.defaultDesc;
                } else {
                  setTimeout(() => {
                    this.calItem1(this.currentDescItem.defaultDesc);
                  }, 40);
                }
              }
            }
          }
        },
        deep: true,
        immediate: true
      },
    },
    created() {
      this.currentProblemItem = this.problemItem;
      if (
        this.currentProblemItem &&
        this.currentProblemItem.desc &&
        this.currentProblemItem.desc.length > 0
      ) {
        for (let i = 0, j = this.currentProblemItem.desc.length; i < j; i++) {
          let tempItem = this.currentProblemItem.desc[i];
          let isExist = this.currentDescList.filter((res) => {
            return res.descId == tempItem.descId;
          });
          if (isExist && isExist.length > 0) {
          } else {
            this.currentDescList.push(tempItem);
            this.currentDescItem.defaultDesc += tempItem.info;
            // this.currentDescItem.defaultDesc = this.currentDescItem.defaultDesc.replace(/\n/g, "<br/>")
            // this.currentDescItem.defaultDesc = this.currentDescItem.defaultDesc.replace(/\n\n/g, '<br/><br/>')
            // this.currentDescItem.defaultDesc = this.currentDescItem.defaultDesc.replace(/\\n/g, '<br/>')
            // this.currentDescItem.defaultDesc = this.currentDescItem.defaultDesc.replace(/\\n\\n/g, '<br/><br/>')
            // this.currentDescItem.defaultDesc = this.currentDescItem.defaultDesc.replace(/\r\n/g, '<br/><br/>')
            // if(this.currentDescItem.defaultDesc) {
            //   this.currentDescItem.defaultDesc = marked(this.currentDescItem.defaultDesc, { sanitize: true }); 
            // }
            if (this.problemItem.isMessage) {
              this.currentDescItem["currentDesc"] =
                this.currentDescItem.defaultDesc;
            } else {
              setTimeout(() => {
                this.calItem1(this.currentDescItem.defaultDesc);
              }, 40);
            }
          }
        }
      }
    },
    methods: {
      analysisDetail() {
        this.$emit("analysisDetailBack")
      },
      markContent() {
        return marked(this.currentDescItem.defaultDesc, { sanitize: true })
      },
      formatTimeVal(val) {
        if (val) {
          return time.formatTime(val);
        } else {
          return "";
        }
      },
      setInterval(item, idx) {
        return item.substring(0, idx++);
      },
      calItem(item, index, stringA = "") {
        let idx = 0;
        let self = this;
        if (stringA.length >= item.info.length) {
          // 退出递归
          item["hiddenCursor"] = true;
          let tempItem = JSON.parse(JSON.stringify(item));
          self.$set(self.currentDescList, index, tempItem);
          return;
        } else {
          setTimeout(() => {
            // console.log("stringA",stringA)
            stringA += item.info.charAt(stringA.length);
            item["currentDesc"] = stringA + "";
  
            let tempItem = JSON.parse(JSON.stringify(item));
            self.$set(self.currentDescList, index, tempItem);
            // console.log("item",self.currentDescList)
            self.calItem(item, index, stringA);
          }, 50);
        }
      },
      calItem1(defaultDesc) {
        let idx = 0;
        let self = this;
        if (self.currentDescItem["currentDesc"].length >= defaultDesc.length) {
          // 退出递归
          // self.currentDescItem["hiddenCursor"] = true;
          self.$emit("isEnd");
          return;
        } else {
          setTimeout(() => {
            // console.log("this.currentProblemItem",self.currentProblemItem["currentDesc"])
            self.currentDescItem["currentDesc"] += defaultDesc.charAt(
              self.currentDescItem["currentDesc"].length
            );
            self.$emit("isEnd");
            self.calItem1(defaultDesc);
          }, 50);
        }
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  @import url("~@/assets/css/problemDesc.less");
  </style>
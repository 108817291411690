<template>
  <div class="content-detail-box-wrap">
    <div class="detail-header">
      <i class="el-icon-back icon-back"
        @click="closeDetail"></i>
      文章详情
    </div>
    <div class="detail-content">
      <div class="title">{{detailInfo && detailInfo.title || ""}}</div>
      <div class="user-info">
        <img class="avatar"
          v-if="detailInfo && detailInfo.user && detailInfo.user.avatar"
          :src="detailInfo.user.avatar">
        <img class="avatar" v-else
          src="https://files.sssrise.com/files/uploads/static/default_avatar.png">
        <div class="user-name">
          {{detailInfo && detailInfo.user && detailInfo.user.nickname || ""}}
        </div>
        <!-- <img class="bade-img"
          src="https://files.sssrise.com/files/uploads/static/cyhz.png">
        <img class="bade-img"
          src="https://files.sssrise.com/files/uploads/static/zshz.png">
        <img class="bade-img"
          src="https://files.sssrise.com/files/uploads/static/xzhz.png"> -->
      </div>
      <div class="mt-16 content-detail" v-if="detailInfo && detailInfo.content">
        {{detailInfo.content}}
      </div>
      <div
        v-if="detailInfo && detailInfo.messages && detailInfo.messages.length > 0"
        class="problem-wrap-list">
        <div class="problem-wrap mt-16"
          v-for="(item,idx) in detailInfo.messages"
          :key="idx">
          <DetailLeft v-if="item.cat == 1"
            :problemItem="item" :problemIdx="idx">
          </DetailLeft>
          <DetailRight v-if="item.cat == 0"
            :problemItem="item" :problemIdx="idx">
          </DetailRight>
        </div>
      </div>
      
    </div>
    <DetailBottom v-if="!hiddenBottom" :contentDetail="detailInfo" @submitLike="submitLike" @submitFav="submitFav"></DetailBottom>
    <FavCom v-if="showFavPopup" @chooseReason="chooseReason"></FavCom>
  </div>
</template>
<script>
import DetailLeft from "./detailLeft.vue";
import DetailRight from "./detailRight.vue";
import DetailBottom from "./detailBottom.vue";
import {  userFav,userLike } from "@/api/user";
import FavCom from "./fav.vue";
export default {
  props: ["detailInfo","hiddenBottom"],
  components: {
    DetailLeft,
    DetailRight,
    DetailBottom,
    FavCom
  },
  data() {
    return {
      showFavPopup: false
    };
  },
  methods: {
    closeDetail() {
      this.$emit("closeDetail");
    },
    submitLike(val) {
				if(val.type == 1 && !val.cancel) {
					this.showFavPopup = true;
				} else {
					this.postLikeReq(val.type,val.cancel,"");
				}
			},
			postLikeReq(type,cancel,reason = "") {
				let text = (type == 1) ? (cancel ? "取消点赞内容成功" : "点赞内容成功") : (cancel ? "取消点踩内容成功" : "点踩内容成功");
				userLike({
					cancel: cancel,
					id: this.detailInfo.id,
					like: type,
					reason: reason,
					type: 'post'
				}).then((res) => {
          if(res.error == 0) {
            this.$message({
              message: text,
              type: "success"
            });
          } else {
            this.$message({
						  message: res && res.msg || "服务器异常，请稍后重试",
              type: "error"
            });
          }
          this.$emit("getPostInfoEmit",this.detailInfo);
				}).catch((err)=> {
					this.$message({
						message: err && err.msg || "服务器异常，请稍后重试",
						type: "error"
					});
				})
			},
			submitFav(fav) {
				let text = (fav == 1) ? "收藏内容成功" : "取消收藏内容成功";
				userFav({id: this.detailInfo.id,fav:fav,type: "post"}).then((res)=> {
					if(res.error == 0) {
            this.$message({
              message: text,
              type: "success"
            });
          } else {
            this.$message({
						  message: res && res.msg || "服务器异常，请稍后重试",
              type: "error"
            });
          }
					this.$emit("getPostInfoEmit",this.detailInfo);
				}).catch((err)=> {
					this.$message({
						message: err && err.msg || "服务器异常，请稍后重试",
						type: "error"
					});
				})
			},
      chooseReason(reason) {
				this.showFavPopup = false;
				this.postLikeReq(1,false,reason);
			},
  },
};
</script>
<style lang="less" scoped>
.content-detail-box-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  .detail-header {
    padding: 0 30px;
    height: 63px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: bold;
    font-size: 17px;
    color: #1a232f;
    .icon-back {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 30px;
      color: #626a83;
      font-size: 20px;
      cursor: pointer;
      font-weight: 400;
      display: flex;
      align-items: center;
    }
  }
  .detail-content {
    margin: 0 30px;
    background: #ffffff;
    border-radius: 10px;
    padding: 20px 0;
    flex: 1;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    .title {
      padding: 0 16px;
      font-weight: bold;
      font-size: 17px;
      color: #1a232f;
      line-height: 24px;
      text-align: left;
    }
    .user-info {
      margin: 0 27px;
      height: 58px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e7e8ed;
      .avatar {
        width: 24px;
        height: 24px;
        border-radius: 100%;
      }
      .user-name {
        font-weight: 400;
        font-size: 12px;
        color: #1a232f;
        line-height: 17px;
        margin-left: 6px;
      }
      .bade-img {
        width: 20px;
        height: 20px;
        margin-left: 2px;
      }
    }
    .content-detail {
      font-weight: 400;
      font-size: 12px;
      color: #626A83;
      line-height: 16px;
      text-align: justify;
      font-style: normal;
      padding-bottom: 60px;
      padding-left: 30px;
      padding-right: 30px;
    }
    .problem-wrap-list {
      display: flex;
      flex: 1;
      overflow-y: auto;
      flex-direction: column;
      padding-bottom: 60px;
    }
  }
}
</style>
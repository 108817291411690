<template>
    <div class="home-product-box">
        <div class="app-logo">
            <img src="@/assets/images/logo_blue.png">
        </div>
        <!-- <div class="app-desc">KIN AI</div>
        <el-popover placement="bottom" width="280"
          trigger="click">
          <div class="">
            <img src="@/assets/images/wechat_3.jpg">
          </div>
          <div slot="reference" class="app-btn">进入产品</div>
        </el-popover> -->
        
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="less" scoped>
.home-product-box {
    width: 100%;
    overflow-y: auto;
    margin-bottom: 20px;
    padding: 0 30px 0 128px;
    .app-logo  {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        img {
            width: 240px;
            height: 192px;
        }
    }
    .app-desc {
        font-weight: bold;
        font-size: 20px;
        color: #1A232F;
        line-height: 28px;
        text-align: center;
        margin-top: 14px;
    }

    .app-btn {
        width: 140px;
        height: 40px;
        background: #3672FD;
        box-shadow: 0px 2px 4px 0px #D9E5FF;
        border-radius: 27px;
        margin: 0 auto;
        margin-top: 34px;
        cursor: pointer;
        font-weight: bold;
        font-size: 14px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
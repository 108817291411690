<template>
  <div class="problem-box">
    <div class="problem-avatar">
      <img
        src="https://files.sssrise.com/files/uploads/static/ai_avatar.png">
    </div>
    <div class="problem-info">
      <div class="problem-desc">
        <div class="dhk-arrow"><img
            style="width: 20px;height: 16px;"
            src="https://files.sssrise.com/files/uploads/static/dhk_arrow.png">
        </div>
        <!-- v-if="chatItem && chatItem.type != 2 && chatItem.type != 3" -->
        <div class="input-cursor-box"
          v-if="currentTypeItem && currentTypeItem.type != 2 && currentTypeItem.type != 3">
          <div class="dot-container">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
          <div class="input-cursor"
            style="margin-top:-15px;margin-left: 5px;">
          </div>
        </div>
        <div v-else class="position-relative"
          style="padding: 25px 30px;">
          <div v-loading="loading" class="">

          </div>
          <i class="el-icon-picture"
            style="color: #626a83;position: absolute;top: 50%;left:50%;transform: translate(-50%,-50%)"></i>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "probleOne",
  props: ["chatItem","currentTypeItem"],
  data() {
    return {
      loading: true,
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
@import url("~@/assets/css/problemDesc.less");

.dot-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5px;
}

.dot {
  width: 5px;
  height: 5px;
  margin-right: 3px;
  background-color: #333;
  border-radius: 50%;
  position: relative;
  animation: pulse 2s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.dot:nth-child(3) {
  animation-delay: 1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.loading-img {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.problem-desc {
  ::v-deep .el-loading-spinner {
    top: 50%;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 60px;
    width: auto;
    margin-top: 0;
  }
}
</style>
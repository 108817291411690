<template>
	<div class="content-detail-box">
		<div class="problem-avatar">
			<img src="https://files.sssrise.com/files/uploads/static/ai_avatar.png"/>
		</div>
		<div class="problem-info">
			<div class="problem-desc flex-column">
				<div class="strong mb-10" v-if="problemItem && problemItem.msg && problemItem.msg.text">
					<div style="white-space:pre-wrap" class="text-left" v-html="problemItem.msg.text"></div>
				</div>
				<div style="padding: 0 10px;" v-if="problemItem && problemItem.msg && problemItem.msg.image">
					<el-image 
                        style="max-width: 100%;"
                        :src="problemItem.msg.image" 
                        :preview-src-list="[problemItem.msg.image]">
                    </el-image>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "probleOne",
		props: ["problemItem","problemIdx"],
		data() {
			return {
			};
		},
		methods: {
			copyTextToClipboard(text) {
			//   uni.setClipboardData({
			//     data: text,
			//     success: function () {
			//       console.log('复制成功');
			//       // 可以添加用户友好的提示，例如使用uni.showToast提示复制成功
			//       uni.showToast({
			//         title: '复制成功',
			//         icon: 'success',
			//         duration: 2000
			//       });
			//     },
			//     fail: function () {
			//       console.log('复制失败');
			// 	  uni.showToast({
			// 	    title: '复制失败',
			// 	    icon: 'error',
			// 	    duration: 2000
			// 	  });
			//       // 可以添加错误处理或用户友好的提示
			//     }
			//   });
			}
		}
	}
</script>

<style lang="less" scoped>
	@import url("@/assets/css/contentDetail.less");
</style>
<template>
	<div class="problem-box">
		<div class="problem-avatar">
			<img src="https://files.sssrise.com/files/uploads/static/jiqren.png">
		</div>
		<div class="problem-info">
			<div class="problem-desc">
				<div class="dhk-arrow"><img style="width: 20px;height: 16px;" src="https://files.sssrise.com/files/uploads/static/dhk_arrow.png"></div>
				<div class="strong">{{currentTypeItem && currentTypeItem.tip_message}}</div>
				<div v-if="problemItem && problemItem.showGuide" class="guide mt-10 flex align-center font-12 text-default font-weight-bolder line-h-32">
					不知道怎么输入关键词，<span class="text-primary text-underline cursor" @click="goMore">点这里快速上手！</span>
				</div>
			</div>
			<div class="problem-list" v-if="problemItem && problemItem.children">
				<div class="problem-item cursor" :class="[problemItem && problemItem.chooseIdx == idx && 'active']" v-for="(item,idx) in problemItem.children"
					:key="idx" @click="chooseProblem(item,idx)">{{item}}</div>
			</div>
			<div class="more-opt flex align-center justify-end mt-10" v-if="problemItem && problemItem.children && problemItem.children.length > 0">
				<span class="pl-10 pr-10 font-12 line-h-16 text-primary flex align-center cursor" @click="refresh">换一换<img class="refresh" src="https://files.sssrise.com/files/uploads/static/refresh.png"></span>
				<span class="view-border"></span>
				<span class="pl-10 font-12 line-h-16 text-primary flex align-center cursor" @click="goMore">更多<i class="el-icon-arrow-right"></i></span>
			</div>
		</div>
		<promptCom v-if="showPrompt" :chatItem="chatItem" :showPrompt="showPrompt" @sumbitTip="sumbitTip" @closePopup="closePopup"></promptCom>
	</div>
</template>

<script>
	import promptCom from "./prompt.vue";
	export default {
		name: "tipItem",
		props: ["problemItem","problemIdx","chatItem","currentTypeItem"],
		components: {
			promptCom
		},
		data() {
			return {
				showPrompt: false
			};
		},
		created() {
			console.log("problemItem",this.problemItem)
		},
		methods: {
			chooseProblem(item, idx) {
				console.log("chooseProblem",item)
				this.$emit("changeTipIdx",{
					problemIdx: this.problemIdx,
					idx: idx,
					msg: item
				})
			},
			goMore() {
				this.showPrompt = true;
			},
			refresh() {
				this.$emit("refresh")
			},
			sumbitTip(val) {
				this.$emit("sumbitTipFun",val)
			},
			closePopup() {
				this.showPrompt = false;
			}
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/css/problemDesc.less");
</style>
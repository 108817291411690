<template>
    <div class="menu-box" :style="{width: showFull ? '242px' : '72px'}">
        <div class="close-menu cursor" @click="openMenu">
            <img src="@/assets/images/menu.png"><span v-if="showFull" class="menu-text ml-24">收起菜单</span>
        </div>
        <div class="menu-list">
            <div class="menu-item cursor"  @click="showHelpFun">
                <img src="@/assets/images/icon_help.png"><span v-if="showFull" class="menu-text ml-24">帮助</span>
            </div>
            <div class="help-list" v-if="showFull">
                <div class="help-item cursor" @click="chooseHelp(item,idx)" :class="[helpIdx == idx ? 'active' : '']" v-for="(item,idx) in helpList" :key="idx">
                    <img :src="item.imgSrc"><span>{{item.name}}</span>
                </div>
            </div>
            <!-- <div class="menu-item cursor" @click="showRecordFun">
                <img src="@/assets/images/huodong.png"><span v-if="showFull" class="menu-text ml-24">活动记录</span>
            </div> -->
            <!-- <div class="menu-item cursor" @click="showSettingFun">
                <img src="@/assets/images/setting.png"><span v-if="showFull" class="menu-text ml-24">设置</span>
            </div> -->
        </div>
        <AboutCom v-if="showAbout" @closeHelp="closeHelp"></AboutCom>
        <PrivacyCom  v-if="showPrivacy" @closeHelp="closeHelp"></PrivacyCom>
        <RuleCom v-if="showRule" @closeHelp="closeHelp"></RuleCom>
        <FeedbackCom v-if="showFeedback" @closeHelp="closeHelp"></FeedbackCom>
        <RecordCom v-if="showRecord" @closeHelp="closeRecord"></RecordCom>
        <SettingCom v-if="showSetting" @closeHelp="closeRecord"></SettingCom>
    </div>
</template>
<script>
import AboutCom from "@/components/about/index.vue";
import PrivacyCom from "@/components/privacy/index.vue";
import RuleCom from "@/components/rule/index.vue";
import FeedbackCom from "@/components/feedback/index.vue";
import RecordCom from "@/components/record/index.vue";
import SettingCom from "@/components/setting/index.vue";
export default {
    components: {
        AboutCom,
        PrivacyCom,
        RuleCom,
        FeedbackCom,
        RecordCom,
        SettingCom
    },
    data() {
        return {
            showFull: false,
            helpList: [{
                name: "关于我们",
                imgSrc: require("@/assets/images/guanyu.png")
            },{
                name: "用户协议",
                imgSrc: require("@/assets/images/privacy.png")
            },{
                name: "个人信息保护规则",
                imgSrc: require("@/assets/images/icon_message.png")
            },{
                name: "帮助与反馈",
                imgSrc: require("@/assets/images/fankui.png")
            }],
            helpIdx: -1,
            showAbout: false,
            showPrivacy: false,
            showRule: false,
            showFeedback: false,
            showRecord: false,
            showSetting: false
        }
    },
    methods: {
        openMenu() {
            this.showFull = !this.showFull;
            if(!this.showFull) {
                this.closeHelp();
                this.closeRecord();
            }
            this.$emit("showFullLayer",this.showFull)
        },
        showHelpFun() {
            this.showFull = true;
            this.$emit("showFullLayer",this.showFull)
        },
        chooseHelp(item,idx) {
            this.showPrivacy = false;
            this.showAbout = false;
            this.showRule = false;
            this.showFeedback = false;
            this.helpIdx = idx;
            switch(this.helpIdx) {
                case 0:
                    this.showAbout = true;
                    break;
                case 1:
                    this.showPrivacy = true;
                    break;
                case 2:
                    this.showRule = true;
                    break;
                case 3:
                    this.showFeedback = true;
                    break;
            }
        },
        closeHelp() {
            this.showPrivacy = false;
            this.showAbout = false;
            this.showRule = false;
            this.showFeedback = false;
            this.helpIdx = -1;
        },
        closeRecord() {
            this.showRecord = false;
            this.showSetting = false;
        },
        showRecordFun() {
            this.closeHelp();
            this.showSetting = false;
            this.showRecord = true;
        },
        showSettingFun() {
            this.closeHelp();
            this.showRecord = false;
            this.showSetting = true;
        }
    }
}
</script>
<style lang="less" scoped>
.menu-box {
    width: 72px;
    background: #1A232F;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 30px;
    bottom: 20px;
    top: 128px;
    z-index: 1000;
    .menu-text {
        font-weight: 400;
        font-size: 14px;
        color: #E7E8ED;
        line-height: 20px;
    }
    .close-menu {
        display: flex;
        margin-top: 36px;
        padding-left: 24px;
        align-items: center;
        img {
            width: 24px;
            height: 28px;
            
        }
    }
    .menu-list {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 38px;
        .menu-item {
            padding: 12px 0;
            display: flex;
            padding-left: 24px;
            &:first-child {
                margin-top: 0;
            }
            img {
                width: 22px;
                height: 22px;
            }
        }
    }

    .help-list {
        .help-item {
            width: 202px;
            height: 44px;
            display: flex;
            align-items: center;
            margin: 0 auto;
            img {
                width: 22px;
                height: 22px;
                margin-left: 28px;
            }
            span {
                font-weight: 400;
                font-size: 14px;
                color: #979DB1;
                margin-left: 12px;
            }
            &.active {
               
                background: #3672FD;
                border-radius: 10px;
                span {
                    color: #E7E8ED;
                }
            }
        }
    }
}
</style>
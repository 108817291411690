<template>
  <div class="flex flex-column about-box">
    <i class="el-icon-circle-close icon-defalut-close cursor"
      @click="close"></i>
    <div class="default-title">帮助与反馈</div>
    <div class="default-content mt-20">
      <el-collapse v-model="activeNames"
        @change="handleChange">
        <el-collapse-item v-for="(item,idx) in itemList"
          :key="idx" :title="item.head" :name="idx">
          <div>{{item.body}}</div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="feedback-bottom flex align-center">
        <div class="flex flex-1  justify-end">
            <div class="default-btn cursor mr-20" @click="goFeedback">报告问题</div>
        </div>
        
    </div>
    <FeedbackProblem v-if="showProblem" @closeHelp="closeHelp"></FeedbackProblem>
  </div>
</template>
    
<script>
import FeedbackProblem from "./feedback.vue";
export default {
    components: {
        FeedbackProblem
    },
  data() {
    return {
        showProblem: false,
        activeNames: ['0'],
      itemList: [
        {
          head: "育儿助手怎么用",
          body: "人工智能（AI）是致力于解决通常与人类智能相关联的认知性问题的计算机科学领域，这些问题包括学习、创造和图像识别等。人工智能有多种定义，但其中一个比较通用的定义是：人工智能是使计算机能够像人类一样思考和行动的科学。人工智能的核心技术包括：机器学习：机器学习是使计算机能够从数据中学习而不进行显式编程的技术。自然语言处理：自然语言处理是使计算机能够理解和处理人类语言的技术。",
          open: false,
        },
        {
          head: "登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助",
          body: "人工智能（AI）是致力于解决通常与人类智能相关联的认知性问题的计算机科学领域，这些问题包括学习、创造和图像识别等。人工智能有多种定义，但其中一个比较通用的定义是：人工智能是使计算机能够像人类一样思考和行动的科学。人工智能的核心技术包括：机器学习：机器学习是使计算机能够从数据中学习而不进行显式编程的技术。自然语言处理：自然语言处理是使计算机能够理解和处理人类语言的技术。",
          open: false,
        },
        {
          head: "管理和删除应用活动记录",
          body: "人工智能（AI）是致力于解决通常与人类智能相关联的认知性问题的计算机科学领域，这些问题包括学习、创造和图像识别等。人工智能有多种定义，但其中一个比较通用的定义是：人工智能是使计算机能够像人类一样思考和行动的科学。人工智能的核心技术包括：机器学习：机器学习是使计算机能够从数据中学习而不进行显式编程的技术。自然语言处理：自然语言处理是使计算机能够理解和处理人类语言的技术。",
          open: false,
        },
        {
          head: "管理和删除应用活动记录",
          body: "人工智能（AI）是致力于解决通常与人类智能相关联的认知性问题的计算机科学领域，这些问题包括学习、创造和图像识别等。人工智能有多种定义，但其中一个比较通用的定义是：人工智能是使计算机能够像人类一样思考和行动的科学。人工智能的核心技术包括：机器学习：机器学习是使计算机能够从数据中学习而不进行显式编程的技术。自然语言处理：自然语言处理是使计算机能够理解和处理人类语言的技术。",
          open: false,
        },
        {
          head: "管理和删除应用活动记录",
          body: "人工智能（AI）是致力于解决通常与人类智能相关联的认知性问题的计算机科学领域，这些问题包括学习、创造和图像识别等。人工智能有多种定义，但其中一个比较通用的定义是：人工智能是使计算机能够像人类一样思考和行动的科学。人工智能的核心技术包括：机器学习：机器学习是使计算机能够从数据中学习而不进行显式编程的技术。自然语言处理：自然语言处理是使计算机能够理解和处理人类语言的技术。",
          open: false,
        },
        {
          head: "管理和删除应用活动记录",
          body: "人工智能（AI）是致力于解决通常与人类智能相关联的认知性问题的计算机科学领域，这些问题包括学习、创造和图像识别等。人工智能有多种定义，但其中一个比较通用的定义是：人工智能是使计算机能够像人类一样思考和行动的科学。人工智能的核心技术包括：机器学习：机器学习是使计算机能够从数据中学习而不进行显式编程的技术。自然语言处理：自然语言处理是使计算机能够理解和处理人类语言的技术。",
          open: false,
        },
        {
          head: "管理和删除应用活动记录",
          body: "人工智能（AI）是致力于解决通常与人类智能相关联的认知性问题的计算机科学领域，这些问题包括学习、创造和图像识别等。人工智能有多种定义，但其中一个比较通用的定义是：人工智能是使计算机能够像人类一样思考和行动的科学。人工智能的核心技术包括：机器学习：机器学习是使计算机能够从数据中学习而不进行显式编程的技术。自然语言处理：自然语言处理是使计算机能够理解和处理人类语言的技术。",
          open: false,
        },
        {
          head: "管理和删除应用活动记录",
          body: "人工智能（AI）是致力于解决通常与人类智能相关联的认知性问题的计算机科学领域，这些问题包括学习、创造和图像识别等。人工智能有多种定义，但其中一个比较通用的定义是：人工智能是使计算机能够像人类一样思考和行动的科学。人工智能的核心技术包括：机器学习：机器学习是使计算机能够从数据中学习而不进行显式编程的技术。自然语言处理：自然语言处理是使计算机能够理解和处理人类语言的技术。",
          open: false,
        },
        {
          head: "如何注销",
          body: "人工智能（AI）是致力于解决通常与人类智能相关联的认知性问题的计算机科学领域，这些问题包括学习、创造和图像识别等。人工智能有多种定义，但其中一个比较通用的定义是：人工智能是使计算机能够像人类一样思考和行动的科学。人工智能的核心技术包括：机器学习：机器学习是使计算机能够从数据中学习而不进行显式编程的技术。自然语言处理：自然语言处理是使计算机能够理解和处理人类语言的技术。",
          open: false,
        },
      ],
    };
  },
  methods: {
    close() {
      this.$emit("closeHelp");
    },
    handleChange() {},
    goFeedback() {
        this.showProblem = true;
    },
    closeHelp() {
        this.showProblem = false;
    }
  },
};
</script>
<style lang="less" scoped>
.about-box {
  width: 380px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px #e7e8ed;
  border-radius: 20px;
  border: 1px solid #e7e8ed;
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  margin-left: 10px;
  display: flex;
  
  .default-content {
    flex: 1;
    overflow-y: auto;
    text-align: left;
    padding-bottom: 70px;
    ::v-deep .el-collapse {
        border-top: none;
        border-bottom: none;
        width: 352px;
        margin: 0 auto;
        .el-collapse-item {
            width: 100%;
            background: #F8F8FA;
            border-radius: 10px;
            margin-bottom: 10px;
            position: relative;
            .el-collapse-item__header {
                background: #F8F8FA;
                border-radius: 10px;
                border-bottom: none;
                padding-left: 16px;
                font-weight: 400;
                font-size: 14px;
                color: #1A232F;
                text-align: left;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 320px;
                display: inline-block;
                .el-collapse-item__arrow {
                    position: absolute;
                    top: 20px;
                    right: 0;
                    display: flex;
                    align-items: center;
                }
            }
            .el-collapse-item__wrap {
                border-bottom: none;
                background: #F8F8FA;
                border-radius: 10px;
                font-weight: 400;
                font-size: 12px;
                color: #626A83;
                line-height: 17px;
                padding: 0 16px 16px 16px;
            }
            .el-collapse-item__content {
                padding-bottom: 0;
            }
        }
    }
  }
 
}
</style>
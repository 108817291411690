<template>
  <div class="container">
    <div class="content-box">
      <div class="left-box">
        <ChatHistory :statusLoadmore="statusLoadmore" :historyList="historyList" :currentIdx="currentIdx"
          @delHistory="delHistory" @getChatMessage="getChatMessage" @goCreate="goCreate" @loadMoreData="loadMoreData">
        </ChatHistory>
      </div>

      <div class="center-box" ref="mainScroll" @scroll="checkVisibility" v-if="showAiBuildRefBox">
        <div class="ai-build-box">
          <div class="normal-header">
            <div class="title">爆款打造专家

            </div>
            <div class="opt" @click="openHideAiBuild()">
              <i class="el-icon-top" v-if="showAiBuildTop"></i>
              <i class="el-icon-bottom" v-if="!showAiBuildTop"></i>
            </div>
          </div>
          <AiBuild v-if="showAiBox" @loadingFinish="loadingFinish" @clearSelect="clearSelect"
            @loadingStatus="loadingStatus"></AiBuild>
        </div>
        <div class="data-reference mt-20" v-if="showDataRefBox && !isLoading">
          <div class="normal-header">
            <div class="title">数据参考</div>
            <div class="opt" @click="openHideDataRef()">
              <i class="el-icon-top" v-if="showDataRefTop"></i>
              <i class="el-icon-bottom" v-if="!showDataRefTop"></i>
            </div>
          </div>
          <DataRef v-if="showDataRef" :priceList="priceList" :priceDefaultList="priceDefaultList"
            :probabilityList="probabilityList" :ipName="ipName" :ipId="ipId" :keywordAvgSales="keywordAvgSales"
            :keywordNoIpSale="keywordNoIpSale" :keywordIpSale="keywordIpSale" :topIpSale="topIpSale"></DataRef>
        </div>
        <div class="flex strategy-box mb-20 mt-20 position-relative" ref="shipeiBox" v-if="showShipeiBox && !isLoading && ipId">
          <div class="normal-header">
            <div class="title">IP与玩具品类适配度评分</div>
            <div class="opt" @click="openHideShipei()">
              <i class="el-icon-top" v-if="showShipeiTop"></i>
              <i class="el-icon-bottom" v-if="!showShipeiTop"></i>
            </div>
          </div>
          <div class="center-default-box center-default-box1" id="mainShipeiScroll" v-show="showShipeiBox">
            <div class="problem-wrap" id="lastWrap" v-for="(problemItem,problemIdx) in shiPeiList"
              :key="problemItem.id + problemIdx">
              <shiPei v-if="problemItem.type == 1 || problemItem.cat == 1" :problemItem="problemItem"
                :problemIdx="problemIdx" :streamStatus="streamStatus" @isEnd="isEnd" >
              </shiPei>
            </div>
            <div class="problem-wrap" v-if="calShipeiAnswer()">
              <ProblemInput :chatItem="chatItem" :currentTypeItem="currentTypeItem">
              </ProblemInput>
            </div>
          </div>



        </div>
        <div class="flex strategy-box mb-20 mt-20 position-relative" ref="strategyBox"
          v-if="showStrategyBox && !isLoading">
          <div class="normal-header">
            <div class="title">爆款策略详情</div>
            <div class="opt" @click="openHideStrategy()">
              <i class="el-icon-top" v-if="showStrategyTop"></i>
              <i class="el-icon-bottom" v-if="!showStrategyTop"></i>
            </div>
          </div>
          <div class="center-default-box" id="mainScroll" v-show="showCenterBox">
            <!-- <div v-show="!isLoading" class="problem-wrap" v-for="(item,idx) in problemDefalutList1" :key="idx">
              <ProblemOne v-if="item.type == 1" :problemItem="item" :problemIdx="idx" @changeIdx="changeDefaultIdx">
              </ProblemOne>
              <ProblemTwo v-if="item.type == 2" :problemItem="item" :problemIdx="idx" @changeIdx="changeDefaultIdx">
              </ProblemTwo>
            </div> -->
            <!-- <div v-if="chatItem && problemList.length == 0">
              <div class="problem-wrap" v-for="(item,idx) in problemTips" :key="idx">
                <TipItem :problemItem="item" :problemIdx="idx" @changeTipIdx="changeTipIdx" @refresh="refresh"
                  :chatItem="chatItem" @sumbitTipFun="sumbitTipFun" :currentTypeItem="currentTypeItem">
                </TipItem>
              </div>
            </div> -->

            <DefaultTextCom :defaultText="defalutText" v-if="isFirst && ipId" @analysisDetailBack="analysisDetailBack"></DefaultTextCom>
            <div class="problem-wrap" v-show="!isFirst" id="lastWrap" v-for="(problemItem,problemIdx) in problemList"
              :key="problemItem.id + problemIdx">
              <ProblemOne v-if="problemItem.type == 2" :problemItem="problemItem" :problemIdx="problemIdx">
              </ProblemOne>
              <AnswerCom v-if="problemItem.type == 1 || problemItem.cat == 1" :problemItem="problemItem"
                :problemIdx="problemIdx" :streamStatus="streamStatus" @isEnd="isEnd">
              </AnswerCom>
              <ProblemThree v-if="problemItem.type == 3 || problemItem.cat == 0" :problemItem="problemItem"
                :problemIdx="problemIdx" :userInfo="userInfo" :streamStatus="streamStatus">
              </ProblemThree>
            </div>
            <div class="problem-wrap" v-if="isSendMessage && !isFirst">
              <ProblemInput :chatItem="chatItem" :currentTypeItem="currentTypeItem">
              </ProblemInput>
            </div>
          </div>
          <div class="bottom-fixed" v-if="showStrategyTop && !isFirst">
            <AskCom @closePopup="closePopup" :chatItem="chatItem" :currentTypeItem="currentTypeItem"
              @hideContentEditFun="hideContentEditFun" @chooseModel="chooseModel" :modelsList="modelsList"
              :modelIdx="modelIdx">
            </AskCom>
          </div>
        </div>

      </div>
      <div class="right-box">

      </div>
    </div>
  </div>
</template>
<script>
  import ChatHistory from "./components/chatHistory.vue";
  import ProblemOne from "./components/problemOne.vue";
  import ProblemTwo from "./components/problemTwo.vue";
  import AskCom from "./components/ask.vue";
  import TipItem from "@/components/home/tipItem.vue";
  import ProblemInput from "@/components/home/problemInput.vue";
  import AnswerCom from "./components/answer.vue";
  import ProblemThree from "./components/problemThree.vue";
  import AiBuild from "./components/aiBuild.vue";
  import DataRef from "./components/dataRef.vue";
  import ShiPei from "./components/shiPei.vue";
  import DefaultTextCom from "./components/defaultText.vue";
  import {
    getChatModels,
    createChat,
    closeChat,
    getChatTipMessages,
    getChatList,
    deleteChat,
    getChatMessages,
  } from "@/api/chat";
  import { getDefaultConfig, userUpdateProfile } from "@/api/user";
  import util from "@/utils/util";
  import { getToken } from "@/utils/auth";
  import { v4 as uuid } from "uuid";
  import * as protobuf from "protobufjs";
  import { pb } from "@/utils/pb/chat_es6.js";
  import { MessageBox } from "element-ui";
  import { EventBus } from '@/utils/eventBus.js';
  export default {
    components: {
      ChatHistory,
      ProblemOne,
      ProblemTwo,
      AskCom,
      TipItem,
      ProblemInput,
      AnswerCom,
      ProblemThree,
      AiBuild,
      DataRef,
      ShiPei,
      DefaultTextCom
    },
    data() {
      return {
        showStationList: false,
        showPay: false,
        payInfo: null,
        showLogin: false,
        isLoading: false,
        problemDefalutList1: [
          // {
          //   type: 1,
          //   name: "您好，我是玩具爆款打造专家，打造爆款玩具需要综合考虑多个因素，每个环节都非常重要。<br>我会从创新设计建议、品牌塑造、用户体验、市场推广、定价策略、持续迭代等层面帮您做分析<br>我将利用这些专业知识和策略，帮助您打造出市场上真正的玩具爆款。",
          //   desc: "",
          //   status: 1,
          //   chooseIdx: -1,
          // },
        ],
        chatItem: null,
        problemTips: [
          {
            type: 1,
            name: "",
            desc: "",
            status: 1,
            chooseIdx: -1,
            showMore: true,
            showGuide: true,
            children: [],
          },
        ],
        problemList: [],
        shiPeiList: [],
        showReward: false,
        showLayer: false,
        defaultConfig: null,
        userInfo: {
          id: "",
          nickname: "",
          mobile: "",
          avatar: "",
          gender: "",
          desc: "",
          real_name: "",
          character: "",
          vip_level: 0,
          vip_expires_in: "",
          coin: 0,
          income: 0,
          balance: 0,
          freeze: 0,
          token: "",
        },
        socketTask: null,
        modelsList: [],
        streamStatus: -1,
        tempItem: null,
        showModal: false,
        currentTypeItem: null,
        modelChooseIdx: "",
        isGetMessage: false,
        connectInter: null,
        showPayModal: false,
        messageList: [],
        currentChatMessageItem: null,
        messageParams: {
          offset: 0,
          pageSize: 0,
          limit: 20,
        },
        params: {
          offset: 0,
          limit: 100,
        },
        tempProblemList: [],
        showAsk: false,
        showCyAsk: false,
        historyList: [],
        currentIdx: -1,
        historyParams: {
          offset: 0,
          pageSize: 0,
          limit: 20,
        },
        delItem: null,
        timer: null,
        isCanClick: true,
        messages: [],
        currentMessage: null,
        isOpen: false,
        isSend: false,
        isClose: false,
        currentChatId: null,
        statusLoadmore: "loadmore",
        messageParams: {
          offset: 0,
          pageSize: 0,
          limit: 100,
        },
        orderInfo: null,
        showAiBuildRefBox: true,
        showAiBuildTop: true,
        showDataRefTop: true,
        showAiBox: true,
        showDataRef: true,
        showDataRefBox: false,
        showStrategyTop: true,
        showStrategyBox: false,
        showCenterBox: true,
        showShipeiBox: false,
        showShipeiTop: true,
        // showAiBuildTop: true,
        // showDataRefTop: true,
        // showAiBox: true,
        // showDataRef: true,
        // showDataRefBox: true,
        // showStrategyTop: true,
        // showStrategyBox: true,
        // showCenterBox: true,
        priceList: [],
        priceDefaultList: [],
        probabilityList: [],
        isRefresh: false,
        keywordIds: [],
        ipName: "",
        ipId: "",
        keywordAvgSales: null,
        keywordNoIpSale: null,
        keywordIpSale: null,
        modelsList: [],
        modelIdx: 0,
        categoryName: "",
        currentKeywordId: null,
        currentChatItem: null,
        price: "",
        topIpSale: [],
        msgText: "",
        isFirst: false,
        defalutText: "",
        isSendMessage: false
      };
    },
    created() {
      console.log("log", Math.log10(25) / Math.log10(5));

      this.historyParams.offset = 0;
      this.historyParams.pageSize = 0;
      this.isRefresh = false;
      util.onLogin().then((res) => {
        if (!res) {
          this.$bus.$emit("openLogin");
        } else {
          // this.getDefaultConfigReq();
          this.getUserInfo();
          this.getChatListReq(true);
        }
      });

      EventBus.$off("initDefaultData");
      EventBus.$on("initDefaultData", () => {
        this.historyParams = {
          offset: 0,
          pageSize: 0,
          limit: 20,
        }
        // this.getDefaultConfigReq();
        this.getUserInfo();
        console.log("init")
        this.getChatListReq(true);
      });

      this.$bus.$off("paySuccess");
      this.$bus.$on("paySuccess", () => {
        this.showStationList = false;
        this.getUserInfo(true);
      });
    },
    mounted() {
      window.addEventListener("scroll", this.checkVisibility);
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.checkVisibility);
      if (this.chatItem && this.chatItem.id) {
        closeChat({ id: this.chatItem.id }).then((res) => { });
      }
      this.socketTask && this.socketTask.close();
      this.timer && clearInterval(this.timer);
      this.connectInter && clearInterval(this.connectInter);
    },
    methods: {
      loadingStatus(val) {
        this.isLoading = val;
      },
      checkVisibility() {
        this.$nextTick(() => {
          let viewportRect = this.$refs.mainScroll.getBoundingClientRect();
          let element = this.$refs.strategyBox;
          // console.log("element", element);
          if (element) {
            let elementRect = element.getBoundingClientRect();
            // console.log(" elementRect.bottom", elementRect.bottom,"viewportRect.top",(viewportRect.top - 350))
            // console.log(" elementRect.bottom", elementRect.top,"viewportRect.top",(viewportRect.bottom - 350))
            let isTotallyAbove = elementRect.bottom < (viewportRect.top - 350);
            let isTotalBelow = elementRect.top > (viewportRect.bottom - 350);
            let isInViewport = !isTotalBelow && !isTotallyAbove;
            // console.log("isInViewport", isInViewport);
            // if (isInViewport) {
            //   this.showAiBuildRefBox = true;
            //   this.showAiBox = false;

            //   this.showDataRefBox = true;
            //   this.showDataRef = false;
            // }
          }
        });
      },
      openHideAiBuild() {
        this.showAiBuildTop = !this.showAiBuildTop;
        this.showAiBox = !this.showAiBox;
      },
      openHideDataRef() {
        this.showDataRefTop = !this.showDataRefTop;
        this.showDataRef = !this.showDataRef;
      },
      openHideStrategy() {
        this.showStrategyTop = !this.showStrategyTop;
        this.showCenterBox = !this.showCenterBox;
      },
      openHideShipei() {
        this.showShipeiTop = !this.showShipeiTop;
        this.showShipeiBox = !this.showShipeiBox;
      },
      clearSelect() { },
      loadingFinish(val, isMessage = false) {
        let res = val.data || null;
        this.ipId = val.ipId || null;
        this.ipName = val.ipName || null;
        this.categoryName = val.categoryName || null;
        this.currentKeywordId = val.keywordId || null;
        this.price = val.price || 0;
        console.log("res", res);
        this.priceList = [];
        this.priceDefaultList = [];
        if (res.distribution && res.distribution.length > 0) {
          for (let i = 0, j = res.distribution.length; i < j; i++) {
            let tempItem = res.distribution[i];
            let priceItem = [
              Math.log10(tempItem.unit_price) / Math.log10(5),
              tempItem.sales || 0,
            ];
            let priceDefalutItem = [
              tempItem.unit_price || 0,
              tempItem.sales || 0,
            ];
            // this.priceDefaultList.push(priceDefalutItem);
            this.priceList.push(priceItem);
          }
        } else {
          this.priceList = [];
          this.priceDefaultList = [];
        }
        this.probabilityList = [];
        if (res.probability) {
          this.probabilityList.push(res.probability.p1 || res.probability.P1 || 0);
          this.probabilityList.push(res.probability.p2 || res.probability.P2 || 0);
          this.probabilityList.push(res.probability.p3 || res.probability.P3 || 0);
          this.probabilityList.push(res.probability.p4 || res.probability.P4 || 0);
          this.probabilityList.push(res.probability.p5 || res.probability.P5 || 0);
          this.probabilityList.push(res.probability.p6 || res.probability.P6 || 0);
        } else {
          this.probabilityList = [];
        }

        this.topIpSale = res.keyword_top_ip_sales || [];

        this.keywordAvgSales = res.keyword_avg_sale || null;
        this.keywordNoIpSale = res.keyword_no_ip_sale || null;
        this.keywordIpSale = res.keyword_ip_sale || null;
        console.log("keywordAvgSales", this.keywordAvgSales);
        this.showDataRefBox = true;
        this.showDataRef = true;
        this.showDataRefTop = true;
        this.showStrategyBox = true;
        this.showStrategyTop = true;
        this.showCenterBox = true;

        if (!this.isRefresh) {
          this.isRefresh = true;
        } else {
          this.$bus.$emit("refreshData");
        }


        this.msgText = "";
        this.defalutText = "";
        let spMsgText = "";
        this.isFirst = val.isFirst || false;
        // console.log("problemList===",this.problemList)
        // if(this.problemList && this.problemList.length > 0) {
        //   let tempProList = JSON.parse(JSON.stringify(this.problemList));
        //   this.problemList = [tempProList[0]];
        // }
          
        // console.log("problemList",this.problemList)
        this.problemList = [];
        this.currentTypeItem = this.modelsList[0];
        this.modelIdx = 0;
        console.log("currentTypeItem---", this.currentTypeItem);
        let msgLeftItem = {
          type: 2,
          desc: this.currentTypeItem.tip_message,
          imageSrc: [],
          status: 0,
          chooseIdx: 0,
          showMore: false,
          showBottom: true,
          dataTime: new Date().getTime(),
          id: "",
          chatId: "",
          isMessage: true,
        };
        this.problemList.push(msgLeftItem);
        if (this.ipId && this.ipName && this.currentKeywordId && this.categoryName) {
          this.msgText = "我要设计一款" + this.categoryName + "，带有" + this.ipName + "的IP，零售价是" + this.price + "人民币。";
          spMsgText = "我拥有" + this.ipName + "的IP，我准备做" + this.categoryName + "，请帮我分析下这个IP和这个玩具品类的适配度应该是多少分，满分100分的话";
          this.defalutText = '我已经了解您要准备要做一款'+ this.categoryName +'，带有'+ this.ipName + '的IP，零售价是'+this.price+'人民币，点击"详细分析”按钮来获取具体信息';
        } else if (this.currentKeywordId && this.categoryName) {
          this.msgText = "我要设计一款" + this.categoryName + "，无IP，零售价是" + this.price + "人民币。"
        } else {
          this.msgText = this.categoryName
        }
        if (!isMessage) {
          if (this.chatItem && this.chatItem.id) {
            if (val.isFirst && this.ipId) {
              this.shiPeiList = [];
              this.sendMessage(spMsgText, "", "", "", true);
              this.showShipeiBox = true;
            } else {
              this.sendMessage(this.msgText, "", "", "", true);
            }


          } else {
            if (val.isFirst && this.ipId) {
              this.shiPeiList = [];
              this.initChat(spMsgText, true, true);
              this.showShipeiBox = true;
            } else {
              this.initChat(this.msgText, true, true);
            }

          }
        }
      },
      getChatMessage(val) {
        let item = val.item || null;
        this.currentIdx = val.idx;
        console.log("currentIdx", this.currentIdx);
        if (item && item.id) {
          this.isGetMessage = true;
          this.messageParams["offset"] = 0;
          this.messageParams["id"] = item.id;
          this.currentChatMessageItem = item;
          this.messageList = [];
          this.problemList = [];
          this.chatItem = item;

          this.getChatMessagesReq();
        }
      },
      getChatMessagesReq() {
        getChatMessages({
          id: this.messageParams.id,
          offset: this.messageParams.offset,
          limit: this.messageParams.limit,
        })
          .then((res) => {
            console.log("getChatMessages-res", res);
            if (res && res.data && res.data.list && res.data.list.length > 0) {
              let list = res.data.list;
              list = list.reverse();
              list.forEach((messageItem) => {
                const isExist = this.messageList.find(
                  (item) => item.message_id === messageItem.message_id
                );
                if (isExist) {
                  return;
                } else {
                  this.messageList.push(messageItem);
                }
              });
              // this.problemList = this.messageList;
              for (let i = 0, j = list.length; i < j; i++) {
                let tempItem = list[i];
                if (tempItem.cat == 0) {
                  let msgItem = {
                    type: 3,
                    desc: (tempItem.msg && tempItem.msg.text) || "",
                    imageSrc: (tempItem.msg && tempItem.msg.image) || "",
                    status: 1,
                    chooseIdx: 0,
                    showMore: false,
                    showBottom: false,
                    dataTime:
                      tempItem.created_at &&
                      new Date(tempItem.created_at).getTime(),
                    id: tempItem.message_id,
                    chatId: this.chatItem.id,
                  };
                  this.problemList.push(msgItem);
                } else {
                  let msgLeftItem = {
                    type: 1,
                    desc: [],
                    imageSrc: [],
                    status: 0,
                    chooseIdx: 0,
                    showMore: false,
                    showBottom: true,
                    dataTime:
                      tempItem.created_at &&
                      new Date(tempItem.created_at).getTime(),
                    id: tempItem.message_id,
                    chatId: this.chatItem.id,
                    isMessage: true,
                  };

                  console.log("tempItem", tempItem);
                  if (
                    (tempItem.msg && tempItem.msg.text) ||
                    (tempItem.msg && tempItem.msg.image)
                  ) {
                    if (tempItem.msg && tempItem.msg.text) {
                      let tempText = {
                        info: tempItem.msg.text,
                        descId: uuid(),
                      };
                      msgLeftItem.desc.push(tempText);
                    }
                    if (tempItem.msg && tempItem.msg.image) {
                      msgLeftItem.imageSrc.push(tempItem.msg.image);
                    }
                    this.problemList.push(msgLeftItem);
                  }
                }
              }
            }
            this.initModel(this.chatItem && this.chatItem.type);
            let isExist = this.modelsList.filter((res) => {
              return (
                res.type == this.currentChatMessageItem.type &&
                res.model == this.currentChatMessageItem.model
              );
            });
            console.log("isExist", isExist);
            if (isExist && isExist.length > 0 && this.problemList.length == 0) {
              this.modelChooseIdx = isExist[0].id;
              let idx = this.modelsList.findIndex((res) => {
                return res.id == this.modelChooseIdx;
              });

              this.modelIdx = Number(idx);
              this.currentTypeItem = this.modelsList[idx];
              console.log("idx", idx, "currentTypeItem", this.currentTypeItem);

              // if (
              //   this.chatItem &&
              //   (this.chatItem.type == 0 || this.chatItem.type == 1)
              // ) {
              //   this.getChatTipMessagesReq();
              // }
              if (this.chatItem && this.chatItem.id) {
                this.$bus.$emit("initDefault", {
                  desc: "",
                  hash: "",
                  imageSrc: "",
                });
              }
            }
            this.modelIdx = 0;
            this.currentTypeItem = this.modelsList[0];
            let msgLeftItem = {
              type: 2,
              desc: this.currentTypeItem.tip_message,
              imageSrc: [],
              status: 0,
              chooseIdx: 0,
              showMore: false,
              showBottom: true,
              dataTime: new Date().getTime(),
              id: "",
              chatId: "",
              isMessage: true,
            };
            this.problemList.push(msgLeftItem);
            // this.showDataRefBox = true;
            // this.showDataRef = true;
            // this.showDataRefTop = true;
            this.showStrategyBox = true;
            this.showStrategyTop = true;
            this.showCenterBox = true;

            this.toBottom();
          })
          .catch((err) => {
            this.$message({
              message: (err && err.msg) || "服务器异常，请稍后重试",
              type: "error",
            });
          });
      },
      isEnd() {
        // this.toBottom();
      },
      closePopup() {
        this.showCyAsk = false;
      },
      hideContentEditFun(param) {
        
        if (this.streamStatus >= 0 && this.streamStatus != 4) {
          this.$message({
            message: "您已经提问，请等待回复!",
            type: "info",
          });
          return;
        }
        this.isFirst = false;
        this.sendMessage(param.text, param.image, param.imageSrc);
        this.toBottom();
      },
      analysisDetailBack() {
        this.isFirst = false;
        this.sendMessage(this.msgText, "", "");
        this.toBottom();
      },
      calAnswer() {
        const isExist = this.problemList.filter((item) => {
          return item.type == 1;
        });
        const isExist1 = this.problemList.filter((item) => {
          return item.type == 3;
        });
        if (isExist && isExist.length > 0) {
          return false;
        } else if (isExist1 && isExist1.length > 0 && !this.isGetMessage && !this.isFirst) {
          return true;
        }
      },
      calShipeiAnswer() {
        const isExist = this.shiPeiList.filter((item) => {
          return item.type == 1;
        });
        if (isExist && isExist.length > 0) {
          return false;
        } else {
          return true;
        }
      },
      loadMoreData() {
        this.statusLoadmore = "loading";
        this.historyParams.pageSize += 1;
        this.historyParams.offset =
          (this.historyParams.pageSize + 1) * this.historyParams.limit;
        console.log("more");
        this.getChatListReq();
      },
      getChatListReq(isRefresh = false) {
        if(isRefresh) {
          this.historyList = [];
        }
        getChatList(this.historyParams)
          .then((res) => {
            if (res && res.error == 0) {
              if (res && res.data && res.data.list) {
                res.data.list.forEach((billItem) => {
                  const isExist = this.historyList.find(
                    (item) => item.id === billItem.id
                  );
                  if (isExist) {
                    return;
                  } else {
                    this.historyList.push(billItem);
                  }
                });
                if (res && res.data && res.data.has_more) {
                  this.statusLoadmore = "loadmore";
                } else {
                  this.statusLoadmore = "nomore";
                }
              } else {
                this.statusLoadmore = "loadmore";
              }
            }
          })
          .catch(() => {
            this.statusLoadmore = "loadmore";
          });
      },
      delHistory(val) {
        deleteChat({ id: val.item.id })
          .then((res) => {
            if (res && res.error == 0) {
              this.$message({
                message: "删除消息成功",
                type: "success",
              });
              this.historyList.splice(val.idx, 1);
              this.currentIdx = -1;
              this.getChatListReq();
            } else {
              this.$message({
                message: (res && res.msg) || "服务器异常，请稍后重试",
                type: "error",
              });
            }
          })
          .catch((err) => {
            this.$message({
              message: (err && err.msg) || "服务器异常，请稍后重试",
              type: "error",
            });
          });
      },
      goCreate() {
        this.showAiBox = true;
        this.showAiBuildTop = true;
        this.showDataRefBox = false;
        this.showStrategyBox = false;
        if (!this.currentTypeItem) {
          this.$message({
            message: "请选择模型",
            type: "error",
          });
          return;
        }
        if (this.modelsList && this.modelsList.length > 0) {
          this.currentTypeItem = this.modelsList[0];
        }
        this.initChat("", false);
        this.toBottom();
      },
      showFullLayer(val) {
        this.showLayer = val;
      },
      sumbitTipFun(val) {
        if (val) {
          if (this.streamStatus >= 0 && this.streamStatus != 4) {
            this.$message({
              message: "您已经提问，请等待回复!",
              type: "warning",
            });
            return;
          }
          this.problemDefalutList1[1]["chooseIdx"] = -1;
          if (this.chatItem && this.chatItem.id) {
            this.sendMessage(val);
          }
          this.toBottom();
        }
      },
      refresh() {
        this.getChatTipMessagesReq();
      },
      changeTipIdx(val) {
        console.log(
          "changeTipIdx",
          val,
          "streamStatus",
          this.streamStatus,
          "chatItem",
          this.chatItem
        );
        if (this.streamStatus >= 0 && this.streamStatus != 4) {
          this.$message({
            message: "您已经提问，请等待回复!",
            type: "warning",
          });
          return;
        }
        this.problemTips[val.problemIdx]["chooseIdx"] = val.idx;
        if (this.chatItem && this.chatItem.id) {
          this.sendMessage(val.msg);
        }

        this.toBottom();
      },
      getDefaultConfigReq() {
        getDefaultConfig()
          .then((res) => {
            if (res && res.error == 0) {
              this.defaultConfig = res.data || null;
            }
          })
          .catch(() => { });
      },
      getUserInfo(isRefresh = false) {
        this.$store
          .dispatch("GetInfo", "")
          .then((res) => {
            this.userInfo = res;
            console.log("isRefresh", isRefresh);
            if (!isRefresh) {
              this.initChatModal();
              this.connectSocket();
            }
          })
          .catch((err) => { });
      },
      //连接websocket
      connectSocket() {
        let that = this;
        console.log("调用连接websocket", process.env.NODE_ENV);
        let authorization = `Bearer ${getToken()}`;
        let wsUrl = (process.env.NODE_ENV == "development" ? 'wss://dev.sssrise.com/ai/ws?user_id=' + (that.userInfo && that.userInfo.id) +
          '&dev_id=asdf&app_id=1&authorization=' + authorization : 'wss://api.sssrise.com/ai/ws?user_id=' + (that.userInfo && that.userInfo.id) +
          '&dev_id=asdf&app_id=1&authorization=' + authorization);
        // that.socketTask = new WebSocket(
        //   "wss://dev.sssrise.com/ai/ws?user_id=" +
        //     (that.userInfo && that.userInfo.id) +
        //     "&dev_id=asdf&app_id=1&authorization=" +
        //     authorization
        // );
        that.socketTask = new WebSocket(
          wsUrl
        );
        that.socketTask.binaryType = "arraybuffer"; // 设置接收二进制数据
        that.socketTask.onopen = () => {
          console.log("WebSocket connected");
          that.isOpen = true;
          that.isClose = false;
          that.heart();
        };
        that.socketTask.onmessage = (res) => {
          // console.log("收到服务器内容：" + res.data);
          let packet = pb.Packet.decode(new Uint8Array(res.data));
          // console.log("收到服务器内容：packet", packet);
          let message = packet.msg;
          // console.log(message, "received");

          if (
            message &&
            message.user &&
            message.user.id &&
            message.user.id == that.userInfo.id &&
            message.ack &&
            message.ack.messageId &&
            message.cmd == 3
          ) {
            that.isCanClick = true;
            // console.log("tempProblemList", that.tempProblemList);
            if (that.tempProblemList && that.tempProblemList.length > 0) {
              let isExist = that.tempProblemList.find(
                (item) =>
                  item.id === message.ack.messageId &&
                  item.chatId == message.chatId
              );
              // console.log("isExist", isExist);
              if (isExist) {
                const isExist1 = that.problemList.find(
                  (item) =>
                    item.id === isExist.id && item.chatId == isExist.chatId
                );
                // console.log("isExist1", isExist1);
                if (!isExist1) {
                  that.isSendMessage = true;
                  that.addData(that.tempProblemList[0]);
                  that.$bus.$emit("clearText");
                }
              }
            }
          }
          if (
            message &&
            message.ai &&
            message.ai.stream &&
            message.ai.stream.status &&
            message.ai.stream.status == 4 &&
            that.chatItem &&
            that.chatItem.id == message.chatId &&
            message.cmd == 1
          ) {
            that.isCanClick = true;
            that.streamStatus = message.ai.stream.status;
            // that.chatItem = null;
            that.$message({
              message: "回复完成，您可以继续提问了",
              type: "success",
            });
          }
          // console.log("that.problemList", that.problemList);
          // if (message && (message.cmd == 1 || (message.ai && message.ai.id && message.ai.model)) && that.chatItem &&  that.chatItem.id == message.chatId && message.cmd != 4) {
          if (
            message &&
            message.cmd == 1 &&
            that.chatItem &&
            that.chatItem.id == message.chatId &&
            message.ai.stream &&
            message.ai.stream.status &&
            message.ai.stream.status != 4 &&
            message.ai.stream.status != 1 &&
            message.ai.stream.status != 3
          ) {
            that.streamStatus = message.ai.stream.status;
            that.isSendMessage = false;
            that.isCanClick = true;
            that.addData1(message, 1);
          }
          if (
            message &&
            !message.cmd &&
            that.chatItem &&
            that.chatItem.id == message.chatId
          ) {
            that.isCanClick = true;
            that.isSendMessage = false;
            that.addData1(message, 2);
          }
          if (packet && packet.cmd == 4) {
            if(packet.api && packet.api.error && packet.api.error > 0) {
              that.$message({
                message: packet.api.msg,
                type: "error",
              });
						}
            if (packet.api && packet.api.data) {
              let resData = JSON.parse(packet.api.data) || null;
              that.isSendMessage = false;
              // console.log("resData", resData);
              if (resData && resData.current_coin) {
                that.$set(that.userInfo, "coin", resData.current_coin);
              } else if (resData && resData.current_cion == 0) {
                that.$set(that.userInfo, "coin", 0);
              }
            }

            setTimeout(() => {
              that.$bus.$emit("refreshHeaderUser");
              that.getUserInfo(true);
            }, 2000);
          }
        };

        that.socketTask.onerror(function (res) {
          console.log("WebSocket连接打开失败，请检查！");
          console.log(res);
          that.isOpen = false;
          if (that.socketTask) {
            that.socketTask.close();
          }
          //进入重新连接
          that.socketTask = null;
          clearInterval(that.timer);

          if (that.connectNum < 6) {
            that.reconnect();
            that.connectNum += 1;
          } else {
            that.connectNum = 1;
          }
        });
        // 监听连接关闭 -
        that.socketTask.onclose((e) => {
          console.log("WebSocket连接关闭！");
          this.isOpen = false;
          clearInterval(that.timer);
          that.timer = "";
          if (that.socketTask) {
            that.socketTask.close();
          }
          that.socketTask = null;
          if (this.connectNum < 6) {
            if (!that.connectInter) {
              that.connectInter = setInterval(() => {
                that.reconnect();
              }, 2000);
            }
          } else {
            that.connectNum = 1;
          }
        });
      },
      addData(msgItem) {

        if (msgItem.desc || msgItem.imageSrc) {
          if (this.isFirst && this.ipId) {
            this.shiPeiList.push(msgItem);
          } else {
            this.problemList.push(msgItem);
          }

        }
        // this.toBottom();
      },
      isImageUrl(url) {
			  return /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(url);
			},
      addData1(message, type) {
        let that = this;
        let msgItem = {
          type: 1,
          desc: [],
          imageSrc: [],
          status:
            message.ai && message.ai.stream && message.ai.stream.status
              ? message.ai.stream.status
              : 0,
          chooseIdx: 0,
          showMore: false,
          showBottom: true,
          dataTime: message.ts * 1000,
          id: message.id,
          chatId: that.chatItem && that.chatItem.id,
        };

        if (this.isFirst && this.ipId) {
          const isExist = that.shiPeiList.find(
            (item) => item.id === msgItem.id && item.chatId == msgItem.chatId
          );
          if (
            (message.msg && message.msg.text) ||
            (message.msg && message.msg.image)
          ) {
            if (isExist) {
              const idx = that.shiPeiList.findIndex(
                (item) => item.id === msgItem.id && item.chatId == msgItem.chatId
              );
              if (message.msg && message.msg.text) {
                let tempText = {
                  info: message.msg.text,
                  descId: uuid(),
                };
                isExist.desc.push(tempText);
              }
              if (message.msg && message.msg.image) {
                let isImage = that.isImageUrl(message.msg.image);
                if(isImage) {
                  isExist.imageSrc.push(message.msg.image);
                } else {
                  let tempText = {
                    info: message.msg.image,
                    descId: uuid()
                  }
                  isExist.desc.push(tempText);
                }
              }
              that.shiPeiList[idx] = isExist;
              that.$set(that.shiPeiList, idx, isExist);
            } else {
              if (message.msg && message.msg.text) {
                let tempText = {
                  info: message.msg.text,
                  descId: uuid(),
                };
                msgItem.desc.push(tempText);
              }
              if (message.msg && message.msg.image) {
                let isImage = that.isImageUrl(message.msg.image);
                if(isImage) {
                  msgItem.imageSrc.push(message.msg.image);
                } else {
                  let tempText = {
                    info: message.msg.image,
                    descId: uuid()
                  }
                  msgItem.desc.push(tempText);
                }
              }
              console.log("msgItem", msgItem);
              that.shiPeiList.push(msgItem);
            }
          }
        } else {
          const isExist = that.problemList.find(
            (item) => item.id === msgItem.id && item.chatId == msgItem.chatId
          );
          if (
            (message.msg && message.msg.text) ||
            (message.msg && message.msg.image)
          ) {
            if (isExist) {
              const idx = that.problemList.findIndex(
                (item) => item.id === msgItem.id && item.chatId == msgItem.chatId
              );
              if (message.msg && message.msg.text) {
                let tempText = {
                  info: message.msg.text,
                  descId: uuid(),
                };
                isExist.desc.push(tempText);
              }
              if (message.msg && message.msg.image) {
                let isImage = that.isImageUrl(message.msg.image);
                if(isImage) {
                  isExist.imageSrc.push(message.msg.image);
                } else {
                  let tempText = {
                    info: message.msg.image,
                    descId: uuid()
                  }
                  isExist.desc.push(tempText);
                }
              }
              that.problemList[idx] = isExist;
              that.$set(that.problemList, idx, isExist);
            } else {
              if (message.msg && message.msg.text) {
                let tempText = {
                  info: message.msg.text,
                  descId: uuid(),
                };
                msgItem.desc.push(tempText);
              }
              if (message.msg && message.msg.image) {
                let isImage = that.isImageUrl(message.msg.image);
                if(isImage) {
                  msgItem.imageSrc.push(message.msg.image);
                } else {
                  let tempText = {
                    info: message.msg.image,
                    descId: uuid()
                  }
                  msgItem.desc.push(tempText);
                }
              }
              console.log("msgItem", msgItem);
              that.problemList.push(msgItem);
            }
          }
          
        }

        // console.log("problemList", that.problemList);
        if (type == 2) {
            that.streamStatus = 4;
            that.$message({
              message: "回复完成，您可以继续提问了",
              type: "success",
            });
          }
        // that.toBottom();
      },
      //进入重新连接
      reconnect() {
        console.log("进入断线重连");
        let that = this;
        if (!that.isOpen) {
          that.socketTask && that.socketTask.close();
          that.socketTask = null;
          if (that.connectInter) {
            clearInterval(that.connectInter);
            that.connectInter = null;
          }
          that.connectSocket();
        }
      },
      sendSocketMessage(msg, isUser = false) {
        console.log("发送信息", msg);
        let self = this;
        return new Promise((reslove, reject) => {
          self.socketTask.send(msg);
        });
      },
      //心跳
      heart() {
        let that = this;
        clearInterval(that.timer);
        that.timer = "";
        let ts = Date.now();
        const packet = pb.Packet.create({
          id: uuid(),
          ts: ts,
          cmd: 2,
        });
        const bin = pb.Packet.encode(packet).finish();
        const buffer = bin.buffer.slice(
          bin.byteOffset,
          bin.byteOffset + bin.byteLength
        );
        that.timer = setInterval(() => {
          if (that.isOpen) {
            that
              .sendSocketMessage(buffer)
              .then((res) => {
                console.log("心跳成功");
              })
              .catch((res) => {
                console.log("发送失败");
                console.log(res);
                that.$message({
                  message: "消息发送失败，请稍后重试",
                  type: "error",
                });
              });
          }
        }, 25000);
      },
      chooseModel(val) {
        if (this.streamStatus >= 0 && this.streamStatus != 4) {
          this.tempItem = val;
          MessageBox.confirm("您已经提问，是否等恢复完成在切换", "系统提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => { })
            .catch(() => {
              this.initChoose(val);
            });
          return;
        }
        this.initChoose(val);
      },
      initChoose(val) {
        this.$bus.$emit("clearText");
        this.currentTypeItem = val.currentItem;
        this.modelIdx = val.idx;
        let msgLeftItem = {
          type: 2,
          desc: this.currentTypeItem.tip_message,
          imageSrc: [],
          status: 0,
          chooseIdx: 0,
          showMore: false,
          showBottom: true,
          dataTime: new Date().getTime(),
          id: "",
          chatId: "",
          isMessage: true,
        };
        this.problemList.push(msgLeftItem);
        this.initChooseType(val);
        this.toBottom();
      },
      initChatModal() {
        this.getChatModelsReq();
      },
      getChatModelsReq(modelChooseIdx = -1) {
        getChatModels(this.params)
          .then((res) => {
            if (res && res.data && res.data.list && res.data.list.length > 0) {
              this.modelsList = res.data.list;
              this.currentTypeItem = this.modelsList[0];
              console.log("currentTypeItem---", this.currentTypeItem);
              let msgLeftItem = {
                type: 2,
                desc: this.currentTypeItem.tip_message,
                imageSrc: [],
                status: 0,
                chooseIdx: 0,
                showMore: false,
                showBottom: true,
                dataTime: new Date().getTime(),
                id: "",
                chatId: "",
                isMessage: true,
              };
              this.problemList.push(msgLeftItem);
            } else {
              console.log("currentTypeItem--123123-", this.currentTypeItem);
              this.modelsList = [];
              this.currentTypeItem = null;
            }
          })
          .catch(() => {
            console.log("currentTypeItem123");
            this.modelsList = [];
            this.currentTypeItem = null;
          });
      },
      initChooseType(val) {
        // this.currentModalItem = val.item;
        // this.problemDefalutList1[val.problemIdx]["chooseIdx"] = val.idx;
        // this.modelIdx = val.idx;
        // this.currentTypeItem = this.modelsList[val.idx];

        // localStorage.setItem(
        //   "modelChooseIdx",
        //   (this.currentModalItem && this.currentModalItem.id) || ""
        // );
        // this.modelChooseIdx = localStorage.getItem("modelChooseIdx") || "";
        // this.initChat();
        this.toBottom();
      },
      initChat(msg, isFirst = true, isAuto = false) {
        let loading = this.$loading({
          lock: true,
          text: "创建聊天...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        console.log("currentTypeItem", this.currentTypeItem);
        let param = {
          ai_id: this.currentTypeItem && this.currentTypeItem.ai_id,
          model: (this.currentTypeItem && this.currentTypeItem.model) || "",
          type: this.currentTypeItem && this.currentTypeItem.type,
        };
        // let currentChatItem = localStorage.getItem("currentChatItem");
        // if (currentChatItem) {
        //   currentChatItem = JSON.parse(currentChatItem);
        //   if (currentChatItem && currentChatItem.id) {
        //     localStorage.setItem("currentChatItem", "");
        //     // closeChat({ id: currentChatItem.id }).then((res) => { });
        //   }
        // }

        if (this.chatItem && this.chatItem.id) {
          closeChat({ id: this.chatItem.id }).then((res) => { });
          this.chatItem = "";
        }
        this.isGetMessage = false;
        if (!isFirst) {
          this.problemList = [];
          let msgLeftItem = {
            type: 2,
            desc: this.currentTypeItem.tip_message,
            imageSrc: [],
            status: 0,
            chooseIdx: 0,
            showMore: false,
            showBottom: true,
            dataTime: new Date().getTime(),
            id: "",
            chatId: "",
            isMessage: true,
          };
          this.problemList.push(msgLeftItem);
        }

        this.streamStatus = -1;
        createChat(param)
          .then((res) => {
            console.log("res", res);
            if (res && res.error == 0) {
              this.chatItem = res.data || null;
              // localStorage.setItem("currentChatItem", JSON.stringify(this.chatItem));
              this.initModel(this.chatItem && this.chatItem.type);
              // if (
              //   this.chatItem &&
              //   (this.chatItem.type == 0 || this.chatItem.type == 1) &&
              //   isFirst
              // ) {
              //   this.getChatTipMessagesReq();
              // } else {
              //   this.problemTips[0]["children"] = [];
              //   this.problemTips[0]["chooseIdx"] = -1;
              // }
              if (this.chatItem && this.chatItem.id && msg) {
                this.sendMessage(msg, "", "", "", isAuto);
              }
            } else {
              this.$message({
                message: (res && res.msg) || "创建聊天失败，请稍后重试",
                type: "error",
              });
            }

            loading.close();
          })
          .catch((err) => {
            loading.close();
            this.$message({
              message: (err && err.msg) || "创建聊天失败，请稍后重试",
              type: "error",
            });
          });
      },
      initModel(idx) {
        console.log("initModel", idx);
        this.showCyAsk = true;
      },
      getChatTipMessagesReq() {
        if (this.chatItem && this.chatItem.id) {
          if (this.chatItem.type == 1) {
            this.problemTips[0]["name"] =
              "我是您的文学创作工具，我可以根据你的描述来帮你讲故事。";
          } else {
            this.problemTips[0]["name"] =
              "我是你的育儿工具，你可以问我关于育儿的知识。我将会给你提供专业的育儿建议。";
          }
          this.problemTips[0]["children"] = [];
          this.problemTips[0]["chooseIdx"] = -1;
          getChatTipMessages(this.chatItem.id)
            .then((res) => {
              console.log("res", res);
              if (res && res.error == 0) {
                if (
                  res &&
                  res.data &&
                  res.data.messages &&
                  res.data.messages.length > 4
                ) {
                  this.problemTips[0]["children"] = res.data.messages.subString(
                    0,
                    4
                  );
                } else {
                  this.problemTips[0]["children"] =
                    (res && res.data.messages) || [];
                }
              } else {
                this.$message({
                  message: (err && err.msg) || "获取推荐数据失败，请稍后重试",
                  type: "error",
                });
              }

              this.toBottom();
            })
            .catch((err) => {
              this.$message({
                message: (err && err.msg) || "获取推荐数据失败，请稍后重试",
                type: "error",
              });
            });
        }
      },
      async sendMessage(msg, hash, imageSrc, id, isAuto = false) {
        console.log("isCanClick", this.isCanClick);
        if (!this.isCanClick) {
          return;
        }
        if (this.streamStatus >= 0 && this.streamStatus != 4) {
          this.$message({
            message: "您已经提问，请等待回复!",
            type: "error",
          });
          return;
        }
        this.isCanClick = false;
        this.isGetMessage = false;
        let sendMsg = {};
        if (msg) {
          sendMsg["text"] = msg;
        }
        if (hash) {
          sendMsg["image"] = hash;
        }
        if (this.chatItem && this.chatItem.id) {
          console.log("uuid", id, "this.userInfo", this.userInfo);
          id = id || uuid();
          const message = pb.Message.create({
            id: id,
            chatId: this.chatItem && this.chatItem.id,
            cmd: 0,
            type: this.currentTypeItem && this.currentTypeItem.type,
            msg: sendMsg,
            user: {
              id: (this.userInfo && this.userInfo.id) || "",
              dev_id: "browser",
            },
            ts: Date.now() / 1000,
            cat: 0,
            stream: {
              status: 0,
            },
            ai: {
              id: this.currentTypeItem && this.currentTypeItem.ai_id,
              model: this.currentTypeItem && this.currentTypeItem.model,
            },
          });
          console.log("message", message);
          let ts = Date.now();
          const packet = pb.Packet.create({
            id: message.id,
            cmd: 0,
            ts: ts,
            msg: message,
          });
          console.log(packet);
          const bin = pb.Packet.encode(packet).finish();
          const buffer = bin.buffer.slice(
            bin.byteOffset,
            bin.byteOffset + bin.byteLength
          );
          let msgItem = {
            type: 3,
            desc: msg,
            status: 1,
            chooseIdx: 0,
            showMore: false,
            showBottom: false,
            dataTime: ts,
            id: message.id,
            chatId: this.chatItem && this.chatItem.id,
          };
          if (hash) {
            msgItem["imageSrc"] = imageSrc;
            msgItem["image"] = hash;
          }
          // if (this.userInfo && this.userInfo.coin < 100) {
          //   this.currentMessage = msgItem;
          //   MessageBox.confirm("积分不够，请前往充值", "提示", {
          //     confirmButtonText: "确定",
          //     cancelButtonText: "取消",
          //     type: "warning",
          //   })
          //     .then(() => {
          //       this.showStationList = true;
          //       return;
          //     })
          //     .catch(() => {
          //       return;
          //     });
          //   return;
          // }

          this.$bus.$emit("clearText");
          this.toBottom();
          if (!isAuto) {
            this.tempProblemList = [];
            this.tempProblemList.push(msgItem);
          }

          this.streamStatus = 0;
          this.sendSocketMessage(buffer, true);
          this.messages.push(this.currentMessage);
          this.currentMessage = "";
        }
      },
      toBottom() {
        this.$nextTick(() => {
          // 获取滚动区域的高度
          // this.scrollToAnchor("mainScroll");
          this.scrollToAnchor("lastWrap");
          let chatRoom = document.querySelector(".center-box");
          setTimeout(() => {
            this.$refs.mainScroll.scrollTo &&
              this.$refs.mainScroll.scrollTo({
                top: chatRoom.scrollHeight + 200,
                behavior: "smooth",
              });
          }, 50);
        });
      },
      scrollToAnchor(anchor) {
        this.$nextTick(() => {
          const anchorElement = document.getElementById(anchor);
          if (anchorElement) {
            anchorElement.scrollIntoView({ behavior: "smooth" });
          }
        });
      },
    },
  };
</script>
<style lang="less" scoped>
  .container {
    height: 100%;
    display: flex;
    /* 禁止文字选择 */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .menu-layer {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: transparent;
      z-index: 999;
    }

    .content-box {
      display: flex;
      padding-top: 128px;
      flex: 1;

      .left-box {
        width: 450px;
        display: flex;
      }

      .center-box {
        flex: 1;
        display: flex;
        min-width: 670px;
        position: relative;
        flex-direction: column;
        overflow-y: auto;

        .strategy-box {
          flex-direction: column;
        }

        .center-default-box {
          width: 100%;
          margin-bottom: 20px;
          display: flex;
          background: #f8f8fa;
          border-radius: 20px;
          border-top-left-radius: inherit;
          border-top-right-radius: inherit;
          flex-direction: column;
          position: relative;
          // overflow-y: auto;
          padding-bottom: 200px;
          &.center-default-box1 {
            padding-bottom: 20px;
          }
          .problem-wrap {
            margin-top: 20px;
          }
        }

        .bottom-fixed {
          bottom: 20px;
        }

        .normal-header {
          width: 100%;
          height: 36px;
          background: #f3f3f8;
          border-radius: 10px 10px 0px 0px;
          border: 1px solid #e7e8ed;
          display: flex;
          align-items: center;

          .title {
            flex: 1;
            font-size: 18px;
            color: #1a232f;
            font-weight: bold;
            text-align: left;
            padding-left: 10px;
          }

          .opt {
            padding: 0 10px;
            cursor: pointer;

            i {
              font-size: 20px;
            }
          }
        }
      }

      .right-box {
        width: 300px;
        display: flex;
      }
    }

    .content-detail-popup {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      background: #f8f8fa;
      border-radius: 20px;
      flex-direction: column;
      z-index: 1;
    }
  }
</style>
<template>
    <div class="ask-content">
        <div class="content-type-list flex">
            <div class="content-type-item flex justify-center align-center" :class="[modelIdx == idx ? 'active' : '']" 
            v-for="(item,idx) in modelsList" :key="item.id" @click="chooseModel(item,idx)">
                {{item.name}}
            </div>
        </div>
        <div class="ask-box">
            <div class="ask-input">
                <input v-model="value" placeholder="在这里输入你的问题" type="text" />
            </div>
            <div class="ask-opt">
                <img class="cursor" src="https://files.sssrise.com/files/uploads/static/fasong.png"
                    @click="showContentEditFun">
            </div>

        </div>
    </div>

</template>
<script>
    const baseURL = process.env.VUE_APP_BASE_API;
    import { getToken } from "@/utils/auth";
    import env from "@/utils/env";
    export default {
        props: ["chatItem", "currentTypeItem","modelsList","currentModalItem","modelIdx"],
        components: {
            
        },
        data() {
            return {
                value: "",
                currentIdx: -1,
                modelList: [{
                    name: "切换成爆款打造专家"
                },{
                    name: "切换成玩具绘图专家"
                },]
            }
        },
        methods: {
            chooseModel(item,idx) {
                this.$emit("chooseModel",{
                    idx:idx,
                    currentItem: item
                })
            },
            showContentEditFun() {
                if (!this.value) {
                    this.$message({
                        message: `请输入问题`,
                        type: "error"
                    })
                    return;
                }
                let param = {

                }
                param["text"] = this.value;
                console.log("param", param)
                this.value = "";
                this.$emit("hideContentEditFun", param)
            }
        }
    }
</script>
<style lang="less" scoped>
    .ask-content {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 0 auto;
        flex-direction: column;
        padding: 10px 0;
        background: rgba(243, 243, 248, 0.8);
        box-shadow: inset 0px 1px 0px 0px #E7E8ED;
        backdrop-filter: blur(10px);
    }

    .content-type-list {
        padding: 0 10px;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 10px;
        .content-type-item {
            padding: 0 10px;
            height: 32px;
            background: #FFFFFF;
            box-shadow: 0 4px 8px 0 #E7E8ED;
            border-radius: 10px;
            border: 1px solid #E7E8ED;
            font-weight: 400;
            font-size: 14px;
            color: #626A83;
            margin-right: 20px;
            cursor: pointer;

            &.active {
                background: #D9E5FF;
                box-shadow: 0px 4px 8px 0px #D9E5FF;
                border: 1px solid #3672FD;
                color: #3672FD;
                font-weight: bolder;
            }
        }
    }

    .ask-box {
        width: 100%;
        display: flex;
        padding: 0 20px;

        .ask-input {
            flex: 1;

            input {
                height: 46px;
                font-weight: 400;
                font-size: 14px;
                color: #626A83;
                padding: 0;
                padding-left: 24px;
                width: 100%;
                border-radius: 36px;
                border-width: 0;
            }

            input:focus {
                outline: none;
            }
        }

        .ask-opt {
            display: flex;
            align-items: center;
            padding-left: 20px;

            .ask-icon {
                margin-right: 20px;
            }

            img {
                width: 24px;
                height: 24px;
                margin-right: 20px;
            }
        }


    }
</style>
import request from "@/utils/request";

/**
 * 取消数据订单
 * id : 订单id
 */
export function statisticsOrderCancel(params) {
    return request({
        url: '/statistics_order/cancel',
        method: 'post',
        data: params
    })
}

/**
 * 数据分析订单分类列表
 */
 export function getStatisticsOrderCats() {
    return request({
        url: '/statistics_order/cats',
        method: 'get'
    })
}

/**
 * 创建数据购买订单
 * price : 购买人民币
 * product_id: 积分产品id
 */
export function statisticsOrderCreate(params) {
    return request({
        url: '/statistics_order/create',
        method: 'post',
        data: params
    })
}

/**
 * 数据订单删除
 * id : 订单id
 */
export function deleteStatisticsOrder(params) {
    return request({
        url: '/statistics_order/delete',
        method: 'post',
        data: params
    })
}

/**
 * 数据订单详情
 * id : 订单id
 */
export function getStatisticsOrderInfo(id) {
    return request({
        url: '/statistics_order/info?id=' + id,
        method: 'get'
    })
}

/**
 * 获取数据订单列表
 * status: 订单状态 全部传99
 * offset
 * limit
 */
export function getStatisticsOrderList(params) {
    return request({
        url: '/statistics_order/list',
        method: 'get',
        params: params
    })
}

/**
 * 数据订单支付
 * channel: 支付渠道微信公众号wx_official 小程序wx_mini
 * id : 订单id
 */
export function statisticsOrderPay(params) {
    return request({
        url: '/statistics_order/pay',
        method: 'post',
        data: params
    })
}

/**
 * 数据分析订单产品列表
 */
export function getStatisticsOrderProducts() {
    return request({
        url: '/statistics_order/products',
        method: 'get'
    })
}
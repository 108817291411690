import request from '@/utils/request';

/**
 * 根据消息创建内容
 * cash  : integer
 * coin  : coin
 * message_ids  :message_ids 
 * title  : title 
 */
export function postCreate(params) {
    return request({
        url: '/post/create',
        method: 'post',
        data: params
    })
}

/**
 * 删除内容
 * id : 内容id
 */
export function postDelete(params) {
    return request({
        url: '/post/delete',
        method: 'post',
        data: params
    })
}



/**
 * 收藏内容
 * fav: 收藏1 取消收藏-1
 * id : 内容id
 */
export function postFav(params) {
    return request({
        url: '/post/fav',
        method: 'post',
        data: params
    })
}

/**
 * 获取内容详情
 * id : 内容id
 */
export function getPostInfo(id) {
    return request({
        url: '/post/info?id=' + id,
        method: 'get'
    })
}

/**
 * 点赞/点踩/取消点赞/取消点踩内容
 * cancel: 是否取消赞/踩 默认为false
 * id : 内容id
 * like 赞1 踩-1
 * reason: reason
 */
export function postLike(params){
    return request({
        url: '/post/like',
        method: 'post',
        data: params
    })
}

/**
 * 获取创意市场发布内容列表
 * offset
 * limit
 * sort:排序规则 asc正序 desc倒序
 * sort_by:排序字段 created_at发布时间 like点赞量 price价格
 */
 export function getPostList(params) {
    return request({
        url: "/post/list",
        method: 'get',
        params: params
    })
}


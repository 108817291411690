<template>
  <div class="container">
    <div class="content-box">
      <div class="left-box">
      </div>
      <div class="center-box">
        <div class="center-default-box">
          <div class="creative-header">
            <el-select v-model="sortBy" placeholder="请选择"
              @change="selectChange">
              <el-option v-for="item in sortList"
                :key="item.sortBy" :label="item.name"
                :value="item.sortBy">
              </el-option>
            </el-select>
            <div class="create-btn"
              @click="showCollect = true">
              <i
                class="el-icon-circle-plus-outline icon-plus"></i>
              征集创意
            </div>
          </div>
          <CommunityList :dataList="postList"
            @goDetail="goDetail" :statusLoadmore="statusLoadmore" @loadMoreData="loadMoreData">
          </CommunityList>
          <ContentDetail :detailInfo="postDetail" class="content-detail-popup"
            v-if="showDetail"
            @closeDetail="showDetail = false" @getPostInfoEmit="getPostInfoEmit">
          </ContentDetail>
          <CollectCom class="content-detail-popup"
            v-if="showCollect"
            @closeCollect="showCollect = false">
          </CollectCom>
        </div>

      </div>
      <div class="right-box">
      </div>
    </div>
  </div>
</template>
<script>
import CommunityList from "@/components/content/list.vue";
import ContentDetail from "@/components/content/detail.vue";
import CollectCom from "@/components/content/collect.vue";
import { getPostList,getPostInfo } from "@/api/content";
export default {
  components: {
    CommunityList,
    ContentDetail,
    CollectCom,
  },
  data() {
    return {
      showLogin: false,
      isLoading: false,
      showCollect: false,
      showDetail: false,
      sortList: [
        {
          id: 2,
          name: "发布时间",
          sortBy: "created_at",
        },
        {
          id: 3,
          name: "点赞量",
          sortBy: "like",
        },
      ],
      sortBy: "created_at",
      postList: [],
      params: {
        sort: "desc",
        pageSize: 0,
        offset: 0,
        limit: 20,
        sort_by: "created_at", //created_at发布时间 like点赞量 price价格
      },
      statusLoadmore: "loadmore",
      postDetail: null
    };
  },
  created() {
    this.getPostListReq();
    this.$bus.$off("initData");
    this.$bus.$on("initData",()=> {
      this.getPostListReq();
    })
  },
  methods: {
    showFullLayer(val) {
      this.showLayer = val;
    },
    closeLogin() {
      this.showLogin = false;
    },
    goLogin() {
      this.showLogin = true;
    },
    changeDefaultIdx() {},
    sumbitTipFun() {},
    refresh() {},
    changeTipIdx() {},
    closeRewardPopup() {
      this.showReward = false;
    },
    goDetail(item) {
      this.showDetail = true;
      getPostInfo(item.id).then((res) => {
        if(res && res.error == 0) {
          this.postDetail = res.data;
        } else {
          this.$message({
            message: (res && res.msg) || "服务器异常，请稍后重试",
            type: "error",
          });
        }
      }).catch((err)=> {
        this.$message({
            message: (err && err.msg) || "服务器异常，请稍后重试",
            type: "error",
          });
      })
    },
    getPostInfoEmit(item) {
      this.goDetail(item);
    },
    loadMoreData() {
      this.statusLoadmore = "loading";
      this.params.pageSize += 1;
      this.params.offset = (this.params.pageSize + 1) * this.params.limit;
      console.log("more");
      this.getPostListReq();
    },
    getPostListReq() {
      getPostList(this.params)
        .then((res) => {
          if (res.error == 0) {
            let list = (res && res.data && res.data.list) || [];
            if (list && list.length > 0) {
              list.forEach((billItem) => {
                const isExist = this.postList.find(
                  (item) => item.id === billItem.id
                );
                const tempItem = billItem;
                if (isExist) {
                  return;
                } else {
                  this.postList.push(tempItem);
                }
              });
            }
            console.log("postList", this.postList);
            if (res && res.data && res.data.has_more) {
              this.statusLoadmore = "loadmore";
            } else {
              this.statusLoadmore = "nomore";
            }
          } else {
            this.statusLoadmore = "loadmore";
            this.$message({
              message: (res && res.msg) || "服务器异常，请稍后重试",
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.statusLoadmore = "loadmore";
          this.$message({
            message: (err && err.msg) || "服务器异常，请稍后重试",
            type: "error",
          });
        });
    },
    selectChange() {
      console.log("this.sortBy", this.sortBy);
      this.params.sort_by = this.sortBy;
      this.params.pageSize = 0;
      this.params.offset = 0;
      this.getPostListReq();
    },
  },
};
</script>
  <style lang="less" scoped>
.container {
  height: 100%;
  display: flex;
  /* 禁止文字选择 */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .menu-layer {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: transparent;
    z-index: 999;
  }
  .content-box {
    display: flex;
    padding-top: 128px;
    flex: 1;

    .left-box {
      width: 450px;
      display: flex;
    }
    .center-box {
      flex: 1;
      display: flex;
      min-width: 670px;
      .center-default-box {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        background: #f8f8fa;
        border-radius: 20px;
        flex-direction: column;
        position: relative;
        .creative-header {
          padding: 20px 30px;
          display: flex;
          justify-content: space-between;
          ::v-deep .el-select {
            width: 94px;
            height: 24px;
            background: #e7e8ed;
            border-radius: 16px;
            display: flex;
            align-items: center;
            .el-input {
              height: 100%;
            }
            .el-input__inner {
              height: 100%;
              background: #e7e8ed;
              border-radius: 16px;
              padding-left: 12px;
              font-weight: 400;
              font-size: 12px;
              color: #1a232f;
              line-height: 24px;
            }
            .el-icon-arrow-up:before {
              content: "\e78f";
            }
            .el-input__icon {
              line-height: 24px;
              color: #3672fd;
            }
          }
          .create-btn {
            width: 112px;
            height: 28px;
            background: #3672fd;
            box-shadow: 0px 2px 4px 0px #d9e5ff;
            border-radius: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 400;
            font-size: 12px;
            color: #ffffff;
            cursor: pointer;
            .icon-plus {
              font-size: 14px;
              color: #fff;
              margin-right: 4px;
            }
          }
        }
        .content-detail-popup {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: flex;
          background: #f8f8fa;
          border-radius: 20px;
          flex-direction: column;
          z-index: 1;
        }
      }
    }
    .right-box {
      width: 300px;
      display: flex;
    }
  }
}
</style>
import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
import Home from "../pages/Home.vue";
import Market from "../pages/market/index.vue";
import Analysis from "../pages/market/analysis.vue";
import Idea from "../pages/market/idea.vue";
import IpInfo from "../pages/market/ipInfo.vue";
import Creative from "../pages/creative/index.vue";
import Ai from "../pages/ai/index.vue";
import HotAi from "../pages/hotAi/index.vue";
import Layout from '@/layout';
let routes = [
//   {
//     path: "/",
//     component: Home,
//     name: "",
//     hidden: true
// },
{
    path: '/404',
    component: () => import('@/pages/error/404'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: "Index",
    hidden: true,
    children: [
      {
        path: 'index',
        component: Home,
        name: 'Index',
        meta: { title: '首页' }
      },
      // {
      //   path: 'market',
      //   component: Market,
      //   name: 'Market',
      //   meta: { title: '市场调研' }
      // },
      {
        path: 'analysis',
        component: Analysis,
        name: 'Analysis',
        meta: { title: '市场综合分析' }
      },
      {
        path: 'idea',
        component: Idea,
        name: 'Idea',
        meta: { title: '产品策略报告' }
      },
      {
        path: 'ipInfo',
        component: IpInfo,
        name: 'IpInfo',
        meta: { title: 'IP详情' }
      },
      // {
      //   path: 'creative',
      //   component: Creative,
      //   name: 'Creative',
      //   meta: { title: '创意市场' }
      // },
      // {
      //   path: 'ai',
      //   component: Ai,
      //   name: 'Ai',
      //   meta: { title: '设计助手' }
      // },
      {
        path: 'hotAi',
        component: HotAi,
        name: 'HotAi',
        meta: { title: '爆款打造AI工具' }
      },
      {
        path: 'user',
        component: () => import('@/pages/user/index'),
        name: 'user',
        meta: { title: '用户中心' }
      }
    ]
  }
]

let routerPush = Router.prototype.push;
let routerReplace = Router.prototype.replace;
// push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push(location) {
  return routerReplace.call(this, location).catch(err => err)
}

export default routes;
<template>
    <div class="flow-box">
      <div class="rank-title"><span>{{title}}</span><span
          class="sub-title"
          v-if="subTitle">({{subTitle}})</span>
          <el-tooltip v-if="showTopTip" style="margin-left:4px;margin-top:2px;" class="item" effect="dark"
          placement="right">
          <div slot="content">
            <div v-html="calTopTip()"></div>
          </div>
          <i class="el-icon-question"></i>
        </el-tooltip>  
      </div>
      <div class="flow-content">
        <div class="flow-analysis">
          <!-- <div class="flex align-center"><div class="text-border"></div><div class="default-title mt-0">人群年龄分布</div></div> -->
          <LineCom :ipInfo="ipInfo" :distributionAge="distributionAge" :distributionAgeDefault="distributionAgeDefault" ></LineCom>
        </div>
      </div>
    </div>
  </template>
  <script>
  import CountTo from 'vue-count-to';
  import LineCom from "./ideaLine.vue";
  export default {
    props: ["title", "subTitle","flowIndex","ipInfo","distributionAge","distributionAgeDefault","categoryName","showTopTip"],
    components: {
      CountTo,
      LineCom
    },
    data() {
      return {
      };
    },
    methods: {
      calTopTip() {
        return "分析品类和IP的受众人群年龄分布，制定合适的产品定位，更好地满足消费者需求。"
      }
    }
  };
  </script>
  <style lang="less" scoped>
  @import url("@/assets/css/rankTitle.less");
  .flow-box {
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #e7e8ed;
    .flow-content {
      padding: 10px;
      .analysis-info {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 13px;
        .analysis-item {
          width: 48%;
          display: flex;
          background: #e0deff;
          border-radius: 10px;
          padding: 12px 20px 12px 15px;
          .item-left {
            flex: 1;
            display: flex;
            flex-direction: column;
            .name {
              font-weight: 400;
              font-size: 12px;
              color: #626a83;
              line-height: 17px;
              text-align: left;
            }
            .count {
              font-weight: bold;
              font-size: 24px;
              color: #1a232f;
              // line-height: 47px;
              text-align: left;
              display: flex;
              margin-top: 4px;
            }
          }
          .item-right {
            display: flex;
            align-items: flex-end;
            .right-info {
              display: flex;
              align-items: center;
            }
            .up {
              font-weight: 400;
              font-size: 12px;
              color: #e6182e;
              line-height: 17px;
              text-align: left;
            }
            .down {
              font-weight: 400;
              font-size: 12px;
              color: #13b918;
              line-height: 17px;
              text-align: left;
            }
            img {
              width: 8px;
              height: 10px;
              margin-left: 6px;
            }
          }
        }
      }
      .flow-analysis {
  
      }
    }
  }
  </style>
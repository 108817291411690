<template>
  <div class="market-analysis-box">

    <div class="analysis-content">
      <div class="analysis-left">
        <div class="idea-analysis">
          <div class="tips">*请选择您要分析的IP</div>
          <div class="category">
            <div class="desc">IP</div>
            <el-autocomplete class="inline-input" v-model="ipVal" :fetch-suggestions="querySearch" placeholder="请输入内容"
              :trigger-on-focus="true" :value-key="autoName" @select="handleSelect" :clearable="true"></el-autocomplete>
          </div>
          <div class="default-btn2 analysis-btn1" @click="beginAnalysis">开始分析</div>
        </div>

        <!-- <div class="datetime-box mt-30">
          <div class="default-title">数据周期</div>
          <el-date-picker v-model="currentDate" type="month" placeholder="选择日期" prefix-icon="el-icon-caret-bottom"
            @change="datetimeChange">
          </el-date-picker>
        </div> -->

        <!-- <div class="ip-img" v-if="ipInfo && ipInfo.cover_img">
          <img :src="ipInfo.cover_img">
        </div> -->

        <IpCategoryCom v-if="!isLoading" class="mt-20" :title="ipInfo && ipInfo.name" :ipInfo="ipInfo"
          :allScore="allScore"></IpCategoryCom>
        <AdviceCom v-if="!isLoading" class="mt-20" title="应用建议" :suggestion="ipInfo"></AdviceCom>
        <FlowCom v-if="!isLoading" class="mt-20" title="人群年龄分布" :flowIndex="flowIndex" :ipInfo="ipInfo"
          :distributionAge="distributionAge" :distributionAgeDefault="distributionAgeDefault">
        </FlowCom>
        <MarketAnalysis v-if="!isLoading" currentType="ipRegion" class="mt-20" title="媒体渠道分析" :currentList="ipMarket"
          bgColor="#9893EB" :showTopTip="true">
        </MarketAnalysis>
        <CompareCom v-if="!isLoading" title="同类IP分析" :currentH="compareIpNewH" :currentList="compareIp"
          :showChooseCate="true" :showRadio="true" :radioList="radioList" placeholderDefault="全部品类"
          :categoryOptions="categoryOptions" :categoryAllOptions="categoryAllOptions"
          :secondCategoryOptions="secondCategoryIpOptions" currentType="compareIpType" valueType="analysisType"
          :ipId="ipId" :isGoIp="true" cateSelectLabel="品类筛选" :hiddenLabel="true" :categoryItems="categoryIpItems"
          :secondCategoryItems="secondCategoryIpItems"
          :currentCategory="ipCategory"
          :currentFirstCategory="ipFirstCategory" :currentSecondCategory="ipSecCategory" :showTopTip="true"
          @handleSecondIpKeywordChange="handleSecondIpKeywordChange" :keywordList="keywordList">
        </CompareCom>


        <!-- <RankCom title="品类销售排行" :currentList="categoryRank" bgColor="#DA8FF5" :hiddenLabel="true"></RankCom>
        <RankCom title="价格分布排行" subTitle="单位:元" :currentList="distributionRank" bgColor="#9893EB">
        </RankCom> -->

        <RankTable class="mt-20" v-if="!isLoading" title="价格分布" subTitle="" :currentH="distributionRankH"
          :currentL="distributionRankL" :distributionRangeList="distributionRangeList" bgColor="#9893EB"
          placeholderDefault="全部品类" :showChooseCate="true"
          :categoryOptions="categoryOptions" :categoryAllOptions="categoryAllOptions"
          :secondCategoryOptions="secondCategoryOptions" currentType="distributionIpRankType" valueType="analysisType"
          :categoryItems="categoryItems" :secondCategoryItems="secondCategoryItems" @delCategoryItem="delCategoryItem"
          @delSecCategoryItem="delSecCategoryItem" :ipId="ipId" :showSwitch="true" :isIpInfo="true"
          @activeChange="activeChange" :currentCategory="disCategory"
          :currentFirstCategory="disFirstCategory" :currentSecondCategory="disSecCategory" :showTopTip="true"
          @handleSecondIpKeywordDisChange="handleSecondIpKeywordDisChange" :keywordList="keywordList"
          :hiddenCate="true">
        </RankTable>
        <el-empty v-if="isLoading" description="请选择您要分析的IP"></el-empty>
      </div>

    </div>
  </div>
</template>
<script>
  import CountTo from "vue-count-to";
  import RankCom from "./rank.vue";
  import RankStar from "./rankStar.vue";
  import IpCategoryCom from "./ipCategory.vue";
  import AdviceCom from "./advice.vue";
  import FlowCom from "./flow.vue";
  import MarketAnalysis from "./marketAnalysis.vue";
  import CompareCom from "./compareNew.vue";
  import {
    getStatisticsIp,
    getStatisticsSearchIp,
    getStatisticsCategoryCompeteIp,
    getStatisticsCategoryList,
    getStatisticsCategoryIpDistribution,
    getStatisticsCategoryCompeteIpDistribution,
    getStatisticsKeywordIpDistribution,
    getStatisticsKeywordCompeteIpDistribution,
    getStatisticsMarketCategoryDistribution,
    getStatisticsKeywordList,
    getStatisticsKeywordCompeteIp,
    getStatisticsIpKeywordList
  } from "@/api/statistics";
  import RankTable from "./rankTable.vue";
  export default {
    components: {
      CountTo,
      RankCom,
      RankStar,
      IpCategoryCom,
      AdviceCom,
      FlowCom,
      MarketAnalysis,
      CompareCom,
      RankTable,
    },
    data() {
      return {
        isLoading: true,
        currentDate: new Date(),
        topRankStar: [],
        radioList: [
          {
            label: "Top 10",
            value: 10,
          },
          {
            label: "Top 20",
            value: 20,
          },
          {
            label: "Top 30",
            value: 30,
          },
        ],
        showRadio: true,
        ipVal: "",
        ipId: "",
        restaurants: [],
        autoName: "name",
        total: null,
        distributionRankH: [
          {
            name: "零售价区间\n\n（单位：元）",
          },
          {
            name: "全部品类",
            children: [
              {
                name: "销量占比",
              },
              {
                name: "销售额占比",
              },
            ],
          },
        ],
        distributionRankL: [],
        categoryRank: [
          {
            name: "销量",
            rankList: [],
          },
          {
            name: "销售额",
            rankList: [],
          },
        ],
        compareIpH: [
        //   {
        //   name: "同类IP平均"
        // }, 
        {
          name: "同类IP最高"
        }, 
        // {
        //   name: "无IP平均"
        // }
      ],
      compareIpNewH: [
          {
            name: "同类IP",
            children: [
              {
                name: "top1",
              },
              {
                name: "top2",
              },
              {
                name: "top3",
              },
              {
                name: "top4",
              },
              {
                name: "top5",
              },
            ],
          },
        ],
        compareIp: [
        ],
        ipMarket: [
          {
            name: "平台热度",
            rankList: [
              {
                region_name: "头条",
                typeIdx: "point_toutiao_index",
                region_index: 0,
                region_index_trend: 0,
              },
              {
                region_name: "抖音",
                typeIdx: "point_douyin_index",
                region_index: 0,
                region_index_trend: 0,
              },
              {
                region_name: "快手",
                typeIdx: "point_kuaishou_index",
                region_index: 0,
                region_index_trend: 0,
              },
              {
                region_name: "微博",
                typeIdx: "point_weibo_index",
                region_index: 0,
                region_index_trend: 0,
              },
              {
                region_name: "百度",
                typeIdx: "point_search_index",
                region_index: 0,
                region_index_trend: 0,
              },
              {
                region_name: "小红书",
                typeIdx: "point_xiaohongshu_index",
                region_index: 0,
                region_index_trend: 0,
              },
            ],
          },
          {
            name: "地域热度",
            rankList: [],
          },
        ],
        ipInfo: null,
        flowIndex: {
          search_index: 0,
          search_index_trend: 0,
          media_index: 0,
          media_index_trend: 0,
        },
        distributionAge: null,
        distributionAgeDefault: null,
        allScore: [
          {
            name: "覆盖人群",
            type: "point_watched",
            value: 0,
          },
          {
            name: "受众认可",
            type: "point_rate",
            value: 0,
          },
          {
            name: "潜在客户",
            type: "point_star5_people",
            value: 0,
          },
          {
            name: "搜索指数",
            type: "point_search_index",
            value: 0,
          },
          {
            name: "媒体资讯",
            type: "point_media_index",
            value: 0,
          },
        ],
        currentId: null,
        categoryOptions: [],
        categoryAllOptions: [],
        categoryItems: [],
        secondCategoryOptions: [],
        secondCategoryItems: [],
        distributionRangeList: [],
        ipActiveText: true,
        disCategory: "",
        disFirstCategory: "",
        disSecCategory: "",
        ipSecCategory: [],
        ipCategory: "",
        ipFirstCategory: "",
        categoryIpItems: [],
        secondCategoryIpOptions: [],
        secondCategoryIpItems: [],
        keywordList: [],
        competeIpData: []
      };
    },
    created() {
      this.initData();
      // this.ipId = 2357;
      // this.ipId = 6212;
      // this.ipId = 2020;
      // this.getStatisticsIpReq();
      this.$bus.$off("initData");
      this.$bus.$on("initData", () => {
        this.initData();
      });
    },
    watch: {
      // 监听路由对象中的变化
      $route: {
        handler: function (to, from) {
          // 路由发生变化时的处理逻辑
          console.log("Route changed from", from.path, "to", to.path);
          this.ipId = this.$route.query.currentIpId || null;
          this.ipVal = this.$route.query.currentIpName
            ? decodeURIComponent(this.$route.query.currentIpName)
            : null;
          if (this.ipId && this.ipVal) {
            this.getStatisticsIpReq();
            this.$emit("goTop");
          }
        },
        // 设置为深度监听
        deep: true,
      },
    },
    methods: {
      initData() {
        this.getStatisticsCategoryListReq();
        this.currentId = this.$route.query.currentId || null;
        this.ipId = this.$route.query.currentIpId || null;
        this.ipVal = this.$route.query.currentIpName
          ? decodeURIComponent(this.$route.query.currentIpName)
          : null;
        if (this.ipId && this.ipVal) {
          this.getStatisticsIpReq();
        }
      },
      mergeAndUnique(array1, ...arrays) {
        const uniqueObjects = new Set();
        const mergedArray = [...array1, ...arrays.flat()];

        mergedArray.forEach((obj) => {
          const stringifiedObj = JSON.stringify(obj);
          if (!uniqueObjects.has(stringifiedObj)) {
            uniqueObjects.add(stringifiedObj);
          }
        });

        return Array.from(uniqueObjects).map(JSON.parse);
      },
      datetimeChange() {
        console.log("currentDate", this.currentDate);
        this.beginAnalysis();
      },
      radioChange(item) {
        if (item.currentType == "compareIpType") {
          this.getStatisticsCategoryCompeteIpReq(item.categoryId, item.limit);
        }
      },
      
      compareArrays(arr1, arr2) {
        return arr1.filter(item => !arr2.includes(item));
      },
      async handleSecondIpKeywordDisChange(item) {
        let isCanGo = false;
        let currentCat = JSON.parse(JSON.stringify(this.disSecCategory));
        let addArr = this.compareArrays(item.categoryIds, currentCat);
        let delArr = this.compareArrays(currentCat, item.categoryIds);
        // console.log("addArr", addArr, "delArr", delArr,"currentCat",currentCat,"item.categoryIds",item.categoryIds)
        this.disSecCategory = item.categoryIds;
        // console.log("disSecCategory",this.disSecCategory) 
       
        
        if (delArr && delArr.length > 0) {
          for (let i = 0, j = delArr.length; i < j; i++) {
            let delItem = delArr[i];
            let delIdx = this.secondCategoryItems.findIndex((res) => {
              return res.id == delItem;
            })
            // console.log("delIdx",delIdx)
            if (delIdx >= 0) {
              this.distributionRankH.splice(delIdx + 2, 1);
              this.distributionRankL.splice(delIdx + 1, 1);
            }
          }
        }
        // console.log("distributionRankH",this.distributionRankH)
        if (item.categoryIds && item.categoryIds.length > 0) {
          this.secondCategoryItems = [];
          for (let i = 0, j = item.categoryIds.length; i < j; i++) {
            let mergeItem = item.categoryIds[i];
            let keywordList = JSON.parse(JSON.stringify(this.keywordList));
            // console.log("this.keywordList",keywordList)
            let isExist = keywordList.find((res) => {
              return res.id == mergeItem;
            });
            // console.log("mergeItem",mergeItem,"isExist",isExist)
            if (isExist) {
              this.secondCategoryItems.push(isExist);
            }
          }
        }
        // console.log("secondCategoryItems",this.secondCategoryItems)
        if (addArr && addArr.length > 0) {
          for (let i = 0, j = addArr.length; i < j; i++) {
            let addItem = addArr[i];
            let param = {
              ip_id: this.ipId,
              keyword_id: addItem,
            };

            let currentAddItem = this.secondCategoryItems.find((res) => {
              return res.id == addItem;
            })

            if (item.ipActiveText) {

              try {
                let res = await getStatisticsKeywordIpDistribution(param);
                this.initDistributionList(res, i,false,currentAddItem && currentAddItem.name || "");
                // 处理结果...
              } catch (error) {
                console.log("error", error)
                // 处理错误...
              }
            } else {

              try {
                let res = await getStatisticsKeywordCompeteIpDistribution(param);
                this.initDistributionList(res, i,false,currentAddItem && currentAddItem.name || "");
                // 处理结果...
              } catch (error) {
                console.log("error", error)
                // 处理错误...
              }
            }
          }
        }



        // if (item.categoryIds && item.categoryIds.length > 0 && this.secondCategoryItems.length == item.categoryIds.length) {
        //   for (let i = 0, j = this.secondCategoryItems.length; i < j; i++) {
        //     let tempItem = this.secondCategoryItems[i];
        //     let isIdExist = item.categoryIds.find((res) => {
        //       return res == tempItem.id;
        //     })
        //     if (isIdExist) {
        //       isCanGo = false;
        //     } else {
        //       isCanGo = true;
        //       break;
        //     }
        //   }
        // } else {
        //   isCanGo = true;
        // }
        // if (!isCanGo) {
        //   return;
        // }
        // this.disSecCategory = item.categoryIds;
        // if (item.categoryIds && item.categoryIds.length > 0) {
        //   this.secondCategoryItems = [];
        //   this.distributionRankH.splice(2, this.distributionRankH.length - 1);
        //   this.distributionRankL = JSON.parse(
        //     JSON.stringify(this.distributionRankL.slice(0, 1))
        //   );
        //   for (let i = 0, j = item.categoryIds.length; i < j; i++) {
        //     let isExist = this.keywordList.find((res) => {
        //       return res.id == item.categoryIds[i];
        //     });
        //     if (isExist) {
        //       this.secondCategoryItems.push(isExist);
        //     }
        //   }
        //   if (this.secondCategoryItems.length > 0) {
        //     for (let i = 0, j = this.secondCategoryItems.length; i < j; i++) {
        //       let param = {
        //         ip_id: this.ipId,
        //         keyword_id: this.secondCategoryItems[i].id,
        //       };

        //       if (item.ipActiveText) {
        //         let res = await getStatisticsKeywordIpDistribution(param);
        //         this.initDistributionList(res, i);
        //       } else {
        //         let res = await getStatisticsKeywordCompeteIpDistribution(param);
        //         this.initDistributionList(res, i);
        //       }
        //     }
        //   }
        // } else {
        //   this.secondCategoryItems = [];
        //   this.distributionRankH.splice(3, this.distributionRankH.length - 1);
        //   this.distributionRankL = this.distributionRankL.slice(0, 2);
        // }
      },
      async handleSecondIpKeywordChange(item) {
        console.log("handleSecondIpKeywordChange",item,"ipSecCategory",this.ipSecCategory)
        let isCanGo = false;
        let currentCat = JSON.parse(JSON.stringify(this.ipSecCategory));
        let addArr = this.compareArrays(item.categoryIds, currentCat);
        let delArr = this.compareArrays(currentCat, item.categoryIds);
        console.log("addArr", addArr, "delArr", delArr,"currentCat",currentCat,"item.categoryIds",item.categoryIds)
        this.ipSecCategory = item.categoryIds;
        console.log("ipSecCategory",this.ipSecCategory) 
        if(item.isFirst) {
          this.ipSecCategory.unshift("all")
        }
        
        if (delArr && delArr.length > 0) {
          for (let i = 0, j = delArr.length; i < j; i++) {
            let delItem = delArr[i];
            if(delItem == "all") {
              this.compareIp.splice(i, 1);
            } else {
              let delIdx = this.secondCategoryIpItems.findIndex((res) => {
                return res.id == delItem;
              })
              console.log("delIdx",delIdx)
              if (delIdx >= 0) {
                // this.distributionRankH.splice(delIdx + 2, 1);
                let delAll = item.categoryIds.findIndex((res) => {
                  return res == 'all';
                })
                console.log("delAll",delAll)
                if(delAll >= 0) {
                  this.compareIp.splice(delIdx + 1, 1);
                } else {
                  this.compareIp.splice(delIdx, 1);
                }
                
              }
            }
            
          }
        }
        console.log("distributionRankH",this.distributionRankH)
        if (item.categoryIds && item.categoryIds.length > 0) {
          this.secondCategoryIpItems = [];
          for (let i = 0, j = item.categoryIds.length; i < j; i++) {
            let mergeItem = item.categoryIds[i];
            console.log("mergeItem",mergeItem)
            let keywordList = JSON.parse(JSON.stringify(this.keywordList));
            // console.log("this.keywordList",keywordList)
            let isExist = keywordList.find((res) => {
              return res.id == mergeItem;
            });
            console.log("mergeItem",mergeItem,"isExist",isExist)
            if (isExist) {
              this.secondCategoryIpItems.push(isExist);
            }
            
          }
        } else {
          this.secondCategoryIpItems = [];
        }
        console.log("secondCategoryIpItems",this.secondCategoryIpItems)
        if (addArr && addArr.length > 0) {
          for (let i = 0, j = addArr.length; i < j; i++) {
            let addItem = addArr[i];
            if(addItem == "all") {
              
              this.compareIp.unshift(this.competeIpData)
            } else {
              let param = {
                ip_id: this.ipId,
                keyword_id: addItem,
              };

              let currentAddItem = this.secondCategoryIpItems.find((res) => {
                return res.id == addItem;
              })

              try {
                let res = await getStatisticsKeywordCompeteIp(param);
                // this.initCompareIpList(res, i,false,currentAddItem && currentAddItem.name || "");
                this.initCompareIpList(res);
                // 处理结果...
              } catch (error) {
                console.log("error", error)
                // 处理错误...
              }
            }
           
          }
        }


        
        // if (
        //   item.categoryIds &&
        //   item.categoryIds.length > 0 &&
        //   this.secondCategoryIpItems.length == item.categoryIds.length
        // ) {
        //   for (let i = 0, j = this.secondCategoryIpItems.length; i < j; i++) {
        //     let tempItem = this.secondCategoryIpItems[i];
        //     let isIdExist = item.categoryIds.find((res) => {
        //       return res == tempItem.id;
        //     });
        //     if (isIdExist) {
        //       isCanGo = false;
        //     } else {
        //       isCanGo = true;
        //       break;
        //     }
        //   }
        // } else {
        //   isCanGo = true;
        // }
        // if (!isCanGo) {
        //   return;
        // }
        // this.ipSecCategory = item.categoryIds;
        // console.log("ipSecCategory", this.ipSecCategory)
        // if (item.categoryIds && item.categoryIds.length > 0) {
        //   this.secondCategoryIpItems = [];
        //   this.compareIp = JSON.parse(JSON.stringify(this.compareIp.slice(0, 1)));
        //   for (let i = 0, j = item.categoryIds.length; i < j; i++) {
        //     let isExist = this.keywordList.find((res) => {
        //       return res.id == item.categoryIds[i];
        //     });
        //     if (isExist) {
        //       this.secondCategoryIpItems.push(isExist);
        //     }
        //   }
        //   if (this.secondCategoryIpItems.length > 0) {
        //     for (let i = 0, j = this.secondCategoryIpItems.length; i < j; i++) {
        //       let param = {
        //         ip_id: this.ipId,
        //         keyword_id: this.secondCategoryIpItems[i].id,
        //       };
        //       let res = await getStatisticsKeywordCompeteIp(param);
        //       console.log("res123123123", res, this.secondCategoryIpItems);
        //       this.initCompareIpList(res);
        //     }
        //   }
        // } else {
        //   this.secondCategoryIpItems = [];
        // }
      },
      initCompareIpList(res) {
        if (res && res.error == 0 && res.data) {
          this.compareIp.push(res.data)
        } else {
          this.compareIp.push([])
        }
        console.log("compareIp",this.compareIp)
      },
      async activeChange(ipActiveText) {
        this.ipActiveText = ipActiveText;
        this.distributionRankH.splice(2, this.distributionRankH.length - 1);
        this.distributionRankL = this.distributionRankL.slice(0, 1);
        console.log("distributionRankL_123123123", this.distributionRankL);

        if (this.secondCategoryItems.length > 0) {
          for (let i = 0, j = this.secondCategoryItems.length; i < j; i++) {
            let param = {
              ip_id: this.ipId,
              keyword_id: this.secondCategoryItems[i].id,
            };

            if (this.ipActiveText) {

              try {
                let res = await getStatisticsKeywordIpDistribution(param);
                this.initDistributionList(res, i,false,this.secondCategoryItems[i].name);
                // 处理结果...
              } catch (error) {
                console.log("error", error)
                // 处理错误...
              }
              
            } else {

              try {
                let res = await getStatisticsKeywordCompeteIpDistribution(param);
                this.initDistributionList(res, i,false,this.secondCategoryItems[i].name);
                // 处理结果...
              } catch (error) {
                console.log("error", error)
                // 处理错误...
              }
              
            }
          }
        }
      },
      delCategoryItem(item) {
        this.distributionRankH.splice(2, this.distributionRankH.length - 1);
        // this.distributionRankL.splice(1, this.distributionRankH.length);
        this.distributionRankL = this.distributionRankL.slice(0, 1);
        this.categoryItems = [];
        this.secondCategoryItems = [];
        this.secondCategoryOptions = [];
      },
      delSecCategoryItem(item) {
        this.distributionRankH.splice(2 + item.idx, 1);
        this.distributionRankL.splice(1 + item.idx, 1);
        this.secondCategoryItems.splice(item.idx - 1, 1);
      },
      getStatisticsKeywordListReq(id, type = "") {
        this.secondCategoryItems = [];
        this.$bus.$emit("clearSecond");
        let param = {
          category_id: id
        };
        getStatisticsKeywordList(param)
          .then((res) => {
            let tempList = (res && res.data && res.data.list) || [];
            if (type == "ip") {
              if (tempList.length > 1) {
                this.secondCategoryIpOptions = tempList;
              } else {
                this.secondCategoryIpOptions = [];
              }
            } else {
              if (tempList.length > 1) {
                this.secondCategoryOptions = tempList;
              } else {
                this.secondCategoryOptions = [];
              }
            }

          })
          .catch(() => {
            this.secondCategoryOptions = [];
            this.secondCategoryIpOptions = [];
          });
      },
      getStatisticsKeywordIpDistributionReq(id) {
        let param = {
          ip_id: this.ipId,
          keyword_id: id,
        };
        getStatisticsKeywordIpDistribution(param)
          .then((res) => {
            this.initDistributionList(res);
          })
          .catch(() => {
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
            }
          });
      },
      getStatisticsKeywordCompeteIpDistributionReq(id) {
        let param = {
          ip_id: this.ipId,
          keyword_id: id,
        };
        getStatisticsKeywordCompeteIpDistribution(param)
          .then((res) => {
            this.initDistributionList(res);
          })
          .catch(() => {
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
            }
          });
      },

      getStatisticsCategoryListReq() {
        getStatisticsCategoryList({
          cat: "market",
        })
          .then((res) => {
            this.categoryOptions = (res && res.data && res.data.list) || [];
          })
          .catch(() => {
            this.categoryOptions = [];
          });
        getStatisticsCategoryList()
          .then((res) => {
            this.categoryAllOptions = (res && res.data && res.data.list) || [];
          })
          .catch(() => {
            this.categoryAllOptions = [];
          });
      },
      getStatisticsCategoryCompeteIpReq(id, limit) {
        let param = {
          ip_id: this.ipId,
          category_id: id || 0,
          limit: limit || 10,
        };
        getStatisticsCategoryCompeteIp(param)
          .then((res) => {
            this.initCompareIpList(res);
          })
          .catch((err) => {
            this.initCompareIpList([]);
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
              // this.initCompareIpDefault();
            }
          });
      },
      initDistributionList(res, secondIdx = -1, isFirst = false,name) {
        let tempList = [];
        if (res && res.error == 0) {
          if (res.data && res.data) {
            for (let i = 0, j = res.data.length; i < j; i++) {
              let tempVal = res.data[i];
              // this.distributionRank[1]["rankList"].push()
              // let tempItem = {
              //   distribution_range: tempVal.distribution_range,
              //   rateList: [
              //     {
              //       rate: tempVal.sales_rate,
              //       trend: tempVal.sales_trend,
              //     },
              //     {
              //       rate: tempVal.price_rate,
              //       trend: tempVal.price_trend,
              //     },
              //   ],
              // };
              tempList.push(tempVal);
            }
          }
          if (tempList.length > 0) {
            let tempH = {
              // name:
              //   this.secondCategoryItems.length == 0 || isFirst
              //     ? this.categoryItems[secondIdx].name
              //     : secondIdx >= 0
              //     ? this.secondCategoryItems[secondIdx].name
              //     : this.secondCategoryItems[this.secondCategoryItems.length - 1]
              //         .name,
              // name:
              //   this.secondCategoryItems.length == 0 || isFirst
              //     ? ((this.categoryItems[secondIdx] && this.categoryItems[secondIdx].name) || this.categoryItems[0].name)
              //     : secondIdx >= 0
              //       ? this.secondCategoryItems[secondIdx].name
              //       : this.secondCategoryItems[this.secondCategoryItems.length - 1]
              //         .name,
              name: name,
              children: [
                {
                  name: "销量占比",
                },
                {
                  name: "销售额占比",
                },
              ],
            };
            this.distributionRankH.push(tempH);
            this.distributionRankL.push(tempList);
            console.log("distributionRankL", this.distributionRankL);
          }
        } else {
          this.$message({
            message: res.msg || "获取数据失败，请稍后重试",
            type: "error",
          });
        }
      },
      getStatisticsCategoryIpDistributionReq(id) {
        console.log("ipId", this.ipId);
        let param = {
          ip_id: this.ipId,
          category_id: id,
        };
        getStatisticsCategoryIpDistribution(param)
          .then((res) => {
            this.initDistributionList(res);
          })
          .catch((err) => {
            this.initDistributionList([]);
            console.log("getStatisticsCategoryIpDistributionReq", err)
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
            }
          });
      },
      getStatisticsCategoryCompeteIpDistributionReq(id) {
        let param = {
          ip_id: this.ipId,
          category_id: id,
        };
        getStatisticsCategoryCompeteIpDistribution(param)
          .then((res) => {
            this.initDistributionList(res);
          })
          .catch((err) => {
            this.initDistributionList([]);
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
            }
          });
      },
      handleSelect() { },
      beginAnalysis() {
        console.log("ipVal", this.ipVal);
        if (!this.ipVal) {
          this.$message({
            message: "请输入ip",
            type: "error",
          });
          return;
        }
        if (this.ipVal) {
          let isExist = this.restaurants.filter((res) => {
            return res.name == this.ipVal;
          });
          if (isExist && isExist.length > 0) {
            this.ipId = isExist[0].id;
          } else {
            this.$message({
              message: "请选择匹配的ip",
              type: "error",
            });
            return;
          }
        }

        this.getStatisticsIpReq();
      },
      getStatisticsIpReq() {
        let param = {
          ip_id: this.ipId,
        };
        getStatisticsIp(param)
          .then((res) => {
            this.distributionRangeList = [];
            this.categoryItems = [];
            this.secondCategoryItems = [];
            this.disCategory = [];

            this.disSecCategory = [];

            this.ipSecCategory = [];
            this.ipCategory = "";
            this.ipFirstCategory = "";
            this.categoryIpItems = [];
            this.secondCategoryIpOptions = [];
            this.secondCategoryIpItems = [];
            this.compareIpH = [
            //   {
            //   name: "同类IP平均"
            // }, 
            {
              name: "同类IP最高"
            },
            //  {
            //   name: "无IP平均"
            // }
          ];
          this.compareIpNewH = [
          {
            name: "同类IP Top5",
            children: [
              {
                name: "1",
              },
              {
                name: "2",
              },
              {
                name: "3",
              },
              {
                name: "4",
              },
              {
                name: "5",
              },
            ],
          },
        ]
            this.initDefaultData();
            if (res && res.error == 0 && res.data) {
              this.total = res.data.total || null;
              if (res.data && res.data.distribution) {
                let tempList = [];
                for (let i = 0, j = res.data.distribution.length; i < j; i++) {
                  let tempVal = res.data.distribution[i];
                  // this.distributionRank[1]["rankList"].push()
                  this.distributionRangeList.push(tempVal.distribution_range);
                  // let tempItem = {
                  //   distribution_range: tempVal.distribution_range,
                  //   rateList: [
                  //     {
                  //       rate: tempVal.sales_rate,
                  //       trend: tempVal.sales_trend,
                  //     },
                  //     {
                  //       rate: tempVal.price_rate,
                  //       trend: tempVal.price_trend,
                  //     },
                  //   ],
                  // };
                  tempList.push(tempVal);
                }
                this.distributionRankL.push(tempList);
                console.log("distributionRankL", this.distributionRankL);
              }

              if (res.data && res.data.category_price) {
                this.categoryRank[1]["rankList"] = res.data.category_price || [];
              }
              if (res.data && res.data.category_sales) {
                this.categoryRank[0]["rankList"] = res.data.category_sales || [];
              }

              let tempH = {
                name: res && res.data && res.data.ip_info.name || ""
              }
              this.compareIpH.unshift(tempH);
              this.compareIpH.unshift({
                name: ""
              });
              this.compareIpNewH.unshift(tempH)
              if (res.data && res.data.compete_ip) {
                this.compareIp.push(res.data.compete_ip);
                this.competeIpData = res.data.compete_ip;
              } else {
                this.competeIpData = [];
                this.compareIp.push([])
              }


              this.ipInfo = res.data.ip_info || null;
              if (res.data && res.data.platform_index) {
                let platformIndex = res.data.platform_index;
                let tempList = this.ipMarket[0]["rankList"];
                for (let i = 0, j = tempList.length; i < j; i++) {
                  let item = tempList[i];
                  if (platformIndex && platformIndex[item.typeIdx]) {
                    item["region_index"] = platformIndex[item.typeIdx];
                  }
                  if (platformIndex && platformIndex[item.typeIdx + "_trend"]) {
                    item["region_index_trend"] =
                      platformIndex[item.typeIdx + "_trend"];
                  }
                }
              }
              this.ipMarket[0]["rankList"].sort((a, b) => {
                return b.region_index - a.region_index;
              })

              if (res.data && res.data.region_index) {
                this.ipMarket[1]["rankList"] = res.data.region_index || [];
              }
              if (res.data && res.data.ip_info) {


                for (let m = 0, n = this.allScore.length; m < n; m++) {
                  let scoreItem = this.allScore[m];
                  scoreItem["value"] = this.ipInfo[scoreItem.type] || 0;
                  this.$set(this.allScore, m, scoreItem);
                }
              }
              this.flowIndex["search_index"] =
                (this.ipInfo && this.ipInfo.search_index) || 0;
              this.flowIndex["search_index_trend"] =
                (this.ipInfo && this.ipInfo.search_index_trend) || 0;
              this.flowIndex["media_index"] =
                (this.ipInfo && this.ipInfo.media_index) || 0;
              this.flowIndex["media_index_trend"] =
                (this.ipInfo && this.ipInfo.media_index_trend) || 0;

              this.distributionAgeDefault =
                (res.data && res.data.distribution_age_default) || null;
              this.distributionAge =
                (res.data && res.data.distribution_age) || null;

              if (this.ipId) {
                getStatisticsIpKeywordList({
                  ip_id: this.ipId
                }).then((resKey) => {
                  this.keywordList = resKey && resKey.data && resKey.data.list || [];
                  if (this.keywordList && this.keywordList.length > 0) {
                    let ipKeywordParam = {
                      categoryIds: [],
                      ipActiveText: true,
                      isFirst: true
                    }
                    if (this.keywordList[0]) {
                      ipKeywordParam.categoryIds.push(this.keywordList[0].id)
                    }
                    if (this.keywordList[1]) {
                      ipKeywordParam.categoryIds.push(this.keywordList[1].id)
                    }

                    this.handleSecondIpKeywordChange(ipKeywordParam);
                    this.handleSecondIpKeywordDisChange(ipKeywordParam)
                  }
                }).catch(() => {
                  this.keywordList = [];
                })
              }
            } else {
              this.$message({
                message: res.msg || "获取数据失败，请稍后重试",
                type: "error",
              });
              this.initCompareIpDefault();

            }
            this.isLoading = false;
          })
          .catch((err) => {
            this.distributionRangeList = [];
            this.isLoading = false;
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
              this.initCompareIpDefault()
              this.initDefaultData();
            }
          });
      },
      initCompareIpDefault() {
        this.compareIpH = [
        //   {
        //   name: "同类IP平均"
        // }, 
        {
          name: "同类IP最高"
        },
        //  {
        //   name: "无IP平均"
        // }
      ];
      this.compareIpNewH = [
          {
            name: "同类IP Top5",
            children: [
              {
                name: "1",
              },
              {
                name: "2",
              },
              {
                name: "3",
              },
              {
                name: "4",
              },
              {
                name: "5",
              },
            ],
          },
        ]
        let tempH = {
          name: this.ipVal || ""
        }
        this.compareIpH.unshift(tempH);
        this.compareIpH.unshift({
          name: ""
        });
        this.compareIpNewH.unshift(tempH)
        this.compareIp.push([])
      },
      querySearch(queryString, cb) {
        console.log("queryString", queryString);
        if (!queryString) {
          return;
        }
        getStatisticsSearchIp({
          q: queryString,
        })
          .then((res) => {
            console.log("res", res, "queryString", queryString);
            if (res && res.error == 0) {
              let restaurants = (res && res.data && res.data.list) || [];
              this.restaurants = restaurants;
              // let results = queryString
              //   ? restaurants.filter(this.createFilter(queryString))
              //   : restaurants;
              // 调用 callback 返回建议列表的数据
              console.log("restaurants", restaurants);
              cb(restaurants);
            }
          })
          .catch((err) => {
            // cb(results);
            this.restaurants = [];
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
            }
          });
      },
      claRate(range, rangeList) {
        let isExist = rangeList.filter((res) => {
          return res.distribution_range == range;
        });
        if (isExist && isExist.length > 0) {
          return isExist[0].rate;
        } else {
          return "";
        }
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (
            restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
            0
          );
        };
      },
      parseNumber(num) {
        return num < 10 ? "0" + num : num;
      },
      initCompareIpDefault() {
        this.compareIp = [
        ];
      },
      initDefaultData() {
        this.total = null;
        this.initCompareIpDefault();
        this.distributionRankH = [
          {
            name: "零售价区间\n\n（单位：元）",
          },
          {
            name: "全部品类",
            children: [
              {
                name: "销量占比",
              },
              {
                name: "销售额占比",
              },
            ],
          },
        ];
        this.distributionRankL = [];
        this.categoryRank = [
          {
            name: "销量",
            rankList: [],
          },
          {
            name: "销售额",
            rankList: [],
          },
        ];

        this.ipMarket = [
          {
            name: "平台热度",
            rankList: [
              {
                region_name: "头条",
                typeIdx: "point_toutiao_index",
                region_index: 0,
                region_index_trend: 0,
              },
              {
                region_name: "抖音",
                typeIdx: "point_douyin_index",
                region_index: 0,
                region_index_trend: 0,
              },
              {
                region_name: "快手",
                typeIdx: "point_kuaishou_index",
                region_index: 0,
                region_index_trend: 0,
              },
              {
                region_name: "微博",
                typeIdx: "point_weibo_index",
                region_index: 0,
                region_index_trend: 0,
              },
              {
                region_name: "百度",
                typeIdx: "point_search_index",
                region_index: 0,
                region_index_trend: 0,
              },
              {
                region_name: "小红书",
                typeIdx: "point_xiaohongshu_index",
                region_index: 0,
                region_index_trend: 0,
              },
            ],
          },
          {
            name: "地域热度",
            rankList: [],
          },
        ];
        this.ipInfo = null;
        this.flowIndex = {
          search_index: 0,
          search_index_trend: 0,
          media_index: 0,
          media_index_trend: 0,
        };
        this.distributionAge = null;
        this.distributionAgeDefault = null;
        this.allScore = [
          {
            name: "覆盖人群",
            type: "point_watched",
            value: 0,
          },
          {
            name: "受众认可",
            type: "point_rate",
            value: 0,
          },
          {
            name: "潜在客户",
            type: "point_star5_people",
            value: 0,
          },
          {
            name: "搜索指数",
            type: "point_search_index",
            value: 0,
          },
          {
            name: "媒体资讯",
            type: "point_media_index",
            value: 0,
          },
        ];
      },
    },
  };
</script>
<style lang="less" scoped>
  .market-analysis-box {
    padding: 0 30px 0 132px;
    display: flex;
    flex-direction: column;
    flex: 1;

    .default-title {
      font-weight: bold;
      font-size: 17px;
      color: #1a232f;
      line-height: 24px;
      text-align: left;
      margin-top: 0;
    }

    .idea-analysis {
      width: 100%;
      padding: 20px;
      background: #f8f8fa;
      border-radius: 10px;
      display: flex;
      margin-top: 17px;
      flex-direction: column;
      position: relative;

      .tips {
        font-size: 16px;
        color: #f56c6c;
        margin-bottom: 10px;
        text-align: left;
      }

      .category {
        display: flex;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        .desc {
          font-weight: 400;
          font-size: 14px;
          color: #626a83;
          line-height: 20px;
          text-align: left;
          width: 118px;
        }
      }

      .analysis-btn1 {
        position: absolute;
        left: 420px;
        cursor: pointer;
        top: 50px;
      }
    }

    .datetime-box {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      ::v-deep .el-date-editor {
        width: 120px;
        height: 24px;
        background: #f3f3f8;
        border-radius: 16px;
        cursor: pointer;
        margin-left: 10px;

        .el-input__inner {
          width: 100%;
          height: 100%;
          border: none;
          background: transparent;
          padding-left: 12px;
          cursor: pointer;
        }

        .el-input__prefix {
          // display: none;
          color: #3672fd;
          left: 100px;

          .el-input__icon {
            align-items: center;
            display: flex;
            line-height: 24px;
          }
        }

        .el-input__suffix {
          display: none;
        }
      }

      .icon-bottom {
        position: absolute;
        top: 0;
        right: 12px;
        bottom: 0;
        display: flex;
        align-items: center;
        color: #3672fd;
      }
    }

    .analysis-content {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      .analysis-left {
        width: 98%;
        display: flex;
        flex-direction: column;

        .ip-img {
          width: 100%;

          img {
            // width: 100%;
          }
        }

        .analysis-info {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 13px;

          .analysis-item {
            width: 48%;
            display: flex;
            background: #e0deff;
            border-radius: 10px;
            padding: 14px 30px;

            .item-left {
              flex: 1;
              display: flex;
              flex-direction: column;

              .name {
                font-weight: 400;
                font-size: 12px;
                color: #626a83;
                line-height: 17px;
                text-align: left;
              }

              .count {
                font-weight: bold;
                font-size: 40px;
                color: #1a232f;
                // line-height: 47px;
                text-align: left;
                display: flex;
                margin-top: 4px;

                .unit {
                  font-weight: bold;
                  font-size: 24px;
                  color: #1a232f;
                  text-align: left;
                  display: flex;
                  align-items: flex-end;
                }

                .unit-puls {
                  font-weight: bold;
                  font-size: 17px;
                  color: #1a232f;
                  text-align: left;
                }
              }
            }

            .item-right {
              display: flex;
              align-items: flex-end;

              .right-info {
                display: flex;
                align-items: center;
              }

              .up {
                font-weight: 400;
                font-size: 12px;
                color: #e6182e;
                line-height: 17px;
                text-align: left;
              }

              .down {
                font-weight: 400;
                font-size: 12px;
                color: #13b918;
                line-height: 17px;
                text-align: left;
              }

              img {
                width: 8px;
                height: 10px;
                margin-left: 6px;
              }
            }
          }
        }
      }

      .analysis-right {
        width: 48%;
      }
    }
  }
</style>
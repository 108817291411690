<template>
  <div class="market-analysis-box">
    <div class="datetime-box">

      <el-date-picker v-model="currentDate" type="month" placeholder="选择日期" prefix-icon="el-icon-caret-bottom"
        @change="datetimeChange" :picker-options="pickerOptions" :append-to-body="false">
      </el-date-picker>
    </div>
    <div class="analysis-content">
      <div class="analysis-left">
        <!-- <div class="analysis-info">

          <el-tooltip class="item" effect="dark" :content="calTotalSales()" placement="right">
            <div class="analysis-item">
              <div class="item-left">
                <div class="name">销量预估</div>
                <div class="count">
                  {{total && total.total_sales || 0}}
                </div>
              </div>
              <div class="item-right">
                <div class="name">环比</div>
                <div class="right-info">
                  <div class="percent" :class="[total && total.total_sales_trend > 0 ? 'up' : 'down']">
                    {{total && total.total_sales_trend || 0}}%
                  </div>
                  <div class="per-img">
                    <img v-if="total && total.total_sales_trend > 0" src="@/assets/images/market/icon_up.png">
                    <img v-else src="@/assets/images/market/icon_down.png">
                  </div>
                </div>

              </div>
            </div>
          </el-tooltip>

          <el-tooltip class="item" effect="dark" :content="calTotalPrice()" placement="right">
            <div class="analysis-item">
              <div class="item-left">
                <div class="name">销售额预估(单位:元)</div>
                <div class="count">
                  {{total && total.total_price || 0}}
                </div>
              </div>
              <div class="item-right">
                <div class="name">环比</div>
                <div class="right-info">
                  <div class="percent" :class="[total && total.total_price_trend > 0 ? 'up' : 'down']">
                    {{total && total.total_price_trend || 0}}%
                  </div>
                  <div class="per-img">
                    <img v-if="total && total.total_price_trend > 0" src="@/assets/images/market/icon_up.png">
                    <img v-else src="@/assets/images/market/icon_down.png">
                  </div>
                </div>

              </div>
            </div>
          </el-tooltip>

        </div> -->
        <RankCom title="热门品类" :currentList="categoryRank" :showChooseCate="true" placeholderDefault="全部品类"
          @categoryChange="categoryChange" :categoryOptions="categoryOptions" currentType="categoryRankType"
          valueType="analysisType" :calMax="true" calType="calPercentMax" :currentCategory="rankCategory"
          :showTip="true" :showTopTip="true" :categoryAllOptions="categoryAllOptions" :currentDate="currentDate"
          :total="total" :showAnalysis="true">
        </RankCom>
        <RankCom title="品类升温榜" :currentList="categoryUpRank" :showChooseCate="true" placeholderDefault="全部品类"
          @categoryChange="categoryChange" :categoryOptions="categoryOptions" currentType="categoryUpRankType"
          valueType="upDownType" :showRank="true" :calMax="true" calType="calPercentMax" labelName="增幅"
          rankTypeName="上期排名" :currentCategory="upRankCategory" :showRankTip="true" :showTip="true" tipType="upTip"
          :categoryAllOptions="categoryAllOptions" :showTopTip="true">
        </RankCom>
        <RankCom title="品类降温榜"
          v-if="categoryDownRank[0].rankList.length > 0 || categoryDownRank[1].rankList.length > 0"
          :currentList="categoryDownRank" :showChooseCate="true" placeholderDefault="全部品类"
          @categoryChange="categoryChange" :categoryOptions="categoryOptions" currentType="categoryDownRankType"
          valueType="upDownType" :showRank="true" :calMax="true" calType="calPercentMax" labelName="降幅"
          rankTypeName="上期排名" :currentCategory="downRankCategory" :showRankTip="true" :showTip="true" tipType="downTip"
          :categoryAllOptions="categoryAllOptions" :showTopTip="true">
        </RankCom>
        <RankTable v-if="calDateHide()" title="价格分布排行" subTitle="" :currentH="distributionRankH" :currentL="distributionRankL"
          :distributionRangeList="distributionRangeList" bgColor="#9893EB" placeholderDefault="全部品类"
          :showChooseCate="true" :categoryOptions="categoryOptions" :categoryAllOptions="categoryAllOptions"
          :secondCategoryOptions="secondCategoryOptions" currentType="distributionRankType" valueType="analysisType"
          :categoryItems="categoryItems" :secondCategoryItems="secondCategoryItems" @delCategoryItem="delCategoryItem"
          @delSecCategoryItem="delSecCategoryItem" @categoryFirstChange="categoryFirstChange"
          @handleSecondChange="handleSecondChange" @categoryChange="categoryChange" :currentCategory="disCategory"
          :currentFirstCategory="disFirstCategory" :currentSecondCategory="disSecCategory" :showTip="true"
          :currentDate="currentDate" :showTopTip="true" :dataLoading="dataLoading">
        </RankTable>
        <div class="flex mt-20" style="justify-content: space-between;">
          <RankCom style="width: 66%" title="流量指数排行" :hiddenLabel="true" :currentList="flowRank" bgColor="#DA8FF5"
            :showChooseCate="true" placeholderDefault="全部品类" @categoryChange="categoryChange" currentType="flowRankType"
            valueType="flowRankValue" :categoryOptions="categoryOptions" :currentCategory="flowCategory" :calMax="true"
            calType="calPercentMax" :showTopTip="true"></RankCom>
          <RankStar style="width: 33%" title="蓝海指数" :currentList="blueOceanIndex" :showChooseCate="true"
            placeholderDefault="全部品类" @categoryChange="categoryChange" :categoryOptions="categoryOptions"
            currentType="blueOceanIndexType" valueType="analysisType" :currentCategory="blueCategory"
            :showTopTip="true"></RankStar>
        </div>

        <RankCom title="热门IP" :currentList="ipRank" bgColor="#88ACFF" :radioList="radioList" :showRadio="showRadio"
          :showMulChooseCate="false" placeholderDefault="全部品类" @categoryChange="categoryChange"
          @radioChange="radioChange" :categoryOptions="categoryAllOptions" cateSelectLabel="品类筛选"
          currentType="ipRankType" valueType="analysisIpType" :isGoIp="true" :hiddenLabel="true" :isThousand="true"
          @categorySecondIpChange="categorySecondIpChange" :currentCategory="ipRankCategory" :hideAll="true"
          :calMax="true" calType="calPercentMax" :showTopTip="true" :showTopKeyword="true" :pageParam="ipRankTypeParam"
          @goPrevNext="goPrevNext" :showPage="true" :showTopH="true" :totalCount="totalIp">
        </RankCom>
        <RankCom title="飙升IP" :currentList="categoryIpUpRank" bgColor="#88ACFF" :radioList="radioList"
          :showRadio="showRadio" :showMulChooseCate="false" placeholderDefault="全部品类" @categoryChange="categoryChange"
          @radioChange="radioChange" :categoryOptions="categoryAllOptions" currentType="categoryIpUpRankType"
          valueType="analysisIpType" :isGoIp="true" :isThousand="true" cateSelectLabel="品类筛选" :hiddenLabel="true"
          @categorySecondIpChange="categorySecondIpChange" :currentCategory="upIpRankCategory" :hideAll="true"
          :calMax="true" calType="calPercentMax" labelName="增幅" :showTopKeyword="true" :showTopTip="true"
          :pageParam="ipUpRankTypeParam" @goPrevNext="goPrevNext" :showPage="true" :showTopH="true" :totalCount="totalIpUp">
        </RankCom>
        <!-- <RankCom title="下降IP榜" :currentList="categoryIpDownRank" bgColor="#88ACFF" :radioList="radioList"
          :showRadio="showRadio" :showMulChooseCate="true" placeholderDefault="全部品类" @categoryChange="categoryChange"
          @radioChange="radioChange" :categoryOptions="categoryAllOptions" currentType="categoryIpDownRankType"
          valueType="analysisIpType" :isGoIp="true" :isThousand="true" cateSelectLabel="品类筛选" :hiddenLabel="false"
          @categorySecondIpChange="categorySecondIpChange" :currentCategory="downIpRankCategory" :hideAll="true"
          :calMax="true" calType="calPercentMax" labelName="降幅">
        </RankCom> -->
        <RankCom  v-if="calDateHide()" title="新晋IP榜" :currentList="categoryIpNewRank" bgColor="#88ACFF" :radioList="radioList"
          :showRadio="showRadio" :showMulChooseCate="false" placeholderDefault="全部品类" @categoryChange="categoryChange"
          @radioChange="radioChange" :categoryOptions="categoryAllOptions" currentType="categoryIpNewRankType"
          valueType="analysisIpType" :isGoIp="true" :isThousand="true" cateSelectLabel="品类筛选" :hiddenLabel="true"
          @categorySecondIpChange="categorySecondIpChange" :currentCategory="newIpRankCategory" :hideAll="true"
          :calMax="true" calType="calPercentMax" labelName="增幅" :showTopTip="true" :showTopKeyword="true"
          :pageParam="ipNewRankTypeParam" @goPrevNext="goPrevNext" :showPage="true" :showTopH="true" :totalCount="totalIpNew">
        </RankCom>
        <div  v-if="calDateHide()" class="flex" style="justify-content: space-between;">
          <RankCom style="width: 52%" title="潜力IP榜" :currentList="categoryIpPotentailRank" bgColor="#88ACFF"
            :radioList="radioList" :showRadio="showRadio" :showChooseCate="false" placeholderDefault="全部品类"
            @categoryChange="categoryChange" @radioChange="radioChange" :categoryOptions="categoryAllOptions"
            currentType="categoryIpPotentailRankType" valueType="analysisPotenType" :isGoIp="true" :isThousand="true"
            :hiddenLabel="true" cateSelectLabel="品类筛选" :calMax="true" calType="calPercentMax"
            :currentCategory="potIpRankCategory" :showTopTip="true">
          </RankCom>

        </div>


      </div>
    </div>
  </div>
</template>
<script>
  import CountTo from "vue-count-to";
  import RankCom from "./rank.vue";
  import RankStar from "./rankStar.vue";
  import RankTable from "./rankTable.vue";
  import IpTable from "./ipTable.vue";
  import {
    getStatisticsCategoryList,
    getStatisticsMarket,
    getStatisticsMarketCategory,
    getStatisticsMarketCategoryUp,
    getStatisticsMarketCategoryDown,
    getStatisticsMarketCategoryDistribution,
    getStatisticsMarketKeyrowdDistribution,
    getStatisticsMarketCategoryIpUp,
    getStatisticsMarketIp,
    getStatisticsMarketCategoryIpDown,
    getStatisticsMarketCategoryIpNew,
    getStatisticsMarketCategoryBlueOcean,
    getStatisticsKeywordList,
    getStatisticsMarketCategoryIpPotential,
    getStatisticsMarketKeywordIp,
    getStatisticsMarketKeywordIpDown,
    getStatisticsMarketKeywordIpUp,
    getStatisticsMarketKeywordIpNew,
    getStatisticsMarketCategoryIndex,
    getStatisticsMarketIpUp,
    getStatisticsMarketIpNew
  } from "@/api/statistics";
  export default {
    components: {
      CountTo,
      RankCom,
      RankStar,
      RankTable,
      IpTable,
    },
    data() {
      return {
        currentDate: new Date(),
        pickerOptions: {
          disabledDate(time) {
            // return time.getTime() > Date.now() || time.getTime() < new Date(2024, 8, 1).getTime();
            return time.getTime() < new Date(2024, 7, 1).getTime();
          }
        },
        topRankStar: [],
        radioList: [
          {
            label: "Top 10",
            value: 10,
          },
          {
            label: "Top 20",
            value: 20,
          },
          {
            label: "Top 30",
            value: 30,
          },
        ],
        showRadio: false,
        categoryRank: [
          {
            name: "销量",
            rankList: [],
          },
          {
            name: "销售额",
            rankList: [],
          },
          // {
          //   name: "零售店铺",
          //   rankList: [],
          // },
        ],
        categoryUpRank: [
          {
            name: "销量",
            rankList: [],
          },
          {
            name: "销售额",
            rankList: [],
          },
          // {
          //   name: "零售店铺",
          //   rankList: [],
          // },
        ],
        categoryDownRank: [
          {
            name: "销量",
            rankList: [],
          },
          {
            name: "销售额",
            rankList: [],
          },
          // {
          //   name: "零售店铺",
          //   rankList: [],
          // },
        ],
        distributionRankH: [
          {
            name: "零售价区间\n\n（单位：元）",
          },
          {
            name: "全部品类",
            children: [
              {
                name: "销量占比",
              },
              {
                name: "销售额占比",
              },
            ],
          },
        ],
        distributionRankL: [],
        distributionRangeList: [],
        distributionRankIpH: [
          {
            name: "全部品类",
            children: [
              {
                name: "平均销量",
              },
              {
                name: "平均销售额",
              },
              {
                name: "平均成交价（单位：元）",
              },
            ],
          },
        ],
        distributionRankIpL: [],
        ipRank: [
          {
            name: "月销量（单位：万）",
            rankList: [],
          },
          {
            name: "月销售额（单位：万元）",
            rankList: [],
          },
          {
            name: "活跃度",
            rankList: [],
          },
        ],
        categoryIpRank: [
          {
            name: "平均月销量",
            rankList: [],
          },
          {
            name: "平均月销售额（单位：元）",
            rankList: [],
          },
          {
            name: "平均成交价（单位：元）",
            rankList: [],
          },
        ],
        categoryIpUpRank: [
          {
            name: "月销量",
            rankList: [],
          },
          {
            name: "月销售额",
            rankList: [],
          },
          // {
          //   name: "平均成交价",
          //   rankList: [],
          // },
        ],
        categoryIpDownRank: [
          {
            name: "平均月销量",
            rankList: [],
          },
          {
            name: "平均月销售额",
            rankList: [],
          },
          {
            name: "平均成交价",
            rankList: [],
          },
        ],
        categoryIpNewRank: [
          {
            name: "月销量（单位：万）",
            rankList: [],
          },
          {
            name: "月销售额（单位：万元）",
            rankList: [],
          },
          // {
          //   name: "平均成交价（单位：元）",
          //   rankList: [],
          // },
        ],
        categoryIpPotentailRank: [
          {
            name: "用户需求指数",
            rankList: [],
          },
        ],
        flowRank: [
          {
            name: "用户需求指数",
            rankList: [],
          },
          {
            name: "媒体关注指数",
            rankList: [],
          },
        ],
        blueOceanIndex: [
          {
            name: "蓝海指数",
            rankList: [],
          },
        ],
        total: null,
        currentId: null,
        categoryOptions: [],
        categoryAllOptions: [],
        categoryItems: [],
        secondCategoryOptions: [],
        secondCategoryItems: [],
        rankCategory: "",
        upRankCategory: "",
        downRankCategory: "",
        ipRankCategory: "",
        upIpRankCategory: "",
        downIpRankCategory: "",
        newIpRankCategory: "",
        potIpRankCategory: "",
        blueCategory: "",
        flowCategory: "",
        disCategory: "",
        disFirstCategory: [],
        disSecCategory: [],
        specificMonth: new Date(2024, 8, 1),
        ipRankTypeParam: {
          offset: 0,
          limit: 10,
          currentPage: 1
        },
        ipUpRankTypeParam: {
          offset: 0,
          limit: 10,
          currentPage: 1
        },
        ipNewRankTypeParam: {
          offset: 0,
          limit: 10,
          currentPage: 1
        },
        totalIp: 0,
        totalIpUp: 0,
        totalIpNew: 0,
        dataLoading: false
      };
    },
    created() {
      this.currentId = this.$route.query.currentId || null;
      console.log("currentId", this.currentId);
      // this.getCurrentDate();
      this.initData();

      this.$bus.$off("initData");
      this.$bus.$on("initData", () => {
        this.initData();
      });
    },
    methods: {
      calDateHide() {
        let tempDate = new Date();
        let tempDateStr = tempDate.getFullYear() + "-" + (tempDate.getMonth() + 1);
        let tempDateDay = tempDate.getDate();
        let currentDateStr = this.currentDate.getFullYear() + "-" + (this.currentDate.getMonth() + 1);
        let temp1 = new Date(tempDateStr).getTime();
        let temp2= new Date(currentDateStr).getTime();
        console.log("tempDateStr",tempDateStr,"currentDateStr",currentDateStr,"tempDateDay",tempDateDay,"temp1",temp2,temp1)
        if((tempDateStr == currentDateStr && tempDateDay >= 7) || temp1 < temp2) {
          // console.log("false")
          return false;
        } else {
          // console.log("true")
          return true;
        }
      },
      calTotalSales() {
        return (
          this.currentDate.getFullYear() +
          "年" +
          (this.currentDate.getMonth() + 1) +
          "月玩具总销售" +
          ((this.total && this.total.total_sales) || 0) +
          "，环比" +
          (this.total && this.total.total_sales_trend > 0 ? "增长" : "下降") +
          ((this.total && this.total.total_sales_trend) || 0) +
          "%"
        );
      },
      calTotalPrice() {
        return (
          this.currentDate.getFullYear() +
          "年" +
          (this.currentDate.getMonth() + 1) +
          "月玩具总销售" +
          ((this.total && this.total.total_price) || 0) +
          "，环比" +
          (this.total && this.total.total_price_trend > 0 ? "增长" : "下降") +
          ((this.total && this.total.total_price_trend) || 0) +
          "%"
        );
      },
      getCurrentDate() {
        let currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth();
        if (month === 0) {
          year -= 1;
          month = 12;
        }
        this.currentDate = new Date(year, month - 1, 1);

      },
      getYm() {
        let year = this.currentDate.getFullYear();
        // let month = this.parseNumber(this.currentDate.getMonth() + 1 - 2);
        let month = this.parseNumber(this.currentDate.getMonth() + 1);
        return Number(year + "" + month);
      },
      initData() {
        this.getStatisticsMarketReq();
        this.getStatisticsCategoryListReq();
      },
      datetimeChange() {
        console.log("currentDate", this.currentDate);
        this.initData();
      },
      goPrevNext(item) {

        if (item.currentType == "ipRankType") {
          if (item.num > 0) {
            this.ipRankTypeParam.currentPage = this.ipRankTypeParam.currentPage + 1;
          } else {
            this.ipRankTypeParam.currentPage = this.ipRankTypeParam.currentPage - 1;
          }
          this.getStatisticsMarketCategoryIpReq();
        } else if (item.currentType == "categoryIpUpRankType") {

          if (item.num > 0) {
            this.ipUpRankTypeParam.currentPage = this.ipUpRankTypeParam.currentPage + 1;
          } else {
            this.ipUpRankTypeParam.currentPage = this.ipUpRankTypeParam.currentPage - 1;
          }
          this.getStatisticsMarketCategoryIpUpReq();
        } else if (item.currentType == "categoryIpNewRankType") {
          if (item.num > 0) {
            this.ipNewRankTypeParam.currentPage = this.ipNewRankTypeParam.currentPage + 1;
          } else {
            this.ipNewRankTypeParam.currentPage = this.ipNewRankTypeParam.currentPage - 1;
          }
          this.getStatisticsMarketCategoryIpNewReq();
        }
      },
      categoryChange(item) {
        if (item.currentType == "categoryRankType") {
          this.rankCategory = item.categoryId || "";
          this.getStatisticsMarketCategoryReq(item.categoryId);
        }
        if (item.currentType == "categoryUpRankType") {
          this.upRankCategory = item.categoryId || "";
          this.getStatisticsMarketCategoryUpReq(item.categoryId);
        }
        if (item.currentType == "categoryDownRankType") {
          this.downRankCategory = item.categoryId || "";
          this.getStatisticsMarketCategoryDownReq(item.categoryId);
        }
        if (item.currentType == "ipRankType") {
          this.ipRankCategory = item.selectCategoryId || "";
          this.getStatisticsMarketCategoryIpReq(item.categoryId, item.limit);
        }
        if (item.currentType == "categoryIpUpRankType") {
          this.upIpRankCategory = item.selectCategoryId || "";
          this.getStatisticsMarketCategoryIpUpReq(item.categoryId, item.limit);
        }
        if (item.currentType == "categoryIpDownRankType") {
          this.downIpRankCategory = item.selectCategoryId || "";
          this.getStatisticsMarketCategoryIpDownReq(item.categoryId, item.limit);
        }
        if (item.currentType == "categoryIpNewRankType") {
          this.newIpRankCategory = item.selectCategoryId || "";
          this.getStatisticsMarketCategoryIpNewReq(item.categoryId, item.limit);
        }
        if (item.currentType == "categoryIpPotentailRankType") {
          this.potIpRankCategory = item.categoryId || "";
          this.getStatisticsMarketCategoryIpPotentialReq(
            item.categoryId,
            item.limit
          );
        }
        if (item.currentType == "blueOceanIndexType") {
          this.blueCategory = item.categoryId || "";
          this.getStatisticsMarketCategoryBlueOceanReq(item.categoryId);
        }
        if (item.currentType == "distributionRankType") {
          console.log("123", item)
          // if (this.categoryItems.length == 0) {
          //   this.categoryItems.push(item.currentItem);
          //   this.getStatisticsKeywordListReq(item.currentItem.id);
          //   this.getStatisticsMarketCategoryDistributionReq(item.categoryId);
          // } else {
          //   this.secondCategoryItems.push(item.currentItem);
          //   this.getStatisticsMarketKeyrowdDistributionReq(item.categoryId);
          // }

          this.categoryItems = [];
          this.disCategory = "";
          this.disFirstCategory = [];
          this.disSecCategory = [];
          this.secondCategoryItems = [];
          this.distributionRankH.splice(2, this.distributionRankH.length - 1);
          this.distributionRankL = this.distributionRankL.slice(0, 1);
          if (item.currentItem) {
            this.disCategory = item.categoryId;
            this.categoryItems.push(item.currentItem);
            this.getStatisticsKeywordListReq(item.currentItem.id);
            console.log("item.ipActiveText", item.ipActiveText);

            this.getStatisticsMarketCategoryDistributionReq(item.categoryId);
          } else {
            this.secondCategoryOptions = [];
          }
        }

        if (item.currentType == "flowRankType") {
          this.flowCategory = item.categoryId || "";
          this.getStatisticsMarketCategoryIndexReq(item.categoryId);
        }
      },
      categorySecondIpChange(item) {
        if (item.currentType == "ipRankType") {
          this.ipRankCategory = item.secondCategoryId;
          this.getStatisticsMarketKeywordIpReq(item.categoryId, item.limit);
        }
        if (item.currentType == "categoryIpUpRankType") {
          this.upIpRankCategory = item.secondCategoryId;
          this.getStatisticsMarketKeywordIpUpReq(item.categoryId, item.limit);
        }
        if (item.currentType == "categoryIpDownRankType") {
          this.downIpRankCategory = item.secondCategoryId;
          this.getStatisticsMarketKeywordIpDownReq(item.categoryId, item.limit);
        }
        if (item.currentType == "categoryIpNewRankType") {
          this.newIpRankCategory = item.secondCategoryId;
          this.getStatisticsMarketKeywordIpNewReq(item.categoryId, item.limit);
        }
      },
      radioChange(item) {
        if (item.currentType == "ipRankType") {
          this.getStatisticsMarketCategoryIpReq(item.categoryId, item.limit);
        }
        if (item.currentType == "categoryIpUpRankType") {
          this.getStatisticsMarketCategoryIpUpReq(item.categoryId, item.limit);
        }
        if (item.currentType == "categoryIpDownRankType") {
          this.getStatisticsMarketCategoryIpDownReq(item.categoryId, item.limit);
        }
        if (item.currentType == "categoryIpNewRankType") {
          this.getStatisticsMarketCategoryIpNewReq(item.categoryId, item.limit);
        }
        if (item.currentType == "categoryIpPotentailRankType") {
          this.getStatisticsMarketCategoryIpPotentialReq(
            item.categoryId,
            item.limit
          );
        }
      },
      async categoryFirstChange(item) {
        console.log("handleSecondIpKeywordChange",item,"disFirstCategory",this.disFirstCategory)
        let isCanGo = false;
        let currentCat = JSON.parse(JSON.stringify(this.disFirstCategory));
        let addArr = this.compareArrays(item.categoryIds, currentCat);
        let delArr = this.compareArrays(currentCat, item.categoryIds);
        console.log("addArr", addArr, "delArr", delArr,"currentCat",currentCat,"item.categoryIds",item.categoryIds)
        this.disFirstCategory = item.categoryIds;
        console.log("disFirstCategory",this.disFirstCategory) 
       
        
        if (delArr && delArr.length > 0) {
          for (let i = 0, j = delArr.length; i < j; i++) {
            let delItem = delArr[i];
            let delIdx = this.categoryItems.findIndex((res) => {
              return res.id == delItem;
            })
            console.log("delIdx",delIdx)
            if (delIdx >= 0) {
              this.distributionRankH.splice(delIdx + 2, 1);
              this.distributionRankL.splice(delIdx + 1, 1);
            }
          }
        }
        console.log("distributionRankH",this.distributionRankH)
        this.categoryItems = [];
        this.secondCategoryItems = [];
        if (item.categoryIds && item.categoryIds.length > 0) {
          
          for (let i = 0, j = item.categoryIds.length; i < j; i++) {
            let mergeItem = item.categoryIds[i];
            let keywordList = JSON.parse(JSON.stringify(this.categoryAllOptions));
            // console.log("this.keywordList",keywordList)
            let isExist = keywordList.find((res) => {
              return res.id == mergeItem;
            });
            console.log("mergeItem",mergeItem,"isExist",isExist)
            if (isExist) {
              this.categoryItems.push(isExist);
            }
          }
        }
        console.log("categoryItems",this.categoryItems)
        if (addArr && addArr.length > 0) {
          for (let i = 0, j = addArr.length; i < j; i++) {
            this.dataLoading = true;
            let addItem = addArr[i];
            let param = {
              ym: this.getYm(),
              category_id: addItem,
            };

            let currentAddItem = this.categoryItems.find((res) => {
              return res.id == addItem;
            })

            try {
              let res = await getStatisticsMarketCategoryDistribution(param);
              this.initDistributionList(res, i, true);
              if(i == addArr.length - 1) {
                this.dataLoading = false;
              }
              

              // 处理结果...
            } catch (error) {
              console.log("error", error)
              if(i == addArr.length - 1) {
                this.dataLoading = false;
              }
              // 处理错误...
            }
          }
        }


        // console.log("categoryFirstChange",item)
        // let isCanGo = false;
        // if (
        //   item.categoryIds &&
        //   item.categoryIds.length > 0 &&
        //   this.categoryItems.length == item.categoryIds.length
        // ) {
        //   for (let i = 0, j = this.categoryItems.length; i < j; i++) {
        //     let tempItem = this.categoryItems[i];
        //     let isIdExist = item.categoryIds.find((res) => {
        //       return res == tempItem.id;
        //     });
        //     if (isIdExist) {
        //       isCanGo = false;
        //     } else {
        //       isCanGo = true;
        //       break;
        //     }
        //   }
        // } else {
        //   isCanGo = true;
        // }
        // if (!isCanGo) {
        //   return;
        // }
        // this.disFirstCategory = item.categoryIds;
        // console.log("disFirstCategory", this.disFirstCategory)
        // this.categoryItems = [];
        // this.secondCategoryItems = [];
        // this.distributionRankH.splice(2, this.distributionRankH.length - 1);
        // this.distributionRankL = this.distributionRankL.slice(0, 1);
        // if (item.categoryIds && item.categoryIds.length > 0) {
        //   for (let i = 0, j = item.categoryIds.length; i < j; i++) {
        //     let isExist = this.categoryAllOptions.find((res) => {
        //       return res.id == item.categoryIds[i];
        //     });
        //     if (isExist) {
        //       this.categoryItems.push(isExist);
        //     }
        //   }
        //   if (this.categoryItems.length > 0) {
        //     if (this.categoryItems.length == 1) {
        //       // this.disCategory = this.categoryItems[0].id;
        //       // this.getStatisticsKeywordListReq(this.categoryItems[0].id);
        //     }
        //     console.log("this.categoryItems", this.categoryItems)
        //     for (let i = 0, j = this.categoryItems.length; i < j; i++) {
        //       let param = {
        //         ym: this.getYm(),
        //         category_id: this.categoryItems[i].id,
        //       };
        //       let res = await getStatisticsMarketCategoryDistribution(param);
        //       this.initDistributionList(res, i, true);
        //     }
        //   }
        // }

      },
      compareArrays(arr1, arr2) {
        return arr1.filter(item => !arr2.includes(item));
      },
      async handleSecondChange(item) {

        console.log("handleSecondIpKeywordChange",item,"disSecCategory",this.disSecCategory)
        let isCanGo = false;
        let currentCat = JSON.parse(JSON.stringify(this.disSecCategory));
        let addArr = this.compareArrays(item.categoryIds, currentCat);
        let delArr = this.compareArrays(currentCat, item.categoryIds);
        console.log("addArr", addArr, "delArr", delArr,"currentCat",currentCat,"item.categoryIds",item.categoryIds)
        this.disSecCategory = item.categoryIds;
        console.log("disSecCategory",this.disSecCategory) 
       
        
        if (delArr && delArr.length > 0) {
          for (let i = 0, j = delArr.length; i < j; i++) {
            let delItem = delArr[i];
            let delIdx = this.secondCategoryItems.findIndex((res) => {
              return res.id == delItem;
            })
            console.log("delIdx",delIdx)
            if (delIdx >= 0) {
              this.distributionRankH.splice(delIdx + 3, 1);
              this.distributionRankL.splice(delIdx + 2, 1);
            }
          }
        }
        console.log("distributionRankH",this.distributionRankH)
        if (item.categoryIds && item.categoryIds.length > 0) {
          this.secondCategoryItems = [];
          for (let i = 0, j = item.categoryIds.length; i < j; i++) {
            let mergeItem = item.categoryIds[i];
            let keywordList = JSON.parse(JSON.stringify(this.secondCategoryOptions));
            // console.log("this.keywordList",keywordList)
            let isExist = keywordList.find((res) => {
              return res.id == mergeItem;
            });
            console.log("mergeItem",mergeItem,"isExist",isExist)
            if (isExist) {
              this.secondCategoryItems.push(isExist);
            }
          }
        }
        console.log("secondCategoryItems",this.secondCategoryItems)
        if (addArr && addArr.length > 0) {
          for (let i = 0, j = addArr.length; i < j; i++) {
            this.dataLoading = true;
            let addItem = addArr[i];
            let param = {
              ym: this.getYm(),
              keyword_id: addItem,
            };

            let currentAddItem = this.secondCategoryItems.find((res) => {
              return res.id == addItem;
            })

            try {
              let res = await getStatisticsMarketKeyrowdDistribution(param);
              if(i == addArr.length - 1) {
                this.dataLoading = false;
              }
              this.initDistributionList(res, i);

              // 处理结果...
            } catch (error) {
              console.log("error", error)
              if(i == addArr.length - 1) {
                this.dataLoading = false;
              }
              // 处理错误...
            }
          }
        }


        // let isCanGo = false;
        // if (
        //   item.categoryIds &&
        //   item.categoryIds.length > 0 &&
        //   this.secondCategoryItems.length == item.categoryIds.length
        // ) {
        //   for (let i = 0, j = this.secondCategoryItems.length; i < j; i++) {
        //     let tempItem = this.secondCategoryItems[i];
        //     let isIdExist = item.categoryIds.find((res) => {
        //       return res == tempItem.id;
        //     });
        //     if (isIdExist) {
        //       isCanGo = false;
        //     } else {
        //       isCanGo = true;
        //       break;
        //     }
        //   }
        // } else {
        //   isCanGo = true;
        // }
        // if (!isCanGo) {
        //   return;
        // }
        // this.disSecCategory = item.categoryIds;
        // if (item.categoryIds && item.categoryIds.length > 0) {
        //   this.secondCategoryItems = [];
        //   this.distributionRankH.splice(3, this.distributionRankH.length - 1);
        //   this.distributionRankL = JSON.parse(
        //     JSON.stringify(this.distributionRankL.slice(0, 2))
        //   );
        //   for (let i = 0, j = item.categoryIds.length; i < j; i++) {
        //     let isExist = this.secondCategoryOptions.find((res) => {
        //       return res.id == item.categoryIds[i];
        //     });
        //     if (isExist) {
        //       this.secondCategoryItems.push(isExist);
        //     }
        //   }
        //   if (this.secondCategoryItems.length > 0) {
        //     for (let i = 0, j = this.secondCategoryItems.length; i < j; i++) {
        //       let param = {
        //         ip_id: this.ipId,
        //         keyword_id: this.secondCategoryItems[i].id,
        //       };

        //       let res = await getStatisticsMarketKeyrowdDistribution(param);
        //       this.initDistributionList(res, i);
        //     }
        //   }
        // } else {
        //   this.secondCategoryItems = [];
        //   this.distributionRankH.splice(3, this.distributionRankH.length - 1);
        //   this.distributionRankL = this.distributionRankL.slice(0, 2);
        // }
      },
      getStatisticsCategoryListReq() {
        getStatisticsCategoryList({
          cat: "market",
        })
          .then((res) => {
            this.categoryOptions = (res && res.data && res.data.list) || [];
          })
          .catch(() => {
            this.categoryOptions = [];
          });
        getStatisticsCategoryList()
          .then((res) => {
            this.categoryAllOptions = (res && res.data && res.data.list) || [];
          })
          .catch(() => {
            this.categoryAllOptions = [];
          });
      },
      getStatisticsMarketReq() {
        let param = {
          ym: this.getYm(),
        };
        let loading = this.$loading({
          lock: true,
          text: '数据分析中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        getStatisticsMarket(param)
          .then((res) => {
            console.log("res", res);
            this.distributionRangeList = [];
            this.categoryItems = [];
            this.secondCategoryItems = [];
            // this.$bus.$emit("clearSelect")
            this.rankCategory = "";
            this.upRankCategory = "";
            this.downRankCategory = "";
            this.ipRankCategory = "";
            this.upIpRankCategory = "";
            this.downIpRankCategory = "";
            this.newIpRankCategory = "";
            this.potIpRankCategory = "";
            this.blueCategory = "";
            this.flowCategory = "";
            this.disCategory = "";
            this.disFirstCategory = [];
            this.disSecCategory = [];
            this.ipRankTypeParam = {
              offset: 0,
              limit: 10,
              currentPage: 1
            }
            this.ipUpRankTypeParam = {
              offset: 0,
              limit: 10,
              currentPage: 1
            }
            this.ipNewRankTypeParam = {
              offset: 0,
              limit: 10,
              currentPage: 1
            }
            this.totalIp  = 0;
            this.totalIpUp = 0;
            this.totalIpNew = 0;
            this.initDefalutData();
            if (res && res.error == 0) {
              this.total = res.data.total || null;
              if (res.data && res.data.category_sales) {
                this.categoryRank[0]["rankList"] = res.data.category_sales || [];
              }
              if (res.data && res.data.category_price) {
                this.categoryRank[1]["rankList"] = res.data.category_price || [];
              }
              // if (res.data && res.data.category_shop) {
              //   this.categoryRank[2]["rankList"] = res.data.category_shop || [];
              // }

              if (res.data && res.data.category_sales_up) {
                this.categoryUpRank[0]["rankList"] =
                  res.data.category_sales_up || [];
              }
              if (res.data && res.data.category_price_up) {
                this.categoryUpRank[1]["rankList"] =
                  res.data.category_price_up || [];
              }
              // if (res.data && res.data.category_shop_up) {
              //   this.categoryUpRank[2]["rankList"] =
              //     res.data.category_shop_up || [];
              // }

              if (res.data && res.data.category_sales_down) {
                this.categoryDownRank[0]["rankList"] =
                  res.data.category_sales_down || [];
              }
              if (res.data && res.data.category_price_down) {
                this.categoryDownRank[1]["rankList"] =
                  res.data.category_price_down || [];
              }
              // if (res.data && res.data.category_shop_down) {
              //   this.categoryDownRank[2]["rankList"] =
              //     res.data.category_shop_down || [];
              // }
              // if (res.data && res.data.distribution) {
              //   this.distributionRank[1]["rankList"] =
              //     res.data.distribution_price || [];
              // }
              if (res.data && res.data.distribution) {
                let tempList = [];
                for (let i = 0, j = res.data.distribution.length; i < j; i++) {
                  let tempVal = res.data.distribution[i];
                  // this.distributionRank[1]["rankList"].push()
                  this.distributionRangeList.push(tempVal.distribution_range);
                  // let tempItem = {
                  //   distribution_range: tempVal.distribution_range,
                  //   rateList: [
                  //     {
                  //       rate: tempVal.sales_rate,
                  //       trend: tempVal.sales_trend,
                  //     },
                  //     {
                  //       rate: tempVal.price_rate,
                  //       trend: tempVal.price_trend,
                  //     },
                  //   ],
                  //   // rateList_0: [{
                  //   //   rate: tempVal.sales_rate
                  //   // },{
                  //   //   rate: this.claRate(tempVal.distribution_range,res.data && res.data.distribution_price || [])
                  //   // }]
                  // };
                  tempList.push(tempVal);
                }
                
                this.distributionRankL.push(tempList);
                console.log("distributionRankL", this.distributionRankL);
              }
              if (res.data && res.data.ip_avg_price) {
                // let tempList = [];
                // for (let i = 0, j = res.data.distribution.length; i < j; i++) {
                //   let tempVal = res.data.distribution[i];
                //   // this.distributionRank[1]["rankList"].push()
                //   this.distributionRangeList.push(tempVal.distribution_range);
                //   // let tempItem = {
                //   //   distribution_range: tempVal.distribution_range,
                //   //   rateList: [
                //   //     {
                //   //       rate: tempVal.sales_rate,
                //   //       trend: tempVal.sales_trend,
                //   //     },
                //   //     {
                //   //       rate: tempVal.price_rate,
                //   //       trend: tempVal.price_trend,
                //   //     },
                //   //   ],
                //   //   // rateList_0: [{
                //   //   //   rate: tempVal.sales_rate
                //   //   // },{
                //   //   //   rate: this.claRate(tempVal.distribution_range,res.data && res.data.distribution_price || [])
                //   //   // }]
                //   // };
                //   tempList.push(tempVal);
                // }
                // this.distributionRankL.push(tempList);
                // console.log("distributionRankL", this.distributionRankL);
              }

              if (res.data && res.data.ip_sales) {
                this.ipRank[0]["rankList"] = res.data.ip_sales || [];
              }
              if (res.data && res.data.ip_price) {
                this.ipRank[1]["rankList"] = res.data.ip_price || [];
              }
              if (res.data && res.data.ip_item_num_trend) {
                this.ipRank[2]["rankList"] = res.data.ip_item_num_trend || [];
              }

              if (res.data && res.data.ip_sales_up) {
                this.categoryIpUpRank[0]["rankList"] =
                  res.data.ip_sales_up || [];
              }
              if (res.data && res.data.ip_price_up) {
                this.categoryIpUpRank[1]["rankList"] =
                  res.data.ip_price_up || [];
              }
              // if (res.data && res.data.ip_unit_price_up) {
              //   this.categoryIpUpRank[2]["rankList"] =
              //     res.data.ip_unit_price_up || [];
              // }
              console.log("categoryIpUpRank", this.categoryIpUpRank)

              if (res.data && res.data.ip_avg_sales_down) {
                this.categoryIpDownRank[0]["rankList"] =
                  res.data.ip_avg_sales_down || [];
              }
              if (res.data && res.data.ip_avg_price_down) {
                this.categoryIpDownRank[1]["rankList"] =
                  res.data.ip_avg_price_down || [];
              }
              if (res.data && res.data.ip_unit_price_down) {
                this.categoryIpDownRank[2]["rankList"] =
                  res.data.ip_unit_price_down || [];
              }

              if (res.data && res.data.ip_sales_new) {
                this.categoryIpNewRank[0]["rankList"] =
                  res.data.ip_sales_new || [];
              }
              if (res.data && res.data.ip_price_new) {
                this.categoryIpNewRank[1]["rankList"] =
                  res.data.ip_price_new || [];
              }
              // if (res.data && res.data.ip_unit_price_new) {
              //   this.categoryIpNewRank[2]["rankList"] =
              //     res.data.ip_unit_price_new || [];
              // }

              if (res.data && res.data.ip_potential) {
                this.categoryIpPotentailRank[0]["rankList"] =
                  res.data.ip_potential || [];
              }
              // if (res.data && res.data.ip_price_potential) {
              //   this.categoryIpPotentailRank[1]["rankList"] =
              //     res.data.ip_price_potential || [];
              // }
              // if (res.data && res.data.ip_potential) {
              //   this.categoryIpPotentailRank[2]["rankList"] =
              //     res.data.ip_potential || [];
              // }

              if (res.data && res.data.media_index) {
                this.flowRank[1]["rankList"] = res.data.media_index || [];
              }
              if (res.data && res.data.search_index) {
                this.flowRank[0]["rankList"] = res.data.search_index || [];
              }

              if (this.flowRank[0]["rankList"].length > 0) {
                this.flowRank[0]["rankList"].sort((a, b) => {
                  return b.point_index - a.point_index;
                })
              }

              if (this.flowRank[1]["rankList"].length > 0) {
                this.flowRank[1]["rankList"].sort((a, b) => {
                  return b.point_index - a.point_index;
                })
              }


              if (res.data && res.data.blue_ocean_index) {
                this.blueOceanIndex[0]["rankList"] =
                  res.data.blue_ocean_index || [];
              }

              this.totalIp  = res.data.total_ip || 0;
              this.totalIpUp = res.data.total_ip_up || 0;
              this.totalIpNew = res.data.total_ip_new || 0;
              loading.close();
            } else {
              loading.close();
              this.$message({
                message: res.msg || "获取数据失败，请稍后重试",
                type: "error",
              });
              this.initDefalutData();
            }
          })
          .catch((err) => {
            loading.close();
            this.distributionRangeList = [];
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
              this.initDefalutData();
            }
          });
      },
      getStatisticsMarketCategoryIndexReq(id) {
        let param = {
          ym: this.getYm(),
          category_id: id,
        };
        getStatisticsMarketCategoryIndex(param)
          .then((res) => {
            this.initFlowRankDefault();
            if (res && res.error == 0) {
              if (res.data && res.data.media_index) {
                this.flowRank[1]["rankList"] = res.data.media_index || [];
              }
              if (res.data && res.data.search_index) {
                this.flowRank[0]["rankList"] = res.data.search_index || [];
              }

              if (this.flowRank[0]["rankList"].length > 0) {
                this.flowRank[0]["rankList"].sort((a, b) => {
                  return b.point_index - a.point_index;
                })
              }

              if (this.flowRank[1]["rankList"].length > 0) {
                this.flowRank[1]["rankList"].sort((a, b) => {
                  return b.point_index - a.point_index;
                })
              }
            } else {
              this.$message({
                message: res.msg || "获取数据失败，请稍后重试",
                type: "error",
              });
            }
          })
          .catch((err) => {
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
              this.initFlowRankDefault();
            }
          });
      },
      getStatisticsMarketCategoryReq(id) {
        let param = {
          ym: this.getYm(),
          category_id: id,
        };
        getStatisticsMarketCategory(param)
          .then((res) => {
            this.initCategoryRankDefault();
            if (res && res.error == 0) {
              if (res.data && res.data.category_sales) {
                this.categoryRank[0]["rankList"] = res.data.category_sales || [];
              }
              if (res.data && res.data.category_price) {
                this.categoryRank[1]["rankList"] = res.data.category_price || [];
              }

              // if (res.data && res.data.category_shop) {
              //   this.categoryRank[2]["rankList"] = res.data.category_shop || [];
              // }

              this.total = res.data.total || null;
            } else {
              this.$message({
                message: res.msg || "获取数据失败，请稍后重试",
                type: "error",
              });

            }
          })
          .catch((err) => {
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
              this.initCategoryRankDefault();
              this.total = null;
            }
          });
      },
      getStatisticsMarketCategoryUpReq(id) {
        let param = {
          ym: this.getYm(),
          category_id: id,
        };
        getStatisticsMarketCategoryUp(param)
          .then((res) => {
            this.initCategoryUpRankDefault();
            if (res && res.error == 0) {
              if (res.data && res.data.category_sales_up) {
                this.categoryUpRank[0]["rankList"] =
                  res.data.category_sales_up || [];
              }
              if (res.data && res.data.category_price_up) {
                this.categoryUpRank[1]["rankList"] =
                  res.data.category_price_up || [];
              }

              // if (res.data && res.data.category_shop_up) {
              //   this.categoryUpRank[2]["rankList"] =
              //     res.data.category_shop_up || [];
              // }
            } else {
              this.$message({
                message: res.msg || "获取数据失败，请稍后重试",
                type: "error",
              });
            }
          })
          .catch((err) => {
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
              this.initCategoryUpRankDefault();
            }
          });
      },
      getStatisticsMarketCategoryDownReq(id) {
        let param = {
          ym: this.getYm(),
          category_id: id,
        };
        getStatisticsMarketCategoryDown(param)
          .then((res) => {
            this.initCategoryDownRankDefault();
            if (res && res.error == 0) {
              if (res.data && res.data.category_sales_down) {
                this.categoryDownRank[0]["rankList"] =
                  res.data.category_sales_down || [];
              }
              if (res.data && res.data.category_price_down) {
                this.categoryDownRank[1]["rankList"] =
                  res.data.category_price_down || [];
              }

              // if (res.data && res.data.category_shop_down) {
              //   this.categoryDownRank[2]["rankList"] =
              //     res.data.category_shop_down || [];
              // }
            } else {
              this.$message({
                message: res.msg || "获取数据失败，请稍后重试",
                type: "error",
              });
            }
          })
          .catch((err) => {
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
              this.initCategoryDownRankDefault();
            }
          });
      },
      getStatisticsMarketCategoryDistributionReq(id) {
        let param = {
          ym: this.getYm(),
          category_id: id,
        };
        this.dataLoading = true;
        getStatisticsMarketCategoryDistribution(param)
          .then((res) => {
            this.dataLoading = false;
            this.initDistributionList(res);
          })
          .catch((err) => {
            this.dataLoading = false;
            this.initDistributionList()
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
            }
          });
      },
      delCategoryItem(item) {
        this.distributionRankH.splice(2, this.distributionRankH.length - 1);
        this.distributionRankL = this.distributionRankL.slice(0, 1);
        this.categoryItems = [];
        this.secondCategoryItems = [];
        this.secondCategoryOptions = [];
      },
      delSecCategoryItem(item) {
        this.distributionRankH.splice(2 + item.idx, 1);
        this.distributionRankL.splice(1 + item.idx, 1);
        this.secondCategoryItems.splice(item.idx - 1, 1);
      },
      getStatisticsKeywordListReq(id) {
        let param = {
          category_id: id,
        };
        this.$bus.$emit("clearSecond");

        getStatisticsKeywordList(param)
          .then((res) => {

            let tempList = (res && res.data && res.data.list) || [];
            if (tempList.length > 1) {
              this.secondCategoryOptions = tempList;
            } else {
              this.secondCategoryOptions = [];
            }

            // if(this.secondCategoryOptions && this.secondCategoryOptions.length > 0) {
            //   this.secondCategoryOptions = this.secondCategoryOptions.filter((tempItem) => {
            //     return tempItem.id != id;
            //   })
            // }
          })
          .catch(() => {

            this.secondCategoryOptions = [];
          });
      },
      initDistributionList(res, secondIdx = -1, isFirst = false) {
        let tempList = [];
        if (res && res.error == 0) {
          if (res.data && res.data) {
            for (let i = 0, j = res.data.length; i < j; i++) {
              let tempVal = res.data[i];
              // this.distributionRank[1]["rankList"].push()
              let tempItem = {
                distribution_range: tempVal.distribution_range,
                rateList: [
                  {
                    rate: tempVal.sales_rate,
                    trend: tempVal.sales_trend,
                  },
                  {
                    rate: tempVal.price_rate,
                    trend: tempVal.price_trend,
                  },
                ],
              };
              tempList.push(tempVal);
            }
          }
          if (tempList.length > 0) {
            let tempH = {
              name:
                this.secondCategoryItems.length == 0 || isFirst
                  ? ((this.categoryItems[secondIdx] && this.categoryItems[secondIdx].name) || this.categoryItems[0].name)
                  : secondIdx >= 0
                    ? this.secondCategoryItems[secondIdx].name
                    : this.secondCategoryItems[this.secondCategoryItems.length - 1]
                      .name,
              // name:
              //   this.secondCategoryItems.length == 0 || isFirst
              //     ? this.categoryItems[0].name
              //     : secondIdx >= 0
              //     ? this.secondCategoryItems[secondIdx].name
              //     : this.secondCategoryItems[this.secondCategoryItems.length - 1]
              //         .name,
              children: [
                {
                  name: "销量占比",
                },
                {
                  name: "销售额占比",
                },
              ],
            };
            this.distributionRankH.push(tempH);
            this.distributionRankL.push(tempList);
          }
        } else {
          this.$message({
            message: res.msg || "获取数据失败，请稍后重试",
            type: "error",
          });
        }
      },
      getStatisticsMarketKeyrowdDistributionReq(id) {
        let param = {
          ym: this.getYm(),
          keyword_id: id,
        };
        getStatisticsMarketKeyrowdDistribution(param)
          .then((res) => {
            this.initDistributionList(res);
          })
          .catch((err) => {
            this.initDistributionList();
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
            }
          });
      },
      getStatisticsMarketCategoryIpReq() {
        let param = {
          ym: this.getYm(),
          offset: (this.ipRankTypeParam.currentPage - 1) * this.ipRankTypeParam.limit,
          limit: this.ipRankTypeParam.limit,
        };
        getStatisticsMarketIp(param)
          .then((res) => {
            if (res && res.error == 0) {
              this.totalIp  = res.data.total || 0;
              if (res.data && res.data.ip_sales) {
                this.ipRank[0]["rankList"] = res.data.ip_sales || [];
              }
              if (res.data && res.data.ip_price) {
                this.ipRank[1]["rankList"] = res.data.ip_price || [];
              }

              // if (res.data && res.data.ip_item_num_trend) {
              //   this.ipRank[2]["rankList"] = res.data.ip_item_num_trend || [];
              // }
            } else {
              this.$message({
                message: res.msg || "获取数据失败，请稍后重试",
                type: "error",
              });
              this.initIpRankDefault();
            }
          })
          .catch((err) => {
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
              this.initIpRankDefault();
            }
          });
      },
      getStatisticsMarketKeywordIpReq(id, limit) {
        let param = {
          ym: this.getYm(),
          keyword_id: id,
          limit: limit || 10,
        };
        getStatisticsMarketKeywordIp(param)
          .then((res) => {
            if (res && res.error == 0) {
              if (res.data && res.data.ip_avg_sales) {
                this.ipRank[0]["rankList"] = res.data.ip_avg_sales || [];
              }
              if (res.data && res.data.ip_avg_price) {
                this.ipRank[1]["rankList"] = res.data.ip_avg_price || [];
              }

              if (res.data && res.data.ip_unit_price) {
                this.ipRank[2]["rankList"] = res.data.ip_unit_price || [];
              }
            } else {
              this.$message({
                message: res.msg || "获取数据失败，请稍后重试",
                type: "error",
              });
              this.initIpRankDefault();
            }
          })
          .catch((err) => {
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
              this.initIpRankDefault();
            }
          });
      },
      getStatisticsMarketCategoryIpUpReq(id, limit) {
        let param = {
          ym: this.getYm(),
          offset: (this.ipUpRankTypeParam.currentPage - 1) * this.ipUpRankTypeParam.limit,
          limit: this.ipUpRankTypeParam.limit,
        };
        getStatisticsMarketIpUp(param)
          .then((res) => {
            if (res && res.error == 0) {
              this.totalIpUp = res.data.total || 0;
              if (res.data && res.data.ip_sales_up) {
                this.categoryIpUpRank[0]["rankList"] =
                  res.data.ip_sales_up || [];
              }
              if (res.data && res.data.ip_price_up) {
                this.categoryIpUpRank[1]["rankList"] =
                  res.data.ip_price_up || [];
              }
              // if (res.data && res.data.ip_unit_price_up) {
              //   this.categoryIpUpRank[2]["rankList"] =
              //     res.data.ip_unit_price_up || [];
              // }
            } else {
              this.$message({
                message: res.msg || "获取数据失败，请稍后重试",
                type: "error",
              });
              this.initIpUpRankDefault();
            }
          })
          .catch((err) => {
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
              this.initIpUpRankDefault();
            }
          });
      },
      getStatisticsMarketKeywordIpUpReq(id, limit) {
        let param = {
          ym: this.getYm(),
          keyword_id: id,
          limit: limit || 10,
        };
        getStatisticsMarketKeywordIpUp(param)
          .then((res) => {
            if (res && res.error == 0) {
              if (res.data && res.data.ip_sales_up) {
                this.categoryIpUpRank[0]["rankList"] =
                  res.data.ip_avg_sales_up || [];
              }
              if (res.data && res.data.ip_price_up) {
                this.categoryIpUpRank[1]["rankList"] =
                  res.data.ip_avg_price_up || [];
              }
              // if (res.data && res.data.ip_unit_price_up) {
              //   this.categoryIpUpRank[2]["rankList"] =
              //     res.data.ip_unit_price_up || [];
              // }
            } else {
              this.$message({
                message: res.msg || "获取数据失败，请稍后重试",
                type: "error",
              });
              this.initIpUpRankDefault();
            }
          })
          .catch((err) => {
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
              this.initIpUpRankDefault();
            }
          });
      },
      getStatisticsMarketCategoryIpDownReq(id, limit) {
        let param = {
          ym: this.getYm(),
          category_id: id,
          limit: limit || 10,
        };
        getStatisticsMarketCategoryIpDown(param)
          .then((res) => {
            if (res && res.error == 0) {
              if (res.data && res.data.ip_avg_sales_down) {
                this.categoryIpDownRank[0]["rankList"] =
                  res.data.ip_avg_sales_down || [];
              }
              if (res.data && res.data.ip_avg_price_down) {
                this.categoryIpDownRank[1]["rankList"] =
                  res.data.ip_avg_price_down || [];
              }
              if (res.data && res.data.ip_unit_price_down) {
                this.categoryIpDownRank[2]["rankList"] =
                  res.data.ip_unit_price_down || [];
              }
            } else {
              this.$message({
                message: res.msg || "获取数据失败，请稍后重试",
                type: "error",
              });
              this.initIpDownRankDefault();
            }
          })
          .catch((err) => {
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
              this.initIpDownRankDefault();
            }
          });
      },
      getStatisticsMarketKeywordIpDownReq(id, limit) {
        let param = {
          ym: this.getYm(),
          keyword_id: id,
          limit: limit || 10,
        };
        getStatisticsMarketKeywordIpDown(param)
          .then((res) => {
            if (res && res.error == 0) {
              if (res.data && res.data.ip_avg_sales_down) {
                this.categoryIpDownRank[0]["rankList"] =
                  res.data.ip_avg_sales_down || [];
              }
              if (res.data && res.data.ip_avg_price_down) {
                this.categoryIpDownRank[1]["rankList"] =
                  res.data.ip_avg_price_down || [];
              }
              if (res.data && res.data.ip_unit_price_down) {
                this.categoryIpDownRank[2]["rankList"] =
                  res.data.ip_unit_price_down || [];
              }
            } else {
              this.$message({
                message: res.msg || "获取数据失败，请稍后重试",
                type: "error",
              });
              this.initIpDownRankDefault();
            }
          })
          .catch((err) => {
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
              this.initIpDownRankDefault();
            }
          });
      },
      getStatisticsMarketCategoryIpNewReq(id, limit) {
        let param = {
          ym: this.getYm(),
          offset: (this.ipNewRankTypeParam.currentPage - 1) * this.ipNewRankTypeParam.limit,
          limit: this.ipNewRankTypeParam.limit,
        };
        getStatisticsMarketIpNew(param)
          .then((res) => {
            this.initIpNewRankDefault();
            if (res && res.error == 0) {
              this.totalIpNew = res.data.total || 0;
              if (res.data && res.data.ip_sales_new) {
                this.categoryIpNewRank[0]["rankList"] =
                  res.data.ip_sales_new || [];
              }
              if (res.data && res.data.ip_price_new) {
                this.categoryIpNewRank[1]["rankList"] =
                  res.data.ip_price_new || [];
              }
              // if (res.data && res.data.ip_unit_price_new) {
              //   this.categoryIpNewRank[2]["rankList"] =
              //     res.data.ip_unit_price_new || [];
              // }
            } else {
              this.$message({
                message: res.msg || "获取数据失败，请稍后重试",
                type: "error",
              });
            }
          })
          .catch((err) => {
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
              this.initIpNewRankDefault();
            }
          });
      },
      getStatisticsMarketKeywordIpNewReq(id, limit) {
        let param = {
          ym: this.getYm(),
          keyword_id: id,
          limit: limit || 10,
        };
        getStatisticsMarketKeywordIpNew(param)
          .then((res) => {
            this.initIpNewRankDefault();
            if (res && res.error == 0) {
              if (res.data && res.data.ip_sales_new) {
                this.categoryIpNewRank[0]["rankList"] =
                  res.data.ip_sales_new || [];
              }
              if (res.data && res.data.ip_price_new) {
                this.categoryIpNewRank[1]["rankList"] =
                  res.data.ip_price_new || [];
              }
              // if (res.data && res.data.ip_unit_price_new) {
              //   this.categoryIpNewRank[2]["rankList"] =
              //     res.data.ip_unit_price_new || [];
              // }
            } else {
              this.$message({
                message: res.msg || "获取数据失败，请稍后重试",
                type: "error",
              });
            }
          })
          .catch((err) => {
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
              this.initIpNewRankDefault();
            }
          });
      },
      getStatisticsMarketCategoryIpPotentialReq(id, limit) {
        let param = {
          ym: this.getYm(),
          category_id: id,
          limit: limit || 10,
        };
        getStatisticsMarketCategoryIpPotential(param)
          .then((res) => {
            this.initIpPotentailRankDefault();
            if (res && res.error == 0) {
              if (res.data && res.data.ip_potential) {
                this.categoryIpPotentailRank[0]["rankList"] =
                  res.data.ip_potential || [];
              }
              // if (res.data && res.data.ip_price_potential) {
              //   this.categoryIpPotentailRank[1]["rankList"] =
              //     res.data.ip_price_potential || [];
              // }

              // if (res.data && res.data.ip_shop_potential) {
              //   this.categoryIpPotentailRank[2]["rankList"] =
              //     res.data.ip_shop_potential || [];
              // }
            } else {
              this.$message({
                message: res.msg || "获取数据失败，请稍后重试",
                type: "error",
              });
            }
          })
          .catch((err) => {
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
              this.initIpPotentailRankDefault();
            }
          });
      },
      getStatisticsMarketCategoryBlueOceanReq(id) {
        let param = {
          ym: this.getYm(),
          category_id: id,
        };
        getStatisticsMarketCategoryBlueOcean(param)
          .then((res) => {
            this.initBlueOceanIndexDefault();
            if (res && res.error == 0) {
              if (res.data && res.data) {
                this.blueOceanIndex[0]["rankList"] = res.data || [];
              }
            } else {
              this.$message({
                message: res.msg || "获取数据失败，请稍后重试",
                type: "error",
              });
            }
          })
          .catch((err) => {
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
              this.initBlueOceanIndexDefault();
            }
          });
      },
      claRate(range, rangeList) {
        let isExist = rangeList.filter((res) => {
          return res.distribution_range == range;
        });
        if (isExist && isExist.length > 0) {
          return isExist[0].rate;
        } else {
          return "";
        }
      },
      parseNumber(num) {
        return num < 10 ? "0" + num : num;
      },
      initCategoryRankDefault() {
        this.categoryRank = [
          {
            name: "销量",
            rankList: [],
          },
          {
            name: "销售额",
            rankList: [],
          },
          // {
          //   name: "零售店铺",
          //   rankList: [],
          // },
        ];
      },
      initCategoryUpRankDefault() {
        this.categoryUpRank = [
          {
            name: "销量",
            rankList: [],
          },
          {
            name: "销售额",
            rankList: [],
          },
          // {
          //   name: "零售店铺",
          //   rankList: [],
          // },
        ];
      },
      initCategoryDownRankDefault() {
        this.categoryDownRank = [
          {
            name: "销量",
            rankList: [],
          },
          {
            name: "销售额",
            rankList: [],
          },
          // {
          //   name: "零售店铺",
          //   rankList: [],
          // },
        ];
      },
      initIpRankDefault() {
        this.ipRank = [
          {
            name: "月销量（单位：万）",
            rankList: [],
          },
          {
            name: "月销售额（单位：万元）",
            rankList: [],
          },
          {
            name: "活跃度",
            rankList: [],
          }
        ];
      },
      initIpUpRankDefault() {
        this.categoryIpUpRank = [
          {
            name: "月销量",
            rankList: [],
          },
          {
            name: "月销售额",
            rankList: [],
          },
          // {
          //   name: "平均成交价",
          //   rankList: [],
          // },
        ];
      },
      initIpDownRankDefault() {
        this.categoryIpDownRank = [
          {
            name: "平均月销量",
            rankList: [],
          },
          {
            name: "平均月销售额",
            rankList: [],
          },
          {
            name: "平均成交价",
            rankList: [],
          },
        ];
      },
      initIpNewRankDefault() {
        this.categoryIpNewRank = [
          {
            name: "月销量（单位：万）",
            rankList: [],
          },
          {
            name: "月销售额（单位：万元）",
            rankList: [],
          },
          // {
          //   name: "平均成交价（单位：元）",
          //   rankList: [],
          // },
        ];
      },
      initIpPotentailRankDefault() {
        this.categoryIpPotentailRank = [
          {
            name: "用户需求指数",
            rankList: [],
          },
        ];
      },
      initBlueOceanIndexDefault() {
        this.blueOceanIndex = [
          {
            name: "蓝海指数",
            rankList: [],
          },
        ];
      },
      initFlowRankDefault() {
        this.flowRank = [
          {
            name: "用户需求指数",
            rankList: [],
          },
          {
            name: "媒体关注指数",
            rankList: [],
          },
        ];
      },
      initDefalutData() {
        this.initCategoryRankDefault();
        this.initCategoryUpRankDefault();
        this.initCategoryDownRankDefault();
        this.initIpPotentailRankDefault();
        this.distributionRankH = [
          {
            name: "零售价区间\n\n（单位：元）",
          },
          {
            name: "全部品类",
            children: [
              {
                name: "销量占比",
              },
              {
                name: "销售额占比",
              },
            ],
          },
        ];
        this.distributionRankL = [];
        this.distributionRankIpH = [
          {
            name: "全部品类",
            children: [
              {
                name: "平均销量",
              },
              {
                name: "平均销售额",
              },
              {
                name: "平均成交价（单位：元）",
              },
            ],
          },
        ];
        this.distributionRankIpL = [];

        this.initIpRankDefault();
        this.initIpUpRankDefault();
        this.initIpDownRankDefault();
        this.initIpNewRankDefault();
        this.initBlueOceanIndexDefault();
        this.initFlowRankDefault();

        this.total = null;
      },
    },
  };
</script>
<style lang="less" scoped>
  .market-analysis-box {
    padding: 0 30px 0 132px;
    display: flex;
    flex-direction: column;
    flex: 1;

    .datetime-box {
      position: relative;
      display: flex;
      justify-content: flex-start;

      ::v-deep .el-date-editor {
        width: 120px;
        height: 24px;
        background: #f3f3f8;
        border-radius: 16px;
        cursor: pointer;

        .today {
          .cell {
            color: #606266;
            font-weight: 400;
          }
        }

        .current {
          .cell {
            color: #409eff !important;
            font-weight: bolder !important;
          }
        }

        .el-input__inner {
          width: 100%;
          height: 100%;
          border: none;
          background: transparent;
          padding-left: 12px;
          cursor: pointer;
        }

        .el-input__prefix {
          // display: none;
          color: #3672fd;
          left: 100px;

          .el-input__icon {
            align-items: center;
            display: flex;
            line-height: 24px;
          }
        }

        .el-input__suffix {
          display: none;
        }
      }

      .icon-bottom {
        position: absolute;
        top: 0;
        right: 12px;
        bottom: 0;
        display: flex;
        align-items: center;
        color: #3672fd;
      }
    }

    .analysis-content {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      .analysis-left {
        width: 98%;
        display: flex;
        flex-direction: column;

        .analysis-info {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 13px;

          .analysis-item {
            width: 48%;
            display: flex;
            background: #e0deff;
            border-radius: 10px;
            padding: 14px 30px;

            .name {
              font-weight: 400;
              font-size: 24px;
              color: #1a232f;
              text-align: left;
            }

            .item-left {
              flex: 1;
              display: flex;
              flex-direction: column;



              .count {
                font-weight: bold;
                font-size: 40px;
                color: #1a232f;
                // line-height: 47px;
                text-align: left;
                justify-content: center;
                display: flex;
                margin-top: 4px;

                .unit {
                  font-weight: bold;
                  font-size: 24px;
                  color: #1a232f;
                  text-align: left;
                  display: flex;
                  align-items: flex-end;
                }

                .unit-puls {
                  font-weight: bold;
                  font-size: 17px;
                  color: #1a232f;
                  text-align: left;
                }
              }
            }

            .item-right {
              display: flex;
              /* align-items: flex-end; */
              flex: 1;
              flex-direction: column;

              .right-info {
                display: flex;
                align-items: center;
                font-size: 40px;
                justify-content: center;
              }

              .up {
                font-weight: 400;
                font-size: 40px;
                color: #e6182e;
                text-align: left;
              }

              .down {
                font-weight: 400;
                font-size: 40px;
                color: #13b918;
                text-align: left;
              }

              img {
                width: 16px;
                height: 20px;
                margin-left: 6px;
              }
            }
          }
        }
      }

      // .analysis-right {
      //   width: 48%;
      // }
    }
  }
</style>
<template>
	<div class="flex flex-column about-box">
        <i class="el-icon-circle-close icon-defalut-close cursor" @click="close"></i>
        <div class="default-title">关于我们</div>
        <div class="default-content">
            <div class="logo flex justify-center mt-55">
                <img class="rounded-20" src="@/assets/images/logo_blue.png">
            </div>
            <div class="mt-28 text-primary font-14 line-h-20 font-weight-bolder text-center">当前版本：V1.0</div>
            <div class="text-normal font-12 line-h-18 mt-5 text-center">当前为最新版</div>
            <div class="text-normal font-12 line-h-18 mb-20 mt-38 px-30 text-left">人工智能（AI）是致力于解决通常与人类智能相关联的认知性问题的计算机科学领域，这些问题包括学习、创造和图像识别等。人工智能有多种定义，但其中一个比较通用的定义是：</div>
            <div class="text-normal font-12 line-h-18 mb-20 px-30 text-left">人工智能是使计算机能够像人类一样思考和行动的科学。人工智能的核心技术包括：机器学习：机器学习是使计算机能够从数据中学习而不进行显式编程的技术。
                自然语言处理：自然语言处理是使计算机能够理解和处理人类语言的技术。计算机视觉：计算机视觉是使计算机能够理解和识别图像和视频的技术。</div>
            <div class="text-normal font-12 line-h-18 px-30 text-left">
                人工智能已经被广泛应用于各个领域，包括：医疗：人工智能可以用于诊断疾病、开发新药和提供个性化治疗。金融：人工智能可以用于检测欺诈、进行风险评估和管理投资组合。制造：人工智能可以用于预测机器故障、优化生产流程和提高产品质量。零售：人工智能可以用于推荐产品、个性化购物体验和优化供应链。
            </div>
        </div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {
            close() {
                this.$emit("closeHelp")
            }
		}
	}
</script>

<style lang="less" scoped>
    .about-box {
        width: 380px;
        background: #FFFFFF;
        box-shadow: 0px 4px 8px 0px #E7E8ED;
        border-radius: 20px;
        border: 1px solid #E7E8ED;
        position: absolute;
        left: 100%;
        top: 0;
        bottom: 0;
        margin-left: 10px;
        display: flex;
    }
	.logo {
		img {
			width: 200px;
			// height: 128px;
		}
	}
    .default-content {
        flex:1;
        overflow-y: auto;
        text-align: left;
    }
</style>
<template>
  <div class="flex flex-column about-box">
    <i class="el-icon-circle-close icon-defalut-close cursor"
      @click="close"></i>
    <div class="default-title">设置</div>
    <div class="default-content px-20">
      <div class="flex align-center mt-20">
        <div class="text-border mr-10"></div>
        <div
          class="font-14 text-default line-h-20 font-weight-bolder flex-1">
          语速设置</div>
      </div>
      <div class="mt-20 speed-box flex align-center">
        <div
          class="speed-item flex-1 flex align-center justify-center font-14 text-default cursor"
          :class="[currentIdx == idx ? 'active text-primary font-weight-bolder' : '']"
          v-for="(item,idx) in speedList" :key="idx"
          @click="changeSpeed(item,idx)">{{item.name}}
        </div>
      </div>
      <div class="flex align-center mt-20">
        <div class="text-border mr-10"></div>
        <div
          class="font-14 text-default line-h-20 font-weight-bolder flex-1">
          语音包设置</div>
      </div>
      <div class="voice-content">
        <el-select v-model="defaultValue" placeholder="请选择">
          <el-option v-for="item in voicePackage"
            :key="item.value" :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>

    </div>
    <div class="feedback-bottom flex align-center">
      <div class="flex flex-1  justify-end">
        <div class="default-btn cursor mr-20" @click="submit">保存设置</div>
      </div>

    </div>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {
      speedList: [
        {
          name: "较慢",
        },
        {
          name: "中等",
        },
        {
          name: "较快",
        },
      ],
      currentIdx: 0,
      voicePackage: [
        {
          value: "1",
          label: "男",
        },
        {
          value: "2",
          label: "女",
        },
      ],
      currentVoice: null,
      defaultValue: "",
      show: false,
    };
  },
  created() {
    this.currentVoice = this.voicePackage[0];
    this.defaultValue = this.currentVoice.label;
  },
  methods: {
    close() {
      this.$emit("closeHelp");
    },
    changeSpeed(item, idx) {
      this.currentIdx = idx;
    },
    submit() {
        this.close()
    }
  },
};
</script>
    
    <style lang="less" scoped>
.about-box {
  width: 380px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px #e7e8ed;
  border-radius: 20px;
  border: 1px solid #e7e8ed;
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  margin-left: 10px;
  display: flex;
  .default-content {
    flex: 1;
    overflow-y: auto;
    text-align: left;
    .speed-box {
      width: 340px;
      height: 36px;
      background: #f3f3f8;
      border-radius: 24px;

      .speed-item {
        height: 36px;

        &.active {
          background: #d9e5ff;
          box-shadow: 0px 2px 4px 0px #d9e5ff;
          border-radius: 22px;
          border: 1px solid #3672fd;
        }
      }
    }
    .voice-content {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        ::v-deep .el-select {
            width: 340px;
            height: 36px;
            background: #FBFBFC;
            box-shadow: 0px 2px 4px 0px #E7E8ED;
            border-radius: 24px;
            // border: 1px solid #E7E8ED;
            .el-input__inner {
                border-radius: 24px;
                border: 1px solid #E7E8ED;
            }
        }
    }
  }
}
</style>
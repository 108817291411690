import request from "@/utils/request";

/**
 * 创建聊天
 * model : ai-4, ernie-bot-4
 * type: 对话类型 0生成式文字聊天 1文学创作 2文生图 3图生图 4图像理解，图生文 5文学创作，图生文
 */
export function createChat(params){
    return request({
        url: '/chat/create',
        method: 'post',
        data: params
    })
}

/**
 * 删除聊天
 * id : chat id
 */
export function deleteChat(params) {
    return request({
        url: '/chat/delete',
        method: 'post',
        data: params
    })
}

/**
 * 获取聊天
 * id : chat id
 */
export function getChatInfo(id) {
    return request({
        url: '/chat/info?id=' + id,
        method: 'get'
    })
}

/**
 * 获取聊天列表
 * offset
 * limit
 */
export function getChatList(params) {
    return request({
        url: '/chat/list',
        method: 'get',
        params: params
    })
}

/**
 * 对话框提示消息
 * id : chat id
 */
export function getChatTipMessages(id) {
    return request({
        url: '/chat/tip_messages?id=' + id,
        method: 'get'
    })
}

/**
 * 获取聊天大语言模型列表
 * offset
 * limit
 */
export function getChatModels(params) {
    return request({
        url: '/chat/models',
        method: 'get',
        params: params
    })
}

/**
 * 对话历史消息
 * id: chat_id
 * offset
 * limit
 */
export function getChatMessages(params) {
    return request({
        url: '/chat/messages',
        method: 'get',
        params: params
    })
}

/**
 * 关闭聊天
 * id : chat id
 */
export function closeChat(params) {
    return request({
        url: '/chat/close',
        method: 'post',
        data: params
    })
}
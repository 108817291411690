<template>
  <div id="app">
    <HeaderTop @goLogin="goLogin" @goVip="goVip"></HeaderTop>
    <div class="menu-layer" v-if="showLayer"></div>
    <!-- <MenuCom @showFullLayer="showFullLayer"></MenuCom> -->
    <LoginRegister v-if="showLogin"
      @closeLogin="closeLogin"></LoginRegister>
    <RewardCom v-if="showReward"
      @closeRewardPopup="closeRewardPopup"></RewardCom>
    <VipPopup v-if="showVip" :userInfo="userInfo" @closeVip="closeVip"></VipPopup>
    <router-view />
  </div>
</template>
<script>
import HeaderTop from "@/components/headerTop/index.vue";
import MenuCom from "@/components/menu/index.vue";
import RewardCom from "@/components/home/reward.vue";
import LoginRegister from "@/components/login/index.vue";
import { EventBus } from '@/utils/eventBus.js';
import VipPopup from "@/components/user/vipPopup.vue";
import util from "@/utils/util";
export default {
  components: {
    HeaderTop,
    MenuCom,
    RewardCom,
    LoginRegister,
    VipPopup
  },
  data() {
    return {
      showLayer: false,
      showReward: false,
      showLogin: false,
      showVip: false,
      userInfo: {
        id: "",
        nickname: "",
        mobile: "",
        avatar: "",
        gender: "",
        desc: "",
        real_name: "",
        character: "",
        vip_level: 0,
        vip_expires_in: "",
        coin: 0,
        income: 0,
        balance: 0,
        freeze: 0,
        token: "",
      },
    };
  },
  created() {
    this.$bus.$off("openLogin");
    this.$bus.$on("openLogin",() => {
      this.showLogin = true;
    })

    EventBus.$off('openLogin');
    EventBus.$on('openLogin', () => {
      this.showLogin = true;
    });
    EventBus.$off('isFirstLogin');
    EventBus.$on('isFirstLogin', (val) => {
      if(val) {
        // this.showReward = true;
      }
      
    });
    
  },
  methods: {
    closeRewardPopup() {
      this.showReward = false;
    },
    showFullLayer(val) {
      this.showLayer = val;
    },
    closeLogin() {
      this.showLogin = false;
    },
    goLogin() {
      this.showLogin = true;
    },
    goVip(userInfo) {
      this.userInfo = userInfo;
      this.showVip = true;
    },
    closeVip() {
      this.showVip = false;
    },
    getUserInfo() {
      this.$store
        .dispatch("GetInfo", "")
        .then((res) => {
          this.userInfo = res;
        })
        .catch((err) => {
        });
    },
  },
};
</script>
<style lang="less" scoped>
</style>
import { render, staticRenderFns } from "./detailLeft.vue?vue&type=template&id=7b52e9f1&scoped=true"
import script from "./detailLeft.vue?vue&type=script&lang=js"
export * from "./detailLeft.vue?vue&type=script&lang=js"
import style0 from "./detailLeft.vue?vue&type=style&index=0&id=7b52e9f1&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b52e9f1",
  null
  
)

export default component.exports
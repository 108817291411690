<template>
    <div class="container">
      <div class="content-box">
        <div class="left-box">
        </div>
        <div class="center-box">
          <div class="center-default-box">
            <MarketCenter></MarketCenter>
          </div>
  
        </div>
        <div class="right-box">
        </div>
      </div>
    </div>
  </template>
  <script>
  import MarketCenter from "@/components/market/center.vue";

  
  export default {
    components: {
        MarketCenter
    },
    data() {
      return {
        showLogin: false,
        isLoading: false,
        showReward: false,
        showLayer: false,
        
      };
    },
    
    methods: {
      showFullLayer(val) {
          this.showLayer = val;
      },
      closeLogin() {
        this.showLogin = false;
      },
      goLogin() {
        this.showLogin = true;
      },
      changeDefaultIdx() {},
      sumbitTipFun() {
  
      },
      refresh() {
  
      },
      changeTipIdx() {
  
      },
      closeRewardPopup() {
          this.showReward = false;
      },
      
    },
  };
  </script>
  <style lang="less" scoped>
  .container {
    height: 100%;
    display: flex;
    /* 禁止文字选择 */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .menu-layer {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: transparent;
      z-index: 999;
    }
    .content-box {
      display: flex;
      padding-top: 128px;
      flex: 1;
  
      .left-box {
        width: 450px;
        display: flex;
      }
      .center-box {
        flex: 1;
        display: flex;
        min-width: 670px;
        .center-default-box {
          width: 100%;
          margin-bottom: 20px;
          display: flex;
          background: #f8f8fa;
          border-radius: 20px;
          flex-direction: column;
          position: relative;
          .problem-wrap {
              margin-top: 20px;
          }
        }
      }
      .right-box {
        width: 300px;
        display: flex;
      }
    }
  }
  </style>
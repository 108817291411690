<template>
    <div class="problem-box justify-end">
        <div class="flex mr-30" v-if="problemItem && problemItem.showZhuanFa">
            <img v-if="problemItem && problemItem.zhuanFaChoose" class="icon-select cursor mt-10"
                src="https://files.sssrise.com/files/uploads/static/icon_select_check.png" @click="chooseZhuanfa(1)">
            <img v-else class="icon-select cursor mt-10"
                src="https://files.sssrise.com/files/uploads/static/icon_select_default.png" @click="chooseZhuanfa(2)">
        </div>
        <div class="flex">
            <div class="problem-info mr-20">
                <div class="problem-right-desc position-relative align-center flex" style="flex-direction: column;">
                    <div class="dhk-arrow right-arrow">
                        <img style="width: 20px;height: 16px;"
                            src="https://files.sssrise.com/files/uploads/static/dhk_right_arrow.png">
                    </div>
                    <div class="strong-right" v-if="problemItem && problemItem.desc">{{problemItem && problemItem.desc}}
                    </div>
                    <div style="padding: 0 10px;" v-if="problemItem && problemItem.imageSrc">
                        <el-image style="max-width: 80%; " :src="problemItem.imageSrc"
                            :preview-src-list="[problemItem.imageSrc]">
                        </el-image>
                    </div>
                </div>
                <div class="date-time font-12 text-gray line-h-14 mt-5 flex justify-end">
                    {{formatTimeVal(problemItem.dataTime)}}</div>
            </div>
            <div class="problem-avatar ml-10">
                <img v-if="userInfo && userInfo.avatar" :src="userInfo.avatar">
                <img v-else src="https://files.sssrise.com/files/uploads/static/default_avatar.png">
            </div>
        </div>


    </div>
</template>

<script>
    import time from "@/utils/time";
    export default {
        props: ["problemItem", "problemIdx", "userInfo"],
        data() {
            return {
            }
        },
        methods: {
            formatTimeVal(val) {
                return time.formatTime(val);
            },
            showEditInput() {

            },
            chooseZhuanfa(type) {
                this.$emit("chooseZhuanfa", {
                    idx: this.problemIdx,
                    type: type
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    @import url("~@/assets/css/problemDesc.less");
</style>
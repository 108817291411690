<template>
  <div class="flex flex-column about-box">
    <i class="el-icon-circle-close icon-defalut-close cursor"
      @click="close"></i>
    <div class="default-title">活动记录</div>
    <div class="default-content">
      <div class="flex flex-column record-list">
        <div class="record-item mt-20 position-relative"
          v-for="(item,idx) in recordList" :key="idx">
          <div class="flex align-center">
            <div class="text-border mr-20"></div>
            <div
              class="font-14 text-default line-h-20 font-weight-bolder flex-1">
              {{item.name}}</div>
            <div class="font-12 line-h-18 text-normal"
              @click="clearItem(item,idx)">清除</div>
          </div>
          <div class="second-list"
            v-if="item.children && item.children.length > 0">
            <div class="second-item position-relative"
              v-for="(secondItem,secondIdx) in item.children"
              :key="secondIdx">
              <div class="text-gray font-12 flex line-h-20">
                <div class="text-default font-14 flex-1 one-text">
                    {{secondItem.name}}
                </div>
                {{secondItem.dateTime}}
              </div>
              <div
                class="text-normal font-12 line-h-18 mt-5 pr-30">
                {{secondItem.desc}}</div>
              <i class="el-icon-delete icon-del cursor"
                @click="delSecondItem(item,idx,secondItem,secondIdx)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      recordList: [
        {
          name: "今天",
          children: [
            {
              name: "知识询问知识询问知识询问知识询问知识询问知识询问知识询问",
              dateTime: "14:26:55",
              desc: "登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助",
            },
            {
              name: "创意市场",
              dateTime: "14:26:55",
              desc: "登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助",
            },
          ],
        },
        {
          name: "昨天",
          children: [
            {
              name: "知识询问",
              dateTime: "14:26:55",
              desc: "登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助",
            },
            {
              name: "创意市场",
              dateTime: "14:26:55",
              desc: "登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助",
            },
          ],
        },
        {
          name: "3月13日",
          children: [
            {
              name: "知识询问",
              dateTime: "14:26:55",
              desc: "登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助",
            },
            {
              name: "创意市场",
              dateTime: "14:26:55",
              desc: "登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助登录育儿助手-育儿助手应用帮助",
            },
          ],
        },
      ],
      show: false,
      content: "是否删除该记录",
      delIdx: -1,
      delSecondIdx: -1,
      isDelSecond: false,
    };
  },
  methods: {
    close() {
      this.$emit("closeHelp");
    },
    clearItem(item, idx) {
      this.show = true;
      this.isDelSecond = false;
      this.delIdx = idx;
    },
    delSecondItem(item, idx, secondItem, secondIdx) {
      this.show = true;
      this.delIdx = idx;
      this.delSecondIdx = secondIdx;
      this.isDelSecond = true;
    },
    confirm() {
      if (!this.isDelSecond) {
        this.recordList.splice(this.delIdx, 1);
      } else {
        let item = this.recordList[this.delIdx];
        if (item.children && item.children.length > 0) {
          item.children.splice(this.delSecondIdx, 1);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.about-box {
  width: 380px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px #e7e8ed;
  border-radius: 20px;
  border: 1px solid #e7e8ed;
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  margin-left: 10px;
  display: flex;
  .default-content {
    flex: 1;
    overflow-y: auto;
    text-align: left;
  }
}
.record-list {
    margin-top: 30px;
  .record-item {
    padding: 0 20px;
    margin-bottom: 30px;
  }
  .second-list {
    margin-top: 15px;
    .title {
        display: inline-block;
    }
    .second-item {
      width: 340px;
      background: #f8f8fa;
      border-radius: 10px;
      margin-bottom: 10px;
      padding: 10px 15px 14px 15px;
      
      &:last-child {
        margin-bottom: 0;
      }
      .icon-del {
        position: absolute;
        right: 15px;
        top: 45%;
      }
    }
  }
}
</style>
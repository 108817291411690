<template>
  <div class="collect-wrap">
    <div class="detail-header">
      <i class="el-icon-back icon-back"
        @click="closeCollect"></i>
      征集创意
    </div>

    <el-form :model="collectForm" :rules="rules"
      ref="collectForm" class="demo-ruleForm">
      <div class="collect-title mb-10">创意主题</div>
      <el-form-item label="" prop="collectName">
        <el-input v-model="collectForm.collectName"
          placeholder="请输入创意主题"></el-input>
      </el-form-item>
      <div class="collect-title mb-10">创意要求</div>
      <el-form-item label="" prop="desc">
        <el-input type="textarea" v-model="collectForm.desc"
          placeholder="请输入创意要求" resize="none" :autosize="{
            minRows: 6, 
            maxRows: 10
          }"></el-input>
      </el-form-item>
      <div class="collect-title">时间限制</div>
      <div class="collect-desc">
        时间到期后，活动会自动下架，如果是永久的话，您需要手动下架活动</div>
      <el-form-item class="radio-content" label=""
        prop="limitTime">
        <el-radio-group v-model="collectForm.limitTime">
          <el-radio v-for="(item,idx) in limitList"
            :key="idx"
            :label="item.value">{{item.label}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button class="submit-btn" type="primary"
          @click="submitForm('collectForm')">发布</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { postCreate } from "@/api/content";
import time from "@/utils/time";
export default {
  data() {
    return {
      collectForm: {
        collectName: "",
        desc: "",
        limitTime: "1",
      },
      limitList: [
        {
          label: "永久",
          value: "1",
        },
        {
          label: "1周",
          value: "2",
        },
        {
          label: "2周",
          value: "3",
        },
        {
          label: "4周",
          value: "4",
        },
      ],
      rules: {
        collectName: [
          { required: true, message: "请输入创意主题", trigger: "blur" },
          { min: 5, message: "长度至少5个字", trigger: "blur" },
        ],
        desc: [{ required: true, message: "请填写创意要求", trigger: "blur" }],
      },
    };
  },
  methods: {
    closeCollect() {
      this.$emit("closeCollect");
    },
    reset() {
      this.collectForm = {
        collectName: "",
        desc: "",
        limitTime: "1",
      };
    },
    calExpired(type) {
      let expiredAt = "";
      console.log("type",type)
      switch (Number(type)) {
        case 1:
          expiredAt = "";
          break;
        case 2:
          expiredAt = time.getFutureDate(new Date(), 7);
          break;
        case 3:
          expiredAt = time.getFutureDate(new Date(), 14);
          break;
        case 4:
          expiredAt = time.getFutureDate(new Date(), 28);
          break;
      }
      return expiredAt;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let expiredAt = this.calExpired(this.collectForm.limitTime);
          let param = {
            channel: "market",
            title: this.collectForm.collectName,
            content: this.collectForm.desc,
            type: 1
          };
          if (expiredAt) {
            expiredAt = time.dateFormat(new Date(expiredAt), "{Y}-{MM}-{DD}");
            param["expired_at"] = expiredAt;
          }
          postCreate(param)
            .then((res) => {
              if (res && res.error == 0) {
                this.closeCollect();
              } else {
                this.$message({
                  message: (res && res.msg) || "发布创意失败，请稍后重试",
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: (err && err.msg) || "发布创意失败，请稍后重试",
              });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.collect-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 30px;
  .detail-header {
    height: 63px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: bold;
    font-size: 17px;
    color: #1a232f;
    .icon-back {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      color: #626a83;
      font-size: 20px;
      cursor: pointer;
      font-weight: 400;
      display: flex;
      align-items: center;
    }
  }
  .collect-title {
    font-weight: bold;
    font-size: 14px;
    color: #1a232f;
    line-height: 20px;
    text-align: left;
    margin-top: 10px;
  }
  .collect-desc {
    font-weight: 400;
    font-size: 12px;
    color: #626a83;
    line-height: 17px;
    text-align: left;
    margin-top: 5px;
  }
  .radio-content {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    ::v-deep .el-radio-group {
      .el-radio__input.is-checked + .el-radio__label {
        color: #3672fd;
      }

      // .el-radio__label {
      //   display: flex;
      //   align-items: center;
      // }

      // /* 创建一个新的图标，这里以字体图标为例 */
      // .el-radio__label:before {
      //   content: ""; /* 这里使用的是字体图标的Unicode */
      //   font-family: "element-icons"; /* 你的图标字体 */
      //   font-style: normal;
      //   font-weight: normal;
      //   font-variant: normal;
      //   text-transform: none;
      //   -webkit-font-smoothing: antialiased;
      //   -moz-osx-font-smoothing: grayscale;
      //   display: inline-block;
      //   margin-right: 5px; /* 图标和文字间的距离 */

      //   width: 19px;
      //   height: 19px;
      //   background: #FFFFFF;
      //   border: 1px solid #CCCFDA;
      //   border-radius: 100%;
      // }

      // /* 当单选按钮被选中时改变图标样式 */
      // .el-radio__input.is-checked + .el-radio__label:before {
      //   content: "\e79c"; /* 选中状态的图标Unicode */
      //   color: #3672fd; /* 选中状态的图标颜色 */
      //   border: none;
      //   font-size: 18px; /* 根据需要调整图标大小 */
      // }
    }
  }
  .submit-btn {
    width: 320px;
    height: 48px;
    background: #3672fd;
    box-shadow: 0px 2px 4px 0px #d9e5ff;
    border-radius: 24px;
    margin-top: 60px;
  }
}
</style>
<template>
	<div class="positon-relative">
        <el-image 
            style="max-width: 80%; "
            :src="item" 
            :preview-src-list="[item]"
            @load="onImageLoad"
        >
        </el-image>
		<div class="position-relative" style="position: absolute;top:0;right:0;bottom: 0;left:0;z-index: 11;display:flex;align-items: center;
		justify-content: center;backdrop-filter: blur(10px);" v-if="loading">
			<div class="position-absolute loading-img" style="color: #3672FD;font-size: 12px;">图片加载中...</div>
            <div v-loading="loading"  class="">

            </div>
		</div>
	</div>
</template>

<script>
	
	export default {
		props: ["item"],
		data() {
			return {
				loading: true
			}
		},
		created() {
			this.loading = true;
		},
		methods: {
			onImageLoad(e) {
				console.log("onImageLoad",e)
			  this.loading = false;
			},
			previewImage(image) {
			  uni.previewImage({
				current: 0,
				urls: [image] // 需要预览的图片http链接列表
			  });
			},
		}
	}
</script>

<style>
</style>
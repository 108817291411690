<template>
  <div class="chat-history" ref="chatHistory">
    <div class="create-btn cursor" @click="goCreate"><img
        src="@/assets/images/menu.png">新建聊天</div>

    <div class="history-list" @scroll="handleScroll">
      <div class="history-item cursor"
        :class="[currentIdx == idx ? 'text-primary active' : 'text-normal']"
        v-for="(item,idx) in historyList" :key="idx"
        @click="getChatMessage(item,idx)">
        <i class="el-icon-chat-dot-round chat pl-10"
          :class="[currentIdx == idx ? 'text-primary' : 'text-normal']"></i>
        <div
          class="title flex-1 content-start one-text pl-10"
          :class="[currentIdx == idx ? 'text-primary' : 'text-normal']">
          {{item.title || ""}}</div>
        <i class="el-icon-delete trash pl-10 pr-10"
          :class="[currentIdx == idx ? 'text-primary' : 'text-normal']"
          @click.stop="delHistory(item,idx)"></i>
      </div>
    </div>
    <!-- <div class="del-box cursor"> <i class="el-icon-circle-close icon-close "></i>清空聊天记录</div> -->
  </div>
</template>
<script>
export default {
  props: ["historyList", "currentIdx", "statusLoadmore"],
  data() {
    return {
      noMore: false,
      isLoading: false,
    };
  },
  mounted() {
    // 确保滚动事件绑定
    this.$el.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    // 移除滚动事件监听
    this.$el.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    getChatMessage(item, idx) {
      this.$emit("getChatMessage", {
        item: item,
        idx: idx,
      });
    },
    handleScroll() {
      if (this.canLoadMore()) {
        this.$emit("loadMoreData");
      }
    },
    canLoadMore() {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      // console.log("scrollTop",scrollTop,"scrollHeight",scrollHeight,"clientHeight",clientHeight)
      const isBottom = scrollTop + clientHeight >= scrollHeight - 50;
      return isBottom && this.statusLoadmore == "loadmore";
    },
    goCreate() {
      this.$emit("goCreate");
    },
    delHistory(item, idx) {
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$emit("delHistory", {
            item: item,
            idx: idx,
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.chat-history {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  overflow-y: hidden;
  .create-btn {
    width: 240px;
    height: 36px;
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #979db1;
    font-weight: 400;
    font-size: 12px;
    color: #1a232f;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    img {
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }
  }
  .history-list {
    overflow-y: auto;
    display: flex;
    justify-content: center;
    flex: 1;
    flex-wrap: wrap;
    .history-item {
      width: 240px;
      height: 36px;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      border: 1px solid #e7e7f0;
      display: flex;
      align-items: center;
      margin-top: 20px;
      &.active {
        background: #d9e5ff;
        box-shadow: 0px 2px 4px 0px #d9e5ff;
        border-radius: 10px;
        border: 1px solid #3672fd;
      }
      .title {
        font-size: 12px;
        text-align: left;
      }
      .edit-pen {
        font-size: 14px;
      }
      .trash {
        font-size: 14px;
      }
    }
  }
  .del-box {
    padding: 6px 0;
    font-weight: 400;
    font-size: 12px;
    color: #1a232f;
    text-align: center;
    .icon-close {
      color: #1a232f;
      font-size: 14px;
      margin-right: 10px;
    }
  }
}
</style>
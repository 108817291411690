<template>
  <div class="product-box">
    <div class="product-center">
      <div class="product-list">
        <div class="product-item"
          v-for="(item,idx) in productList" :key="idx"
          :id="item.elementId"
          @click="goAnalysis(item.type)">
          <div class="product-img">
            <img :src="item.imgSrc">
          </div>
          <div class="product-info">
            <SecondTitle :secTitle="item.name">
            </SecondTitle>
            <div class="desc" v-html="item.desc"></div>
            <div class="opt">
              <div class="btn">立即体验</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import SecondTitle from "@/components/secondTitle/index.vue";
import { getToken } from "@/utils/auth";
import { getUserStatisticsCats } from "@/api/user";
export default {
  components: {
    SecondTitle,
  },
  data() {
    return {
      productList: [
        {
          name: "市场预测",
          desc: "探索玩具市场的无限潜力，全面掌握市场动态，洞悉消费趋势。通过深入的分析和精准的数据支持，助您发现市场的新机遇，抢占先机。无论是了解热门品类，关注市场变化，还是挖掘潜力产品，我们都为您提供最权威的市场洞察，助您在竞争中脱颖而出",
          imgSrc: require("@/assets/images/home/icon_1.png"),
          elementId: "report",
          type: "1-1",
        },
        // {
        //   name: "策略分析",
        //   desc: "通过我们的创新解决方案，您将获得无与伦比的市场洞察力，从而精准把握市场机遇和消费者需求，助力您制定高效的产品策略，抢占市场先机，获得成功",
        //   imgSrc: require("@/assets/images/home/icon_2.png"),
        //   elementId: "strategy",
        //   type: "1-2",
        // },
        {
          name: "IP分析",
          desc: "解锁IP市场的无限潜力，助您精准把握IP动态，优化策略，实现品牌价值最大化。在竞争激烈的市场中，始终保持领先地位。",
          imgSrc: require("@/assets/images/home/icon_3.png"),
          type: "1-3",
        },
        {
          name: "爆款策略",
          desc: "通过先进大模型计算技术，对玩具产品进行多维度的分析，并提供科学的数据支持，确保您的产品在市场上脱颖而出。",
          imgSrc: require("@/assets/images/home/icon_4.png"),
          type: "1-4",
        },
      ],
      isLogin: getToken() ? true : false,
      params: {
        offset: 0,
        limit: 20,
      },
    };
  },
  methods: {
    async goAnalysis(type) {
      this.isLogin = getToken() ? true : false;
      if (!this.isLogin) {
        this.$bus.$emit("openLogin");
        return;
      }
      let catInfo = await getUserStatisticsCats(this.params);
      console.log("catInfo",catInfo)
      if (type == "1-4") {
        this.$router.push({
          path: "/hotAi",
        });
      } else {
        if (catInfo && catInfo.data && catInfo.data.days > 0) {
          switch (type) {
            case "1-1":
              this.$router.push({
                path: "/analysis",
              });
              break;
            case "1-2":
              this.$router.push({
                path: "/idea",
              });
              break;
            case "1-3":
              this.$router.push({
                path: "/ipInfo",
              });
              break;
            case "1-4":
              this.$router.push({
                path: "/hotAi",
              });
              break;
          }
        } else {
          this.$message({
            message: "请先购买本产品",
            type: "error",
          });
          this.goMy();
        }
      }
    },
    goMy() {
      this.$router.push({
        path: "/user",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.product-box {
  width: 100%;
  // overflow-y: auto;
  // overflow-x: hidden;
  .product-center {
    // width: 1200px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;

    .top-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 50px 0;

      .btn {
        width: 198px;
        height: 56px;
        background: #3672fd;
        border-radius: 10px;
        font-weight: bold;
        font-size: 20px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .product-list {
      display: flex;
      flex-direction: column;
      /* margin-top: 50px; */
      width: 100%;
      .product-item {
        display: flex;
        margin-bottom: 60px;
        cursor: pointer;
        width: 100%;
        .product-img {
          img {
            width: 600px;
            height: 340px;
            border-radius: 10px;
          }
        }

        .product-info {
          display: flex;
          flex-direction: column;
          padding-left: 30px;
          flex: 1;
          .desc {
            font-weight: 400;
            font-size: 18px;
            color: #626a83;
            line-height: 24px;
            text-align: justify;
            font-style: normal;
            margin-top: 24px;
            padding-left: 16px;
            flex: 1;
          }

          .opt {
            display: flex;

            justify-content: flex-end;
            .btn {
              width: 150px;
              height: 40px;
              background: #3672fd;
              border-radius: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: bold;
              font-size: 17px;
              color: #ffffff;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}
</style>
<template>
  <div class="market-analysis-box">

    <div class="analysis-content">
      <div class="analysis-left">
        <div class="default-title">产品信息</div>
        <div class="idea-analysis">
          <div class="tips">*请选择您要分析的品类</div>
          <div class="category">
            <div class="desc">品类</div>
            <!-- <el-cascader v-model="category"
              :options="categoryAllOptions"
              :props="{value:'id', label: 'name'}"
              @change="handleChange"
              placeholder="请选择品类"></el-cascader> -->
            <el-cascader v-model="currentCategory" :options="options" placeholder="请选择品类" :props="props"
              @change="expandChange" clearable v-removeAriaHidden ref="cascader"></el-cascader>
          </div>
          <div class="category">
            <div class="desc">IP</div>
            <el-autocomplete class="inline-input" v-model="ipVal" :fetch-suggestions="querySearch" placeholder="请输入内容"
              :trigger-on-focus="true" :value-key="autoName" @select="handleSelect" :clearable="true"
              @clear="ipChange"></el-autocomplete>
          </div>
          <div class="default-btn2 analysis-btn" @click="beginAnalysis">开始分析</div>
        </div>
        <!-- <div class="datetime-box mt-30">
          <div class="default-title">数据周期</div>
          <el-date-picker v-model="currentDate" type="month"
            placeholder="选择日期"
            prefix-icon="el-icon-caret-bottom" @change="datetimeChange">
          </el-date-picker>
        </div> -->
        <!-- <div class="mt-30 flex content-between">
          <div class="default-title">品类销售分析</div>
        </div>

        <div class="analysis-info mt-16">
          <div class="analysis-item">
            <div class="item-left">
              <div class="name">销售</div>
              <div class="count">
                {{total && total.total_sales || 0}}
              </div>
            </div>
            <div class="item-right">
              <div class="right-info">
                <div class="percent" :class="[total && total.total_sales_trend > 0 ? 'up' : 'down']">
                  {{total && total.total_sales_trend || 0}}%
                </div>
                <div class="per-img">
                  <img v-if="total && total.total_sales_trend > 0" src="@/assets/images/market/icon_up.png">
                  <img v-else src="@/assets/images/market/icon_down.png">
                </div>
              </div>

            </div>
          </div>
          <div class="analysis-item">
            <div class="item-left">
              <div class="name">销售额</div>
              <div class="count">
                {{total && total.total_price || 0}}
              </div>
            </div>
            <div class="item-right">
              <div class="right-info">
                <div class="percent" :class="[total && total.total_price_trend > 0 ? 'up' : 'down']">
                  {{total && total.total_price_trend || 0}}%
                </div>
                <div class="per-img">
                  <img v-if="total && total.total_price_trend > 0" src="@/assets/images/market/icon_up.png">
                  <img v-else src="@/assets/images/market/icon_down.png">
                </div>
              </div>

            </div>
          </div>
        </div> -->

        <TextCom v-if="(productSalesStr || blueOceanStr) && !isLoading" class="mt-20" title="品类月销"
          :descText="productSalesStr" :secondText="blueOceanStr" currentType="plyxType" :showTopTip="true"></TextCom>
        <TextCom v-if="ipSalesStr && !isLoading" class="mt-20" title="IP影响" currentType="ipyxType"
          :descText="ipSalesStr" :showTopTip="true">
        </TextCom>
        <!-- :currentCategory="category"  -->
        <RankCom v-if="!isLoading && !secondCategory" class="mt-20" title="品类小类排行" bgColor="#9893EB"
          :currentList="categoryRank" :showChooseCate="true" placeholderDefault="全部品类" @categoryChange="categoryChange"
          :categoryOptions="categoryOptions" currentType="categorySmallRankType" valueType="analysisType" :calMax="true"
          :currentCategoryName="categoryName" calType="calPercentMax" :showTip="true" :showTopTip="true"></RankCom>

        <CompareCom v-if="!isLoading && ipId" class="mt-20" title="同类IP分析" :currentH="compareIpH"
          :currentList="compareIp" :showChooseCate="true" :showRadio="true" placeholderDefault="全部品类"
          @categoryChange="categoryChange" :categoryOptions="categoryOptions" :categoryAllOptions="categoryAllOptions"
          :secondCategoryOptions="secondCategoryIpOptions" currentType="compareIdeaIpType" valueType="analysisType"
          :ipId="ipId" :isGoIp="true" cateSelectLabel="品类筛选" :hiddenLabel="true" :categoryItems="categoryIpItems"
          :secondCategoryItems="secondCategoryIpItems" @categoryIpFirstChange="categoryIpFirstChange"
          @handleSecondIpChange="handleSecondIpChange" @handleSecondIpKeywordChange="handleSecondIpKeywordChange"
          :currentCategory="ipCategory" :currentFirstCategory="ipFirstCategory" :currentSecondCategory="ipSecCategory"
          :showTopTip="true" :keywordList="keywordList">
        </CompareCom>
        <RankTable v-if="!isLoading" class="mt-20" title="售价区间分析" subTitle="" :currentH="distributionRankH"
          :currentL="distributionRankL" :distributionRangeList="distributionRangeList" bgColor="#9893EB"
          placeholderDefault="全部品类" :showChooseCate="true" @categoryChange="categoryChange"
          :categoryOptions="categoryOptions" :categoryAllOptions="categoryAllOptions"
          :secondCategoryOptions="secondCategoryOptions" currentType="distributionIdeaRankType" valueType="analysisType"
          :categoryItems="categoryItems" :secondCategoryItems="secondCategoryItems" :ipId="ipId" :showSwitch="true"
          :isIpInfo="false" @activeChange="activeChange" @delCategoryItem="delCategoryItem"
          @delSecCategoryItem="delSecCategoryItem" @handleSecondChange="handleSecondChange"
          @categoryFirstChange="categoryFirstChange" :currentCategory="disCategory"
          :currentFirstCategory="disFirstCategory" :currentSecondCategory="disSecCategory"
          :selectSecondCategory="secondCategory" :showTopTip="true"
          @handleSecondIpKeywordDisChange="handleSecondIpKeywordDisChange" :keywordList="keywordList" :hiddenCate="true"
          :dataLoading="dataLoading">
        </RankTable>


        <!-- <IpAnalysis title="IP分析" :ipInfo="ipInfo"></IpAnalysis> -->
        <!-- <RankStar title="品牌排行" :currentList="topRankStar">
        </RankStar> -->
        <!-- <RankCom title="爆款消费者印象" :currentList="topRank1"
          bgColor="#DA8FF5" :hiddenLabel="true"></RankCom> -->
        <!-- <AdviceCom class="mt-20" title="应用建议" :suggestion="ipInfo"></AdviceCom> -->


        <FlowCom v-if="!isLoading && ipId" class="mt-20" title="消费者年龄分析" :ipInfo="ipInfo" :categoryName="categoryName"
          :distributionAge="distributionAge" :distributionAgeDefault="distributionAgeDefault" :showTopTip="true">
        </FlowCom>
        <MarketAnalysis v-if="!isLoading && ipId" class="mt-20" title="平台热度" :currentList="ipMarket" bgColor="#9893EB"
          currentType="ipMarket" :showTopTip="true">
        </MarketAnalysis>
        <MarketAnalysis v-if="!isLoading && ipId" class="mt-20" title="地域热度" :currentList="ipMarketRegion"
          bgColor="#9893EB" sum="1000" currentType="ipMarketRegion" :showTopTip="true">
        </MarketAnalysis>
        <el-empty v-if="isLoading" description="请选择您要分析的品类"></el-empty>
      </div>

    </div>
  </div>
</template>
<script>
  import CountTo from "vue-count-to";
  import RankCom from "./rank.vue";
  import RankStar from "./rankStar.vue";
  import IpAnalysis from "./ipAnalysis.vue";
  import AdviceCom from "./advice.vue";
  import FlowCom from "./ideaFlow.vue";
  import MarketAnalysis from "./marketAnalysis.vue";
  import TextCom from "./text.vue";
  import RankTable from "./rankTable.vue";
  import CompareCom from "./compareNew.vue";
  import {
    getStatisticsCategoryList,
    getStatisticsProduct,
    getStatisticsSearchIp,
    getStatisticsKeywordIpDistribution,
    getStatisticsCategoryCompeteIpDistribution,
    getStatisticsCategoryIpDistribution,
    getStatisticsMarketCategory,
    getStatisticsKeywordCompeteIpDistribution,
    getStatisticsMarketKeyrowdDistribution,
    getStatisticsKeywordList,
    getStatisticsMarketCategoryDistribution,
    getStatisticsCategoryCompeteIp,
    getStatisticsKeywordCompeteIp,
    getStatisticsIpKeywordList
  } from "@/api/statistics";
  export default {
    components: {
      CountTo,
      RankCom,
      RankStar,
      IpAnalysis,
      AdviceCom,
      FlowCom,
      MarketAnalysis,
      TextCom,
      RankTable,
      CompareCom,
    },
    data() {
      return {
        isLoading: true,
        currentDate: new Date(),
        currentCategory: "",
        category: "",
        categoryOptions: [],
        categoryAllOptions: [],
        secondCategory: "",
        ipVal: "",
        ipId: "",
        restaurants: [],
        autoName: "name",
        total: null,
        categoryRank: [
          {
            name: "销量",
            rankList: [],
          },
          {
            name: "销售额",
            rankList: [],
          },
          {
            name: "零售店铺",
            rankList: [],
          },
        ],
        distributionRankH: [
          {
            name: "零售价区间\n\n（单位：元）",
          },
          {
            name: "全部品类",
            children: [
              {
                name: "销量占比",
              },
              {
                name: "销售额占比",
              },
            ],
          },
        ],

        distributionRankL: [],
        ipMarket: [
          // {
          //   name: "品类",
          //   rankList: [
          //     {
          //       region_name: "头条",
          //       typeIdx: "point_toutiao_index",
          //       region_index: 0,
          //       region_index_trend: 0,
          //     },
          //     {
          //       region_name: "抖音",
          //       typeIdx: "point_douyin_index",
          //       region_index: 0,
          //       region_index_trend: 0,
          //     },
          //     {
          //       region_name: "快手",
          //       typeIdx: "point_kuaishou_index",
          //       region_index: 0,
          //       region_index_trend: 0,
          //     },
          //     {
          //       region_name: "微博",
          //       typeIdx: "point_weibo_index",
          //       region_index: 0,
          //       region_index_trend: 0,
          //     },
          //     {
          //       region_name: "百度",
          //       typeIdx: "point_search_index",
          //       region_index: 0,
          //       region_index_trend: 0,
          //     },
          //     {
          //       region_name: "小红书",
          //       typeIdx: "point_xiaohongshu_index",
          //       region_index: 0,
          //       region_index_trend: 0,
          //     },
          //   ],
          // },
          {
            name: "IP",
            rankList: [
              {
                region_name: "头条",
                typeIdx: "point_toutiao_index",
                region_index: 0,
                region_index_trend: 0,
              },
              {
                region_name: "抖音",
                typeIdx: "point_douyin_index",
                region_index: 0,
                region_index_trend: 0,
              },
              {
                region_name: "快手",
                typeIdx: "point_kuaishou_index",
                region_index: 0,
                region_index_trend: 0,
              },
              {
                region_name: "微博",
                typeIdx: "point_weibo_index",
                region_index: 0,
                region_index_trend: 0,
              },
              {
                region_name: "百度",
                typeIdx: "point_search_index",
                region_index: 0,
                region_index_trend: 0,
              },
              {
                region_name: "小红书",
                typeIdx: "point_xiaohongshu_index",
                region_index: 0,
                region_index_trend: 0,
              },
            ],
          },
        ],
        ipMarketRegion: [
          // {
          //   name: "品类",
          //   rankList: []
          // },
          {
            name: "IP",
            rankList: []
          }
        ],
        ipInfo: null,
        flowIndex: {
          search_index: 0,
          search_index_trend: 0,
          media_index: 0,
          media_index_trend: 0,
        },
        distributionAge: null,
        distributionAgeDefault: null,
        productSalesStr: null,
        ipSalesStr: null,
        blueOceanStr: null,
        categoryItems: [],
        secondCategoryOptions: [],
        distributionRangeList: [],
        secondCategoryItems: [],
        categoryName: "测试",
        options: [],
        disCategory: "",
        disFirstCategory: [],
        disSecCategory: [],
        ipSecCategory: [],
        ipCategory: "",
        ipFirstCategory: "",
        categoryIpItems: [],
        secondCategoryIpOptions: [],
        secondCategoryIpItems: [],
        compareIpH: [
          // {
          //   name: "同类IP平均",
          // },
          {
            name: "同类IP最高",
          },
          // {
          //   name: "无IP平均",
          // },
        ],
        compareIp: [],
        keywordList: [],
        dataLoading: false
      };
    },
    created() {
      this.initData();

      // this.category = 23;
      // this.secondCategory = 204;
      // this.ipId = 2020;
      // this.getStatisticsProductReq();

      this.$bus.$off("initData");
      this.$bus.$on("initData", () => {
        this.initData();
      });
    },
    computed: {
      props() {
        let self = this;
        return {
          lazy: true,
          value: "id",
          label: "name",
          children: "children",
          checkStrictly: false,
          expandTrigger: "click",
          lazyLoad(node, resolve) {
            const { level, children } = node;
            // console.log("level", level, "node", node, "currentDate",self);
            if (level == 1) {
              let param = {
                category_id: node.value,
              };
              if (children && children.length > 0) {
                resolve([]);
                return;
              }
              let nodes = [];
              let firstNode = {
                id: "all_" + node.value,
                dataId: node.value,
                name: "全部" + node.label,
                leaf: true,
                isAll: true,
              };
              nodes.push(firstNode);
              getStatisticsKeywordList(param)
                .then((res) => {
                  let tempList = (res && res.data && res.data.list) || [];
                  if (tempList.length > 1) {
                    let tempNodes = tempList.map((item) => {
                      return {
                        id: item.id,
                        name: item.name,
                        leaf: true,
                        isAll: false,
                      };
                    });
                    nodes = nodes.concat(tempNodes);
                    console.log("nodes", nodes);
                    resolve(nodes);
                  } else {
                    resolve(nodes);
                  }
                })
                .catch(() => {
                  resolve(nodes);
                });
            } else if (level > 1) {
              resolve([]);
            }
          },
        };
      },
    },
    methods: {
      initData() {
        this.getStatisticsCategoryListReq();
      },
      expandChange(e) {
        console.log("expandChange123123", e);
        this.categoryName = "品类";
        if (e.length == 0) {
          this.currentCategory = "";
          this.category = 0;
          this.secondCategory = "";
        } else {
          this.currentCategory = e;
          let nodeData = this.$refs["cascader"].getCheckedNodes()[0].data;
          console.log("nodeData", nodeData)
          this.categoryName = nodeData.name || "品类";
          if (nodeData.isAll) {
            this.category = nodeData.dataId || 0;
            this.secondCategory = "";
          } else {
            this.category = e[0];
            this.secondCategory = e[1];
          }
        }

      },
      categoryChange(item) {
        if (item.currentType == "categoryRankType") {
          this.getStatisticsMarketCategoryReq(item.categoryId);
        }
        if (item.currentType == "compareIdeaIpType") {
          // this.getStatisticsCategoryCompeteIpReq(item.categoryId, item.limit);
          this.ipFirstCategory = "";
          this.ipSecCategory = [];
          this.categoryIpItems = [];
          this.secondCategoryIpItems = [];
          // this.distributionRankH.splice(2, this.distributionRankH.length - 1);
          this.compareIp = this.compareIp.slice(0, 1);
          if (item.currentItem) {
            this.ipCategory = item.currentItem.id;
            this.categoryIpItems.push(item.currentItem);
            this.getStatisticsKeywordListReq(item.currentItem.id, "ip");
            this.getStatisticsCategoryCompeteIpReq(item.categoryId);
          } else {
            this.secondCategoryIpOptions = [];
          }
        }
        if (item.currentType == "distributionIdeaRankType") {
          this.disFirstCategory = [];
          this.disSecCategory = [];
          this.categoryItems = [];
          this.secondCategoryItems = [];
          this.distributionRankH.splice(2, this.distributionRankH.length - 1);
          this.distributionRankL = this.distributionRankL.slice(0, 1);
          if (item.currentItem) {
            this.disCategory = item.categoryId;
            this.categoryItems.push(item.currentItem);
            this.getStatisticsKeywordListReq(item.currentItem.id);
            if (this.ipId) {
              if (item.ipActiveText) {
                this.getStatisticsCategoryIpDistributionReq(item.categoryId, item.currentItem.name);
              } else {
                this.getStatisticsCategoryCompeteIpDistributionReq(
                  item.categoryId, item.currentItem.name
                );
              }
            } else {
              this.getStatisticsMarketCategoryDistributionReq(item.categoryId, item.currentItem.name);
            }
          } else {
            this.secondCategoryOptions = [];
          }
        }
      },
      async categoryFirstChange(item) {
        console.log("handleSecondIpKeywordChange", item, "disFirstCategory", this.disFirstCategory)
        let isCanGo = false;
        let currentCat = JSON.parse(JSON.stringify(this.disFirstCategory));
        let addArr = this.compareArrays(item.categoryIds, currentCat);
        let delArr = this.compareArrays(currentCat, item.categoryIds);
        console.log("addArr", addArr, "delArr", delArr, "currentCat", currentCat, "item.categoryIds", item.categoryIds)
        this.disFirstCategory = item.categoryIds;
        console.log("disFirstCategory", this.disFirstCategory)


        if (delArr && delArr.length > 0) {
          for (let i = 0, j = delArr.length; i < j; i++) {
            let delItem = delArr[i];
            let delIdx = this.categoryItems.findIndex((res) => {
              return res.id == delItem;
            })
            console.log("delIdx", delIdx)
            if (delIdx >= 0) {
              this.distributionRankH.splice(delIdx + 2, 1);
              this.distributionRankL.splice(delIdx + 1, 1);
            }
          }
        }
        console.log("distributionRankH", this.distributionRankH)
        this.categoryItems = [];
        this.secondCategoryItems = [];
        if (item.categoryIds && item.categoryIds.length > 0) {

          for (let i = 0, j = item.categoryIds.length; i < j; i++) {
            let mergeItem = item.categoryIds[i];
            let keywordList = JSON.parse(JSON.stringify(this.categoryAllOptions));
            // console.log("this.keywordList",keywordList)
            let isExist = keywordList.find((res) => {
              return res.id == mergeItem;
            });
            console.log("mergeItem", mergeItem, "isExist", isExist)
            if (isExist) {
              this.categoryItems.push(isExist);
            }
          }
        }
        console.log("categoryItems", this.categoryItems)
        if (addArr && addArr.length > 0) {
          for (let i = 0, j = addArr.length; i < j; i++) {
            this.dataLoading = true;
            let addItem = addArr[i];
            let param = {

            }
            let res = null;


            let currentAddItem = this.categoryItems.find((res) => {
              return res.id == addItem;
            })



            try {
              if (this.ipId) {
                if (item.ipActiveText) {
                  param = {
                    ip_id: this.ipId,
                    category_id: addItem,
                  };
                  res = await getStatisticsCategoryIpDistribution(param);
                } else {
                  param = {
                    ip_id: this.ipId,
                    category_id: addItem,
                  };
                  res = await getStatisticsCategoryCompeteIpDistribution(param);
                }
              } else {
                param = {
                  category_id: addItem,
                };
                res = await getStatisticsMarketCategoryDistribution(param);
              }

              this.initDistributionList(res, i, true, currentAddItem && currentAddItem.name || "");


              if (i == addArr.length - 1) {
                this.dataLoading = false;
              }


              // 处理结果...
            } catch (error) {
              console.log("error", error)
              if (i == addArr.length - 1) {
                this.dataLoading = false;
              }
              // 处理错误...
            }
          }
        }



        // let isCanGo = false;
        // if (
        //   item.categoryIds &&
        //   item.categoryIds.length > 0 &&
        //   this.categoryItems.length == item.categoryIds.length
        // ) {
        //   for (let i = 0, j = this.categoryItems.length; i < j; i++) {
        //     let tempItem = this.categoryItems[i];
        //     let isIdExist = item.categoryIds.find((res) => {
        //       return res == tempItem.id;
        //     });
        //     if (isIdExist) {
        //       isCanGo = false;
        //     } else {
        //       isCanGo = true;
        //       break;
        //     }
        //   }
        // } else {
        //   isCanGo = true;
        // }
        // if (!isCanGo) {
        //   return;
        // }

        // this.disFirstCategory = item.categoryIds;
        // this.categoryItems = [];
        // this.secondCategoryItems = [];
        // this.distributionRankH.splice(2, this.distributionRankH.length - 1);
        // this.distributionRankL = this.distributionRankL.slice(0, 1);
        // if (item.categoryIds && item.categoryIds.length > 0) {
        //   for (let i = 0, j = item.categoryIds.length; i < j; i++) {
        //     let isExist = this.categoryAllOptions.find((res) => {
        //       return res.id == item.categoryIds[i];
        //     });
        //     if (isExist) {
        //       this.categoryItems.push(isExist);
        //     }
        //   }
        //   if (this.categoryItems.length > 0) {
        //     if (this.categoryItems.length == 1) {
        //       // this.disCategory = this.categoryItems[0].id;
        //       // this.getStatisticsKeywordListReq(this.categoryItems[0].id);
        //     }

        //     for (let i = 0, j = this.categoryItems.length; i < j; i++) {
        //       let param = {};
        //       let res = null;

        //       if (this.ipId) {
        //         if (item.ipActiveText) {
        //           param = {
        //             ip_id: this.ipId,
        //             category_id: this.categoryItems[i].id,
        //           };
        //           res = await getStatisticsCategoryIpDistribution(param);
        //         } else {
        //           param = {
        //             ip_id: this.ipId,
        //             category_id: this.categoryItems[i].id,
        //           };
        //           res = await getStatisticsCategoryCompeteIpDistribution(param);
        //         }
        //       } else {
        //         param = {
        //           category_id: this.categoryItems[i].id,
        //         };
        //         res = await getStatisticsMarketCategoryDistribution(param);
        //       }

        //       this.initDistributionList(res, i, true);
        //     }
        //   }
        // }
      },
      compareArrays(arr1, arr2) {
        return arr1.filter(item => !arr2.includes(item));
      },
      async handleSecondChange(item) {
        console.log("handleSecondIpKeywordChange", item, "disSecCategory", this.disSecCategory)
        let isCanGo = false;
        let currentCat = JSON.parse(JSON.stringify(this.disSecCategory));
        let addArr = this.compareArrays(item.categoryIds, currentCat);
        let delArr = this.compareArrays(currentCat, item.categoryIds);
        console.log("addArr", addArr, "delArr", delArr, "currentCat", currentCat, "item.categoryIds", item.categoryIds)
        this.disSecCategory = item.categoryIds;
        console.log("disSecCategory", this.disSecCategory)


        if (delArr && delArr.length > 0) {
          for (let i = 0, j = delArr.length; i < j; i++) {
            let delItem = delArr[i];
            let delIdx = this.secondCategoryItems.findIndex((res) => {
              return res.id == delItem;
            })
            console.log("delIdx", delIdx)
            if (delIdx >= 0) {
              this.distributionRankH.splice(delIdx + 3, 1);
              this.distributionRankL.splice(delIdx + 2, 1);
            }
          }
        }
        console.log("distributionRankH", this.distributionRankH)
        if (item.categoryIds && item.categoryIds.length > 0) {
          this.secondCategoryItems = [];
          for (let i = 0, j = item.categoryIds.length; i < j; i++) {
            let mergeItem = item.categoryIds[i];
            let keywordList = JSON.parse(JSON.stringify(this.secondCategoryOptions));
            // console.log("this.keywordList",keywordList)
            let isExist = keywordList.find((res) => {
              return res.id == mergeItem;
            });
            console.log("mergeItem", mergeItem, "isExist", isExist)
            if (isExist) {
              this.secondCategoryItems.push(isExist);
            }
          }
        }
        console.log("secondCategoryItems", this.secondCategoryItems)
        if (addArr && addArr.length > 0) {
          for (let i = 0, j = addArr.length; i < j; i++) {
            this.dataLoading = true;
            let addItem = addArr[i];
            let param = {
              ip_id: this.ipId,
              keyword_id: addItem,
            };

            let currentAddItem = this.secondCategoryItems.find((res) => {
              return res.id == addItem;
            })

            if (this.ipId) {
              let param = {
                ip_id: this.ipId,
                keyword_id: addItem,
              };

              if (item.ipActiveText) {

                try {
                  let res = await getStatisticsKeywordIpDistribution(param);
                  this.initDistributionList(res, i, false, currentAddItem && currentAddItem.name || "");
                  if (i == addArr.length - 1) {
                    this.dataLoading = false;
                  }
                  // 处理结果...
                } catch (error) {
                  console.log("error", error)
                  if (i == addArr.length - 1) {
                    this.dataLoading = false;
                  }
                  // 处理错误...
                }
              } else {


                try {
                  let res = await getStatisticsKeywordCompeteIpDistribution(
                    param
                  );
                  this.initDistributionList(res, i, false, currentAddItem && currentAddItem.name || "");
                  if (i == addArr.length - 1) {
                    this.dataLoading = false;
                  }
                  // 处理结果...
                } catch (error) {
                  if (i == addArr.length - 1) {
                    this.dataLoading = false;
                  }
                  console.log("error", error)
                  // 处理错误...
                }
              }
            } else {
              let param = {
                keyword_id: addItem,
              };


              try {
                let res = await getStatisticsMarketKeyrowdDistribution(param);
                if (i == addArr.length - 1) {
                  this.dataLoading = false;
                }
                this.initDistributionList(res, i, false, currentAddItem && currentAddItem.name || "");

                // 处理结果...
              } catch (error) {
                if (i == addArr.length - 1) {
                  this.dataLoading = false;
                }
                console.log("error", error)
                // 处理错误...
              }
            }


          }
        }


        // if (item.categoryIds && item.categoryIds.length > 0) {
        //   this.secondCategoryItems = [];
        //   this.distributionRankH.splice(3, this.distributionRankH.length - 1);
        //   this.distributionRankL = JSON.parse(
        //     JSON.stringify(this.distributionRankL.slice(0, 2))
        //   );
        //   for (let i = 0, j = item.categoryIds.length; i < j; i++) {
        //     let isExist = this.secondCategoryOptions.find((res) => {
        //       return res.id == item.categoryIds[i];
        //     });
        //     if (isExist) {
        //       this.secondCategoryItems.push(isExist);
        //     }
        //   }
        //   if (this.secondCategoryItems.length > 0) {
        //     for (let i = 0, j = this.secondCategoryItems.length; i < j; i++) {
        //       if (this.ipId) {
        //         let param = {
        //           ip_id: this.ipId,
        //           keyword_id: this.secondCategoryItems[i].id,
        //         };

        //         if (item.ipActiveText) {
        //           let res = await getStatisticsKeywordIpDistribution(param);
        //           this.initDistributionList(res, i);
        //         } else {
        //           let res = await getStatisticsKeywordCompeteIpDistribution(
        //             param
        //           );
        //           this.initDistributionList(res, i);
        //         }
        //       } else {
        //         let param = {
        //           keyword_id: this.secondCategoryItems[i].id,
        //         };

        //         let res = await getStatisticsMarketKeyrowdDistribution(param);
        //         this.initDistributionList(res, i);
        //       }
        //     }
        //   }
        // } else {
        //   this.secondCategoryItems = [];
        //   this.distributionRankH.splice(3, this.distributionRankH.length - 1);
        //   this.distributionRankL = this.distributionRankL.slice(0, 2);
        // }
      },
      initDistributionList(res, secondIdx = -1, isFirst = false, name) {
        let tempList = [];

        if (res && res.error == 0) {
          if (res.data && res.data) {
            for (let i = 0, j = res.data.length; i < j; i++) {
              let tempVal = res.data[i];
              // this.distributionRank[1]["rankList"].push()
              let tempItem = {
                distribution_range: tempVal.distribution_range,
                rateList: [
                  {
                    rate: tempVal.sales_rate,
                    trend: tempVal.sales_trend,
                  },
                  {
                    rate: tempVal.price_rate,
                    trend: tempVal.price_trend,
                  },
                ],
              };
              tempList.push(tempVal);
            }
          }
          if (tempList.length > 0) {
            let tempH = {
              // name:
              //   this.secondCategoryItems.length == 0 || isFirst
              //     ? this.categoryItems[secondIdx].name
              //     : secondIdx >= 0
              //       ? this.secondCategoryItems[secondIdx].name
              //       : this.secondCategoryItems[this.secondCategoryItems.length - 1]
              //         .name,
              // name:
              //   this.secondCategoryItems.length == 0 || isFirst
              //     ? (this.categoryItems[secondIdx] &&
              //       this.categoryItems[secondIdx].name) ||
              //     this.categoryItems[0].name
              //     : secondIdx >= 0
              //       ? this.secondCategoryItems[secondIdx].name
              //       : this.secondCategoryItems[this.secondCategoryItems.length - 1]
              //         .name,
              name: name,
              children: [
                {
                  name: "销量占比",
                },
                {
                  name: "销售额占比",
                },
              ],
            };
            this.distributionRankH.push(tempH);
            this.distributionRankL.push(tempList);
          }
        } else {
          this.$message({
            message: res.msg || "获取数据失败，请稍后重试",
            type: "error",
          });
        }
      },
      async categoryIpFirstChange(item) {
        let isCanGo = false;
        if (
          item.categoryIds &&
          item.categoryIds.length > 0 &&
          this.categoryIpItems.length == item.categoryIds.length
        ) {
          for (let i = 0, j = this.categoryIpItems.length; i < j; i++) {
            let tempItem = this.categoryIpItems[i];
            let isIdExist = item.categoryIds.find((res) => {
              return res == tempItem.id;
            });
            if (isIdExist) {
              isCanGo = false;
            } else {
              isCanGo = true;
              break;
            }
          }
        } else {
          isCanGo = true;
        }
        if (!isCanGo) {
          return;
        }
        this.ipFirstCategory = item.categoryIds;
        this.categoryIpItems = [];
        this.secondCategoryIpItems = [];
        this.compareIp = this.compareIp.slice(0, 1);
        if (item.categoryIds && item.categoryIds.length > 0) {
          for (let i = 0, j = item.categoryIds.length; i < j; i++) {
            let isExist = this.categoryAllOptions.find((res) => {
              return res.id == item.categoryIds[i];
            });
            if (isExist) {
              this.categoryIpItems.push(isExist);
            }
          }
          if (this.categoryIpItems.length > 0) {
            if (this.categoryIpItems.length == 1) {
              // this.ipCategory = this.categoryIpItems[0].id;
              // this.getStatisticsKeywordListReq(this.categoryIpItems[0].id, "ip");
            }

            for (let i = 0, j = this.categoryIpItems.length; i < j; i++) {
              let param = {
                ip_id: this.ipId,
                category_id: this.categoryIpItems[i].id,
                keyword_id: "",
              };
              let res = await getStatisticsCategoryCompeteIp(param);
              this.initCompareIpList(res);
            }
          }
        }
      },
      async handleSecondIpChange(item) {
        let isCanGo = false;
        if (
          item.categoryIds &&
          item.categoryIds.length > 0 &&
          this.secondCategoryIpItems.length == item.categoryIds.length
        ) {
          for (let i = 0, j = this.secondCategoryIpItems.length; i < j; i++) {
            let tempItem = this.secondCategoryIpItems[i];
            let isIdExist = item.categoryIds.find((res) => {
              return res == tempItem.id;
            });
            if (isIdExist) {
              isCanGo = false;
            } else {
              isCanGo = true;
              break;
            }
          }
        } else {
          isCanGo = true;
        }
        if (!isCanGo) {
          return;
        }
        this.ipSecCategory = item.categoryIds;
        if (item.categoryIds && item.categoryIds.length > 0) {
          this.secondCategoryIpItems = [];
          this.compareIp = JSON.parse(JSON.stringify(this.compareIp.slice(0, 2)));
          for (let i = 0, j = item.categoryIds.length; i < j; i++) {
            let isExist = this.secondCategoryIpOptions.find((res) => {
              return res.id == item.categoryIds[i];
            });
            if (isExist) {
              this.secondCategoryIpItems.push(isExist);
            }
          }
          if (this.secondCategoryIpItems.length > 0) {
            for (let i = 0, j = this.secondCategoryIpItems.length; i < j; i++) {
              let param = {
                ip_id: this.ipId,
                keyword_id: this.secondCategoryIpItems[i].id,
              };
              let res = await getStatisticsKeywordCompeteIp(param);
              console.log("res123123123", res);
              this.initCompareIpList(res);
            }
          }
        } else {
          this.secondCategoryIpItems = [];
        }
      },
      async handleSecondIpKeywordChange(item) {

        console.log("handleSecondIpKeywordChange", item, "ipSecCategory", this.ipSecCategory)
        let isCanGo = false;
        let currentCat = JSON.parse(JSON.stringify(this.ipSecCategory));
        let addArr = this.compareArrays(item.categoryIds, currentCat);
        let delArr = this.compareArrays(currentCat, item.categoryIds);
        console.log("addArr", addArr, "delArr", delArr, "currentCat", currentCat, "item.categoryIds", item.categoryIds)
        this.ipSecCategory = item.categoryIds;
        console.log("ipSecCategory", this.ipSecCategory)


        if (delArr && delArr.length > 0) {
          for (let i = 0, j = delArr.length; i < j; i++) {
            let delItem = delArr[i];
            let delIdx = this.secondCategoryIpItems.findIndex((res) => {
              return res.id == delItem;
            })
            console.log("delIdx", delIdx)
            if (delIdx >= 0) {
              // this.distributionRankH.splice(delIdx + 2, 1);
              this.compareIp.splice(delIdx + 1, 1);
            }
          }
        }
        console.log("distributionRankH", this.distributionRankH)
        if (item.categoryIds && item.categoryIds.length > 0) {
          this.secondCategoryIpItems = [];
          for (let i = 0, j = item.categoryIds.length; i < j; i++) {
            let mergeItem = item.categoryIds[i];
            let keywordList = JSON.parse(JSON.stringify(this.keywordList));
            // console.log("this.keywordList",keywordList)
            let isExist = keywordList.find((res) => {
              return res.id == mergeItem;
            });
            console.log("mergeItem", mergeItem, "isExist", isExist)
            if (isExist) {
              this.secondCategoryIpItems.push(isExist);
            }
          }
        }
        console.log("secondCategoryIpItems", this.secondCategoryIpItems)
        if (addArr && addArr.length > 0) {
          for (let i = 0, j = addArr.length; i < j; i++) {
            let addItem = addArr[i];
            let param = {
              ip_id: this.ipId,
              keyword_id: addItem,
            };

            let currentAddItem = this.secondCategoryIpItems.find((res) => {
              return res.id == addItem;
            })

            try {
              let res = await getStatisticsKeywordCompeteIp(param);
              // this.initCompareIpList(res, i,false,currentAddItem && currentAddItem.name || "");
              this.initCompareIpList(res);
              // 处理结果...
            } catch (error) {
              console.log("error", error)
              // 处理错误...
            }
          }
        }

        // console.log("secondCategoryIpItems", this.secondCategoryIpItems)
        // let isCanGo = false;
        // if (
        //   item.categoryIds &&
        //   item.categoryIds.length > 0 &&
        //   this.secondCategoryIpItems.length == item.categoryIds.length
        // ) {
        //   for (let i = 0, j = this.secondCategoryIpItems.length; i < j; i++) {
        //     let tempItem = this.secondCategoryIpItems[i];
        //     let isIdExist = item.categoryIds.find((res) => {
        //       return res == tempItem.id;
        //     });
        //     if (isIdExist) {
        //       isCanGo = false;
        //     } else {
        //       isCanGo = true;
        //       break;
        //     }
        //   }
        // } else {
        //   isCanGo = true;
        // }
        // if (!isCanGo) {
        //   return;
        // }
        // this.ipSecCategory = item.categoryIds;
        // console.log("ipSecCategory", this.ipSecCategory)
        // if (item.categoryIds && item.categoryIds.length > 0) {
        //   this.secondCategoryIpItems = [];
        //   this.compareIp = JSON.parse(JSON.stringify(this.compareIp.slice(0, 1)));
        //   for (let i = 0, j = item.categoryIds.length; i < j; i++) {
        //     let isExist = this.keywordList.find((res) => {
        //       return res.id == item.categoryIds[i];
        //     });
        //     if (isExist) {
        //       this.secondCategoryIpItems.push(isExist);
        //     }
        //   }
        //   if (this.secondCategoryIpItems.length > 0) {
        //     for (let i = 0, j = this.secondCategoryIpItems.length; i < j; i++) {
        //       let param = {
        //         ip_id: this.ipId,
        //         keyword_id: this.secondCategoryIpItems[i].id,
        //       };
        //       let res = await getStatisticsKeywordCompeteIp(param);
        //       console.log("res123123123", res, this.secondCategoryIpItems);
        //       this.initCompareIpList(res);
        //     }
        //   }
        // } else {
        //   this.secondCategoryIpItems = [];
        // }
      },
      initCompareIpList(res) {
        if (res && res.error == 0 && res.data) {
          this.compareIp.push(res.data);
        } else {
          this.compareIp.push([]);
        }
      },
      getStatisticsCategoryCompeteIpReq(id, limit) {
        let param = {
          ip_id: this.ipId,
          category_id: id || 0,
          limit: limit || 10,
        };
        getStatisticsCategoryCompeteIp(param)
          .then((res) => {
            this.initCompareIpList(res);
          })
          .catch((err) => {
            this.initCompareIpList([]);
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
              // this.initCompareIpDefault();
            }
          });
      },
      getStatisticsMarketKeyrowdDistributionReq(id) {
        let param = {
          keyword_id: id,
        };
        getStatisticsMarketKeyrowdDistribution(param)
          .then((res) => {
            this.initDistributionList(res);
          })
          .catch((err) => {
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
            }
          });
      },
      getStatisticsKeywordCompeteIpDistributionReq(id) {
        let param = {
          ip_id: this.ipId,
          keyword_id: id,
        };
        getStatisticsKeywordCompeteIpDistribution(param)
          .then((res) => {
            this.initDistributionList(res);
          })
          .catch(() => {
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
            }
          });
      },
      getStatisticsCategoryIpDistributionReq(id, name) {
        let param = {
          ip_id: this.ipId,
          category_id: id,
        };
        this.dataLoading = true;
        getStatisticsCategoryIpDistribution(param)
          .then((res) => {
            this.dataLoading = false;
            this.initDistributionList(res, 0, false, name);
          })
          .catch((err) => {
            this.dataLoading = false;
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
            }
          });
      },
      getStatisticsKeywordListReq(id, type) {
        let param = {
          category_id: id,
        };
        this.$bus.$emit("clearSecond");
        getStatisticsKeywordList(param)
          .then((res) => {
            let tempList = (res && res.data && res.data.list) || [];
            if (type == "ip") {
              if (tempList.length > 1) {
                this.secondCategoryIpOptions = tempList;
              } else {
                this.secondCategoryIpOptions = [];
              }
            } else {
              if (tempList.length > 1) {
                this.secondCategoryOptions = tempList;
              } else {
                this.secondCategoryOptions = [];
              }
            }
          })
          .catch(() => {
            this.secondCategoryOptions = [];
            this.secondCategoryIpOptions = [];
          });
      },
      getStatisticsKeywordIpDistributionReq(id) {
        let param = {
          ip_id: this.ipId,
          keyword_id: id,
        };
        getStatisticsKeywordIpDistribution(param)
          .then((res) => {
            this.initDistributionList(res);
          })
          .catch(() => {
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
            }
          });
      },
      getStatisticsMarketCategoryReq(id) {
        let param = {
          category_id: id,
        };
        getStatisticsMarketCategory(param)
          .then((res) => {
            this.initCategoryRankDefault();
            if (res && res.error == 0) {
              if (res.data && res.data.category_price) {
                this.categoryRank[1]["rankList"] = res.data.category_price || [];
              }
              if (res.data && res.data.category_sales) {
                this.categoryRank[0]["rankList"] = res.data.category_sales || [];
              }
              if (res.data && res.data.category_shop) {
                this.categoryRank[2]["rankList"] = res.data.category_shop || [];
              }
            } else {
              this.$message({
                message: res.msg || "获取数据失败，请稍后重试",
                type: "error",
              });
            }
          })
          .catch((err) => {
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
              this.initCategoryRankDefault();
            }
          });
      },
      getStatisticsCategoryCompeteIpDistributionReq(id, name) {
        let param = {
          ip_id: this.ipId,
          category_id: id,
        };
        this.dataLoading = true;
        getStatisticsCategoryCompeteIpDistribution(param)
          .then((res) => {
            this.dataLoading = false;
            this.initDistributionList(res, 0, false, name);
          })
          .catch((err) => {
            this.dataLoading = false;
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
            }
          });
      },
      getStatisticsMarketCategoryDistributionReq(id, name) {
        let param = {
          category_id: id,
        };
        this.dataLoading = true;
        getStatisticsMarketCategoryDistribution(param)
          .then((res) => {
            this.dataLoading = false;
            this.initDistributionList(res, 0, false, name);
          })
          .catch((err) => {
            this.dataLoading = false;
            this.initDistributionList();
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
            }
          });
      },
      datetimeChange() {
        console.log("currentDate", this.currentDate);
        this.beginAnalysis();
      },
      beginAnalysis() {
        // this.category = [4];
        // this.ipId = 6212;
        if (
          (!this.category || this.category.length == 0) &&
          !this.secondCategory
        ) {
          this.$message({
            message: "请选择品类",
            type: "error",
          });
          return;
        }
        console.log("ipVal", this.ipVal);
        // if (!this.ipVal) {
        //   this.$message({
        //     message: "请输入ip",
        //     type: "error",
        //   });
        //   return;
        // }
        if (this.ipVal) {
          let isExist = this.restaurants.filter((res) => {
            return res.name == this.ipVal;
          });
          if (isExist && isExist.length > 0) {
            this.ipId = isExist[0].id;
          }
          // else {
          //   this.$message({
          //     message: "请选择匹配的ip",
          //     type: "error",
          //   });
          //   return;
          // }
        }

        this.getStatisticsProductReq();
      },
      getStatisticsCategoryListReq() {
        getStatisticsCategoryList({
          cat: "market",
        })
          .then((res) => {
            this.categoryOptions = (res && res.data && res.data.list) || [];
          })
          .catch(() => {
            this.categoryOptions = [];
          });
        getStatisticsCategoryList()
          .then((res) => {
            this.categoryAllOptions = (res && res.data && res.data.list) || [];
            this.options = JSON.parse(JSON.stringify(this.categoryAllOptions));
          })
          .catch(() => {
            this.categoryAllOptions = [];
            this.options = [];
          });
      },
      handleChange() {
        console.log("handleChange", this.category);
        let categoryItem = this.categoryOptions.find((res) => {
          return res.id == this.category[0];
        });
        if (categoryItem) {
          this.categoryName = categoryItem.name || "";
        }
      },
      async activeChange(ipActiveText) {
        this.ipActiveText = ipActiveText;
        // if (this.categoryItems.length > 0) {
        //   this.distributionRankH.splice(2, this.distributionRankH.length - 1);
        //   this.distributionRankL = this.distributionRankL.slice(0, 1);
        //   console.log("distributionRankL_123123123", this.distributionRankL);
        //   if (this.categoryItems.length > 0) {
        //     let param = {
        //       ip_id: this.ipId,
        //       category_id: this.categoryItems[0].id,
        //     };
        //     if (this.ipActiveText) {
        //       let res = await getStatisticsCategoryIpDistribution(param);
        //       this.initDistributionList(res, -1, true);
        //     } else {
        //       let res = await getStatisticsCategoryCompeteIpDistribution(param);
        //       this.initDistributionList(res, -1, true);
        //     }
        //   }

        //   if (this.secondCategoryItems.length > 0) {
        //     for (let i = 0, j = this.secondCategoryItems.length; i < j; i++) {
        //       let param = {
        //         ip_id: this.ipId,
        //         keyword_id: this.secondCategoryItems[i].id,
        //       };

        //       if (this.ipActiveText) {
        //         let res = await getStatisticsKeywordIpDistribution(param);
        //         this.initDistributionList(res, i);
        //       } else {
        //         let res = await getStatisticsKeywordCompeteIpDistribution(param);
        //         this.initDistributionList(res, i);
        //       }
        //     }
        //   }
        // }
        this.distributionRankH.splice(2, this.distributionRankH.length - 1);
        this.distributionRankL = this.distributionRankL.slice(0, 1);
        console.log("distributionRankL_123123123", this.distributionRankL);

        if (this.secondCategoryItems.length > 0) {
          for (let i = 0, j = this.secondCategoryItems.length; i < j; i++) {
            let param = {
              ip_id: this.ipId,
              keyword_id: this.secondCategoryItems[i].id,
            };

            if (this.ipActiveText) {
              let res = await getStatisticsKeywordIpDistribution(param);
              this.initDistributionList(res, i, false, this.secondCategoryItems[i].name);
            } else {
              let res = await getStatisticsKeywordCompeteIpDistribution(param);
              this.initDistributionList(res, i, false, this.secondCategoryItems[i].name);
            }
          }
        }
      },
      async handleSecondIpKeywordDisChange(item) {

        let isCanGo = false;
        let currentCat = JSON.parse(JSON.stringify(this.disSecCategory));
        let addArr = this.compareArrays(item.categoryIds, currentCat);
        let delArr = this.compareArrays(currentCat, item.categoryIds);
        // console.log("addArr", addArr, "delArr", delArr,"currentCat",currentCat,"item.categoryIds",item.categoryIds)
        this.disSecCategory = item.categoryIds;
        // console.log("disSecCategory",this.disSecCategory) 


        if (delArr && delArr.length > 0) {
          for (let i = 0, j = delArr.length; i < j; i++) {
            let delItem = delArr[i];
            let delIdx = this.secondCategoryItems.findIndex((res) => {
              return res.id == delItem;
            })
            // console.log("delIdx",delIdx)
            if (delIdx >= 0) {
              this.distributionRankH.splice(delIdx + 2, 1);
              this.distributionRankL.splice(delIdx + 1, 1);
            }
          }
        }
        // console.log("distributionRankH",this.distributionRankH)
        if (item.categoryIds && item.categoryIds.length > 0) {
          this.secondCategoryItems = [];
          for (let i = 0, j = item.categoryIds.length; i < j; i++) {
            let mergeItem = item.categoryIds[i];
            let keywordList = JSON.parse(JSON.stringify(this.keywordList));
            // console.log("this.keywordList",keywordList)
            let isExist = keywordList.find((res) => {
              return res.id == mergeItem;
            });
            // console.log("mergeItem",mergeItem,"isExist",isExist)
            if (isExist) {
              this.secondCategoryItems.push(isExist);
            }
          }
        }
        // console.log("secondCategoryItems",this.secondCategoryItems)
        if (addArr && addArr.length > 0) {
          for (let i = 0, j = addArr.length; i < j; i++) {
            let addItem = addArr[i];
            let param = {
              ip_id: this.ipId,
              keyword_id: addItem,
            };

            let currentAddItem = this.secondCategoryItems.find((res) => {
              return res.id == addItem;
            })

            if (item.ipActiveText) {

              try {
                let res = await getStatisticsKeywordIpDistribution(param);
                this.initDistributionList(res, i, false, currentAddItem && currentAddItem.name || "");
                // 处理结果...
              } catch (error) {
                console.log("error", error)
                // 处理错误...
              }
            } else {

              try {
                let res = await getStatisticsKeywordCompeteIpDistribution(param);
                this.initDistributionList(res, i, false, currentAddItem && currentAddItem.name || "");
                // 处理结果...
              } catch (error) {
                console.log("error", error)
                // 处理错误...
              }
            }
          }
        }



        // let isCanGo = false;
        // if (item.categoryIds && item.categoryIds.length > 0 && this.secondCategoryItems.length == item.categoryIds.length) {
        //   for (let i = 0, j = this.secondCategoryItems.length; i < j; i++) {
        //     let tempItem = this.secondCategoryItems[i];
        //     let isIdExist = item.categoryIds.find((res) => {
        //       return res == tempItem.id;
        //     })
        //     if (isIdExist) {
        //       isCanGo = false;
        //     } else {
        //       isCanGo = true;
        //       break;
        //     }
        //   }
        // } else {
        //   isCanGo = true;
        // }
        // if (!isCanGo) {
        //   return;
        // }
        // this.disSecCategory = item.categoryIds;
        // if (item.categoryIds && item.categoryIds.length > 0) {
        //   this.secondCategoryItems = [];
        //   this.distributionRankH.splice(2, this.distributionRankH.length - 1);
        //   this.distributionRankL = JSON.parse(
        //     JSON.stringify(this.distributionRankL.slice(0, 1))
        //   );
        //   for (let i = 0, j = item.categoryIds.length; i < j; i++) {
        //     let isExist = this.keywordList.find((res) => {
        //       return res.id == item.categoryIds[i];
        //     });
        //     if (isExist) {
        //       this.secondCategoryItems.push(isExist);
        //     }
        //   }
        //   if (this.secondCategoryItems.length > 0) {
        //     for (let i = 0, j = this.secondCategoryItems.length; i < j; i++) {
        //       let param = {
        //         ip_id: this.ipId,
        //         keyword_id: this.secondCategoryItems[i].id,
        //       };

        //       if (item.ipActiveText) {
        //         let res = await getStatisticsKeywordIpDistribution(param);
        //         this.initDistributionList(res, i);
        //       } else {
        //         let res = await getStatisticsKeywordCompeteIpDistribution(param);
        //         this.initDistributionList(res, i);
        //       }
        //     }
        //   }
        // } else {
        //   this.secondCategoryItems = [];
        //   this.distributionRankH.splice(3, this.distributionRankH.length - 1);
        //   this.distributionRankL = this.distributionRankL.slice(0, 2);
        // }
      },
      delCategoryItem(item) {
        this.distributionRankH.splice(2, this.distributionRankH.length - 1);
        this.distributionRankL = this.distributionRankL.slice(0, 1);
        this.categoryItems = [];
        this.secondCategoryItems = [];
        this.secondCategoryOptions = [];
      },
      delSecCategoryItem(item) {
        this.distributionRankH.splice(2 + item.idx, 1);
        this.distributionRankL.splice(1 + item.idx, 1);
        this.secondCategoryItems.splice(item.idx - 1, 1);
      },
      getStatisticsProductReq() {
        console.log(
          "secondCategory",
          this.secondCategory,
          "category",
          this.category
        );
        let param = {
          category_id: this.category,
          keyword_id: this.secondCategory,
          ip_id: this.ipId,
        };
        // let param = {
        //   category_id: 1,
        //   ip_id: 6212,
        // };
        let loading = this.$loading({
          lock: true,
          text: '数据分析中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        getStatisticsProduct(param)
          .then((res) => {
            this.distributionRangeList = [];
            this.categoryItems = [];
            this.secondCategoryItems = [];
            this.disCategory = [];
            this.disSecCategory = [];
            this.disFirstCategory = [];
            this.ipSecCategory = [];
            this.ipCategory = "";
            this.ipFirstCategory = "";
            this.categoryIpItems = [];
            this.secondCategoryIpOptions = [];
            this.secondCategoryIpItems = [];
            this.compareIpH = [
              // {
              //   name: "同类IP平均",
              // },
              {
                name: "同类IP最高",
              },
              // {
              //   name: "无IP平均",
              // },
            ];
            this.initDefaultData();
            loading.close();
            if (res && res.error == 0 && res.data) {
              this.total = res.data.total || null;
              if (res.data && res.data.category_price) {
                this.categoryRank[1]["rankList"] = res.data.category_price || [];
              }
              if (res.data && res.data.category_sales) {
                this.categoryRank[0]["rankList"] = res.data.category_sales || [];
              }
              if (res.data && res.data.category_shop) {
                this.categoryRank[2]["rankList"] = res.data.category_shop || [];
              }
              if (res.data && res.data.distribution) {
                let tempList = [];
                for (let i = 0, j = res.data.distribution.length; i < j; i++) {
                  let tempVal = res.data.distribution[i];
                  this.distributionRangeList.push(tempVal.distribution_range);
                  tempList.push(tempVal);
                }
                this.distributionRankL.push(tempList);
                console.log("distributionRankL", this.distributionRankL);
              }

              if (this.ipId) {
                let tempH = {
                  name: (res && res.data && res.data.ip_info.name) || "",
                };
                this.compareIpH.unshift(tempH);
                this.compareIpH.unshift({
                  name: "",
                });
                if (res.data && res.data.compete_ip) {
                  this.compareIp.push(res.data.compete_ip);
                } else {
                  this.compareIp.push([]);
                }
              }

              this.ipInfo = res.data.ip_info || null;

              // if (res.data && res.data.platform_index) {
              //   let platformIndex = res.data.platform_index;
              //   let tempList = this.ipMarket[0]["rankList"];
              //   this.ipMarket[0]["name"] = this.categoryName;
              //   for (let i = 0, j = tempList.length; i < j; i++) {
              //     let item = tempList[i];
              //     if (platformIndex && platformIndex[item.typeIdx]) {
              //       item["region_index"] = platformIndex[item.typeIdx];
              //     }
              //     if (platformIndex && platformIndex[item.typeIdx + "_trend"]) {
              //       item["region_index_trend"] =
              //       platformIndex[item.typeIdx + "_trend"];
              //     }
              //   }
              // }

              if (res.data && res.data.ip_platform_index) {
                let ipPlatformIndex = res.data.ip_platform_index;
                let tempList = this.ipMarket[0]["rankList"];
                this.ipMarket[0]["name"] = this.ipInfo && this.ipInfo.name || "IP";
                for (let i = 0, j = tempList.length; i < j; i++) {
                  let item = tempList[i];
                  if (ipPlatformIndex && ipPlatformIndex[item.typeIdx]) {
                    item["region_index"] = ipPlatformIndex[item.typeIdx];
                  }
                  if (ipPlatformIndex && ipPlatformIndex[item.typeIdx + "_trend"]) {
                    item["region_index_trend"] =
                      ipPlatformIndex[item.typeIdx + "_trend"];
                  }
                }
              } else {
                this.ipMarket[0]["rankList"] = [];
              }
              this.ipMarket[0]["rankList"].sort((a, b) => {
                return b.region_index - a.region_index;
              });
              // this.ipMarket[1]["rankList"].sort((a, b) => {
              //   return b.region_index - a.region_index;
              // });

              // if(res.data && res.data.region_index) {
              //   this.ipMarketRegion[0]["name"] = this.categoryName;
              //   this.ipMarketRegion[0]["rankList"] = res.data.region_index || [];
              // }

              if (res.data && res.data.ip_region_index) {
                this.ipMarketRegion[0]["name"] = this.ipInfo && this.ipInfo.name || "IP";
                this.ipMarketRegion[0]["rankList"] = res.data.ip_region_index || [];
              }

              this.flowIndex["search_index"] = res.data.search_index || 0;
              this.flowIndex["search_index_trend"] =
                res.data.search_index_trend || 0;
              this.flowIndex["media_index"] = res.data.media_index || 0;
              this.flowIndex["media_index_trend"] =
                res.data.media_index_trend || 0;

              if (res.data && res.data.ip_distribution_age) {
                this.distributionAgeDefault =
                  res.data.ip_distribution_age || null;
              }
              // if (res.data && res.data.distribution_age) {
              //   this.distributionAge = res.data.distribution_age || null;
              // }
              this.productSalesStr =
                (res.data && res.data.product_sales_str) || "";
              this.ipSalesStr = (res.data && res.data.ip_sales_str) || "";
              this.blueOceanStr = (res.data && res.data.blue_ocean_str) || "";

              if (this.ipId) {
                getStatisticsIpKeywordList({
                  ip_id: this.ipId
                }).then((resKey) => {
                  this.keywordList = resKey && resKey.data && resKey.data.list || [];
                  if (this.keywordList && this.keywordList.length > 0) {
                    let ipKeywordParam = {
                      categoryIds: [],
                      ipActiveText: true
                    }
                    if (this.keywordList[0]) {
                      ipKeywordParam.categoryIds.push(this.keywordList[0].id)
                    }
                    if (this.keywordList[1]) {
                      ipKeywordParam.categoryIds.push(this.keywordList[1].id)
                    }
                    this.handleSecondIpKeywordChange(ipKeywordParam);
                    this.handleSecondIpKeywordDisChange(ipKeywordParam)
                  }
                }).catch(() => {
                  this.keywordList = [];
                })
              }
            } else {
              this.$message({
                message: res.msg || "获取数据失败，请稍后重试",
                type: "error",
              });
              this.initCompareIpDefault();
            }
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            loading.close();
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
              this.distributionRangeList = [];

              this.initCompareIpDefault();
              this.initDefaultData();
            }
          });



      },
      initCompareIpDefault() {
        this.compareIpH = [
          // {
          //   name: "同类IP平均",
          // },
          {
            name: "同类IP最高",
          },
          // {
          //   name: "无IP平均",
          // },
        ];
        let tempH = {
          name: this.ipVal || "",
        };
        this.compareIpH.unshift(tempH);
        this.compareIpH.unshift({
          name: "",
        });
        this.compareIp.push([]);
      },
      querySearch(queryString, cb) {
        console.log("queryString", queryString);
        if (!queryString) {
          return;
        }
        getStatisticsSearchIp({
          q: queryString,
        })
          .then((res) => {
            if (res && res.error == 0) {
              let restaurants = (res && res.data && res.data.list) || [];
              this.restaurants = restaurants;
              // let results = queryString
              //   ? restaurants.filter(this.createFilter(queryString))
              //   : restaurants;
              // 调用 callback 返回建议列表的数据
              cb(restaurants);
            }
          })
          .catch((err) => {
            // cb(results);
            this.restaurants = [];
            this.$message({
              message: err.msg || "获取数据失败，请稍后重试",
              type: "error",
            });
            if (err.error == 20912) {
              this.$router.push("user");
            } else {
            }
          });
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (
            restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
          );
        };
      },
      handleSelect() { },
      ipChange() {
        this.ipId = "";
        this.ipVal = "";
      },
      parseNumber(num) {
        return num < 10 ? "0" + num : num;
      },
      initCategoryRankDefault() {
        this.categoryRank = [
          {
            name: "销量",
            rankList: [],
          },
          {
            name: "销售额",
            rankList: [],
          },
          {
            name: "店商选品",
            rankList: [],
          },
        ];
      },
      initDefaultData() {
        this.total = null;
        this.initCategoryRankDefault();
        this.distributionRankH = [
          {
            name: "零售价区间\n\n（单位：元）",
          },
          {
            name: "全部品类",
            children: [
              {
                name: "销量占比",
              },
              {
                name: "销售额占比",
              },
            ],
          },
        ];
        this.distributionRankL = [];
        this.ipMarket = [
          // {
          //   name: "品类",
          //   rankList: [
          //     {
          //       region_name: "头条",
          //       typeIdx: "point_toutiao_index",
          //       region_index: 0,
          //       region_index_trend: 0,
          //     },
          //     {
          //       region_name: "抖音",
          //       typeIdx: "point_douyin_index",
          //       region_index: 0,
          //       region_index_trend: 0,
          //     },
          //     {
          //       region_name: "快手",
          //       typeIdx: "point_kuaishou_index",
          //       region_index: 0,
          //       region_index_trend: 0,
          //     },
          //     {
          //       region_name: "微博",
          //       typeIdx: "point_weibo_index",
          //       region_index: 0,
          //       region_index_trend: 0,
          //     },
          //     {
          //       region_name: "百度",
          //       typeIdx: "point_search_index",
          //       region_index: 0,
          //       region_index_trend: 0,
          //     },
          //     {
          //       region_name: "小红书",
          //       typeIdx: "point_xiaohongshu_index",
          //       region_index: 0,
          //       region_index_trend: 0,
          //     },
          //   ],
          // },
          {
            name: "IP",
            rankList: [{
              region_name: "头条",
              typeIdx: "point_toutiao_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "抖音",
              typeIdx: "point_douyin_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "快手",
              typeIdx: "point_kuaishou_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "微博",
              typeIdx: "point_weibo_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "百度",
              typeIdx: "point_search_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "小红书",
              typeIdx: "point_xiaohongshu_index",
              region_index: 0,
              region_index_trend: 0,
            },],
          },
        ];
        this.ipMarketRegion = [
          // {
          //   name: "品类",
          //   rankList: []
          // },
          {
            name: "IP",
            rankList: []
          }
        ]
        this.ipInfo = null;
        this.compareIp = [];
        this.flowIndex = {
          search_index: 0,
          search_index_trend: 0,
          media_index: 0,
          media_index_trend: 0,
        };
        this.distributionAge = null;
        this.distributionAgeDefault = null;
        this.productSalesStr = null;
        this.ipSalesStr = null;
        this.blueOceanStr = null;
      },
    },
  };
</script>
<style lang="less" scoped>
  .market-analysis-box {
    padding: 0 30px 0 132px;
    display: flex;
    flex-direction: column;
    flex: 1;

    .default-title {
      font-weight: bold;
      font-size: 17px;
      color: #1a232f;
      line-height: 24px;
      text-align: left;
      margin-top: 0;
    }

    .idea-analysis {
      width: 100%;
      padding: 20px;
      background: #f8f8fa;
      border-radius: 10px;
      display: flex;
      margin-top: 17px;
      display: flex;
      flex-direction: column;
      position: relative;

      .tips {
        font-size: 16px;
        color: #f56c6c;
        margin-bottom: 10px;
        text-align: left;
      }

      .category {
        display: flex;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        .desc {
          font-weight: 400;
          font-size: 14px;
          color: #626a83;
          line-height: 20px;
          text-align: left;
          width: 118px;
        }
      }

      .analysis-btn {
        position: absolute;
        left: 420px;
        bottom: 40px;
        cursor: pointer;
      }
    }

    .datetime-box {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      ::v-deep .el-date-editor {
        width: 120px;
        height: 24px;
        background: #f3f3f8;
        border-radius: 16px;
        cursor: pointer;
        margin-left: 10px;

        .el-input__inner {
          width: 100%;
          height: 100%;
          border: none;
          background: transparent;
          padding-left: 12px;
          cursor: pointer;
        }

        .el-input__prefix {
          // display: none;
          color: #3672fd;
          left: 100px;

          .el-input__icon {
            align-items: center;
            display: flex;
            line-height: 24px;
          }
        }

        .el-input__suffix {
          display: none;
        }
      }

      .icon-bottom {
        position: absolute;
        top: 0;
        right: 12px;
        bottom: 0;
        display: flex;
        align-items: center;
        color: #3672fd;
      }
    }

    .analysis-content {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      .analysis-left {
        width: 98%;
        display: flex;
        flex-direction: column;

        .analysis-info {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 13px;

          .analysis-item {
            width: 48%;
            display: flex;
            background: #e0deff;
            border-radius: 10px;
            padding: 14px 30px;

            .item-left {
              flex: 1;
              display: flex;
              flex-direction: column;

              .name {
                font-weight: 400;
                font-size: 12px;
                color: #626a83;
                line-height: 17px;
                text-align: left;
              }

              .count {
                font-weight: bold;
                font-size: 40px;
                color: #1a232f;
                // line-height: 47px;
                text-align: left;
                display: flex;
                margin-top: 4px;

                .unit {
                  font-weight: bold;
                  font-size: 24px;
                  color: #1a232f;
                  text-align: left;
                  display: flex;
                  align-items: flex-end;
                }

                .unit-puls {
                  font-weight: bold;
                  font-size: 17px;
                  color: #1a232f;
                  text-align: left;
                }
              }
            }

            .item-right {
              display: flex;
              align-items: flex-end;

              .right-info {
                display: flex;
                align-items: center;
              }

              .up {
                font-weight: 400;
                font-size: 12px;
                color: #e6182e;
                line-height: 17px;
                text-align: left;
              }

              .down {
                font-weight: 400;
                font-size: 12px;
                color: #13b918;
                line-height: 17px;
                text-align: left;
              }

              img {
                width: 8px;
                height: 10px;
                margin-left: 6px;
              }
            }
          }
        }
      }

      .analysis-right {
        width: 48%;
      }
    }
  }
</style>
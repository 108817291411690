<template>
    <div class="ip-analysis-box">
        <div class="rank-title"><span>{{title}}</span><span class="sub-title" v-if="subTitle">({{subTitle}})</span></div>
        <div class="evaluate-box">
            <div class="evaluate-list">
                <div class="evaluate-item">
                    <div class="left-info">
                        <img src="@/assets/images/market/yingshi.png">
                    </div>
                    <div class="right-info">
                        <div class="tag-list">
                            <div class="tag-item tag-year" v-if="ipInfo && ipInfo.year"><span>{{ipInfo && ipInfo.year}}</span></div>
                            <div class="flex" v-if="ipInfo && ipInfo.tags">
                                <div class="tag-item tag-action" v-for="(item,idx) in ipInfo.tags" :key="idx"><span>{{item}}</span></div>
                            </div>
                        </div>
                        <div class="user-desc">用户评价<span>({{ipInfo && ipInfo.rating_people || 0}}人参与评分）</span></div>
                        <div class="user-rate">
                            <el-rate
                                :value="(ipInfo && ipInfo.rate) ? ipInfo.rate/2 : 0"
                                :max="5"
                                disabled>
                            </el-rate>
                            <span class="rate-score">{{ipInfo && ipInfo.rate || 0}}</span>
                            <!-- <span class="rate-before">超越了85%影视IP</span> -->
                        </div>
                        <div class="star-list">
                            <div class="star-item">
                                <div class="star-desc">5星</div>
                                <el-progress :text-inside="false" :stroke-width="4" :percentage="ipInfo && ipInfo.star5_pct || 0"></el-progress>
                            </div>
                            <div class="star-item">
                                <div class="star-desc">4星</div>
                                <el-progress :text-inside="false" :stroke-width="4" :percentage="ipInfo && ipInfo.star4_pct || 0"></el-progress>
                            </div>
                            <div class="star-item">
                                <div class="star-desc">3星</div>
                                <el-progress :text-inside="false" :stroke-width="4" :percentage="ipInfo && ipInfo.star3_pct || 0"></el-progress>
                            </div>
                            <div class="star-item">
                                <div class="star-desc">2星</div>
                                <el-progress :text-inside="false" :stroke-width="4" :percentage="ipInfo && ipInfo.star2_pct || 0"></el-progress>
                            </div>
                            <div class="star-item">
                                <div class="star-desc">1星</div>
                                <el-progress :text-inside="false" :stroke-width="4" :percentage="ipInfo && ipInfo.star1_pct || 0"></el-progress>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="evaluate-item">
                    <div class="left-info">
                        <img src="@/assets/images/market/youxi.png">
                    </div>
                    <div class="right-info">
                        <div class="tag-list">
                            <div class="tag-item tag-year"><span>2018</span></div>
                            <div class="tag-item tag-action"><span>即时战略</span></div>
                            <div class="tag-item tag-type"><span>奇幻</span></div>
                        </div>
                        <div class="user-desc">用户评价<span>(12345人参与评分）</span></div>
                        <div class="user-rate">
                            <el-rate
                                :value="4"
                                :max="5"
                                disabled>
                            </el-rate>
                            <span class="rate-score">8.8</span>
                            <span class="rate-before">超越了85%影视IP</span>
                        </div>
                        <div class="star-list">
                            <div class="star-item">
                                <div class="star-desc">5星</div>
                                <el-progress :text-inside="false" :stroke-width="4" :percentage="70"></el-progress>
                            </div>
                            <div class="star-item">
                                <div class="star-desc">4星</div>
                                <el-progress :text-inside="false" :stroke-width="4" :percentage="70"></el-progress>
                            </div>
                            <div class="star-item">
                                <div class="star-desc">3星</div>
                                <el-progress :text-inside="false" :stroke-width="4" :percentage="70"></el-progress>
                            </div>
                            <div class="star-item">
                                <div class="star-desc">2星</div>
                                <el-progress :text-inside="false" :stroke-width="4" :percentage="70"></el-progress>
                            </div>
                            <div class="star-item">
                                <div class="star-desc">1星</div>
                                <el-progress :text-inside="false" :stroke-width="4" :percentage="70"></el-progress>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["title", "subTitle","ipInfo"],
}
</script>
<style lang="less" scoped>
@import url("@/assets/css/rankTitle.less");
.ip-analysis-box {
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #E7E8ED;
    .evaluate-box {
        padding: 20px;
        .evaluate-list {
            display: flex;
            flex-direction: column;
            .evaluate-item {
                display: flex;
                .left-info {
                    padding-right: 10px;
                    img {
                        width: 36px;
                        height: 36px;
                    }
                }
                .right-info {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    .tag-list {
                        display: flex;
                        margin-top: 10px;
                        .tag-item {
                            margin-right: 5px;
                        }
                        .tag-year {
                            padding: 0 8px;
                            height: 16px;
                            background: #F3F3F8;
                            border-radius: 3px;
                            border: 1px solid #E7E8ED;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            span {
                                font-weight: 400;
                                font-size: 12px;
                                scale: 0.83;
                                color: #1A232F;
                            }
                        }
                        .tag-action {
                            padding: 0 8px;
                            height: 16px;
                            background: #D9E5FF;
                            border-radius: 3px;
                            border: 1px solid #3672FD;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            span {
                                font-weight: 400;
                                font-size: 12px;
                                scale: 0.83;
                                color: #1A232F;
                            }
                        }
                        .tag-type {
                            padding: 0 8px;
                            height: 16px;
                            background: #F8E5FF;
                            border-radius: 3px;
                            border: 1px solid #D664FF;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            span {
                                font-weight: 400;
                                font-size: 12px;
                                scale: 0.83;
                                color: #1A232F;
                            }
                        }
                    }
                    .user-desc {
                        font-weight: bold;
                        font-size: 14px;
                        color: #1A232F;
                        line-height: 20px;
                        text-align: left;
                        display: flex;
                        align-items: center;
                        margin-top: 6px;
                        span {
                            font-weight: 400;
                            font-size: 12px;
                            color: #626A83;
                            margin-left: 10px;
                        }
                    }
                    .user-rate {
                        display: flex;
                        align-items: center;
                        margin-top: 6px;
                        .rate-score {
                            font-weight: bold;
                            font-size: 14px;
                            color: #626A83;
                            line-height: 20px;
                            margin-left: 8px;
                        }
                        .rate-before {
                            font-weight: 400;
                            font-size: 12px;
                            color: #1A232F;
                            text-align: left;
                            font-style: normal;
                            margin-left: 8px;
                        }
                    }
                    .star-list {
                        display: flex;
                        flex-direction: column;
                        margin-top: 10px;
                        .star-item {
                            margin-bottom: 10px;
                            display: flex;
                            align-items: center;
                            width: 100%;
                            .star-desc {
                                font-weight: 400;
                                font-size: 12px;
                                color: #1A232F;
                                line-height: 17px;
                            }
                            .el-progress {
                                width: 80%;
                                ::v-deep .el-progress__text {
                                    font-size: 12px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
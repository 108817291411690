<template>
  <div class="rank-box">
    <div class="rank-title">
      <div class="flex-1 text-left">
        <span>{{title}}</span><span class="sub-title" v-if="subTitle">({{subTitle}})</span>
        <el-tooltip v-if="showTopTip" style="margin-left:4px;margin-top:2px;" class="item" effect="dark"
          placement="right">
          <div slot="content">
            <div v-html="calTopTip()"></div>
          </div>
          <i class="el-icon-question"></i>
        </el-tooltip>
      </div>

      <div class="right-opt">
        <el-radio-group style="margin-left: 20px;" v-model="radioVal" @input="radioChange" size="mini">
          <el-radio-button v-for="(item,idx) in radioList" :label="item.value"
            :key="idx">{{item.label}}</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div class="flex" style="padding: 20px 0 20px 20px;" v-if="keywordList && keywordList.length > 0">
      <div style="width: 100px;">品类对比：</div>
      <div class="flex flex-1">
        
        <el-checkbox-group v-model="secondCategory" @change="chooseKeyword" :max="6">
          <el-checkbox label="all">
            全部品类
          </el-checkbox>
          <el-checkbox :label="item.id" v-for="(item,idx) in keywordList" :key="idx">
            {{item.name}}
          </el-checkbox>
        </el-checkbox-group>
      </div>
     
    </div>
   
    <div class="compare-ip-rank-list">
      <!-- {{currentH}} -->
    
      <!-- <div class="rank-item">
        <div class="rank-desc" v-for="(item,idx) in currentH" :key="idx">
          {{item.name}}</div>
      </div> -->
      <div style="display: flex;flex-direction: column;flex: 1;">
        <div class="flex">
          <div class="rank-item rank-item1">
            <div class="rank-desc"></div>
          </div>
          <div class="rank-item" :class="[idx == 0 && 'rank-item1 rank-item3']" v-for="(item,idx) in currentH" :key="idx">
            <div class="rank-desc" >
              {{item.name}}
              <div
                v-if="item.children && item.children.length > 0"
                class="sub-list">
                <div class="sub-item"
                  v-for="(subItem,subIdx) in item.children"
                  :key="subIdx"><span>{{subItem.name}}</span></div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex">
            <div class="rank-item rank-item1">
              <div class="rank-desc" v-if="calAll()">全部品类</div>
              <div class="rank-desc" v-for="(item,idx) in secondCategoryItems" :key="idx">
                {{item.name}}</div>
            </div>
            <div style="display: flex;flex-direction: column">
              <div class="rank-item rank-item1 rank-item2" v-for="(listItem,listIdx) in currentList" :key="listIdx">
                <div class="rank-desc">
                  {{calSaleVal(listItem,"ip")}}
                  <!-- <img :title="calUpDownVal(listItem,'ip')" v-if="calUpDown(listItem,'ip') == 1"
                    src="@/assets/images/market/icon_up.png">
                  <img :title="calUpDownVal(listItem,'ip')" v-if="calUpDown(listItem,'ip') == 2"
                    src="@/assets/images/market/icon_down.png"> -->
                </div>
                
              </div>
              
            </div>
            <div class="rank-item" v-for="topIdx in 5" :key="topIdx">

              <div class="rank-desc" v-for="(listItem,listIdx) in currentList" :key="listIdx">
                {{calSaleVal2(listItem,"compete_ip_top",topIdx -1)}}{{calSaleName2(listItem,"compete_ip_top",topIdx-1)}}
              </div>
              <!-- <div class="rank-desc">
                {{calSaleVal(listItem,"compete_ip_top")}}{{radioVal == "unit_price" ? "元" : "万"}}({{calSaleName(listItem,"compete_ip_top")}})
                <img :title="calUpDownVal(listItem,'compete_ip_top')" v-if="calUpDown(listItem,'compete_ip_top') == 1"
                  src="@/assets/images/market/icon_up.png">
                <img :title="calUpDownVal(listItem,'compete_ip_top')" v-if="calUpDown(listItem,'compete_ip_top') == 2"
                  src="@/assets/images/market/icon_down.png">
              </div> -->
            </div>
        </div>
       
      </div>
      
      
    </div>
  </div>
</template>
<script>
  export default {
    props: [
      "title",
      "subTitle",
      "currentH",
      "currentList",
      "bgColor",
      "hiddenLabel",
      "categoryOptions",
      "showChooseCate",
      "placeholderDefault",
      "currentType",
      "valueType",
      "isGoIp",
      "isThousand",
      "currentCategory",
      "calMax",
      "cateSelectLabel",
      "calType",
      "showRank",
      "rankTypeName",
      "labelName",
      "showMulChooseCate",
      "categoryItems",
      "secondCategoryOptions",
      "secondCategoryItems",
      "currentFirstCategory",
      "currentSecondCategory",
      "selectSecondCategory",
      "categoryAllOptions",
      "showTopTip",
      "keywordList"
    ],
    data() {
      return {
        checkList: [],
        category: "",
        firstCategory: "",
        secondCategory: [],
        radioVal: "sale",
        options: [],
        ipActiveText: "",
        radioList: [
          {
            label: "月销量",
            value: "sale",
          },
          {
            label: "月销售额",
            value: "price",
          },
          {
            label: "平均单价",
            value: "unit_price",
          },
        ],
      };
    },
    watch: {
      currentCategory() {
        this.category = this.currentCategory;
      },
      categoryOptions() {
        if (this.showMulChooseCate && this.categoryOptions.length > 0) {
          this.options = this.categoryOptions;
        }
      },
      currentFirstCategory() {
        console.log('currentFirstCategory', this.currentFirstCategory)
        this.firstCategory = this.currentFirstCategory;
      },
      currentSecondCategory() {
        this.secondCategory = this.currentSecondCategory || [];
        // console.log("currentSecondCategory1",this.currentSecondCategory)
      },
    },
    created() {
      
    },
    mounted() {
      // console.log("this",this)
      this.category = this.currentCategory;
      this.secondCategory = this.currentSecondCategory || [];
      // console.log("currentSecondCategory",this.currentSecondCategory)
    },
    methods: {
      calAll() {
        let idx = this.secondCategory.findIndex((res) => {
          return res == "all";
        })
        if(idx >= 0) {
          return true;
        } else {
          return false;
        }
      },
      chooseKeyword() {
        console.log("chooseKeyword",this.secondCategory)
        this.$emit("handleSecondIpKeywordChange", {
            categoryIds: this.secondCategory || [],
            currentType: this.currentType,
            ipActiveText: this.ipActiveText,
          });
      },
      calTopTip() {
        switch (this.currentType) {
          case "compareIpType":
            return "分析IP在各品类的市场表现，了解IP与各玩具品类的匹配度和消费者接受度。";
            break;
          case "compareIdeaIpType":

            return "分析IP在各品类的市场表现，了解IP与各玩具品类的匹配度和消费者接受度。";
            break;
        }

      },
      calSaleVal(listItem, type) {
        // console.log("listItem[type]",listItem[type],type)
        if(type == "ip") {
          switch (this.radioVal) {
            case "sale":
              return (listItem[type] && listItem[type]["sales"] && listItem[type].sales) ? ((Number(listItem[type].sales) == 0 ? "<0.01" : listItem[type].sales) + '万') : "-";
            case "price":
              return (listItem[type] && listItem[type]["price"] && listItem[type].price) ? ((Number(listItem[type].price) == 0 ? "<0.01" : listItem[type].price) + '万') : "-";
            case "unit_price":
              return (listItem[type] && listItem[type]["unit_price"] && listItem[type].unit_price) || "-";
          }
        } else {
          switch (this.radioVal) {
            case "sale":
              return (listItem[type] && listItem[type]["sales"] && listItem[type]["sales"].sales) ? ((Number(listItem[type].sales) == 0 ? "<0.01" : listItem[type].sales) + '万') : "-";
            case "price":
              return (listItem[type] && listItem[type]["price"] && listItem[type]["price"].price) ? ((Number(listItem[type].price) == 0 ? "<0.01" : listItem[type].price) + '万') : "-";
            case "unit_price":
              return (listItem[type] && listItem[type]["unit_price"] && listItem[type]["unit_price"].unit_price) || "-";
          }
        }
        
      },
      calSaleName(listItem, type) {
        // console.log("listItem[type]",listItem[type])
        switch (this.radioVal) {
          case "sale":
            return  (listItem[type] && listItem[type]["sales"] &&  listItem[type]["sales"].name) ? '('+listItem[type]["sales"].name+')' : "";
          case "price":
            return (listItem[type] && listItem[type]["price"] && listItem[type]["price"].name) ? '('+listItem[type]["sales"].name+')' : "";
          case "unit_price":
            return (listItem[type] && listItem[type]["unit_price"] && listItem[type]["unit_price"].name) ? '('+listItem[type]["sales"].name+')' : "";
        }
      },
      calSaleVal2(listItem, type,topIdx) {
        
        switch (this.radioVal) {
          case "sale":
            return (listItem[type] && listItem[type]["sales"] && listItem[type]["sales"][topIdx] && listItem[type]["sales"][topIdx].sales) ? ((Number(listItem[type]["sales"][topIdx].sales) == 0 ? '<0.01' : listItem[type]["sales"][topIdx].sales) + '万') : "-";
          case "price":
            return (listItem[type] && listItem[type]["price"] && listItem[type]["price"][topIdx] && listItem[type]["price"][topIdx].price) ? ((Number(listItem[type]["price"][topIdx].price) == 0 ? '<0.01' : listItem[type]["price"][topIdx].price) + '万') :  "-";
          case "unit_price":
            return (listItem[type] && listItem[type]["unit_price"] && listItem[type]["unit_price"][topIdx] && listItem[type]["unit_price"][topIdx].unit_price) || "-";
        }
        
      },
      calSaleName2(listItem, type,topIdx) {
        // console.log("listItem[type]",listItem[type])
        switch (this.radioVal) {
          case "sale":
            return  (listItem[type] && listItem[type]["sales"] && listItem[type]["sales"][topIdx] &&  listItem[type]["sales"][topIdx].name) ? '('+listItem[type]["sales"][topIdx].name+')' : "";
          case "price":
            return (listItem[type] && listItem[type]["price"] && listItem[type]["price"][topIdx] && listItem[type]["price"][topIdx].name) ? '('+listItem[type]["price"][topIdx].name+')' : "";
          case "unit_price":
            return (listItem[type] && listItem[type]["unit_price"] && listItem[type]["unit_price"][topIdx] && listItem[type]["unit_price"][topIdx].name) ? '('+listItem[type]["unit_price"][topIdx].name+')' : "";
        }
      },
      calUpDown(listItem, type) {
        let rate = 0;
        switch (this.radioVal) {
          case "sale":
            rate = (listItem[type] && listItem[type].sales_trend) || 0;
            break;
          case "price":
            rate = (listItem[type] && listItem[type].price_trend) || 0;
            break;
          case "unit_price":
            rate = (listItem[type] && listItem[type].unit_price_trend) || 0;
            break;
        }
        
        if (rate > 0) {
          return 1;
        }
        if (rate < 0) {
          return 2;
        }
      },
      calUpDownVal(listItem, type) {
        let rate = 0;
        switch (this.radioVal) {
          case "sale":
            rate = (listItem[type] && listItem[type].sales_trend) || 0;
            break;
          case "price":
            rate = (listItem[type] && listItem[type].price_trend) || 0;
            break;
          case "unit_price":
            rate = (listItem[type] && listItem[type].unit_price_trend) || 0;
            break;
        }
        if (rate != 0) {
          return rate;
        } else {
          return "-";
        }
      },
      getTopName(listIdx) {
        // console.log("listIdx", listIdx,this.categoryItems);
        if (this.categoryItems.length == 1) {
          if (listIdx == 1 && this.categoryItems[listIdx - 1]) {
            return this.categoryItems[listIdx - 1].name + "品类";
          } else if (listIdx > 1 && this.secondCategoryItems[listIdx - 2]) {
            return this.secondCategoryItems[listIdx - 2].name;
          }
        } else if (this.categoryItems.length > 1 && this.categoryItems[listIdx - 1].name) {
          return this.categoryItems[listIdx - 1].name + "品类";
        } else if(this.categoryItems.length == 0 && this.secondCategoryItems[listIdx - 1]){
          return this.secondCategoryItems[listIdx - 1].name;
        }
      },
      goIpDetail(item) {
        if (this.isGoIp && item.ip > 0 && item.ip < 9999999) {
          this.$emit("goIpDetail", item);
          this.$router.push({
            path:
              "/ipInfo?currentIpId=" +
              item.id +
              "&currentIpName=" +
              encodeURIComponent(item.name),
          });
        }
      },
      radioChange() { },
    },
  };
</script>
<style lang="less" scoped>
  @import url("@/assets/css/rankTitle.less");

  .rank-box {
    display: flex;
    width: 100%;
    // height: 206px;
    padding-bottom: 20px;
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #e7e8ed;
    /* margin-bottom: 20px; */
    flex-direction: column;
    ::v-deep .el-checkbox-group {
    .el-checkbox {
      width: 150px;
      margin-bottom: 4px;
      /* &:nth-child(6n) {
        margin-right: 0;
      } */
    }
  }
    .rank-title {
      .right-opt {
        margin-right: 10px;
      }

      ::v-deep {
        .el-cascader {
          line-height: 30px;
          margin-left: 10px;

          input {
            height: 30px;
            line-height: 30px;
          }

          .el-input__icon {
            line-height: 30px;
          }
        }
      }

      ::v-deep {
        .el-select {
          line-height: 30px;
          margin-left: 10px;

          input {
            height: 30px;
            line-height: 30px;
          }

          .el-input__icon {
            line-height: 30px;
          }
        }
      }
    }

    .compare-ip-rank-list {
      display: flex;
      padding: 10px;

      .rank-item {
        display: flex;
        padding: 0 0;
        flex-direction: column;
        border: 1px solid #ebeef5;
        /* border-bottom: none; */
        
        flex: 1;
        &.rank-item1 {
          width: 160px;
          flex: none;
        }

        &.rank-item2 {
          border-bottom: none;
            &:last-child {
            border-bottom: 1px solid #ebeef5;
          }
        }

        &.rank-item3 {
          align-items: center;
          .rank-desc {
            height: 100%;
            align-items: center;
            display: flex;
          }
        }

        .rank-desc {
          border-bottom: 1px solid #ebeef5;
          min-height: 40px;
          /* padding: 0 15px; */
          line-height: 40px;
          /* display: flex; */
          /* align-items: center; */
          text-align: center;
          &:last-child {
            border-bottom: none;
          }

          img {
            width: 12px;
            height: 15px;
            margin-left: 4px;
          }
        }
      }
    }
    
    .sub-list {
      width: 100%;
      display: flex;

      &.sub-list-item {
        margin-top: 0;
        height: 100%;
        display: flex;
        align-items: center;

        .sub-item {
          padding: 10px 0;
          border-top: none;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          &.is-max {
            background: #f56c6c;
            color: #fff;
          }
          
          img {
            width: 8px;
            height: 10px;
            margin-left: 6px;
          }
        }
      }

      .sub-item {
        flex: 1;
        padding: 5px 0 5px 0;

        border-right: 1px solid #ebeef5;
        border-top: 1px solid #ebeef5;
        display: flex;
        justify-content: center;
          span {
            width: 24px;
            height: 24px;
            border-radius: 100%;
            border: 2px solid #ebeef5;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #606266;
          }

        &:last-child {
          border-right: 0;
        }
      }
    }
  }
</style>
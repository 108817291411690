<template>
  <div class="ip-analysis-box">
    <div class="rank-title"><span>综合信息</span><span class="sub-title" v-if="subTitle">({{subTitle}})</span>
    </div>
    <div class="flex align-center mt-20 pl-20">
      <div class="text-border" v-if="title"></div>
      <div class="default-title mt-0">{{title}}</div>
    </div>
    <div class="comprehensive-box">
      <div class="ip-img">
        <el-image v-if="ipInfo && ipInfo.cover_img" :src="ipInfo.cover_img" fit="contain"></el-image>
      </div>
      <!-- <div class="radar-list">
        <div class="radar-item"
          v-for="(item,idx) in allScore" :key="idx">
          <div class="name">{{item.name}}</div>
          <div class="count">{{item.value}}</div>
        </div>
      </div> -->
      
      <div class="evaluate-box">
        <div class="evaluate-list">
          <div class="evaluate-item">
            <div class="left-info">
              <img src="@/assets/images/market/yingshi.png">
            </div>
            <div class="right-info">
              <div class="tag-list">
                <div class="tag-item tag-year" v-if="ipInfo && ipInfo.year">
                  <span>{{ipInfo && ipInfo.year}}</span>
                </div>
                <div class="flex" style="flex-wrap: wrap;" v-if="ipInfo && ipInfo.tags">
                  <div class="tag-item tag-action" style="margin-bottom: 4px;" v-for="(item,idx) in ipInfo.tags" :key="idx" v-show="item"><span>{{item}}</span>
                  </div>
                </div>
              </div>
              <div class="user-desc">
                用户评价
                <!-- <span>({{ipInfo && ipInfo.rating_people || 0}}人参与评分）</span> -->
              </div>
              <div class="user-rate">
                <el-rate :value="(ipInfo && ipInfo.rate) ? ipInfo.rate/2 : 0" :max="5" disabled>
                </el-rate>
                <span class="rate-score">{{ipInfo && ipInfo.rate || 0}}</span>
                <!-- <span class="rate-before">超越了85%影视IP</span> -->
              </div>
              <div class="star-list">
                <div class="star-item">
                  <div class="star-desc">5星</div>
                  <el-progress :text-inside="false" :stroke-width="4"
                    :percentage="ipInfo && ipInfo.star5_pct || 0"></el-progress>
                </div>
                <div class="star-item">
                  <div class="star-desc">4星</div>
                  <el-progress :text-inside="false" :stroke-width="4"
                    :percentage="ipInfo && ipInfo.star4_pct || 0"></el-progress>
                </div>
                <div class="star-item">
                  <div class="star-desc">3星</div>
                  <el-progress :text-inside="false" :stroke-width="4"
                    :percentage="ipInfo && ipInfo.star3_pct || 0"></el-progress>
                </div>
                <div class="star-item">
                  <div class="star-desc">2星</div>
                  <el-progress :text-inside="false" :stroke-width="4"
                    :percentage="ipInfo && ipInfo.star2_pct || 0"></el-progress>
                </div>
                <div class="star-item">
                  <div class="star-desc">1星</div>
                  <el-progress :text-inside="false" :stroke-width="4"
                    :percentage="ipInfo && ipInfo.star1_pct || 0"></el-progress>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="evaluate-item">
                      <div class="left-info">
                          <img src="@/assets/images/market/youxi.png">
                      </div>
                      <div class="right-info">
                          <div class="tag-list">
                              <div class="tag-item tag-year"><span>2018</span></div>
                              <div class="tag-item tag-action"><span>即时战略</span></div>
                              <div class="tag-item tag-type"><span>奇幻</span></div>
                          </div>
                          <div class="user-desc">用户评价<span>(12345人参与评分）</span></div>
                          <div class="user-rate">
                              <el-rate :value="4" :max="5" disabled>
                              </el-rate>
                              <span class="rate-score">8.8</span>
                              <span class="rate-before">超越了85%影视IP</span>
                          </div>
                          <div class="star-list">
                              <div class="star-item">
                                  <div class="star-desc">5星</div>
                                  <el-progress :text-inside="false" :stroke-width="4" :percentage="70"></el-progress>
                              </div>
                              <div class="star-item">
                                  <div class="star-desc">4星</div>
                                  <el-progress :text-inside="false" :stroke-width="4" :percentage="70"></el-progress>
                              </div>
                              <div class="star-item">
                                  <div class="star-desc">3星</div>
                                  <el-progress :text-inside="false" :stroke-width="4" :percentage="70"></el-progress>
                              </div>
                              <div class="star-item">
                                  <div class="star-desc">2星</div>
                                  <el-progress :text-inside="false" :stroke-width="4" :percentage="70"></el-progress>
                              </div>
                              <div class="star-item">
                                  <div class="star-desc">1星</div>
                                  <el-progress :text-inside="false" :stroke-width="4" :percentage="70"></el-progress>
                              </div>
                          </div>
                      </div>
                  </div> -->
        </div>
      </div>
      <div class="radar-ana">
        <div id="radarAna"></div>
      </div>
    </div>

  </div>
</template>
<script>
  import * as echarts from "echarts";
  export default {
    props: ["title", "subTitle", "ipInfo", "allScore"],
    data() {
      return {};
    },
    watch: {
      allScore() {
        this.$nextTick(() => {
          this.initRadar();
        });
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.initRadar();
      });
    },
    methods: {
      initRadar() {
        let chartDom = document.getElementById("radarAna");
        let myChart = echarts.init(chartDom);
        let option;
        let seriesData = [];
        let indicator = [];
        for (let i = 0, j = this.allScore.length; i < j; i++) {
          seriesData.push(this.allScore[i].value);
          let tempItem = { 
            value: this.allScore[i].value,
            text: this.allScore[i].name,
            max: 100 
          }
          indicator.push(tempItem);
        }
        console.log("seriesData", seriesData);
        option = {
          radar: {
            // shape: 'circle',
            indicator: indicator,
            axisName: {
              formatter: function (value, params) {
                // console.log("params",params)
                return (
                  "" +
                  params.name +
                  "" +
                  "\n" +
                  "" +
                  parseFloat(params.value).toFixed(0) +
                  ""
                );
              },
              color: '#409EFF',
              fontWeight: 500,
              // color: 'red',
              // rich: {
              //   // 属性上部分的样式
              //   top: {
              //     // color: "#626A83",
              //     color: "#3672FD",
              //     fontSize: 12,
              //     fontWeight: 400,
              //     align: "center",
              //     lineHeight: 20,
              //   },
              //   // 属性下部分样式
              //   end: {
              //     color: "#3672FD",
              //     fontSize: 12,
              //     align: "center",
              //     lineHeight: 20,
              //   },
              // },
            },
            // center: ['25%', '40%'],
            radius: 80
          },
          tooltip: {
            trigger: "axis",
            show: false
          },
          series: [
            {
              name: "",
              type: "radar",
              tooltip: {
                trigger: "item",
              },
              lineStyle: {
                color: "rgba(136, 172, 255, 1)",
              },
              areaStyle: {
                color: "rgba(217,229,255,0.6)",
              },
              data: [
                {
                  value: seriesData,
                  name: "综合评分",
                },
              ],
            },
          ],
        };
        // console.log("option", option);
        option && myChart.setOption(option);
      },
    },
  };
</script>
<style lang="less" scoped>
  @import url("@/assets/css/rankTitle.less");

  .ip-analysis-box {
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #e7e8ed;

    .comprehensive-box {
      display: flex;
      margin-top: 15px;
      width: 100%;

      .ip-img {
        padding: 0 10px;
        display: flex;
        // flex: 1;
        justify-content: center;
        width: 250px;

        img {
          width: auto;
          height: 300px;
        }

        ::v-deep .el-image {
          img {
            width: auto;
            max-height: 260px;
            margin: 0 auto;
          }
        }
      }

      .radar-list {
        display: flex;
        padding-left: 60px;
        flex-direction: column;
        padding-top: 60px;

        .radar-item {
          display: flex;
          height: 30px;
          align-items: center;
          width: 160px;

          .name {
            font-weight: 500;
            font-size: 14px;
            color: #626a83;
            line-height: 30px;
            text-align: left;
            font-style: normal;
            padding-right: 20px;
          }

          .count {
            font-weight: bold;
            font-size: 14px;
            color: #3672fd;
            line-height: 30px;
            text-align: left;
            font-style: normal;
          }
        }
      }

      .radar-ana {
        width: 360px;

        div {
          width: 100%;
          height: 300px;
        }
      }
    }

    .evaluate-box {
      padding: 20px;

      .evaluate-list {
        display: flex;

        .evaluate-item {
          display: flex;
          flex: 1;

          .left-info {
            padding-right: 10px;

            img {
              width: 36px;
              height: 36px;
            }
          }

          .right-info {
            display: flex;
            flex-direction: column;
            flex: 1;

            .tag-list {
              display: flex;
              margin-top: 10px;

              .tag-item {
                margin-right: 5px;
              }

              .tag-year {
                padding: 0 8px;
                height: 16px;
                background: #f3f3f8;
                border-radius: 3px;
                border: 1px solid #e7e8ed;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                  font-weight: 400;
                  font-size: 12px;
                  scale: 0.83;
                  color: #1a232f;
                }
              }

              .tag-action {
                padding: 0 8px;
                height: 16px;
                background: #d9e5ff;
                border-radius: 3px;
                border: 1px solid #3672fd;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                  font-weight: 400;
                  font-size: 12px;
                  scale: 0.83;
                  color: #1a232f;
                }
              }

              .tag-type {
                padding: 0 8px;
                height: 16px;
                background: #f8e5ff;
                border-radius: 3px;
                border: 1px solid #d664ff;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                  font-weight: 400;
                  font-size: 12px;
                  scale: 0.83;
                  color: #1a232f;
                }
              }
            }

            .user-desc {
              font-weight: bold;
              font-size: 14px;
              color: #1a232f;
              line-height: 20px;
              text-align: left;
              display: flex;
              align-items: center;
              margin-top: 6px;

              span {
                font-weight: 400;
                font-size: 12px;
                color: #626a83;
                margin-left: 10px;
              }
            }

            .user-rate {
              display: flex;
              align-items: center;
              margin-top: 6px;

              .rate-score {
                font-weight: bold;
                font-size: 14px;
                color: #626a83;
                line-height: 20px;
                margin-left: 8px;
              }

              .rate-before {
                font-weight: 400;
                font-size: 12px;
                color: #1a232f;
                text-align: left;
                font-style: normal;
                margin-left: 8px;
              }

              ::v-deep .el-rate {
                .el-rate__item {
                  padding: 0;

                  .el-rate__icon {
                    margin-right: 0;
                  }
                }
              }
            }

            .star-list {
              display: flex;
              flex-direction: column;
              margin-top: 10px;

              .star-item {
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                width: 100%;

                .star-desc {
                  font-weight: 400;
                  font-size: 12px;
                  color: #1a232f;
                  line-height: 17px;
                }

                .el-progress {
                  width: 80%;

                  ::v-deep .el-progress__text {
                    font-size: 12px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
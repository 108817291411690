<template>
    <div class="line-box">
      <div v-if="distributionAge || distributionAgeDefault" class="" id="flowLine"></div>
      <el-empty v-show="!distributionAge && !distributionAgeDefault"></el-empty>
    </div>
  </template>
  <script>
  import * as echarts from "echarts";
  export default {
    props: ["ipInfo", "distributionAge", "distributionAgeDefault","categoryName"],
    mounted() {
      this.$nextTick(() => {
        this.initFlowLine();
      });
    },
    watch: {
      distributionAge() {
        this.$nextTick(() => {
          this.initFlowLine();
        });
      },
    },
    methods: {
      initFlowLine() {
        let self = this;
        if(!self.distributionAge && !self.distributionAgeDefault) {
          return;
        }
        let chartDom = document.getElementById("flowLine");
        let myChart = echarts.init(chartDom);
        let distributionAge = [];
        let distributionAgeDefault = [];
        console.log("self.distributionAge",self.distributionAge,"distributionAgeDefault",self.distributionAgeDefault)
        distributionAge.push(
          (self.distributionAge && self.distributionAge["index_0_19"]) || 0
        );
        distributionAge.push(
          (self.distributionAge && self.distributionAge["index_20_29"]) || 0
        );
        distributionAge.push(
          (self.distributionAge && self.distributionAge["index_30_39"]) || 0
        );
        distributionAge.push(
          (self.distributionAge && self.distributionAge["index_40_49"]) || 0
        );
        distributionAge.push(
          (self.distributionAge && self.distributionAge["index_50"]) || 0
        );
  
        distributionAgeDefault.push(
          (self.distributionAgeDefault &&
            self.distributionAgeDefault["index_0_19"]) ||
            0
        );
        distributionAgeDefault.push(
          (self.distributionAgeDefault &&
            self.distributionAgeDefault["index_20_29"]) ||
            0
        );
        distributionAgeDefault.push(
          (self.distributionAgeDefault &&
            self.distributionAgeDefault["index_30_39"]) ||
            0
        );
        distributionAgeDefault.push(
          (self.distributionAgeDefault &&
            self.distributionAgeDefault["index_40_49"]) ||
            0
        );
        distributionAgeDefault.push(
          (self.distributionAgeDefault &&
            self.distributionAgeDefault["index_50"]) ||
            0
        );


  
        let option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },
          },
          legend: {
            data: [self.categoryName,self.ipInfo && self.ipInfo.name],
          },
          xAxis: [
            {
              type: "category",
              data: ["≤19", "20～29", "30～39", "40～49", "≥50"],
              axisPointer: {
                type: "shadow",
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              name: self.ipInfo && self.ipInfo.name,
              min: 0,
              max: Math.max.apply(null, distributionAgeDefault) + 10,
              interval: 10,
              axisLabel: {
                formatter: "{value}",
              },
            },
            // {
            //   type: "value",
            //   name: "全网分布",
            //   min: 0,
            //   max: (Math.max.apply(null, distributionAgeDefault) + 10),
            //   interval: 10,
            //   axisLabel: {
            //     formatter: "{value}",
            //   },
            // },
          ],
          series: [
            {
              name: self.categoryName,
              type: "bar",
              tooltip: {
                valueFormatter: function (value) {
                  return value;
                },
              },
              data: distributionAge,
              label: {
                show: true, // 显示数据
                position: "top", // 数据显示的位置
                formatter: function(param) {
                  if(param.value > 0) {
                    return param.value
                  } else {
                    return ""
                  }
                }
              },
            },
            {
              name: self.ipInfo && self.ipInfo.name,
              type: "bar",
              tooltip: {
                valueFormatter: function (value) {
                  return value;
                },
              },
              data: distributionAgeDefault,
              label: {
                show: true, // 显示数据
                position: "top", // 数据显示的位置
                formatter: function(param) {
                  if(param.value > 0) {
                    return param.value
                  } else {
                    return ""
                  }
                }
              },
            },
            // {
            //   name: "TGI",
            //   type: "line",
            //   yAxisIndex: 1,
            //   tooltip: {
            //     valueFormatter: function (value) {
            //       return value;
            //     },
            //   },
            //   data: [
            //     2.0, 2.2, 3.3, 4.5, 6.3
            //   ],
            // },
          ],
        };
  
        option && myChart.setOption(option);
      },
    },
  };
  </script>
  <style lang="less" scoped>
  .line-box {
    width: 100%;
    #flowLine {
      width: 100%;
      height: 300px;
    }
  }
  </style>
<template>
    <div class="rank-box">
      <div class="rank-title">
        <div class=" text-left">
          <span>{{title}}</span><span class="sub-title" v-if="subTitle">({{subTitle}})</span>
        </div>
        <div class="right-opt flex flex-1 content-end">
          <div class="flex align-center">
            <span v-if="showChooseCate">品类筛选:</span>
              <el-select v-if="showChooseCate" v-model="category" :collapse-tags="true"  clearable :multiple-limit="4" multiple :placeholder="placeholderDefault ? placeholderDefault : '请选择'" @change="handleChange">
                <el-option
                  v-for="item in categoryOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id" >
                </el-option>
              </el-select>
  
              <el-select class="second-select" :collapse-tags="true" v-if="categoryItems.length > 0 && categoryItems.length == 1 " :multiple-limit="4" v-model="secondCategory" @remove-tag="handleDelSecondChange" multiple  placeholder="请选择品类对比，支持多选"  @change="handleSecondChange">
                <el-option
                  v-for="item in secondCategoryOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
          </div>
  
        </div>
      </div>
      <div class="table-rank-list">
        <div class="table-rank-h">
          <div v-for="(item,idx) in currentH" :key="idx" :class="['table-rank-second']">
            {{item.name}}
            <div v-if="item.children && item.children.length > 0" class="sub-list">
              <div class="sub-item" v-for="(subItem,subIdx) in item.children" :key="subIdx"> {{subItem.name}}</div>
            </div>
          </div>
        </div>
        <div class="flex">
          <!-- <div class="flex" style="flex-direction: column">
            <div class="table-rank-item" v-for="item in distributionRangeList" :key="item">
              <div class="table-rank-first">
                {{item}}</div>
            </div>
          </div> -->
  
          <div class="flex-1" v-for="(firstItem,Firstidx) in currentL" :key="Firstidx">
            <div class="table-rank-item" v-for="(secondItem,secondIdx) in firstItem" :key="secondIdx"
              @click="goIp(secondItem)">
              <div class="table-rank-l-second">
                <div class="sub-list sub-list-item">
                  <div class="sub-item" :class="[calMax(firstItem,secondItem.sales_rate,'salesRate')]">
                    {{secondItem.sales_rate}}%
  
                    <img v-if="secondItem.sales_rate > 0" src="@/assets/images/market/icon_up.png">
                    <img v-else-if="secondItem.sales_rate < 0" src="@/assets/images/market/icon_down.png">
                  </div>
                  <div class="sub-item" :class="[calMax(firstItem,secondItem.price_rate,'priceRate')]">
                    {{secondItem.price_rate}}%
                    <img v-if="secondItem.price_rate > 0" src="@/assets/images/market/icon_up.png">
                    <img v-else-if="secondItem.price_rate < 0" src="@/assets/images/market/icon_down.png">
                  </div>
                </div>
  
              </div>
            </div>
          </div>
        </div>
  
  
      </div>
    </div>
  </template>
  <script>
    export default {
      props: [
        "title",
        "subTitle",
        "bgColor",
        "hiddenLabel",
        "categoryOptions",
        "showChooseCate",
        "currentH",
        "currentL",
        "placeholderDefault",
        "currentType",
        "categoryItems",
        "secondCategoryOptions",
        "secondCategoryItems",
        "distributionRangeList",
        "showSwitch",
        "isIpInfo",
        "ipId"
      ],
      data() {
        return {
          category: "",
          radioVal: "",
          showPannel: false,
          ipActiveText: true,
          secondCategory: ""
        };
      },
      created() {
        this.$bus.$off("clearSecond");
        this.$bus.$on("clearSecond", () => {
          this.secondCategory = ""
        })
      },
      methods: {
        calMax(rateList, currentVal, type) {
  
          let maxValue = 0;
          if (type == "salesRate") {
            maxValue = Math.max(...rateList.map(obj => obj.sales_rate));
          } else {
            maxValue = Math.max(...rateList.map(obj => obj.price_rate));
  
          }
          if (currentVal == maxValue && maxValue > 0) {
            return "is-max"
          }
  
        },
        delCategoryItem(item) {
          this.$emit("delCategoryItem", item);
        },
        delSecCategoryItem(item, idx) {
          this.$emit("delSecCategoryItem", {
            currentItem: item,
            idx: idx + 1
          })
        },
        goItem(item) { },
        handleChange() {
          console.log("handleChange", this.category);
          this.$emit("categoryFirstIpChange", {
            categoryIds: this.category || [],
            currentType: this.currentType,
            ipActiveText: this.ipActiveText
          });
          // this.category = "";
        },
        handleSecondChange() {
          console.log("handleSecondChange",this.secondCategory);
          this.$emit("handleSecondIpChange", {
            categoryIds: this.secondCategory || [],
            currentType: this.currentType,
            ipActiveText: this.ipActiveText
          });
        },
        handleDelSecondChange() {
          console.log("handleDelSecondChange",this.secondCategory);
        },
        calPerWidth(rankList, percent) {
          let sum = 0;
          for (let i = 0, j = rankList.length; i < j; i++) {
            if (rankList[i].rate) {
              sum += Number(rankList[i].rate);
            }
            if (rankList[i].point_index) {
              sum += Number(rankList[i].point_index);
            }
          }
          if (percent == 0) {
            return 0;
          }
          if (sum == 0) {
            return 0;
          } else {
            return parseFloat((Number(percent) / sum) * 100).toFixed(2);
          }
        },
      },
    };
  </script>
  <style lang="less" scoped>
    @import url("@/assets/css/rankTitle.less");
  
    .rank-box {
      display: flex;
      width: 100%;
      // height: 206px;
      padding-bottom: 20px;
      background: #ffffff;
      border-radius: 10px;
      border: 1px solid #e7e8ed;
      margin-bottom: 20px;
      flex-direction: column;
  
      .rank-title {
        .right-opt {
          margin-right: 10px;
        }
  
        ::v-deep {
          .el-cascader {
            line-height: 30px;
            margin-left: 10px;
  
            input {
              height: 30px;
              line-height: 30px;
            }
  
            .el-input__icon {
              line-height: 30px;
            }
  
            .el-input--suffix .el-input__inner {
              padding-right: 0;
            }
          }
        }
  
        ::v-deep {
          .el-select {
            line-height: 30px;
            margin-left: 10px;
  
            input {
              height: 30px;
              line-height: 30px;
            }
  
            .el-input__icon {
              line-height: 30px;
            }
            &.second-select {
              width: max-content !important;
              max-width: fit-content !important;
              .el-select__tags {
                flex-wrap: nowrap;
                max-width: fit-content !important;
                /* padding: 0 20px; */
              }
              .el-input {
                width: max-content;
              }
              .el-input__inner {
                padding-right: 60px;
              }
            }
          }
        }
  
        .dropdown-reset {
          color: rgb(64, 158, 255);
          font-size: 16px;
          cursor: pointer;
  
          i {
            margin-right: 4px;
          }
        }
      }
  
      .label-list {
        -webkit-appearance: none;
        background-color: #FFF;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        height: 30px;
        line-height: 30px;
        outline: 0;
        padding: 0 15px;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 14px;
  
        .el-tag {
          height: 24px;
          line-height: 24px;
          margin-left: 8px;
        }
      }
  
      .el-cascader-panel {
        position: absolute;
        top: 24px;
        right: -5px;
        background: #fff;
  
        .el-cascader-node {
          color: #606266;
        }
      }
  
      .table-rank-list {
        display: flex;
        width: 100%;
        padding: 10px;
        flex-direction: column;
  
        .table-rank-h {
          display: flex;
          width: 100%;
          flex: 1;
          border: 1px solid #ebeef5;
          // border-bottom: none;
        }
  
        .table-rank-first {
          width: 110px;
          border-right: 1px solid #ebeef5;
          padding: 10px 0;
        }
  
        .table-rank-second {
          flex: 1;
          // padding: 10px 0;
          padding-top: 10px;
          border-right: 1px solid #ebeef5;
  
          &:last-child {
            border-right: none;
          }
        }
  
        .table-rank-l-second {
          flex: 1;
          display: flex;
          height: 100%;
          align-items: center;
        }
  
        .sub-list {
          width: 100%;
          display: flex;
          margin-top: 10px;
  
          &.sub-list-item {
            margin-top: 0;
            height: 100%;
            display: flex;
            align-items: center;
  
            .sub-item {
              padding: 10px 0;
              border-top: none;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
  
              &.is-max {
                background: #F56C6C;
                color: #fff;
              }
  
              img {
                width: 8px;
                height: 10px;
                margin-left: 6px;
              }
            }
          }
  
          .sub-item {
            flex: 1;
            padding: 5px 0 5px 0;
  
            border-right: 1px solid #ebeef5;
            border-top: 1px solid #ebeef5;
  
            &:last-child {
              border-right: 0;
            }
          }
        }
  
        .table-rank-item {
          display: flex;
          align-items: center;
          width: 100%;
          border-bottom: 1px solid #ebeef5;
          border-right: 1px solid #ebeef5;
          border-left: 1px solid #ebeef5;
        }
      }
    }
  </style>
<template>
    <div class="default-box">
        <div class="title">欢迎使用时升AI</div>
        <div class="desc">你可以提出问题获取答案，从中获得更多创作灵感</div>
        <div class="default-list">
            <div class="default-item cursor" :class="[defaultIdx == idx ? 'active' : '']" v-for="(item,idx) in defaultList" :key="idx" @click="chooseDefault(item,idx)">{{item.name}}</div>
        </div>
      
        
    </div>
</template>
<script>

export default {
    data() {
        return {
            defaultList: [{
                name: "写一篇儿童故事"
            },{
                name: "写一篇儿童故事"
            },{
                name: "写一篇儿童故事"
            },{
                name: "写一篇儿童故事"
            },{
                name: "写一篇儿童故事"
            },{
                name: "写一篇儿童故事"
            },{
                name: "写一篇儿童故事"
            }],
            defaultIdx: -1
        }
    },
    methods: {
        chooseDefault(item,idx) {
            this.defaultIdx = idx;
        }
    }
}
</script>
<style lang="less" scoped>
.default-box {
    flex-direction: column;
    position: relative;
    .title {
        font-weight: 600;
        font-size: 32px;
        color: #1A232F;
        line-height: 45px;
        text-align: center;
        margin-top: 90px;
    }
    .desc {
        font-weight: 400;
        font-size: 12px;
        color: #626A83;
        line-height: 17px;
        text-align: center;
        margin-top: 8px;
    }
    .default-list {
        padding: 0 76px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 70px;
        .default-item {
            width: 170px;
            height: 36px;
            background: #FFFFFF;
            box-shadow: 0px 2px 4px 0px #E7E8ED;
            border-radius: 10px;
            border: 1px solid #E7E8ED;
            font-weight: 400;
            font-size: 12px;
            color: #626A83;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 13px;
            margin-bottom: 10px;
            &.active {
                background: #D9E5FF;
                box-shadow: 0px 2px 4px 0px #D9E5FF;
                border-radius: 10px;
                border: 1px solid #3672FD;
                color: #3672FD;
                font-weight: bold;
            }
        }
    }
    
}
</style>

  export default {
    defaultConfig: {
      verCode: 1,
      App: 7,
      Os: 2,
      Uuid: navigator.productSub,
      AppVersion: "1.0.0",
      NativeVersion: "1.0.0",
    }
  }
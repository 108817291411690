<template>
  <div class="flow-box">
    <div class="rank-title"><span>{{title}}</span><span
        class="sub-title"
        v-if="subTitle">({{subTitle}})</span></div>
    <div class="flow-content">
      <!-- <div class="analysis-info">
        <div class="analysis-item">
          <div class="item-left">
            <div class="name">需求指数</div>
            <div class="count">
              <CountTo :startVal='0' :endVal='flowIndex && flowIndex.search_index || 0' />
            </div>
          </div>
          <div class="item-right">
            <div class="right-info">
              <div class="percent"
                :class="[flowIndex && flowIndex.search_index_trend > 0 ? 'up' : 'down']">
                {{flowIndex && flowIndex.search_index_trend || 0}}%</div>
              <div class="per-img">
                <img v-if="flowIndex && flowIndex.search_index_trend > 0"
                  src="@/assets/images/market/icon_up.png">
                <img v-else
                  src="@/assets/images/market/icon_down.png">
              </div>
            </div>

          </div>
        </div>
        <div class="analysis-item">
          <div class="item-left">
            <div class="name">媒体关注度</div>
            <div class="count">
              <CountTo :startVal='0' :endVal='flowIndex && flowIndex.media_index || 0' />
            </div>
          </div>
          <div class="item-right">
            <div class="right-info">
              <div class="percent"
                :class="[flowIndex && flowIndex.media_index_trend > 0 ? 'up' : 'down']">
                {{flowIndex && flowIndex.media_index_trend}}%</div>
              <div class="per-img">
                <img v-if="flowIndex && flowIndex.media_index_trend > 0"
                  src="@/assets/images/market/icon_up.png">
                <img v-else
                  src="@/assets/images/market/icon_down.png">
              </div>
            </div>

          </div>
        </div>
      </div> -->
      <div class="flow-analysis">
        <!-- <div class="flex align-center"><div class="text-border"></div><div class="default-title mt-0">人群年龄分布</div></div> -->
        <LineCom :ipInfo="ipInfo" :distributionAge="distributionAge" :distributionAgeDefault="distributionAgeDefault"></LineCom>
      </div>
    </div>
  </div>
</template>
<script>
import CountTo from 'vue-count-to';
import LineCom from "./line.vue";
export default {
  props: ["title", "subTitle","flowIndex","ipInfo","distributionAge","distributionAgeDefault"],
  components: {
    CountTo,
    LineCom
  },
  data() {
    return {
    };
  },
};
</script>
<style lang="less" scoped>
@import url("@/assets/css/rankTitle.less");
.flow-box {
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #e7e8ed;
  .flow-content {
    padding: 10px;
    .analysis-info {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 13px;
      .analysis-item {
        width: 48%;
        display: flex;
        background: #e0deff;
        border-radius: 10px;
        padding: 12px 20px 12px 15px;
        .item-left {
          flex: 1;
          display: flex;
          flex-direction: column;
          .name {
            font-weight: 400;
            font-size: 12px;
            color: #626a83;
            line-height: 17px;
            text-align: left;
          }
          .count {
            font-weight: bold;
            font-size: 24px;
            color: #1a232f;
            // line-height: 47px;
            text-align: left;
            display: flex;
            margin-top: 4px;
          }
        }
        .item-right {
          display: flex;
          align-items: flex-end;
          .right-info {
            display: flex;
            align-items: center;
          }
          .up {
            font-weight: 400;
            font-size: 12px;
            color: #e6182e;
            line-height: 17px;
            text-align: left;
          }
          .down {
            font-weight: 400;
            font-size: 12px;
            color: #13b918;
            line-height: 17px;
            text-align: left;
          }
          img {
            width: 8px;
            height: 10px;
            margin-left: 6px;
          }
        }
      }
    }
    .flow-analysis {

    }
  }
}
</style>
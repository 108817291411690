import request from "@/utils/request";

/**
 * 取消积分订单
 * id : 订单id
 */
export function coinOrderCancel(params) {
    return request({
        url: '/coin_order/cancel',
        method: 'post',
        data: params
    })
}

/**
 * 创建积分订单
 * price : 购买人民币
 * product_id: 购买类型： 内容0
 */
export function coinOrderCreate(params) {
    return request({
        url: '/coin_order/create',
        method: 'post',
        data: params
    })
}

/**
 * 积分订单删除
 * id : 订单id
 */
export function deleteCoinOrder(params) {
    return request({
        url: '/coin_order/delete',
        method: 'post',
        data: params
    })
}

/**
 * 积分订单详情
 * id : 订单id
 */
export function getCoinOrderInfo(id) {
    return request({
        url: '/coin_order/info?id=' + id,
        method: 'get'
    })
}

/**
 * 获取积分订单列表
 * status: 订单状态 全部传99
 * offset
 * limit
 */
export function getCoinOrderList(params) {
    return request({
        url: '/coin_order/list',
        method: 'get',
        params: params
    })
}

/**
 * 积分订单支付
 * channel: 支付渠道微信公众号wx_official 小程序wx_mini
 * id : 订单id
 */
export function coinOrderPay(params) {
    return request({
        url: '/coin_order/pay',
        method: 'post',
        data: params
    })
}

/**
 * 积分订单产品
 */
export function getCoinOrderProducts() {
    return request({
        url: '/coin_order/products',
        method: 'get'
    })
}
<template>
  <div class="container" ref="scrollContainer">
    <div class="content-box">
      <div class="center-box">
        <MarketIdea></MarketIdea>
      </div>
    </div>
  </div>
</template>
  <script>
import MarketIdea from "@/components/market/idea.vue";
export default {
  components: {
    MarketIdea,
  },
  data() {
    return {
      showLogin: false,
      isLoading: false,
      showReward: false,
      showLayer: false,
    };
  },
  created() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      // 使用this.$refs访问到容器，并调用scrollTo方法
      this.$nextTick(() => {
        this.$refs.scrollContainer.scrollTo({
          top: 0,
          behavior: "smooth", // 平滑滚动
        });
      });
    },
    showFullLayer(val) {
      this.showLayer = val;
    },
    closeLogin() {
      this.showLogin = false;
    },
    goLogin() {
      this.showLogin = true;
    },
    changeDefaultIdx() {},
    sumbitTipFun() {},
    refresh() {},
    changeTipIdx() {},
    closeRewardPopup() {
      this.showReward = false;
    },
  },
};
</script>
  <style lang="less" scoped>
.container {
  height: 100%;
  display: flex;
  /* 禁止文字选择 */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .menu-layer {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: transparent;
    z-index: 999;
  }
  .content-box {
    display: flex;
    padding-top: 128px;
    flex: 1;

    .left-box {
      width: 450px;
      display: flex;
    }
    .center-box {
      flex: 1;
      display: flex;
      min-width: 670px;
      .center-default-box {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        background: #f8f8fa;
        border-radius: 20px;
        flex-direction: column;
        position: relative;
        .problem-wrap {
          margin-top: 20px;
        }
      }
    }
    .right-box {
      width: 300px;
      display: flex;
    }
  }
}
</style>
/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const pb = $root.pb = (() => {

    /**
     * Namespace pb.
     * @exports pb
     * @namespace
     */
    const pb = {};

    pb.Packet = (function() {

        /**
         * Properties of a Packet.
         * @memberof pb
         * @interface IPacket
         * @property {string|null} [id] Packet id
         * @property {pb.CmdType|null} [cmd] Packet cmd
         * @property {pb.IMessage|null} [msg] Packet msg
         * @property {pb.IApiResponse|null} [api] Packet api
         * @property {number|Long|null} [ts] Packet ts
         */

        /**
         * Constructs a new Packet.
         * @memberof pb
         * @classdesc Represents a Packet.
         * @implements IPacket
         * @constructor
         * @param {pb.IPacket=} [properties] Properties to set
         */
        function Packet(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Packet id.
         * @member {string} id
         * @memberof pb.Packet
         * @instance
         */
        Packet.prototype.id = "";

        /**
         * Packet cmd.
         * @member {pb.CmdType} cmd
         * @memberof pb.Packet
         * @instance
         */
        Packet.prototype.cmd = 0;

        /**
         * Packet msg.
         * @member {pb.IMessage|null|undefined} msg
         * @memberof pb.Packet
         * @instance
         */
        Packet.prototype.msg = null;

        /**
         * Packet api.
         * @member {pb.IApiResponse|null|undefined} api
         * @memberof pb.Packet
         * @instance
         */
        Packet.prototype.api = null;

        /**
         * Packet ts.
         * @member {number|Long} ts
         * @memberof pb.Packet
         * @instance
         */
        Packet.prototype.ts = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Packet content.
         * @member {"msg"|"api"|undefined} content
         * @memberof pb.Packet
         * @instance
         */
        Object.defineProperty(Packet.prototype, "content", {
            get: $util.oneOfGetter($oneOfFields = ["msg", "api"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Packet instance using the specified properties.
         * @function create
         * @memberof pb.Packet
         * @static
         * @param {pb.IPacket=} [properties] Properties to set
         * @returns {pb.Packet} Packet instance
         */
        Packet.create = function create(properties) {
            return new Packet(properties);
        };

        /**
         * Encodes the specified Packet message. Does not implicitly {@link pb.Packet.verify|verify} messages.
         * @function encode
         * @memberof pb.Packet
         * @static
         * @param {pb.IPacket} message Packet message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Packet.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.cmd != null && Object.hasOwnProperty.call(message, "cmd"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.cmd);
            if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
                $root.pb.Message.encode(message.msg, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.api != null && Object.hasOwnProperty.call(message, "api"))
                $root.pb.ApiResponse.encode(message.api, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.ts != null && Object.hasOwnProperty.call(message, "ts"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.ts);
            return writer;
        };

        /**
         * Encodes the specified Packet message, length delimited. Does not implicitly {@link pb.Packet.verify|verify} messages.
         * @function encodeDelimited
         * @memberof pb.Packet
         * @static
         * @param {pb.IPacket} message Packet message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Packet.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Packet message from the specified reader or buffer.
         * @function decode
         * @memberof pb.Packet
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {pb.Packet} Packet
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Packet.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.pb.Packet();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.cmd = reader.int32();
                        break;
                    }
                case 3: {
                        message.msg = $root.pb.Message.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.api = $root.pb.ApiResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.ts = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Packet message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof pb.Packet
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {pb.Packet} Packet
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Packet.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Packet message.
         * @function verify
         * @memberof pb.Packet
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Packet.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.cmd != null && message.hasOwnProperty("cmd"))
                switch (message.cmd) {
                default:
                    return "cmd: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            if (message.msg != null && message.hasOwnProperty("msg")) {
                properties.content = 1;
                {
                    let error = $root.pb.Message.verify(message.msg);
                    if (error)
                        return "msg." + error;
                }
            }
            if (message.api != null && message.hasOwnProperty("api")) {
                if (properties.content === 1)
                    return "content: multiple values";
                properties.content = 1;
                {
                    let error = $root.pb.ApiResponse.verify(message.api);
                    if (error)
                        return "api." + error;
                }
            }
            if (message.ts != null && message.hasOwnProperty("ts"))
                if (!$util.isInteger(message.ts) && !(message.ts && $util.isInteger(message.ts.low) && $util.isInteger(message.ts.high)))
                    return "ts: integer|Long expected";
            return null;
        };

        /**
         * Creates a Packet message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof pb.Packet
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {pb.Packet} Packet
         */
        Packet.fromObject = function fromObject(object) {
            if (object instanceof $root.pb.Packet)
                return object;
            let message = new $root.pb.Packet();
            if (object.id != null)
                message.id = String(object.id);
            switch (object.cmd) {
            default:
                if (typeof object.cmd === "number") {
                    message.cmd = object.cmd;
                    break;
                }
                break;
            case "CMD_IM":
            case 0:
                message.cmd = 0;
                break;
            case "CMD_STREAM_IM":
            case 1:
                message.cmd = 1;
                break;
            case "CMD_HEARTBEAT":
            case 2:
                message.cmd = 2;
                break;
            case "CMD_ACK":
            case 3:
                message.cmd = 3;
                break;
            case "CMD_API":
            case 4:
                message.cmd = 4;
                break;
            case "CMD_ERROR":
            case 5:
                message.cmd = 5;
                break;
            }
            if (object.msg != null) {
                if (typeof object.msg !== "object")
                    throw TypeError(".pb.Packet.msg: object expected");
                message.msg = $root.pb.Message.fromObject(object.msg);
            }
            if (object.api != null) {
                if (typeof object.api !== "object")
                    throw TypeError(".pb.Packet.api: object expected");
                message.api = $root.pb.ApiResponse.fromObject(object.api);
            }
            if (object.ts != null)
                if ($util.Long)
                    (message.ts = $util.Long.fromValue(object.ts)).unsigned = false;
                else if (typeof object.ts === "string")
                    message.ts = parseInt(object.ts, 10);
                else if (typeof object.ts === "number")
                    message.ts = object.ts;
                else if (typeof object.ts === "object")
                    message.ts = new $util.LongBits(object.ts.low >>> 0, object.ts.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a Packet message. Also converts values to other types if specified.
         * @function toObject
         * @memberof pb.Packet
         * @static
         * @param {pb.Packet} message Packet
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Packet.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.cmd = options.enums === String ? "CMD_IM" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.ts = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.ts = options.longs === String ? "0" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.cmd != null && message.hasOwnProperty("cmd"))
                object.cmd = options.enums === String ? $root.pb.CmdType[message.cmd] === undefined ? message.cmd : $root.pb.CmdType[message.cmd] : message.cmd;
            if (message.msg != null && message.hasOwnProperty("msg")) {
                object.msg = $root.pb.Message.toObject(message.msg, options);
                if (options.oneofs)
                    object.content = "msg";
            }
            if (message.api != null && message.hasOwnProperty("api")) {
                object.api = $root.pb.ApiResponse.toObject(message.api, options);
                if (options.oneofs)
                    object.content = "api";
            }
            if (message.ts != null && message.hasOwnProperty("ts"))
                if (typeof message.ts === "number")
                    object.ts = options.longs === String ? String(message.ts) : message.ts;
                else
                    object.ts = options.longs === String ? $util.Long.prototype.toString.call(message.ts) : options.longs === Number ? new $util.LongBits(message.ts.low >>> 0, message.ts.high >>> 0).toNumber() : message.ts;
            return object;
        };

        /**
         * Converts this Packet to JSON.
         * @function toJSON
         * @memberof pb.Packet
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Packet.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Packet
         * @function getTypeUrl
         * @memberof pb.Packet
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Packet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/pb.Packet";
        };

        return Packet;
    })();

    /**
     * CmdType enum.
     * @name pb.CmdType
     * @enum {number}
     * @property {number} CMD_IM=0 CMD_IM value
     * @property {number} CMD_STREAM_IM=1 CMD_STREAM_IM value
     * @property {number} CMD_HEARTBEAT=2 CMD_HEARTBEAT value
     * @property {number} CMD_ACK=3 CMD_ACK value
     * @property {number} CMD_API=4 CMD_API value
     * @property {number} CMD_ERROR=5 CMD_ERROR value
     */
    pb.CmdType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CMD_IM"] = 0;
        values[valuesById[1] = "CMD_STREAM_IM"] = 1;
        values[valuesById[2] = "CMD_HEARTBEAT"] = 2;
        values[valuesById[3] = "CMD_ACK"] = 3;
        values[valuesById[4] = "CMD_API"] = 4;
        values[valuesById[5] = "CMD_ERROR"] = 5;
        return values;
    })();

    pb.Message = (function() {

        /**
         * Properties of a Message.
         * @memberof pb
         * @interface IMessage
         * @property {string|null} [id] Message id
         * @property {string|null} [chatId] Message chatId
         * @property {pb.CmdType|null} [cmd] Message cmd
         * @property {pb.Message.MessageType|null} [type] Message type
         * @property {pb.Message.IMsg|null} [msg] Message msg
         * @property {pb.Message.IUser|null} [user] Message user
         * @property {pb.Message.IAi|null} [ai] Message ai
         * @property {pb.Message.IUser|null} [toUser] Message toUser
         * @property {pb.Message.MessageCat|null} [cat] Message cat
         * @property {pb.Message.IAckMessage|null} [ack] Message ack
         * @property {number|Long|null} [ts] Message ts
         */

        /**
         * Constructs a new Message.
         * @memberof pb
         * @classdesc Represents a Message.
         * @implements IMessage
         * @constructor
         * @param {pb.IMessage=} [properties] Properties to set
         */
        function Message(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Message id.
         * @member {string} id
         * @memberof pb.Message
         * @instance
         */
        Message.prototype.id = "";

        /**
         * Message chatId.
         * @member {string} chatId
         * @memberof pb.Message
         * @instance
         */
        Message.prototype.chatId = "";

        /**
         * Message cmd.
         * @member {pb.CmdType} cmd
         * @memberof pb.Message
         * @instance
         */
        Message.prototype.cmd = 0;

        /**
         * Message type.
         * @member {pb.Message.MessageType} type
         * @memberof pb.Message
         * @instance
         */
        Message.prototype.type = 0;

        /**
         * Message msg.
         * @member {pb.Message.IMsg|null|undefined} msg
         * @memberof pb.Message
         * @instance
         */
        Message.prototype.msg = null;

        /**
         * Message user.
         * @member {pb.Message.IUser|null|undefined} user
         * @memberof pb.Message
         * @instance
         */
        Message.prototype.user = null;

        /**
         * Message ai.
         * @member {pb.Message.IAi|null|undefined} ai
         * @memberof pb.Message
         * @instance
         */
        Message.prototype.ai = null;

        /**
         * Message toUser.
         * @member {pb.Message.IUser|null|undefined} toUser
         * @memberof pb.Message
         * @instance
         */
        Message.prototype.toUser = null;

        /**
         * Message cat.
         * @member {pb.Message.MessageCat} cat
         * @memberof pb.Message
         * @instance
         */
        Message.prototype.cat = 0;

        /**
         * Message ack.
         * @member {pb.Message.IAckMessage|null|undefined} ack
         * @memberof pb.Message
         * @instance
         */
        Message.prototype.ack = null;

        /**
         * Message ts.
         * @member {number|Long} ts
         * @memberof pb.Message
         * @instance
         */
        Message.prototype.ts = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Message to.
         * @member {"ai"|"toUser"|undefined} to
         * @memberof pb.Message
         * @instance
         */
        Object.defineProperty(Message.prototype, "to", {
            get: $util.oneOfGetter($oneOfFields = ["ai", "toUser"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Message _ack.
         * @member {"ack"|undefined} _ack
         * @memberof pb.Message
         * @instance
         */
        Object.defineProperty(Message.prototype, "_ack", {
            get: $util.oneOfGetter($oneOfFields = ["ack"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Message instance using the specified properties.
         * @function create
         * @memberof pb.Message
         * @static
         * @param {pb.IMessage=} [properties] Properties to set
         * @returns {pb.Message} Message instance
         */
        Message.create = function create(properties) {
            return new Message(properties);
        };

        /**
         * Encodes the specified Message message. Does not implicitly {@link pb.Message.verify|verify} messages.
         * @function encode
         * @memberof pb.Message
         * @static
         * @param {pb.IMessage} message Message message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Message.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.chatId);
            if (message.cmd != null && Object.hasOwnProperty.call(message, "cmd"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.cmd);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
            if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
                $root.pb.Message.Msg.encode(message.msg, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.pb.Message.User.encode(message.user, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.ai != null && Object.hasOwnProperty.call(message, "ai"))
                $root.pb.Message.Ai.encode(message.ai, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.toUser != null && Object.hasOwnProperty.call(message, "toUser"))
                $root.pb.Message.User.encode(message.toUser, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.cat != null && Object.hasOwnProperty.call(message, "cat"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.cat);
            if (message.ack != null && Object.hasOwnProperty.call(message, "ack"))
                $root.pb.Message.AckMessage.encode(message.ack, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.ts != null && Object.hasOwnProperty.call(message, "ts"))
                writer.uint32(/* id 11, wireType 0 =*/88).int64(message.ts);
            return writer;
        };

        /**
         * Encodes the specified Message message, length delimited. Does not implicitly {@link pb.Message.verify|verify} messages.
         * @function encodeDelimited
         * @memberof pb.Message
         * @static
         * @param {pb.IMessage} message Message message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Message.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Message message from the specified reader or buffer.
         * @function decode
         * @memberof pb.Message
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {pb.Message} Message
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Message.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.pb.Message();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.chatId = reader.string();
                        break;
                    }
                case 3: {
                        message.cmd = reader.int32();
                        break;
                    }
                case 4: {
                        message.type = reader.int32();
                        break;
                    }
                case 5: {
                        message.msg = $root.pb.Message.Msg.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.user = $root.pb.Message.User.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.ai = $root.pb.Message.Ai.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.toUser = $root.pb.Message.User.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.cat = reader.int32();
                        break;
                    }
                case 10: {
                        message.ack = $root.pb.Message.AckMessage.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.ts = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Message message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof pb.Message
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {pb.Message} Message
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Message.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Message message.
         * @function verify
         * @memberof pb.Message
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Message.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.chatId != null && message.hasOwnProperty("chatId"))
                if (!$util.isString(message.chatId))
                    return "chatId: string expected";
            if (message.cmd != null && message.hasOwnProperty("cmd"))
                switch (message.cmd) {
                default:
                    return "cmd: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            if (message.msg != null && message.hasOwnProperty("msg")) {
                let error = $root.pb.Message.Msg.verify(message.msg);
                if (error)
                    return "msg." + error;
            }
            if (message.user != null && message.hasOwnProperty("user")) {
                let error = $root.pb.Message.User.verify(message.user);
                if (error)
                    return "user." + error;
            }
            if (message.ai != null && message.hasOwnProperty("ai")) {
                properties.to = 1;
                {
                    let error = $root.pb.Message.Ai.verify(message.ai);
                    if (error)
                        return "ai." + error;
                }
            }
            if (message.toUser != null && message.hasOwnProperty("toUser")) {
                if (properties.to === 1)
                    return "to: multiple values";
                properties.to = 1;
                {
                    let error = $root.pb.Message.User.verify(message.toUser);
                    if (error)
                        return "toUser." + error;
                }
            }
            if (message.cat != null && message.hasOwnProperty("cat"))
                switch (message.cat) {
                default:
                    return "cat: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.ack != null && message.hasOwnProperty("ack")) {
                properties._ack = 1;
                {
                    let error = $root.pb.Message.AckMessage.verify(message.ack);
                    if (error)
                        return "ack." + error;
                }
            }
            if (message.ts != null && message.hasOwnProperty("ts"))
                if (!$util.isInteger(message.ts) && !(message.ts && $util.isInteger(message.ts.low) && $util.isInteger(message.ts.high)))
                    return "ts: integer|Long expected";
            return null;
        };

        /**
         * Creates a Message message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof pb.Message
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {pb.Message} Message
         */
        Message.fromObject = function fromObject(object) {
            if (object instanceof $root.pb.Message)
                return object;
            let message = new $root.pb.Message();
            if (object.id != null)
                message.id = String(object.id);
            if (object.chatId != null)
                message.chatId = String(object.chatId);
            switch (object.cmd) {
            default:
                if (typeof object.cmd === "number") {
                    message.cmd = object.cmd;
                    break;
                }
                break;
            case "CMD_IM":
            case 0:
                message.cmd = 0;
                break;
            case "CMD_STREAM_IM":
            case 1:
                message.cmd = 1;
                break;
            case "CMD_HEARTBEAT":
            case 2:
                message.cmd = 2;
                break;
            case "CMD_ACK":
            case 3:
                message.cmd = 3;
                break;
            case "CMD_API":
            case 4:
                message.cmd = 4;
                break;
            case "CMD_ERROR":
            case 5:
                message.cmd = 5;
                break;
            }
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "TYPE_TEXT":
            case 0:
                message.type = 0;
                break;
            case "TYPE_STORY":
            case 1:
                message.type = 1;
                break;
            case "TYPE_IMAGE":
            case 2:
                message.type = 2;
                break;
            case "TYPE_IMAGE_TO_IMAGE":
            case 3:
                message.type = 3;
                break;
            case "TYPE_VISION":
            case 4:
                message.type = 4;
                break;
            case "TYPE_VISION_STORY":
            case 5:
                message.type = 5;
                break;
            }
            if (object.msg != null) {
                if (typeof object.msg !== "object")
                    throw TypeError(".pb.Message.msg: object expected");
                message.msg = $root.pb.Message.Msg.fromObject(object.msg);
            }
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".pb.Message.user: object expected");
                message.user = $root.pb.Message.User.fromObject(object.user);
            }
            if (object.ai != null) {
                if (typeof object.ai !== "object")
                    throw TypeError(".pb.Message.ai: object expected");
                message.ai = $root.pb.Message.Ai.fromObject(object.ai);
            }
            if (object.toUser != null) {
                if (typeof object.toUser !== "object")
                    throw TypeError(".pb.Message.toUser: object expected");
                message.toUser = $root.pb.Message.User.fromObject(object.toUser);
            }
            switch (object.cat) {
            default:
                if (typeof object.cat === "number") {
                    message.cat = object.cat;
                    break;
                }
                break;
            case "MESSAGE_CAT_USER_TO_AI":
            case 0:
                message.cat = 0;
                break;
            case "MESSAGE_CAT_AI_TO_USER":
            case 1:
                message.cat = 1;
                break;
            case "MESSAGE_CAT_USER_TO_USER":
            case 2:
                message.cat = 2;
                break;
            }
            if (object.ack != null) {
                if (typeof object.ack !== "object")
                    throw TypeError(".pb.Message.ack: object expected");
                message.ack = $root.pb.Message.AckMessage.fromObject(object.ack);
            }
            if (object.ts != null)
                if ($util.Long)
                    (message.ts = $util.Long.fromValue(object.ts)).unsigned = false;
                else if (typeof object.ts === "string")
                    message.ts = parseInt(object.ts, 10);
                else if (typeof object.ts === "number")
                    message.ts = object.ts;
                else if (typeof object.ts === "object")
                    message.ts = new $util.LongBits(object.ts.low >>> 0, object.ts.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a Message message. Also converts values to other types if specified.
         * @function toObject
         * @memberof pb.Message
         * @static
         * @param {pb.Message} message Message
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Message.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.chatId = "";
                object.cmd = options.enums === String ? "CMD_IM" : 0;
                object.type = options.enums === String ? "TYPE_TEXT" : 0;
                object.msg = null;
                object.user = null;
                object.cat = options.enums === String ? "MESSAGE_CAT_USER_TO_AI" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.ts = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.ts = options.longs === String ? "0" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.chatId != null && message.hasOwnProperty("chatId"))
                object.chatId = message.chatId;
            if (message.cmd != null && message.hasOwnProperty("cmd"))
                object.cmd = options.enums === String ? $root.pb.CmdType[message.cmd] === undefined ? message.cmd : $root.pb.CmdType[message.cmd] : message.cmd;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.pb.Message.MessageType[message.type] === undefined ? message.type : $root.pb.Message.MessageType[message.type] : message.type;
            if (message.msg != null && message.hasOwnProperty("msg"))
                object.msg = $root.pb.Message.Msg.toObject(message.msg, options);
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.pb.Message.User.toObject(message.user, options);
            if (message.ai != null && message.hasOwnProperty("ai")) {
                object.ai = $root.pb.Message.Ai.toObject(message.ai, options);
                if (options.oneofs)
                    object.to = "ai";
            }
            if (message.toUser != null && message.hasOwnProperty("toUser")) {
                object.toUser = $root.pb.Message.User.toObject(message.toUser, options);
                if (options.oneofs)
                    object.to = "toUser";
            }
            if (message.cat != null && message.hasOwnProperty("cat"))
                object.cat = options.enums === String ? $root.pb.Message.MessageCat[message.cat] === undefined ? message.cat : $root.pb.Message.MessageCat[message.cat] : message.cat;
            if (message.ack != null && message.hasOwnProperty("ack")) {
                object.ack = $root.pb.Message.AckMessage.toObject(message.ack, options);
                if (options.oneofs)
                    object._ack = "ack";
            }
            if (message.ts != null && message.hasOwnProperty("ts"))
                if (typeof message.ts === "number")
                    object.ts = options.longs === String ? String(message.ts) : message.ts;
                else
                    object.ts = options.longs === String ? $util.Long.prototype.toString.call(message.ts) : options.longs === Number ? new $util.LongBits(message.ts.low >>> 0, message.ts.high >>> 0).toNumber() : message.ts;
            return object;
        };

        /**
         * Converts this Message to JSON.
         * @function toJSON
         * @memberof pb.Message
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Message.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Message
         * @function getTypeUrl
         * @memberof pb.Message
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Message.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/pb.Message";
        };

        Message.Msg = (function() {

            /**
             * Properties of a Msg.
             * @memberof pb.Message
             * @interface IMsg
             * @property {string|null} [text] Msg text
             * @property {string|null} [image] Msg image
             * @property {string|null} [video] Msg video
             * @property {string|null} [audio] Msg audio
             */

            /**
             * Constructs a new Msg.
             * @memberof pb.Message
             * @classdesc Represents a Msg.
             * @implements IMsg
             * @constructor
             * @param {pb.Message.IMsg=} [properties] Properties to set
             */
            function Msg(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Msg text.
             * @member {string} text
             * @memberof pb.Message.Msg
             * @instance
             */
            Msg.prototype.text = "";

            /**
             * Msg image.
             * @member {string|null|undefined} image
             * @memberof pb.Message.Msg
             * @instance
             */
            Msg.prototype.image = null;

            /**
             * Msg video.
             * @member {string|null|undefined} video
             * @memberof pb.Message.Msg
             * @instance
             */
            Msg.prototype.video = null;

            /**
             * Msg audio.
             * @member {string|null|undefined} audio
             * @memberof pb.Message.Msg
             * @instance
             */
            Msg.prototype.audio = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * Msg _image.
             * @member {"image"|undefined} _image
             * @memberof pb.Message.Msg
             * @instance
             */
            Object.defineProperty(Msg.prototype, "_image", {
                get: $util.oneOfGetter($oneOfFields = ["image"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Msg _video.
             * @member {"video"|undefined} _video
             * @memberof pb.Message.Msg
             * @instance
             */
            Object.defineProperty(Msg.prototype, "_video", {
                get: $util.oneOfGetter($oneOfFields = ["video"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Msg _audio.
             * @member {"audio"|undefined} _audio
             * @memberof pb.Message.Msg
             * @instance
             */
            Object.defineProperty(Msg.prototype, "_audio", {
                get: $util.oneOfGetter($oneOfFields = ["audio"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new Msg instance using the specified properties.
             * @function create
             * @memberof pb.Message.Msg
             * @static
             * @param {pb.Message.IMsg=} [properties] Properties to set
             * @returns {pb.Message.Msg} Msg instance
             */
            Msg.create = function create(properties) {
                return new Msg(properties);
            };

            /**
             * Encodes the specified Msg message. Does not implicitly {@link pb.Message.Msg.verify|verify} messages.
             * @function encode
             * @memberof pb.Message.Msg
             * @static
             * @param {pb.Message.IMsg} message Msg message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Msg.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
                if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.image);
                if (message.video != null && Object.hasOwnProperty.call(message, "video"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.video);
                if (message.audio != null && Object.hasOwnProperty.call(message, "audio"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.audio);
                return writer;
            };

            /**
             * Encodes the specified Msg message, length delimited. Does not implicitly {@link pb.Message.Msg.verify|verify} messages.
             * @function encodeDelimited
             * @memberof pb.Message.Msg
             * @static
             * @param {pb.Message.IMsg} message Msg message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Msg.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Msg message from the specified reader or buffer.
             * @function decode
             * @memberof pb.Message.Msg
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {pb.Message.Msg} Msg
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Msg.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.pb.Message.Msg();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.text = reader.string();
                            break;
                        }
                    case 2: {
                            message.image = reader.string();
                            break;
                        }
                    case 3: {
                            message.video = reader.string();
                            break;
                        }
                    case 4: {
                            message.audio = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Msg message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof pb.Message.Msg
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {pb.Message.Msg} Msg
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Msg.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Msg message.
             * @function verify
             * @memberof pb.Message.Msg
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Msg.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.text != null && message.hasOwnProperty("text"))
                    if (!$util.isString(message.text))
                        return "text: string expected";
                if (message.image != null && message.hasOwnProperty("image")) {
                    properties._image = 1;
                    if (!$util.isString(message.image))
                        return "image: string expected";
                }
                if (message.video != null && message.hasOwnProperty("video")) {
                    properties._video = 1;
                    if (!$util.isString(message.video))
                        return "video: string expected";
                }
                if (message.audio != null && message.hasOwnProperty("audio")) {
                    properties._audio = 1;
                    if (!$util.isString(message.audio))
                        return "audio: string expected";
                }
                return null;
            };

            /**
             * Creates a Msg message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof pb.Message.Msg
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {pb.Message.Msg} Msg
             */
            Msg.fromObject = function fromObject(object) {
                if (object instanceof $root.pb.Message.Msg)
                    return object;
                let message = new $root.pb.Message.Msg();
                if (object.text != null)
                    message.text = String(object.text);
                if (object.image != null)
                    message.image = String(object.image);
                if (object.video != null)
                    message.video = String(object.video);
                if (object.audio != null)
                    message.audio = String(object.audio);
                return message;
            };

            /**
             * Creates a plain object from a Msg message. Also converts values to other types if specified.
             * @function toObject
             * @memberof pb.Message.Msg
             * @static
             * @param {pb.Message.Msg} message Msg
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Msg.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.text = "";
                if (message.text != null && message.hasOwnProperty("text"))
                    object.text = message.text;
                if (message.image != null && message.hasOwnProperty("image")) {
                    object.image = message.image;
                    if (options.oneofs)
                        object._image = "image";
                }
                if (message.video != null && message.hasOwnProperty("video")) {
                    object.video = message.video;
                    if (options.oneofs)
                        object._video = "video";
                }
                if (message.audio != null && message.hasOwnProperty("audio")) {
                    object.audio = message.audio;
                    if (options.oneofs)
                        object._audio = "audio";
                }
                return object;
            };

            /**
             * Converts this Msg to JSON.
             * @function toJSON
             * @memberof pb.Message.Msg
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Msg.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Msg
             * @function getTypeUrl
             * @memberof pb.Message.Msg
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/pb.Message.Msg";
            };

            return Msg;
        })();

        /**
         * MessageType enum.
         * @name pb.Message.MessageType
         * @enum {number}
         * @property {number} TYPE_TEXT=0 TYPE_TEXT value
         * @property {number} TYPE_STORY=1 TYPE_STORY value
         * @property {number} TYPE_IMAGE=2 TYPE_IMAGE value
         * @property {number} TYPE_IMAGE_TO_IMAGE=3 TYPE_IMAGE_TO_IMAGE value
         * @property {number} TYPE_VISION=4 TYPE_VISION value
         * @property {number} TYPE_VISION_STORY=5 TYPE_VISION_STORY value
         */
        Message.MessageType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "TYPE_TEXT"] = 0;
            values[valuesById[1] = "TYPE_STORY"] = 1;
            values[valuesById[2] = "TYPE_IMAGE"] = 2;
            values[valuesById[3] = "TYPE_IMAGE_TO_IMAGE"] = 3;
            values[valuesById[4] = "TYPE_VISION"] = 4;
            values[valuesById[5] = "TYPE_VISION_STORY"] = 5;
            return values;
        })();

        Message.User = (function() {

            /**
             * Properties of a User.
             * @memberof pb.Message
             * @interface IUser
             * @property {number|Long|null} [id] User id
             * @property {string|null} [devId] User devId
             * @property {number|Long|null} [appId] User appId
             */

            /**
             * Constructs a new User.
             * @memberof pb.Message
             * @classdesc Represents a User.
             * @implements IUser
             * @constructor
             * @param {pb.Message.IUser=} [properties] Properties to set
             */
            function User(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * User id.
             * @member {number|Long} id
             * @memberof pb.Message.User
             * @instance
             */
            User.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * User devId.
             * @member {string} devId
             * @memberof pb.Message.User
             * @instance
             */
            User.prototype.devId = "";

            /**
             * User appId.
             * @member {number|Long} appId
             * @memberof pb.Message.User
             * @instance
             */
            User.prototype.appId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new User instance using the specified properties.
             * @function create
             * @memberof pb.Message.User
             * @static
             * @param {pb.Message.IUser=} [properties] Properties to set
             * @returns {pb.Message.User} User instance
             */
            User.create = function create(properties) {
                return new User(properties);
            };

            /**
             * Encodes the specified User message. Does not implicitly {@link pb.Message.User.verify|verify} messages.
             * @function encode
             * @memberof pb.Message.User
             * @static
             * @param {pb.Message.IUser} message User message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            User.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.devId != null && Object.hasOwnProperty.call(message, "devId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.devId);
                if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.appId);
                return writer;
            };

            /**
             * Encodes the specified User message, length delimited. Does not implicitly {@link pb.Message.User.verify|verify} messages.
             * @function encodeDelimited
             * @memberof pb.Message.User
             * @static
             * @param {pb.Message.IUser} message User message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            User.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a User message from the specified reader or buffer.
             * @function decode
             * @memberof pb.Message.User
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {pb.Message.User} User
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            User.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.pb.Message.User();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.devId = reader.string();
                            break;
                        }
                    case 3: {
                            message.appId = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a User message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof pb.Message.User
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {pb.Message.User} User
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            User.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a User message.
             * @function verify
             * @memberof pb.Message.User
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            User.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.devId != null && message.hasOwnProperty("devId"))
                    if (!$util.isString(message.devId))
                        return "devId: string expected";
                if (message.appId != null && message.hasOwnProperty("appId"))
                    if (!$util.isInteger(message.appId) && !(message.appId && $util.isInteger(message.appId.low) && $util.isInteger(message.appId.high)))
                        return "appId: integer|Long expected";
                return null;
            };

            /**
             * Creates a User message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof pb.Message.User
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {pb.Message.User} User
             */
            User.fromObject = function fromObject(object) {
                if (object instanceof $root.pb.Message.User)
                    return object;
                let message = new $root.pb.Message.User();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.devId != null)
                    message.devId = String(object.devId);
                if (object.appId != null)
                    if ($util.Long)
                        (message.appId = $util.Long.fromValue(object.appId)).unsigned = true;
                    else if (typeof object.appId === "string")
                        message.appId = parseInt(object.appId, 10);
                    else if (typeof object.appId === "number")
                        message.appId = object.appId;
                    else if (typeof object.appId === "object")
                        message.appId = new $util.LongBits(object.appId.low >>> 0, object.appId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a User message. Also converts values to other types if specified.
             * @function toObject
             * @memberof pb.Message.User
             * @static
             * @param {pb.Message.User} message User
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            User.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.devId = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.appId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.appId = options.longs === String ? "0" : 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.devId != null && message.hasOwnProperty("devId"))
                    object.devId = message.devId;
                if (message.appId != null && message.hasOwnProperty("appId"))
                    if (typeof message.appId === "number")
                        object.appId = options.longs === String ? String(message.appId) : message.appId;
                    else
                        object.appId = options.longs === String ? $util.Long.prototype.toString.call(message.appId) : options.longs === Number ? new $util.LongBits(message.appId.low >>> 0, message.appId.high >>> 0).toNumber(true) : message.appId;
                return object;
            };

            /**
             * Converts this User to JSON.
             * @function toJSON
             * @memberof pb.Message.User
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            User.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for User
             * @function getTypeUrl
             * @memberof pb.Message.User
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            User.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/pb.Message.User";
            };

            return User;
        })();

        Message.Ai = (function() {

            /**
             * Properties of an Ai.
             * @memberof pb.Message
             * @interface IAi
             * @property {pb.Message.Ai.AiId|null} [id] Ai id
             * @property {string|null} [model] Ai model
             * @property {pb.Message.Ai.IStream|null} [stream] Ai stream
             */

            /**
             * Constructs a new Ai.
             * @memberof pb.Message
             * @classdesc Represents an Ai.
             * @implements IAi
             * @constructor
             * @param {pb.Message.IAi=} [properties] Properties to set
             */
            function Ai(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Ai id.
             * @member {pb.Message.Ai.AiId} id
             * @memberof pb.Message.Ai
             * @instance
             */
            Ai.prototype.id = 0;

            /**
             * Ai model.
             * @member {string} model
             * @memberof pb.Message.Ai
             * @instance
             */
            Ai.prototype.model = "";

            /**
             * Ai stream.
             * @member {pb.Message.Ai.IStream|null|undefined} stream
             * @memberof pb.Message.Ai
             * @instance
             */
            Ai.prototype.stream = null;

            /**
             * Creates a new Ai instance using the specified properties.
             * @function create
             * @memberof pb.Message.Ai
             * @static
             * @param {pb.Message.IAi=} [properties] Properties to set
             * @returns {pb.Message.Ai} Ai instance
             */
            Ai.create = function create(properties) {
                return new Ai(properties);
            };

            /**
             * Encodes the specified Ai message. Does not implicitly {@link pb.Message.Ai.verify|verify} messages.
             * @function encode
             * @memberof pb.Message.Ai
             * @static
             * @param {pb.Message.IAi} message Ai message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Ai.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
                if (message.model != null && Object.hasOwnProperty.call(message, "model"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.model);
                if (message.stream != null && Object.hasOwnProperty.call(message, "stream"))
                    $root.pb.Message.Ai.Stream.encode(message.stream, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Ai message, length delimited. Does not implicitly {@link pb.Message.Ai.verify|verify} messages.
             * @function encodeDelimited
             * @memberof pb.Message.Ai
             * @static
             * @param {pb.Message.IAi} message Ai message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Ai.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Ai message from the specified reader or buffer.
             * @function decode
             * @memberof pb.Message.Ai
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {pb.Message.Ai} Ai
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Ai.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.pb.Message.Ai();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.int32();
                            break;
                        }
                    case 2: {
                            message.model = reader.string();
                            break;
                        }
                    case 10: {
                            message.stream = $root.pb.Message.Ai.Stream.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Ai message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof pb.Message.Ai
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {pb.Message.Ai} Ai
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Ai.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Ai message.
             * @function verify
             * @memberof pb.Message.Ai
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Ai.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    switch (message.id) {
                    default:
                        return "id: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.model != null && message.hasOwnProperty("model"))
                    if (!$util.isString(message.model))
                        return "model: string expected";
                if (message.stream != null && message.hasOwnProperty("stream")) {
                    let error = $root.pb.Message.Ai.Stream.verify(message.stream);
                    if (error)
                        return "stream." + error;
                }
                return null;
            };

            /**
             * Creates an Ai message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof pb.Message.Ai
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {pb.Message.Ai} Ai
             */
            Ai.fromObject = function fromObject(object) {
                if (object instanceof $root.pb.Message.Ai)
                    return object;
                let message = new $root.pb.Message.Ai();
                switch (object.id) {
                default:
                    if (typeof object.id === "number") {
                        message.id = object.id;
                        break;
                    }
                    break;
                case "AI_ID_AI":
                case 0:
                    message.id = 0;
                    break;
                case "AI_ID_QIANFAN":
                case 1:
                    message.id = 1;
                    break;
                }
                if (object.model != null)
                    message.model = String(object.model);
                if (object.stream != null) {
                    if (typeof object.stream !== "object")
                        throw TypeError(".pb.Message.Ai.stream: object expected");
                    message.stream = $root.pb.Message.Ai.Stream.fromObject(object.stream);
                }
                return message;
            };

            /**
             * Creates a plain object from an Ai message. Also converts values to other types if specified.
             * @function toObject
             * @memberof pb.Message.Ai
             * @static
             * @param {pb.Message.Ai} message Ai
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Ai.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.id = options.enums === String ? "AI_ID_AI" : 0;
                    object.model = "";
                    object.stream = null;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = options.enums === String ? $root.pb.Message.Ai.AiId[message.id] === undefined ? message.id : $root.pb.Message.Ai.AiId[message.id] : message.id;
                if (message.model != null && message.hasOwnProperty("model"))
                    object.model = message.model;
                if (message.stream != null && message.hasOwnProperty("stream"))
                    object.stream = $root.pb.Message.Ai.Stream.toObject(message.stream, options);
                return object;
            };

            /**
             * Converts this Ai to JSON.
             * @function toJSON
             * @memberof pb.Message.Ai
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Ai.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Ai
             * @function getTypeUrl
             * @memberof pb.Message.Ai
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Ai.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/pb.Message.Ai";
            };

            /**
             * AiId enum.
             * @name pb.Message.Ai.AiId
             * @enum {number}
             * @property {number} AI_ID_AI=0 AI_ID_AI value
             * @property {number} AI_ID_QIANFAN=1 AI_ID_QIANFAN value
             */
            Ai.AiId = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "AI_ID_AI"] = 0;
                values[valuesById[1] = "AI_ID_QIANFAN"] = 1;
                return values;
            })();

            Ai.Stream = (function() {

                /**
                 * Properties of a Stream.
                 * @memberof pb.Message.Ai
                 * @interface IStream
                 * @property {string|null} [id] Stream id
                 * @property {pb.Message.Ai.Stream.StreamStatus|null} [status] Stream status
                 */

                /**
                 * Constructs a new Stream.
                 * @memberof pb.Message.Ai
                 * @classdesc Represents a Stream.
                 * @implements IStream
                 * @constructor
                 * @param {pb.Message.Ai.IStream=} [properties] Properties to set
                 */
                function Stream(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Stream id.
                 * @member {string} id
                 * @memberof pb.Message.Ai.Stream
                 * @instance
                 */
                Stream.prototype.id = "";

                /**
                 * Stream status.
                 * @member {pb.Message.Ai.Stream.StreamStatus} status
                 * @memberof pb.Message.Ai.Stream
                 * @instance
                 */
                Stream.prototype.status = 0;

                /**
                 * Creates a new Stream instance using the specified properties.
                 * @function create
                 * @memberof pb.Message.Ai.Stream
                 * @static
                 * @param {pb.Message.Ai.IStream=} [properties] Properties to set
                 * @returns {pb.Message.Ai.Stream} Stream instance
                 */
                Stream.create = function create(properties) {
                    return new Stream(properties);
                };

                /**
                 * Encodes the specified Stream message. Does not implicitly {@link pb.Message.Ai.Stream.verify|verify} messages.
                 * @function encode
                 * @memberof pb.Message.Ai.Stream
                 * @static
                 * @param {pb.Message.Ai.IStream} message Stream message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Stream.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
                    return writer;
                };

                /**
                 * Encodes the specified Stream message, length delimited. Does not implicitly {@link pb.Message.Ai.Stream.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof pb.Message.Ai.Stream
                 * @static
                 * @param {pb.Message.Ai.IStream} message Stream message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Stream.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Stream message from the specified reader or buffer.
                 * @function decode
                 * @memberof pb.Message.Ai.Stream
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {pb.Message.Ai.Stream} Stream
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Stream.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.pb.Message.Ai.Stream();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        case 2: {
                                message.status = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Stream message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof pb.Message.Ai.Stream
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {pb.Message.Ai.Stream} Stream
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Stream.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Stream message.
                 * @function verify
                 * @memberof pb.Message.Ai.Stream
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Stream.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a Stream message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof pb.Message.Ai.Stream
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {pb.Message.Ai.Stream} Stream
                 */
                Stream.fromObject = function fromObject(object) {
                    if (object instanceof $root.pb.Message.Ai.Stream)
                        return object;
                    let message = new $root.pb.Message.Ai.Stream();
                    if (object.id != null)
                        message.id = String(object.id);
                    switch (object.status) {
                    default:
                        if (typeof object.status === "number") {
                            message.status = object.status;
                            break;
                        }
                        break;
                    case "STREAM_STATUS_NOT":
                    case 0:
                        message.status = 0;
                        break;
                    case "STREAM_STATUS_START":
                    case 1:
                        message.status = 1;
                        break;
                    case "STREAM_STATUS_ING":
                    case 2:
                        message.status = 2;
                        break;
                    case "STREAM_STATUS_PAUSE":
                    case 3:
                        message.status = 3;
                        break;
                    case "STREAM_STATUS_END":
                    case 4:
                        message.status = 4;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Stream message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof pb.Message.Ai.Stream
                 * @static
                 * @param {pb.Message.Ai.Stream} message Stream
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Stream.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.id = "";
                        object.status = options.enums === String ? "STREAM_STATUS_NOT" : 0;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.pb.Message.Ai.Stream.StreamStatus[message.status] === undefined ? message.status : $root.pb.Message.Ai.Stream.StreamStatus[message.status] : message.status;
                    return object;
                };

                /**
                 * Converts this Stream to JSON.
                 * @function toJSON
                 * @memberof pb.Message.Ai.Stream
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Stream.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Stream
                 * @function getTypeUrl
                 * @memberof pb.Message.Ai.Stream
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Stream.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/pb.Message.Ai.Stream";
                };

                /**
                 * StreamStatus enum.
                 * @name pb.Message.Ai.Stream.StreamStatus
                 * @enum {number}
                 * @property {number} STREAM_STATUS_NOT=0 STREAM_STATUS_NOT value
                 * @property {number} STREAM_STATUS_START=1 STREAM_STATUS_START value
                 * @property {number} STREAM_STATUS_ING=2 STREAM_STATUS_ING value
                 * @property {number} STREAM_STATUS_PAUSE=3 STREAM_STATUS_PAUSE value
                 * @property {number} STREAM_STATUS_END=4 STREAM_STATUS_END value
                 */
                Stream.StreamStatus = (function() {
                    const valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "STREAM_STATUS_NOT"] = 0;
                    values[valuesById[1] = "STREAM_STATUS_START"] = 1;
                    values[valuesById[2] = "STREAM_STATUS_ING"] = 2;
                    values[valuesById[3] = "STREAM_STATUS_PAUSE"] = 3;
                    values[valuesById[4] = "STREAM_STATUS_END"] = 4;
                    return values;
                })();

                return Stream;
            })();

            return Ai;
        })();

        /**
         * MessageCat enum.
         * @name pb.Message.MessageCat
         * @enum {number}
         * @property {number} MESSAGE_CAT_USER_TO_AI=0 MESSAGE_CAT_USER_TO_AI value
         * @property {number} MESSAGE_CAT_AI_TO_USER=1 MESSAGE_CAT_AI_TO_USER value
         * @property {number} MESSAGE_CAT_USER_TO_USER=2 MESSAGE_CAT_USER_TO_USER value
         */
        Message.MessageCat = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "MESSAGE_CAT_USER_TO_AI"] = 0;
            values[valuesById[1] = "MESSAGE_CAT_AI_TO_USER"] = 1;
            values[valuesById[2] = "MESSAGE_CAT_USER_TO_USER"] = 2;
            return values;
        })();

        Message.AckMessage = (function() {

            /**
             * Properties of an AckMessage.
             * @memberof pb.Message
             * @interface IAckMessage
             * @property {string|null} [messageId] AckMessage messageId
             * @property {pb.Message.MessageCode|null} [code] AckMessage code
             * @property {string|null} [detail] AckMessage detail
             */

            /**
             * Constructs a new AckMessage.
             * @memberof pb.Message
             * @classdesc Represents an AckMessage.
             * @implements IAckMessage
             * @constructor
             * @param {pb.Message.IAckMessage=} [properties] Properties to set
             */
            function AckMessage(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AckMessage messageId.
             * @member {string} messageId
             * @memberof pb.Message.AckMessage
             * @instance
             */
            AckMessage.prototype.messageId = "";

            /**
             * AckMessage code.
             * @member {pb.Message.MessageCode} code
             * @memberof pb.Message.AckMessage
             * @instance
             */
            AckMessage.prototype.code = 0;

            /**
             * AckMessage detail.
             * @member {string} detail
             * @memberof pb.Message.AckMessage
             * @instance
             */
            AckMessage.prototype.detail = "";

            /**
             * Creates a new AckMessage instance using the specified properties.
             * @function create
             * @memberof pb.Message.AckMessage
             * @static
             * @param {pb.Message.IAckMessage=} [properties] Properties to set
             * @returns {pb.Message.AckMessage} AckMessage instance
             */
            AckMessage.create = function create(properties) {
                return new AckMessage(properties);
            };

            /**
             * Encodes the specified AckMessage message. Does not implicitly {@link pb.Message.AckMessage.verify|verify} messages.
             * @function encode
             * @memberof pb.Message.AckMessage
             * @static
             * @param {pb.Message.IAckMessage} message AckMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AckMessage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.messageId != null && Object.hasOwnProperty.call(message, "messageId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.messageId);
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.code);
                if (message.detail != null && Object.hasOwnProperty.call(message, "detail"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.detail);
                return writer;
            };

            /**
             * Encodes the specified AckMessage message, length delimited. Does not implicitly {@link pb.Message.AckMessage.verify|verify} messages.
             * @function encodeDelimited
             * @memberof pb.Message.AckMessage
             * @static
             * @param {pb.Message.IAckMessage} message AckMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AckMessage.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AckMessage message from the specified reader or buffer.
             * @function decode
             * @memberof pb.Message.AckMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {pb.Message.AckMessage} AckMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AckMessage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.pb.Message.AckMessage();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.messageId = reader.string();
                            break;
                        }
                    case 2: {
                            message.code = reader.int32();
                            break;
                        }
                    case 3: {
                            message.detail = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AckMessage message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof pb.Message.AckMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {pb.Message.AckMessage} AckMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AckMessage.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AckMessage message.
             * @function verify
             * @memberof pb.Message.AckMessage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AckMessage.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.messageId != null && message.hasOwnProperty("messageId"))
                    if (!$util.isString(message.messageId))
                        return "messageId: string expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    switch (message.code) {
                    default:
                        return "code: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.detail != null && message.hasOwnProperty("detail"))
                    if (!$util.isString(message.detail))
                        return "detail: string expected";
                return null;
            };

            /**
             * Creates an AckMessage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof pb.Message.AckMessage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {pb.Message.AckMessage} AckMessage
             */
            AckMessage.fromObject = function fromObject(object) {
                if (object instanceof $root.pb.Message.AckMessage)
                    return object;
                let message = new $root.pb.Message.AckMessage();
                if (object.messageId != null)
                    message.messageId = String(object.messageId);
                switch (object.code) {
                default:
                    if (typeof object.code === "number") {
                        message.code = object.code;
                        break;
                    }
                    break;
                case "MESSAGE_CODE_SUCCESS":
                case 0:
                    message.code = 0;
                    break;
                case "MESSAGE_CODE_FAILED":
                case 1:
                    message.code = 1;
                    break;
                }
                if (object.detail != null)
                    message.detail = String(object.detail);
                return message;
            };

            /**
             * Creates a plain object from an AckMessage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof pb.Message.AckMessage
             * @static
             * @param {pb.Message.AckMessage} message AckMessage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AckMessage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.messageId = "";
                    object.code = options.enums === String ? "MESSAGE_CODE_SUCCESS" : 0;
                    object.detail = "";
                }
                if (message.messageId != null && message.hasOwnProperty("messageId"))
                    object.messageId = message.messageId;
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = options.enums === String ? $root.pb.Message.MessageCode[message.code] === undefined ? message.code : $root.pb.Message.MessageCode[message.code] : message.code;
                if (message.detail != null && message.hasOwnProperty("detail"))
                    object.detail = message.detail;
                return object;
            };

            /**
             * Converts this AckMessage to JSON.
             * @function toJSON
             * @memberof pb.Message.AckMessage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AckMessage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AckMessage
             * @function getTypeUrl
             * @memberof pb.Message.AckMessage
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AckMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/pb.Message.AckMessage";
            };

            return AckMessage;
        })();

        /**
         * MessageCode enum.
         * @name pb.Message.MessageCode
         * @enum {number}
         * @property {number} MESSAGE_CODE_SUCCESS=0 MESSAGE_CODE_SUCCESS value
         * @property {number} MESSAGE_CODE_FAILED=1 MESSAGE_CODE_FAILED value
         */
        Message.MessageCode = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "MESSAGE_CODE_SUCCESS"] = 0;
            values[valuesById[1] = "MESSAGE_CODE_FAILED"] = 1;
            return values;
        })();

        return Message;
    })();

    pb.ApiResponse = (function() {

        /**
         * Properties of an ApiResponse.
         * @memberof pb
         * @interface IApiResponse
         * @property {number|null} [error] ApiResponse error
         * @property {string|null} [msg] ApiResponse msg
         * @property {string|null} [data] ApiResponse data
         * @property {string|null} [name] ApiResponse name
         */

        /**
         * Constructs a new ApiResponse.
         * @memberof pb
         * @classdesc Represents an ApiResponse.
         * @implements IApiResponse
         * @constructor
         * @param {pb.IApiResponse=} [properties] Properties to set
         */
        function ApiResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiResponse error.
         * @member {number} error
         * @memberof pb.ApiResponse
         * @instance
         */
        ApiResponse.prototype.error = 0;

        /**
         * ApiResponse msg.
         * @member {string} msg
         * @memberof pb.ApiResponse
         * @instance
         */
        ApiResponse.prototype.msg = "";

        /**
         * ApiResponse data.
         * @member {string} data
         * @memberof pb.ApiResponse
         * @instance
         */
        ApiResponse.prototype.data = "";

        /**
         * ApiResponse name.
         * @member {string} name
         * @memberof pb.ApiResponse
         * @instance
         */
        ApiResponse.prototype.name = "";

        /**
         * Creates a new ApiResponse instance using the specified properties.
         * @function create
         * @memberof pb.ApiResponse
         * @static
         * @param {pb.IApiResponse=} [properties] Properties to set
         * @returns {pb.ApiResponse} ApiResponse instance
         */
        ApiResponse.create = function create(properties) {
            return new ApiResponse(properties);
        };

        /**
         * Encodes the specified ApiResponse message. Does not implicitly {@link pb.ApiResponse.verify|verify} messages.
         * @function encode
         * @memberof pb.ApiResponse
         * @static
         * @param {pb.IApiResponse} message ApiResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.error);
            if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.msg);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.data);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified ApiResponse message, length delimited. Does not implicitly {@link pb.ApiResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof pb.ApiResponse
         * @static
         * @param {pb.IApiResponse} message ApiResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiResponse message from the specified reader or buffer.
         * @function decode
         * @memberof pb.ApiResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {pb.ApiResponse} ApiResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.pb.ApiResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.error = reader.int32();
                        break;
                    }
                case 2: {
                        message.msg = reader.string();
                        break;
                    }
                case 3: {
                        message.data = reader.string();
                        break;
                    }
                case 4: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof pb.ApiResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {pb.ApiResponse} ApiResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiResponse message.
         * @function verify
         * @memberof pb.ApiResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.error != null && message.hasOwnProperty("error"))
                if (!$util.isInteger(message.error))
                    return "error: integer expected";
            if (message.msg != null && message.hasOwnProperty("msg"))
                if (!$util.isString(message.msg))
                    return "msg: string expected";
            if (message.data != null && message.hasOwnProperty("data"))
                if (!$util.isString(message.data))
                    return "data: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates an ApiResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof pb.ApiResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {pb.ApiResponse} ApiResponse
         */
        ApiResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.pb.ApiResponse)
                return object;
            let message = new $root.pb.ApiResponse();
            if (object.error != null)
                message.error = object.error | 0;
            if (object.msg != null)
                message.msg = String(object.msg);
            if (object.data != null)
                message.data = String(object.data);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from an ApiResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof pb.ApiResponse
         * @static
         * @param {pb.ApiResponse} message ApiResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.error = 0;
                object.msg = "";
                object.data = "";
                object.name = "";
            }
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = message.error;
            if (message.msg != null && message.hasOwnProperty("msg"))
                object.msg = message.msg;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = message.data;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this ApiResponse to JSON.
         * @function toJSON
         * @memberof pb.ApiResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiResponse
         * @function getTypeUrl
         * @memberof pb.ApiResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/pb.ApiResponse";
        };

        return ApiResponse;
    })();

    return pb;
})();

export { $root as default };

<template>
  <div class="rank-box">
    <div class="rank-title">
      <div class=" text-left">
        <span>{{title}}</span><span class="sub-title"
          v-if="subTitle">({{subTitle}})</span>
          <el-tooltip v-if="showTopTip" style="margin-left:4px;margin-top:2px;" class="item" effect="dark"
          :content="calTopTip()" placement="right">
          <i class="el-icon-question"></i>
        </el-tooltip>
      </div>
      <div class="right-opt flex flex-1 content-end" >
        
        <div class="flex align-center">
          <span
            v-if="showChooseCate && !selectSecondCategory && (!keywordList || keywordList.length == 0)">品类筛选：</span>
          <el-select
            v-if="showChooseCate && !selectSecondCategory  && (!keywordList || keywordList.length == 0)"
            clearable
            v-model="category" :collapse-tags="true"
            :placeholder="placeholderDefault ? placeholderDefault : '请选择'"
            @change="handleChange">
            <el-option v-for="item in categoryOptions"
              :key="item.id" :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <span
            v-if="showChooseCate && !selectSecondCategory && (!keywordList || keywordList.length == 0)">细分对比：</span>
          <el-select
            v-if="showChooseCate && !selectSecondCategory && (categoryItems.length == 0 || currentFirstCategory.length > 0) && (!keywordList || keywordList.length == 0)"
            v-model="firstCategory" :collapse-tags="true"
            :multiple-limit="4" multiple
            :placeholder="placeholderDefault ? placeholderDefault : '请选择'"
            @visible-change="handleFirstChange"
            @remove-tag="removeFirstTag">
            <el-option v-for="item in categoryAllOptions"
              :key="item.id" :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <el-select
            v-if="categoryItems.length > 0 && categoryItems.length == 1 && currentFirstCategory.length == 0 && !selectSecondCategory && (!keywordList || keywordList.length == 0)"
            class="second-select" :collapse-tags="true"
            :multiple-limit="4" v-model="secondCategory"
            @remove-tag="handleDelSecondChange" multiple
            placeholder="请选择品类"
            @visible-change="handleSecondChange">
            <el-option v-for="item in secondCategoryOptions"
              :key="item.id" :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <el-switch style="margin-left: 20px;"
            v-if="showSwitch && ipId" v-model="ipActiveText"
            active-text="同IP" inactive-text="同类IP"
            @change="activeChange">
          </el-switch>
        </div>

      </div>
    </div>
    <div class="flex" style="padding: 20px 0 20px 20px;" v-if="keywordList && keywordList.length > 0">
      <div style="width: 100px;">品类对比：</div>
      <div class="flex flex-1">
        <el-checkbox-group v-model="secondCategory" @change="chooseKeyword" :max="5">
          <el-checkbox :label="item.id" v-for="(item,idx) in keywordList" :key="idx">
            {{item.name}}
          </el-checkbox>
        </el-checkbox-group>
      </div>
     
    </div>
    <div class="table-rank-list" v-loading="dataLoading"  element-loading-text="数据请求中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
      <div class="table-rank-h">
        <div v-for="(item,idx) in currentH" :key="idx"
          :class="[idx<=0 ? 'table-rank-first' : 'table-rank-second']">
          <!-- white-space: pre-wrap; -->
          <span style="line-height: 20px;" v-if="idx == 0"
            v-html="item.name"></span>
          <span
            v-else>{{item.name}}{{((idx >1 && categoryItems.length > 1) || (idx == 2 && categoryItems.length == 1)) ? "品类" : ''}}</span>
          <div
            v-if="item.children && item.children.length > 0"
            class="sub-list">
            <div class="sub-item"
              v-for="(subItem,subIdx) in item.children"
              :key="subIdx"> {{subItem.name}}</div>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="flex" style="flex-direction: column">
          <div class="table-rank-item"
            v-for="item in distributionRangeList"
            :key="item">
            <div class="table-rank-first">
              {{item}}</div>
          </div>
        </div>

        <div class="flex-1"
          v-for="(firstItem,Firstidx) in currentL"
          :key="Firstidx">
          <div class="table-rank-item"
            v-for="(secondItem,secondIdx) in firstItem"
            :key="secondIdx" >
            <div class="table-rank-l-second">
              <div class="sub-list sub-list-item">
                <div class="sub-item"
                  :class="[calMax(firstItem,secondItem.sales_rate,'salesRate')]">

                  <!-- <el-tooltip v-if="showTip" class="item" effect="dark" :content="calTip(secondItem,secondIdx)" placement="right">
                    <span>{{secondItem.sales_rate}}%</span>
                  </el-tooltip>
                  <span v-else>{{secondItem.sales_rate}}%</span> -->

                  {{secondItem.sales_rate}}%
                  <!-- <img v-if="secondItem.sales_rate > 0"
                    src="@/assets/images/market/icon_up.png">
                  <img v-else-if="secondItem.sales_rate < 0"
                    src="@/assets/images/market/icon_down.png"> -->
                </div>
                <div class="sub-item"
                  :class="[calMax(firstItem,secondItem.price_rate,'priceRate')]">
                  {{secondItem.price_rate}}%
                  <!-- <img v-if="secondItem.price_rate > 0"
                    src="@/assets/images/market/icon_up.png">
                  <img v-else-if="secondItem.price_rate < 0"
                    src="@/assets/images/market/icon_down.png"> -->
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  props: [
    "title",
    "subTitle",
    "bgColor",
    "hiddenLabel",
    "categoryOptions",
    "showChooseCate",
    "currentH",
    "currentL",
    "placeholderDefault",
    "currentType",
    "categoryItems",
    "secondCategoryOptions",
    "secondCategoryItems",
    "distributionRangeList",
    "showSwitch",
    "isIpInfo",
    "ipId",
    "currentCategory",
    "currentFirstCategory",
    "currentSecondCategory",
    "selectSecondCategory",
    "showTip",
    "currentDate",
    "categoryAllOptions",
    "showTopTip",
    "keywordList",
    "hiddenCate",
    "dataLoading"
  ],
  data() {
    return {
      category: "",
      radioVal: "",
      showPannel: false,
      ipActiveText: true,
      firstCategory: "",
      secondCategory: "",
    };
  },
  watch: {
    currentCategory() {
      this.category = this.currentCategory;
    },
    currentSecondCategory() {
      this.secondCategory = this.currentSecondCategory;
    },
    currentFirstCategory() {
      console.log('currentFirstCategory',this.currentFirstCategory)
      this.firstCategory = this.currentFirstCategory;
    },
  },
  created() {
    this.$bus.$off("clearSecond");
    this.$bus.$on("clearSecond", () => {
      this.secondCategory = "";
    });
  },
  methods: {
    chooseKeyword() {
        console.log("chooseKeyword",this.secondCategory)
        this.$emit("handleSecondIpKeywordDisChange", {
            categoryIds: this.secondCategory || [],
            currentType: this.currentType,
            ipActiveText: this.ipActiveText,
          });
      },
    calTopTip() {
        switch (this.currentType) {
          case "categoryRankType":
            // return "分析玩具市场各品类占比和变化趋势，了解市场趋势与消费者偏好，制定营销策略、产品开发计划以及市场定位，从而更有效地满足消费者需求，提升市场竞争力。"
            return "分析玩具市场各品类占比和变化趋势，了解市场趋势与消费者偏好，制定营销策略、产品开发计划以及市场定位。"
            break;
          case "categoryUpRankType":
            return "分析短期市场趋势和消费者偏好，如果品类销售显著增长，可能反映了短期内消费者对该品类玩具的兴趣增加。"
            break;
          case "categoryDownRankType":
            return "分析短期市场趋势和消费者偏好，如果品类销售显著下降，可能表明消费者对该品类玩具的兴趣减弱。"
            break;
          case "distributionRankType":
            return "分析零售价和销售情况，了解市场结构和消费者需求，优化产品组合和定价策略，提升市场竞争力和盈利能力。";
            break;
          case "flowRankType":
            return "分析各玩具品类的搜索热度和媒体热度，更好地了解市场动态和消费者需求，优化产品开发和市场推广策略，提升市场竞争力和品牌影响力。";
            break;
          case "blueOceanIndexType":
            return "分析各玩具品类的市场空间，蓝海指数低则表示现有市场空间竞争激烈，增长有限，蓝海指数越高表示可开发的市场空间更大，可以通过创新和差异化策略，创造新的市场需求，获得高利润和高增长。";
            break;
          case "ipRankType":
            return "分析各IP商品销售数据，了解当前市场非常受欢迎、具有较高品牌知名度和粉丝基础的IP。";
            break;
          case "categoryIpUpRankType":
            return "分析IP商品销售数据，了解市场需求增加或者消费者关注增加的IP。";
            break;
          case "categoryIpNewRankType":
            return "分析IP商品数据，了解新开发的市场非常受欢迎的IP。";
            break;
          case "categoryIpPotentailRankType":
            return "挖掘品牌知名度和粉丝基础较高而且市场开发空间较大的蓝海IP";
            break;
          case "distributionIdeaRankType": 
            return "分析零售价和销售情况，了解市场结构和消费者需求，优化产品组合和定价策略，提升市场竞争力和盈利能力。";
            break;
          case "distributionIpRankType": 
            return "分析零售价和销售情况，了解市场结构和消费者需求，优化产品组合和定价策略，提升市场竞争力和盈利能力。";
            break;
        }
      },
    getYm() {
      let year = this.currentDate.getFullYear();
      let month = this.currentDate.getMonth() + 1;
      return year + "年" + month + "月";
    },
    calTip(item, idx) {
      console.log("idx", idx);
      if (idx == 0) {
        return this.getYm() + "玩具总销量" + item.sales_trend;
      } else {
        return this.getYm() + "玩具总销量" + item.price_trend;
      }
    },
    calMax(rateList, currentVal, type) {
      let maxValue = 0;
      if (type == "salesRate") {
        maxValue = Math.max(...rateList.map((obj) => obj.sales_rate));
      } else {
        maxValue = Math.max(...rateList.map((obj) => obj.price_rate));
      }
      if (currentVal == maxValue && maxValue > 0) {
        return "is-max";
      }
    },
    openPannel() {
      if (this.isIpInfo && !this.ipId) {
        this.$message({
          message: "请先输入ip",
          type: "error",
        });
        return;
      }
      this.showPannel = !this.showPannel;
    },
    delCategoryItem(item) {
      this.$emit("delCategoryItem", item);
    },
    delSecCategoryItem(item, idx) {
      this.$emit("delSecCategoryItem", {
        currentItem: item,
        idx: idx + 1,
      });
    },
    activeChange() {
      console.log("ipActiveText", this.ipActiveText);
      this.$emit("activeChange", this.ipActiveText);
    },
    removeTag(e) {
      console.log("removeTag", e, "category", this.category);
      // this.$emit("categoryFirstChange", {
      //     categoryIds: this.category || [],
      //     currentType: this.currentType,
      //     ipActiveText: this.ipActiveText,
      //   });
      this.$emit("categoryChange", {
        categoryId: this.category || 0,
        currentType: this.currentType,
        ipActiveText: this.ipActiveText,
      });
    },
    goItem(item) {},
    handleChange(e) {
      console.log("e", e);

      let isExist = null;
      if (this.category) {
        isExist = this.categoryAllOptions.find((res) => {
          return res.id == this.category;
        });
      }
      console.log("handleChange", this.category);
      this.$emit("categoryChange", {
        categoryId: this.category,
        currentItem: isExist,
        currentType: this.currentType,
        ipActiveText: this.ipActiveText,
      });
    },
    handleFirstChange(e) {
      if (!e) {
        console.log("handleChange", this.firstCategory);
        this.$emit("categoryFirstChange", {
          categoryIds: this.firstCategory || [],
          currentType: this.currentType,
          ipActiveText: this.ipActiveText,
        });
      }
    },
    removeFirstTag(e) {
      console.log("removeTag", e, "category", this.firstCategory);
      this.$emit("categoryFirstChange", {
          categoryIds: this.firstCategory || [],
          currentType: this.currentType,
          ipActiveText: this.ipActiveText,
        });
    },
    handleSecondChange(e) {
      if (!e) {
        console.log("handleSecondChange", this.secondCategory);
        this.$emit("handleSecondChange", {
          categoryIds: this.secondCategory || [],
          currentType: this.currentType,
          ipActiveText: this.ipActiveText,
        });
      }
    },
    handleDelSecondChange() {
      console.log("handleDelSecondChange", this.secondCategory);
      this.$emit("handleSecondChange", {
        categoryIds: this.secondCategory || [],
        currentType: this.currentType,
        ipActiveText: this.ipActiveText,
      });
    },
    calPerWidth(rankList, percent) {
      let sum = 0;
      for (let i = 0, j = rankList.length; i < j; i++) {
        if (rankList[i].rate) {
          sum += Number(rankList[i].rate);
        }
        if (rankList[i].point_index) {
          sum += Number(rankList[i].point_index);
        }
      }
      if (percent == 0) {
        return 0;
      }
      if (sum == 0) {
        return 0;
      } else {
        return parseFloat((Number(percent) / sum) * 100).toFixed(2);
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import url("@/assets/css/rankTitle.less");

.rank-box {
  display: flex;
  width: 100%;
  // height: 206px;
  padding-bottom: 20px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #e7e8ed;
  /* margin-bottom: 20px; */
  flex-direction: column;

  ::v-deep .el-checkbox-group {
    .el-checkbox {
      width: 150px;
      margin-bottom: 4px;
      /* &:nth-child(6n) {
        margin-right: 0;
      } */
    }
  }

  .rank-title {
    .right-opt {
      margin-right: 10px;
    }

    ::v-deep {
      .el-cascader {
        line-height: 30px;
        margin-left: 10px;

        input {
          height: 30px;
          line-height: 30px;
        }

        .el-input__icon {
          line-height: 30px;
        }

        .el-input--suffix .el-input__inner {
          padding-right: 0;
        }
      }
    }

    ::v-deep {
      .el-select {
        line-height: 30px;
        margin-left: 10px;

        input {
          height: 30px;
          line-height: 30px;
        }

        .el-input__icon {
          line-height: 30px;
        }
        &.second-select {
          width: max-content !important;
          max-width: fit-content !important;
          .el-select__tags {
            flex-wrap: nowrap;
            max-width: fit-content !important;
            /* padding: 0 20px; */
          }
          .el-input {
            width: max-content;
          }
          .el-input__inner {
            padding-right: 60px;
          }
        }
      }
    }

    .dropdown-reset {
      color: rgb(64, 158, 255);
      font-size: 16px;
      cursor: pointer;

      i {
        margin-right: 4px;
      }
    }
  }

  .label-list {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    outline: 0;
    padding: 0 15px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 14px;

    .el-tag {
      height: 24px;
      line-height: 24px;
      margin-left: 8px;
    }
  }

  .el-cascader-panel {
    position: absolute;
    top: 24px;
    right: -5px;
    background: #fff;

    .el-cascader-node {
      color: #606266;
    }
  }

  .table-rank-list {
    display: flex;
    width: 100%;
    padding: 10px;
    flex-direction: column;

    .table-rank-h {
      display: flex;
      width: 100%;
      flex: 1;
      border: 1px solid #ebeef5;
      // border-bottom: none;
    }

    .table-rank-first {
      width: 110px;
      border-right: 1px solid #ebeef5;
      padding: 10px 0;
    }

    .table-rank-second {
      flex: 1;
      // padding: 10px 0;
      padding-top: 10px;
      border-right: 1px solid #ebeef5;
      text-align: center;

      &:last-child {
        border-right: none;
      }
    }

    .table-rank-l-second {
      flex: 1;
      display: flex;
      height: 100%;
      align-items: center;
    }

    .sub-list {
      width: 100%;
      display: flex;
      margin-top: 10px;

      &.sub-list-item {
        margin-top: 0;
        height: 100%;
        display: flex;
        align-items: center;

        .sub-item {
          padding: 10px 0;
          border-top: none;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          &.is-max {
            background: #f56c6c;
            color: #fff;
          }

          img {
            width: 8px;
            height: 10px;
            margin-left: 6px;
          }
        }
      }

      .sub-item {
        flex: 1;
        padding: 5px 0 5px 0;

        border-right: 1px solid #ebeef5;
        border-top: 1px solid #ebeef5;

        &:last-child {
          border-right: 0;
        }
      }
    }

    .table-rank-item {
      display: flex;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid #ebeef5;
      border-right: 1px solid #ebeef5;
      border-left: 1px solid #ebeef5;
    }
  }
}
</style>
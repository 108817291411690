<template>
  <div class="problem-box">
    <div class="flex mr-15"
      v-if="currentProblemItem && currentProblemItem.showZhuanFa">
      <img
        v-if="currentProblemItem && currentProblemItem.zhuanFaChoose"
        class="icon-select mt-15 cursor"
        src="https://files.sssrise.com/files/uploads/static/icon_select_check.png"
        @click="chooseZhuanfa(1)">
      <img v-else class="icon-select mt-15 cursor"
        src="https://files.sssrise.com/files/uploads/static/icon_select_default.png"
        @click="chooseZhuanfa(2)">
    </div>
    <div class="problem-avatar">
      <img
        src="https://files.sssrise.com/files/uploads/static/ai_avatar.png">
    </div>
    <div class="problem-info">
      <div class="problem-desc" ref="textRef">
        <div class="dhk-arrow"><img
            style="width: 20px;height: 16px;"
            src="https://files.sssrise.com/files/uploads/static/dhk_arrow.png">
        </div>
        <div class="desc"
          v-if="currentProblemItem && currentProblemItem.name">
          {{currentProblemItem && currentProblemItem.name}}
        </div>
        <div
          v-if="currentDescItem && currentDescItem.currentDesc"
          class="strong">
          <div class="line-h-16 position-relative">
            <!-- <span>{{currentDescItem["currentDesc"] || ""}}</span> -->
            <span style="white-space:pre-wrap"
              v-html="currentDescItem['currentDesc'] || ''"></span>
            <span v-if="streamStatus!=4"
              class="input-cursor"></span>
          </div>
        </div>

        <div
          v-if="currentProblemItem && currentProblemItem.imageSrc && currentProblemItem.imageSrc.length > 0"
          class="">
          <div
            v-for="(imgItem,imgIdx) in currentProblemItem.imageSrc"
            :key="imgIdx" class="position-relative">
            <ImgCom :item="imgItem"></ImgCom>
          </div>

        </div>
      </div>
      <div class="flex justify-between align-center">
        <div
          class="date-time font-12 text-gray line-h-14 mt-5 text-left">
          {{formatTimeVal(problemItem && problemItem.dataTime)}}
        </div>
        <div class=" flex align-center justify-end w-100-b" v-if="problemItem && problemItem.showBottom && !problemItem.showZhuanFa && 
			(streamStatus == 4 || currentProblemItem.isMessage)">
          <div
            class="flex justify-center bottom-opts align-center">
            <img class="cursor"
              style="width: 20px;height: 20px;"
              src="https://files.sssrise.com/files/uploads/static/zf.png"
              @click="showZhuanFa">
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { messageLike } from "@/api/user";
import time from "@/utils/time";
import ImgCom from "./img.vue";
export default {
  name: "probleOne",
  props: ["problemItem", "problemIdx", "streamStatus"],
  components: {
    ImgCom,
  },
  data() {
    return {
      currentProblemItem: null,
      currentDescList: [],
      currentDescItem: {
        defaultDesc: "",
        currentDesc: "",
      },
      loading: true,
      loadingPercentage: 0,
    };
  },
  watch: {
    problemItem: {
      handler(newVal) {
        console.log("newVal", newVal);
        this.currentProblemItem = this.problemItem;
        if (
          this.currentProblemItem &&
          this.currentProblemItem.desc &&
          this.currentProblemItem.desc.length > 0
        ) {
          for (let i = 0, j = this.currentProblemItem.desc.length; i < j; i++) {
            let tempItem = this.currentProblemItem.desc[i];
            let isExist = this.currentDescList.filter((res) => {
              return res.descId == tempItem.descId;
            });
            if (isExist && isExist.length > 0) {
            } else {
              this.currentDescList.push(tempItem);
              this.currentDescItem.defaultDesc += tempItem.info;
              this.currentDescItem.defaultDesc = this.currentDescItem.defaultDesc.replace(/\n/g, "<br/>")
              this.currentDescItem.defaultDesc = this.currentDescItem.defaultDesc.replace(/\n\n/g, '<br/><br/>')
              this.currentDescItem.defaultDesc = this.currentDescItem.defaultDesc.replace(/\\n/g, '<br/>')
              this.currentDescItem.defaultDesc = this.currentDescItem.defaultDesc.replace(/\\n\\n/g, '<br/><br/>')
              this.currentDescItem.defaultDesc = this.currentDescItem.defaultDesc.replace(/\r\n/g, '<br/><br/>')
              // console.log("this.currentDescItem.defaultDesc",this.currentDescItem.defaultDesc)
              if (this.problemItem.isMessage) {
                this.currentDescItem["currentDesc"] =
                  this.currentDescItem.defaultDesc;
              } else {
                setTimeout(() => {
                  this.calItem1(this.currentDescItem.defaultDesc);
                }, 40);
              }
            }
          }
        }
      },
      deep: true,
	  immediate: true
    },
  },
  created() {
    this.currentProblemItem = this.problemItem;
    if (
      this.currentProblemItem &&
      this.currentProblemItem.desc &&
      this.currentProblemItem.desc.length > 0
    ) {
      for (let i = 0, j = this.currentProblemItem.desc.length; i < j; i++) {
        let tempItem = this.currentProblemItem.desc[i];
        let isExist = this.currentDescList.filter((res) => {
          return res.descId == tempItem.descId;
        });
        if (isExist && isExist.length > 0) {
        } else {
          this.currentDescList.push(tempItem);
          this.currentDescItem.defaultDesc += tempItem.info;
          this.currentDescItem.defaultDesc = this.currentDescItem.defaultDesc.replace(/\n/g, "<br/>")
          this.currentDescItem.defaultDesc = this.currentDescItem.defaultDesc.replace(/\n\n/g, '<br/><br/>')
          this.currentDescItem.defaultDesc = this.currentDescItem.defaultDesc.replace(/\\n/g, '<br/>')
          this.currentDescItem.defaultDesc = this.currentDescItem.defaultDesc.replace(/\\n\\n/g, '<br/><br/>')
          this.currentDescItem.defaultDesc = this.currentDescItem.defaultDesc.replace(/\r\n/g, '<br/><br/>')
          if (this.problemItem.isMessage) {
            this.currentDescItem["currentDesc"] =
              this.currentDescItem.defaultDesc;
          } else {
            setTimeout(() => {
              this.calItem1(this.currentDescItem.defaultDesc);
            }, 40);
          }
        }
      }
    }
  },
  methods: {
    formatTimeVal(val) {
      if (val) {
        return time.formatTime(val);
      } else {
        return "";
      }
    },
    chooseProblem(item, idx) {
      console.log("chooseProblem", item);
      this.$emit("changeIdx", {
        problemIdx: this.problemIdx,
        idx: idx,
      });
    },
    showZhuanFa() {
      this.$emit("showZhuanFa");
    },
    chooseZhuanfa(type) {
      this.$emit("chooseZhuanfa", {
        idx: this.problemIdx,
        type: type,
      });
    },
    messageLikeReq(item) {
      let param = {
        id: item.id,
        like: item.fav ? -1 : 1,
      };
      messageLike(param)
        .then((res) => {
          if (res && res.error == 0) {
            this.$message({
              message: item.fav ? "消息点踩成功" : "消息点赞成功",
              type: "success",
            });
            item["fav"] = item["fav"] ? false : true;
          } else {
            this.$message({
              message: (res && res.msg) || "服务器异常，请稍后重试",
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: (err && err.msg) || "服务器异常，请稍后重试",
            type: "error",
          });
        });
    },
    setInterval(item, idx) {
      return item.substring(0, idx++);
    },
    calItem(item, index, stringA = "") {
      let idx = 0;
      let self = this;
      if (stringA.length >= item.info.length) {
        // 退出递归
        item["hiddenCursor"] = true;
        let tempItem = JSON.parse(JSON.stringify(item));
        self.$set(self.currentDescList, index, tempItem);
        return;
      } else {
        setTimeout(() => {
          // console.log("stringA",stringA)
          stringA += item.info.charAt(stringA.length);
          item["currentDesc"] = stringA + "";

          let tempItem = JSON.parse(JSON.stringify(item));
          self.$set(self.currentDescList, index, tempItem);
          // console.log("item",self.currentDescList)
          self.calItem(item, index, stringA);
        }, 50);
      }
    },
    calItem1(defaultDesc) {
      let idx = 0;
      let self = this;
      if (self.currentDescItem["currentDesc"].length >= defaultDesc.length) {
        // 退出递归
        // self.currentDescItem["hiddenCursor"] = true;
        self.$emit("isEnd");
        return;
      } else {
        setTimeout(() => {
          // console.log("this.currentProblemItem",self.currentProblemItem["currentDesc"])
          self.currentDescItem["currentDesc"] += defaultDesc.charAt(
            self.currentDescItem["currentDesc"].length
          );
          self.$emit("isEnd");
          self.calItem1(defaultDesc);
        }, 50);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/assets/css/problemDesc.less");
</style>

import { getUserInfo } from "@/api/user";
import { getToken } from "./auth";
export default {
	onLogin: function () {
		return new Promise((resolve) => {
			const token = getToken();
			// console.log("token",token)
			if (!token) {
				resolve(false)
			} else {
				resolve(true);
			}
		});
	},
	getUserInfoReq(id) {
		return new Promise(resolve => {
			getUserInfo(id).then(userInfo => {
				resolve(userInfo);
			}).catch((err)=>{
				resolve(err);
			})
		});
	},
}
<template>
    <div class="advice-box">
        <div class="rank-title"><span>{{title}}</span><span class="sub-title" v-if="subTitle">({{subTitle}})</span></div>
        <div class="advice-content">
            <div class="advice-desc" v-html="markdownToHtml(suggestion && suggestion.suggestion)"></div>
        </div>
    </div>
</template>
<script>
import { marked } from 'marked';
export default {
    props: ["title", "subTitle","suggestion"],
    components: {
    },
    methods: {
        markdownToHtml(val) {
            if(val) {
                return marked(val); 
            } else {
                return ""
            }
            
        }
    }
}
</script>
<style lang="less" scoped>
@import url("@/assets/css/rankTitle.less");
.advice-box {
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #E7E8ED;
    .advice-content {
        padding: 15px 20px;
        .advice-desc {
            font-weight: 400;
            font-size: 16px;
            color: #626A83;
            line-height: 20px;
            text-align: justify;
        }
    }
}
</style>
<template>
  <div class="community-list-content"
    >
    <div class="community-list" @scroll="handleScroll">
      <div class="community-item cursor"
        v-for="(item,idx) in dataList" :key="idx" @click="goDetail(item)">
        <div class="community-img" v-if="item.imgSrc">
          <img :src="item.imgSrc">
        </div>
        <div class="community-info">
          <div class="name">{{item.title || ""}}</div>
          <div class="desc"
            v-if="!item.imgSrc && item.desc">{{item.desc}}
          </div>
          <div class="user-info">
            <img class="avatar"
              v-if="item.user && item.user.avatar"
              :src="item.user.avatar">
            <img class="avatar" v-else
              src="https://files.sssrise.com/files/uploads/static/default_avatar.png">
            <div class="user-name">
              {{item.user && item.user.nickname || ""}}
            </div>
            <img class="bade-img"
              src="https://files.sssrise.com/files/uploads/static/cyhz.png">
            <img class="bade-img"
              src="https://files.sssrise.com/files/uploads/static/zshz.png">
            <img class="bade-img"
              src="https://files.sssrise.com/files/uploads/static/xzhz.png">
          </div>
          <div class="bottom">
            <div class="create-time">{{item.created_at}}
            </div>
            <div class="fav-box">

              <img src="@/assets/images/fav.png">
              <div class="fav">{{item.fav}}</div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <el-empty v-if="!dataList || (dataList && dataList.length == 0)" :image-size="200"></el-empty>
  </div>
</template>
<script>
export default {
  props: ["dataList","statusLoadmore"],
  data() {
    return {
    };
  },
  mounted() {
    // 确保滚动事件绑定
    this.$el.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    // 移除滚动事件监听
    this.$el.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.canLoadMore()) {
        this.$emit("loadMoreData");
      }
    },
    canLoadMore() {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      // console.log("scrollTop",scrollTop,"scrollHeight",scrollHeight,"clientHeight",clientHeight)
      const isBottom = scrollTop + clientHeight >= scrollHeight - 50;
      return isBottom && this.statusLoadmore == "loadmore";
    },
    goDetail(item) {
      this.$emit("goDetail",item)
    }
  },
};
</script>
<style lang="less" scoped>
.community-list-content {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  flex: 1;
  overflow-y: auto;
  .community-list {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    .community-item {
      width: 100%;
      background: #ffffff;
      border-radius: 10px;
      margin-bottom: 10px;
      padding: 10px;
      display: flex;
      .community-img {
        padding-right: 10px;
        img {
          width: 72px;
          height: 72px;
        }
      }
      .community-info {
        display: flex;
        flex-direction: column;
        width: 100%;
        .name {
          font-weight: 400;
          font-size: 14px;
          color: #1a232f;
          line-height: 20px;
          text-align: left;
        }
        .desc {
          font-weight: 400;
          font-size: 12px;
          color: #626a83;
          line-height: 17px;
          margin-top: 7px;
          text-align: left;
        }
        .user-info {
          display: flex;
          margin-top: 6px;
          align-items: center;
          .avatar {
            width: 24px;
            height: 24px;
            border-radius: 100%;
          }
          .user-name {
            font-weight: 400;
            font-size: 12px;
            color: #1a232f;
            line-height: 17px;
            margin-left: 6px;
          }
          .bade-img {
            width: 20px;
            height: 20px;
            margin-left: 2px;
          }
        }
        .bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 11px;
          .create-time {
            font-weight: 400;
            font-size: 10px;
            color: #979db1;
          }
          .fav-box {
            display: flex;
            img {
              width: 14px;
              height: 14px;
            }
            .fav {
              font-weight: 400;
              font-size: 12px;
              color: #626a83;
              line-height: 17px;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}
</style>
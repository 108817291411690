<template>
  <div class="rank-box">
    <div class="rank-title">
      <div class="flex-1 text-left">
        <span>{{title}}</span><span class="sub-title" v-if="subTitle">({{subTitle}})</span>
        <el-tooltip v-if="showTopTip" style="margin-left:4px;margin-top:2px;" class="item" effect="dark"
          placement="right">
          <div slot="content">
            <div v-html="calTopTip()"></div>
          </div>
          <i class="el-icon-question"></i>
        </el-tooltip>

      </div>

      <div class="right-opt flex">
        <span v-if="showChooseCate || showMulChooseCate">{{cateSelectLabel || "品类细分"}}</span>
        <!-- <el-cascader v-if="showChooseCate"
          v-model="category" :options="categoryOptions"
          :props="{value:'id', label: 'name'}"
          @change="handleChange"
          :placeholder="placeholderDefault ? placeholderDefault : '请选择'"></el-cascader> -->
        <el-cascader v-model="category" :options="options" v-show="showMulChooseCate"
          :placeholder="placeholderDefault ? placeholderDefault : '请选择'" :props="props" @change="expandChange" clearable
          v-removeAriaHidden ref="cascader"></el-cascader>
        <el-select v-if="showChooseCate" v-model="category" clearable
          :placeholder="placeholderDefault ? placeholderDefault : '请选择'" @change="handleChange">
          <el-option v-for="item in categoryOptions" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <el-radio-group style="margin-left: 20px;" v-if="showRadio" v-model="radioVal" @input="radioChange" size="mini">
          <el-radio-button v-for="(item,idx) in radioList" :label="item.value"
            :key="idx">{{item.label}}</el-radio-button>
        </el-radio-group>
        <div class="flex" v-if="showPage">
          <el-button-group class="flex" style="display: flex;align-items: center;">
            <el-button type="primary" :disabled="pageParam && pageParam.currentPage == 1" icon="el-icon-arrow-left" size="mini"  @click="goPrevNext(-1)">上一页</el-button>
            <div style="font-size: 16px;
            color: #626a83;margin: 0 8px;">{{pageParam && pageParam.currentPage}}/{{totalPage}}</div>
            <el-button type="primary" :disabled="pageParam && pageParam.currentPage == totalPage" size="mini" @click="goPrevNext(1)">下一页<i class="el-icon-arrow-right el-icon--right" ></i></el-button>
          </el-button-group>
        </div>
        
      </div>

    </div>
    <div class="analysis-info" v-if="showAnalysis">

      <el-tooltip class="item" effect="dark" :content="calTotalSales()" placement="right">
        <div class="analysis-item">
          <div class="item-left">
            <div class="name">销量预测(单位:万)</div>
            <div class="count">
              {{total && total.total_sales || 0}}
            </div>
          </div>
          <div class="item-right">
            <div class="name">环比</div>
            <div class="right-info">
              <div class="percent" :class="[total && total.total_sales_trend > 0 ? 'up' : 'down']">
                {{total && total.total_sales_trend || 0}}%
              </div>
              <div class="per-img">
                <img v-if="total && total.total_sales_trend > 0" src="@/assets/images/market/icon_up.png">
                <img v-else src="@/assets/images/market/icon_down.png">
              </div>
            </div>

          </div>
        </div>
      </el-tooltip>

      <el-tooltip class="item" effect="dark" :content="calTotalPrice()" placement="right">
        <div class="analysis-item">
          <div class="item-left">
            <div class="name">销售额预测(单位:万元)</div>
            <div class="count">
              {{total && total.total_price || 0}}
            </div>
          </div>
          <div class="item-right">
            <div class="name">环比</div>
            <div class="right-info">
              <div class="percent" :class="[total && total.total_price_trend > 0 ? 'up' : 'down']">
                {{total && total.total_price_trend || 0}}%
              </div>
              <div class="per-img">
                <img v-if="total && total.total_price_trend > 0" src="@/assets/images/market/icon_up.png">
                <img v-else src="@/assets/images/market/icon_down.png">
              </div>
            </div>

          </div>
        </div>
      </el-tooltip>

    </div>
    
    <div class="rank-list">
      <div class="rank-item" v-for="(item,idx) in currentList" :key="idx">
        <div class="title">
          <el-tooltip v-if="valueType == 'analysisIpType' " class="item" effect="dark" :content="calIpContent(idx)"
            placement="right">
            <div style="display: inline-block;">
              <span>{{item.name}}</span>
              <i class="el-icon-question"></i>
            </div>
            
          </el-tooltip>
          <span v-else>{{item.name}}</span>
        </div>
        <div class="zhanbi" :class="[(currentType == 'categoryIpUpRankType' || currentType == 'categoryIpNewRankType') && 'zhanbi1' ]" v-if="!hiddenLabel && item.rankList.length">
          {{labelName || '占比'}}
          <el-tooltip v-if="showTip" class="item" effect="dark" :content="calTipContent(idx)" placement="right">
            <i class="el-icon-question"></i>
          </el-tooltip>

        </div>
        <div class="rank-info" v-if="showTopH" style="padding: 10px 10px;">
          <div class="" style="width: 40px;">排名</div>
          <div class="short-name">IP名称</div>
          <div class="percent-count flex-1" >{{calTopH(idx)}}</div>
          <div class="short-name">主要品类</div>
        </div>
        <div class="rank-info-list" v-for="(subItem,secIdx) in item.rankList" :key="secIdx"
          :class="calHideClass(subItem)">

          <div class="rank-info">
            <el-tooltip v-if="showRankTip" class="item" effect="dark" placement="right">
              <div slot="content">
                上期排名{{calRankNum(subItem,idx) == "-" ? "-" : calRankNum(subItem,idx)}}
                <img style="width: 12px;
            height: 15px;
            margin-left: 4px;
            margin-top: 2px;" v-if="calRankUpDown(subItem,idx,1)  == 1" src="@/assets/images/market/icon_up.png">
                <img style="width: 12px;
            height: 15px;
            margin-left: 4px;
            margin-top: 2px;" v-if="calRankUpDown(subItem,idx,2) == 2" src="@/assets/images/market/icon_down.png">
              </div>
              <div class="num">
                <span v-if="currentType == 'ipRankType' || currentType == 'categoryIpUpRankType' || currentType == 'categoryIpNewRankType' ">
                    {{(currentType == 'ipRankType' && idx == 2) ? secIdx + 1 : 10 * (pageParam.currentPage - 1) + secIdx + 1}}
                </span>
                <span v-else>{{secIdx + 1}}</span>
              </div>
            </el-tooltip>
            <div
              v-else-if="valueType != 'analysisIpType' || (valueType == 'analysisIpType' && subItem.id > 0 && subItem.id < 9999999)"
              class="num">
              <span v-if="currentType == 'ipRankType' || currentType == 'categoryIpUpRankType' || currentType == 'categoryIpNewRankType' ">
                {{(currentType == 'ipRankType' && idx == 2) ? secIdx + 1 : 10 * (pageParam.currentPage - 1) + secIdx + 1}}
              </span>
              <span v-else>{{secIdx + 1}}</span>
            </div>
            <div v-else class="num no-num"></div>
            <div class="short-name one-text" :class="[isGoIp ? 'cursor' : '']" @click="goIpDetail(subItem)"
              :title="subItem.name || subItem.category_name || subItem.distribution_range || subItem.ip_name">
              {{subItem.name || subItem.category_name || subItem.distribution_range || subItem.ip_name || ""}}
            </div>

            <el-tooltip v-if="calType == 'calPercentMax' && valueType == 'analysisType'" class="item" effect="dark"
              placement="right">
              <div slot="content" class="flex content-center">
                {{calRateUpDownPer(subItem,idx)}}<img style="width: 12px;
            height: 15px;
            margin-left: 4px;" v-if="calRateUpDown(subItem,idx,1)  == 1" src="@/assets/images/market/icon_up.png">
                <img style="width: 12px;
            height: 15px;
            margin-left: 4px;" v-if="calRateUpDown(subItem,idx,2) == 2" src="@/assets/images/market/icon_down.png">
              </div>
              <div class="percent">
                <div class="active"
                  :style="{width: calPerWidth1(item.rankList,subItem,idx) + '%',background: bgColor || '#da8ff5'}">
                </div>
              </div>
            </el-tooltip>
            <el-tooltip v-else-if="valueType == 'analysisIpType'" class="item" effect="dark" placement="right">
              <div slot="content" class="flex content-center">
                <span v-html="calIpTipContent(subItem,idx)"></span>
              </div>
              <div class="percent">
                <div class="active"
                  :style="{width: calPerWidth1(item.rankList,subItem,idx) + '%',background: bgColor || '#da8ff5'}">
                </div>
              </div>
            </el-tooltip>
            <div v-else class="percent">
              <div class="active"
                :style="{width: calPerWidth1(item.rankList,subItem,idx) + '%',background: bgColor || '#da8ff5'}">
              </div>
            </div>

            <div class="percent-count position-relative"
              :class="[calType == 'calPercentMax' && valueType == 'analysisType' ? 'cursor' : '']">

              <el-tooltip v-if="calType == 'calPercentMax' && valueType == 'analysisType'" class="item" effect="dark"
                placement="right">
                <div slot="content" class="flex content-center">
                  {{calRateUpDownPer(subItem,idx)}}<img style="width: 12px;
            height: 15px;
            margin-left: 4px;" v-if="calRateUpDown(subItem,idx,1)  == 1" src="@/assets/images/market/icon_up.png">
                  <img style="width: 12px;
            height: 15px;
            margin-left: 4px;" v-if="calRateUpDown(subItem,idx,2) == 2" src="@/assets/images/market/icon_down.png">
                </div>
                <div>
                  <span>
                    {{calRateCount(subItem,idx)}}</span>
                  <span v-if="!hiddenLabel">{{isThousand ? '‰': '%'}}</span>
                  <img v-if="calRateUpDown(subItem,idx,1)  == 1" src="@/assets/images/market/icon_up.png">
                  <img v-if="calRateUpDown(subItem,idx,2) == 2" src="@/assets/images/market/icon_down.png">
                </div>
              </el-tooltip>
              <el-tooltip v-else-if="valueType == 'analysisIpType'" class="item" effect="dark" placement="right">
                <div slot="content" class="flex content-center">
                  <span v-html="calIpTipContent(subItem,idx)"></span>
                </div>
                <div>
                  {{calRateCount(subItem,idx)}}
                </div>
              </el-tooltip>
              <div v-else>
                <span>
                  {{calRateCount(subItem,idx)}}</span>
                <span v-if="!hiddenLabel">{{isThousand ? '‰': '%'}}</span>
                <img v-if="calRateUpDown(subItem,idx,1)  == 1 && valueType == 'analysisType' "
                  src="@/assets/images/market/icon_up.png">
                <img v-if="calRateUpDown(subItem,idx,2) == 2 && valueType == 'analysisType'"
                  src="@/assets/images/market/icon_down.png">
              </div>
            </div>
            <!-- v-if="showTopKeyword && (idx != 1 || currentType == 'categoryIpUpRankType' || currentType== 'categoryIpNewRankType')" -->
            <div class="short-name one-text"
              :title="subItem.top_keyword" v-if="showTopKeyword">
              {{subItem.top_keyword || ""}}
            </div>
          </div>
        </div>
        <el-empty v-if="item.rankList.length == 0">
        </el-empty>
      </div>
    </div>
  </div>
</template>
<script>
  import { getStatisticsKeywordList } from "@/api/statistics";
  export default {
    props: [
      "title",
      "subTitle",
      "currentList",
      "bgColor",
      "hiddenLabel",
      "categoryOptions",
      "showChooseCate",
      "radioList",
      "showRadio",
      "placeholderDefault",
      "currentType",
      "valueType",
      "isGoIp",
      "isThousand",
      "currentCategory",
      "calMax",
      "cateSelectLabel",
      "calType",
      "showRank",
      "rankTypeName",
      "labelName",
      "showMulChooseCate",
      "showTip",
      "showRankTip",
      "tipType",
      "currentCategoryName",
      "categoryAllOptions",
      "showTopTip",
      "total",
      "currentDate",
      "showAnalysis",
      "showTopKeyword",
      "pageParam",
      "showPage",
      "showTopH",
      "totalCount"
    ],
    data() {
      return {
        category: "",
        categoryName: "",
        secondCategory: "",
        radioVal: 10,
        tipContent: "各品类销量占总销量的比例",
        options: [], // 初始时为空
        // props: {
        //     lazy: true,
        //     value:'id',
        //     label: 'name',
        //     children: 'children',
        //     checkStrictly: true,
        //     lazyLoad (node, resolve) {
        //       const { level } = node;
        //       console.log("level",level,"node",node,"currentDate")
        //       // if(level > 0) {
        //       //   let param = {
        //       //     keyword_id: node.value,
        //       //     ym: this.getYm()
        //       //   }
        //       //   getStatisticsMarketKeyrowdIp(param).then((res) => {
        //       //     console.log("res",res)
        //       //   }).catch(() => {
        //       //     resolve([]);
        //       //   })
        //       // }
        //     }
        //   },
        totalPage: 0
      };
    },
    watch: {
      currentCategory() {
        this.category = this.currentCategory;
      },
      categoryOptions() {
        if (this.showMulChooseCate && this.categoryOptions.length > 0) {
          this.options = this.categoryOptions;
        }
      },
      totalCount() {
        this.getTotalPage();
      }
    },
    computed: {
      props() {
        let self = this;
        return {
          lazy: true,
          value: "id",
          label: "name",
          children: "children",
          checkStrictly: false,
          expandTrigger: "click",
          lazyLoad(node, resolve) {
            const { level, children } = node;

            if (level == 1) {
              console.log("level", level, "node", node);
              let param = {
                category_id: node.value,
              };
              if (children && children.length > 0) {
                resolve([]);
                return;
              }
              let nodes = [];
              let firstNode = {
                id: "all_" + node.value,
                dataId: node.value,
                name: "全部" + node.label,
                leaf: true,
                isAll: true,
              };
              nodes.push(firstNode);
              getStatisticsKeywordList(param)
                .then((res) => {
                  let tempList = (res && res.data && res.data.list) || [];
                  if (tempList.length > 1) {
                    let tempNodes = tempList.map((item) => {
                      return {
                        id: item.id,
                        name: item.name,
                        leaf: true,
                        isAll: false,
                      };
                    });
                    nodes = nodes.concat(tempNodes);
                    console.log("nodes", nodes);
                    resolve(nodes);
                  } else {
                    resolve(nodes);
                  }
                })
                .catch(() => {
                  resolve(nodes);
                });
            } else if (level > 1) {
              resolve([]);
            }
          },
        };
      },
    },
    created() {
      this.category = this.currentCategory;
      this.getTotalPage();
      // this.$bus.$off("clearSelect");
      // this.$bus.$on("clearSelect",() => {
      //   console.log("clearSelect")
      //   this.category = "";
      //   this.secondCategory = ""
      // })
    },
    methods: {
      getTotalPage() {
        if(this.pageParam && this.pageParam.limit) {
          this.totalPage = Math.ceil(this.totalCount / this.pageParam.limit);
          console.log("totalPage",this.totalPage,'totalCount',this.totalCount)
        }
          
      },
      calTopH(idx) {
        
        if(idx == 0) {
          if(this.currentType == "categoryIpUpRankType") {
            return "月销量增幅（%）"
          } else {
            return "月销量"
          }
          
        } else if(idx == 1) {
          if(this.currentType == "categoryIpUpRankType") {
            return "月销售额增幅（%）"
          } else {
            return "月销售额"
          }
        } else if(idx == 2) {
          return "活跃度"
        }
      },
      goPrevNext(num) {
        this.$emit("goPrevNext",{
          currentType: this.currentType,
          num: num
        })
      },
      calTotalSales() {
        return (
          this.currentDate.getFullYear() +
          "年" +
          (this.currentDate.getMonth() + 1) +
          "月玩具总销量" +
          ((this.total && this.total.total_sales) || 0) +
          "万，环比" +
          (this.total && this.total.total_sales_trend > 0 ? "增长" : "下降") +
          ((this.total && this.total.total_sales_trend) || 0) +
          "%"
        );
      },
      calTotalPrice() {
        return (
          this.currentDate.getFullYear() +
          "年" +
          (this.currentDate.getMonth() + 1) +
          "月玩具总销售额" +
          ((this.total && this.total.total_price) || 0) +
          "万元，环比" +
          (this.total && this.total.total_price_trend > 0 ? "增长" : "下降") +
          ((this.total && this.total.total_price_trend) || 0) +
          "%"
        );
      },
      calTopTip() {
        switch (this.currentType) {
          case "categoryRankType":
            // return "分析玩具市场各品类占比和变化趋势，了解市场趋势与消费者偏好，制定营销策略、产品开发计划以及市场定位，从而更有效地满足消费者需求，提升市场竞争力。"
            return "分析玩具市场各品类占比和变化趋势，了解市场趋势与消费者偏好，制定营销策略、产品开发计划以及市场定位。"
            break;
          case "categoryUpRankType":
            return "分析短期市场趋势和消费者偏好，如果品类销售显著增长，可能反映了短期内消费者对该品类玩具的兴趣增加。"
            break;
          case "categoryDownRankType":
            return "分析短期市场趋势和消费者偏好，如果品类销售显著下降，可能表明消费者对该品类玩具的兴趣减弱。"
            break;
          case "distributionRankType":
            return "分析零售价和销售情况，了解市场结构和消费者需求，优化产品组合和定价策略，提升市场竞争力和盈利能力。";
            break;
          case "flowRankType":
            return "分析各玩具品类的搜索热度和媒体热度，更好地了解市场动态和消费者需求，<br>优化产品开发和市场推广策略，提升市场竞争力和品牌影响力。";
            break;
          case "blueOceanIndexType":
            return "分析各玩具品类的市场空间，蓝海指数低则表示现有市场空间竞争激烈，增长有限，蓝海指数越高表示可开发的市场空间更大，可以通过创新和差异化策略，创造新的市场需求，获得高利润和高增长。";
            break;
          case "ipRankType":
            return "分析各IP商品销售数据，了解当前市场非常受欢迎、具有较高品牌知名度和粉丝基础的IP。";
            break;
          case "categoryIpUpRankType":
            return "分析IP商品销售数据，了解市场需求增加或者消费者关注增加的IP。";
            break;
          case "categoryIpNewRankType":
            return "分析IP商品数据，了解新开发的市场非常受欢迎的IP。";
            break;
          case "categoryIpPotentailRankType":
            return "挖掘品牌知名度和粉丝基础较高而且市场开发空间较大的蓝海IP";
            break;
          case "categorySmallRankType":
            return "分析所选品类细分品类当前的销售占比，了解市场趋势与消费者偏好，制定营销策略、<br>产品开发计划以及市场定位，从而更有效地满足消费者需求，提升市场竞争力。";
            break;
        }
      },
      calHideClass(subItem) {
        if (
          this.currentType == "categoryIpUpRankType" ||
          this.currentType == "categoryIpDownRankType"
        ) {
          if (subItem.id > 0 && subItem.id < 9999999) {
            return "";
          } else {
            return "itemHide";
          }
        } else {
          return "";
        }
      },
      expandChange(e) {
        console.log("expandChange", e);
        console.log(this.$refs["cascader"].getCheckedNodes()[0]);
        if (e.length == 0) {
          this.category = 0;
          this.$emit("categoryChange", {
            categoryId: 0,
            currentType: this.currentType,
            limit: this.radioVal,
            selectCategoryId: 0,
          });
        } else {
          let nodeData = this.$refs["cascader"].getCheckedNodes()[0].data;
          if (nodeData.isAll) {
            // this.category = e[1] || 0;
            // this.secondCategory = "";
            this.$emit("categoryChange", {
              categoryId: nodeData.dataId || 0,
              currentType: this.currentType,
              limit: this.radioVal,
              selectCategoryId: e,
            });
          } else {
            // this.category = "";
            // this.secondCategory = e[1];
            this.$emit("categorySecondIpChange", {
              categoryId: e[1],
              currentType: this.currentType,
              limit: this.radioVal,
              secondCategoryId: e,
            });
          }
        }

        // if (e.length == 0 || e.length == 1) {
        //   if (e.length == 1) {
        //     let param = {
        //       category_id: e[0],
        //     };
        //     getStatisticsKeywordList(param)
        //       .then((res) => {
        //         let tempList = (res && res.data && res.data.list) || [];
        //         if (tempList.length > 0) {
        //           let tempOpt = JSON.parse(JSON.stringify(this.options));
        //           let isExistIdx = tempOpt.findIndex((temp) => {
        //             return temp.id == e[0];
        //           });
        //           let nodes = tempList.map((item) => {
        //             return {
        //               id: item.id,
        //               name: item.name,
        //               leaf: true,
        //             };
        //           });
        //           if (isExistIdx >= 0) {
        //             tempOpt[isExistIdx]["children"] = nodes;
        //           }
        //           this.options = tempOpt;
        //           this.$nextTick(() => {
        //             this.$forceUpdate();
        //           });
        //         }
        //       })
        //       .catch(() => {});
        //   }
        //   this.category = e[0] || 0;
        //   this.secondCategory = "";
        //   this.$emit("categoryChange", {
        //     categoryId: e[0] || 0,
        //     currentType: this.currentType,
        //     limit: this.radioVal,
        //   });
        // } else {
        //   this.category = "";
        //   this.secondCategory = e[1];
        //   this.$emit("categorySecondIpChange", {
        //     categoryId: e[1],
        //     currentType: this.currentType,
        //     limit: this.radioVal,
        //     secondCategoryId: e,
        //   });
        // }
      },
      goIpDetail(item) {
        if (this.isGoIp && item.id > 0 && item.id < 9999999) {
          this.$emit("goIpDetail", item);
          this.$router.push({
            path:
              "/ipInfo?currentIpId=" +
              item.id +
              "&currentIpName=" +
              encodeURIComponent(item.name),
          });
        }
      },
      calTipContent(idx) {
        if (this.valueType == "analysisType") {
          if (idx == 0) {
            if (this.currentCategoryName) {
              return "各品类销量占" + this.currentCategoryName + "品类总销量的比例";
            } else {
              if (this.category) {

                let tempItem = this.categoryOptions.find((res) => {
                  return res.id == this.category;
                });
                console.log("tempItem", tempItem, "this.category", this.category)
                this.categoryName = tempItem.name;
                return "各品类销量占" + this.categoryName + "品类总销量的比例";
              } else {
                return "各品类销售额占总销量的比例";
              }
            }
          }
          if (idx == 1) {
            if (this.currentCategoryName) {
              return "各品类销售额占" + this.currentCategoryName + "品类总销售额的比例";
            } else {
              if (this.category) {

                let tempItem = this.categoryOptions.find((res) => {
                  return res.id == this.category;
                });
                console.log("tempItem", tempItem, "this.category", this.category)
                this.categoryName = tempItem.name;
                return "各品类销售额占" + this.categoryName + "品类总销售额的比例";
              } else {
                return "各品类销售额占总销售额的比例";
              }
            }
          } else if (idx == 2) {

            if (this.currentCategoryName) {

              return "销售该品类玩具的零售店铺占销售" + this.currentCategoryName + "品类总玩具店铺的比例"
            } else {
              if (this.category) {

                let tempItem = this.categoryOptions.find((res) => {
                  return res.id == this.category;
                });
                console.log("tempItem", tempItem, "this.category", this.category)
                this.categoryName = tempItem.name;
                return "销售该品类玩具的零售店铺占销售" + this.categoryName + "品类总玩具店铺的比例"
              } else {
                return "销售该品类玩具的零售店铺占总玩具店铺的比例";
              }
            }
          }


        }
        if (this.tipType == "upTip") {
          return "相比上期零售店铺增长比例";
        }
        if (this.tipType == "downTip") {
          return "相比上期零售店铺降幅比例";
        }
      },
      calIpContent(idx) {
        if (idx == 0) {
          return "平均每款商品的月销量";
        } else if (idx == 1) {
          return "平均每款商品的月销售额";
        } else if (idx == 2) {
          return "IP应用扩张热度";
        }
      },
      handleChange() {
        console.log("handleChange", this.category);
        // if (this.showTip) {
        //   if (this.currentCategoryName) {
        //     return "各品类销量占" + this.currentCategoryName + "品类销量的比例";
        //   } else {
        //     if (this.category) {
        //       let tempItem = this.categoryAllOptions.find((res) => {
        //         return res.id == this.category;
        //       });

        //       this.categoryName = tempItem.name;
        //       this.tipContent =
        //         "销售该品类玩具的零售店铺占销售" + this.categoryName + "品类销量的比例";
        //     } else {
        //       this.tipContent = "各品类销售额占总销售额的比例";
        //     }
        //   }

        // }
        this.$emit("categoryChange", {
          categoryId: this.category || 0,
          currentType: this.currentType,
          limit: this.radioVal,
        });
      },
      radioChange() {
        console.log("radioChange", this.radioVal);
        if (this.secondCategory) {
          this.$emit("categorySecondIpChange", {
            categoryId: this.secondCategory,
            currentType: this.currentType,
            limit: this.radioVal,
          });
        } else {
          this.$emit("radioChange", {
            categoryId: (this.category && this.category[0]) || "",
            currentType: this.currentType,
            limit: this.radioVal,
          });
        }
      },
      calRankNum(subItem, idx) {
        let rate = 0;
        if (idx == 0) {
          rate = subItem.last_sales_trend_rank;
        }
        if (idx == 1) {
          rate = subItem.last_price_trend_rank;
        }

        if (idx == 2) {
          rate = subItem.last_shop_num_rate_trend_rank;
        }
        if (rate == 0) {
          return "-";
        } else {
          return rate;
        }
      },
      calRankUpDown(subItem, idx) {
        let rate = 0;
        if (idx == 0) {
          rate = subItem.last_sales_trend_rank || 0;
        }
        if (idx == 1) {
          rate = subItem.last_price_trend_rank || 0;
        }

        if (idx == 2) {
          rate = subItem.last_shop_num_rate_trend_rank || 0;
        }
        let currentRank = 0;

        if (idx == 0) {
          currentRank = subItem.sales_trend_rank;
        }
        if (idx == 1) {
          currentRank = subItem.price_trend_rank;
        }

        if (idx == 2) {
          currentRank = subItem.shop_num_rate_trend_rank;
        }
        if (rate == 0) {
          return 3;
        } else if (rate == currentRank) {
          return 3;
        } else if (rate > currentRank) {
          return 2;
        } else if (rate < currentRank) {
          return 1;
        }
      },
      calRateCount(subItem, idx) {
        if (this.currentType == "categoryIpPotentailRankType") {
          return subItem.potential_index.toFixed(2);
        } else if (this.currentType == "flowRankType") {
          return subItem.point_index;
        } else {
          if (this.valueType == "analysisType") {
            if (idx == 0) {
              return subItem.sales_rate;
            }
            if (idx == 1) {
              return subItem.price_rate;
            }

            if (idx == 2) {
              return subItem.shop_num_rate;
            }
          }
          if (this.valueType == "upDownType") {
            if (idx == 0) {
              return subItem.sales_trend;
            }
            if (idx == 1) {
              return subItem.price_trend;
            }

            if (idx == 2) {
              return subItem.shop_num_rate_trend;
            }
          }
          if (this.valueType == "analysisIpType") {
            if( this.currentType == "ipRankType") {
              if (idx == 0) {
                return subItem.sales_str;
              }
              if (idx == 1) {
                return subItem.price_str;
              }

              if (idx == 2) {
                return subItem.item_num_trend;
              }
            }else if (
              this.currentType == "categoryIpNewRankType"
            ) {
              if (idx == 0) {
                return subItem.sales_str;
              }
              if (idx == 1) {
                return subItem.price_str;
              }

              if (idx == 2) {
                return subItem.unit_price;
              }
            } else {
              if (idx == 0) {
                // return subItem.avg_sales_str;
                return this.currentType == "categoryIpUpRankType"
                  ? subItem.sales_trend + "%"
                  : subItem.avg_sales_trend + "%";
              }
              if (idx == 1) {
                // return subItem.avg_price_str;
                return this.currentType == "categoryIpUpRankType"
                  ? subItem.price_trend + "%"
                  : subItem.avg_price_trend + "%";
              }

              if (idx == 2) {
                // return subItem.unit_price;
                return this.currentType == "categoryIpUpRankType"
                  ? Math.round(
                    Number((subItem.unit_price_trend / 100).toFixed(0)) + 1
                  ) + "倍"
                  : subItem.unit_price_trend + "%";
              }
            }
          }
        }
      },
      calIpTipContent(subItem, idx) {
        return (
          "月销量" +
          subItem.sales_str + '万' +
          "<br>" +
          "月销售额" +
          subItem.price_str  + '万元'
        );
        // if (idx == 0) {
        //   // return subItem.avg_sales;
        //   return "平均月销量" + subItem.avg_sales_str + "\n" + "平均月销售额" + subItem.avg_price_str + "\n" +"平均成交价" + subItem.unit_price;
        // }
        // if (idx == 1) {
        //   // return subItem.avg_price;
        //   return "平均月销售额" + subItem.avg_price_str;
        // }

        // if (idx == 2) {
        //   return "平均成交价" + subItem.unit_price;
        // }
      },
      calRateUpDown(subItem, idx, type) {
        let rate = 0;
        if (this.calType == "calPercentMax") {
          if (idx == 0) {
            rate = subItem.sales_rate_trend;
          }
          if (idx == 1) {
            rate = subItem.price_rate_trend;
          }

          if (idx == 2) {
            rate = subItem.shop_num_rate_trend;
          }
          if (rate > 0) {
            return 1;
          } else if (rate < 0) {
            return 2;
          } else {
            return 3;
          }
        }
      },
      calRateUpDownPer(subItem, idx) {
        let rate = 0;
        if (this.calType == "calPercentMax") {
          if (
            this.valueType == "analysisType" ||
            this.valueType == "upDownType"
          ) {
            if (idx == 0) {
              rate = subItem.sales_rate_trend;
            }
            if (idx == 1) {
              rate = subItem.sales_rate_trend;
            }
            if (idx == 2) {
              rate = subItem.shop_num_rate_trend;
            }
          } else {
            if (idx == 0) {
              rate = subItem.last_sales_trend_rank;
            }
            if (idx == 1) {
              rate = subItem.last_price_trend_rank;
            }

            if (idx == 2) {
              rate = subItem.last_shop_num_rate_trend_rank;
            }
          }

          if (rate != 0) {
            if (this.valueType == "analysisType") {
              return (
                "相比上期" +
                (rate > 0 ? "增长" : "下降") +
                Math.abs(rate) +
                "个百分点"
              );
            } else if (this.valueType == "upDownType") {
              return "相比上期" + (rate > 0 ? "增幅" : "降幅") + Math.abs(rate);
            } else {
              return rate;
            }
          } else {
            return "-";
          }
        }
      },
      getCalMax(rateList, type) {
        let maxValue = 0;
        if (type == "sales_rate") {
          maxValue = Math.max(...rateList.map((obj) => Math.abs(obj.sales_rate)));
        } else if (type == "price_rate") {
          maxValue = Math.max(...rateList.map((obj) => Math.abs(obj.price_rate)));
        } else if (type == "shop_num") {
          maxValue = Math.max(
            ...rateList.map((obj) => Math.abs(obj.shop_num_rate))
          );
        } else if (type == "potential_index") {
          maxValue = Math.max(
            ...rateList.map((obj) => Math.abs(obj.potential_index))
          );
        } else {
          maxValue = Math.max(...rateList.map((obj) => Math.abs(obj.price_rate)));
        }
        return maxValue;
      },
      getCalMax1(rateList, type) {
        let maxValue = 0;
        if (type == "sales_rate") {
          maxValue = Math.max(
            ...rateList.map((obj) => Math.abs(obj.sales_trend))
          );
        } else if (type == "price_rate") {
          maxValue = Math.max(
            ...rateList.map((obj) => Math.abs(obj.price_trend))
          );
        } else if (type == "shop_num") {
          maxValue = Math.max(
            ...rateList.map((obj) => Math.abs(obj.shop_num_rate_trend))
          );
        } else {
          maxValue = Math.max(
            ...rateList.map((obj) => Math.abs(obj.sales_trend))
          );
        }
        // console.log("maxValue",maxValue)
        return maxValue;
      },
      getCalMax2(rateList, type, currentType) {
        rateList = rateList.filter((res) => {
          return res.id > 0 && res.id < 9999999;
        });
        let maxValue = 0;
        if (type == "sales_rate") {
          if (currentType == 1) {
            maxValue = Math.max(
              ...rateList.map((obj) => Math.abs(obj.avg_sales))
            );
          } else {
            maxValue = Math.max(
              ...rateList.map((obj) => Math.abs(obj.avg_sales_trend))
            );
          }
        } else if (type == "price_rate") {
          if (currentType == 1) {
            maxValue = Math.max(
              ...rateList.map((obj) => Math.abs(obj.avg_price))
            );
          } else {
            maxValue = Math.max(
              ...rateList.map((obj) => Math.abs(obj.avg_price_trend))
            );
          }
        } else if (type == "shop_num") {
          if (currentType == 1) {
            maxValue = Math.max(
              ...rateList.map((obj) => Math.abs(obj.unit_price))
            );
          } else {
            maxValue = Math.max(
              ...rateList.map((obj) => Math.abs(obj.unit_price_trend))
            );
          }
        } else {
          if (currentType == 1) {
            maxValue = Math.max(
              ...rateList.map((obj) => Math.abs(obj.avg_sales))
            );
          } else {
            maxValue = Math.max(
              ...rateList.map((obj) => Math.abs(obj.avg_sales_trend))
            );
          }
        }
        // console.log("maxValue",maxValue)
        return maxValue;
      },
      getCalMax3(rateList, type) {
        let maxValue = Math.max(
          ...rateList.map((obj) => Math.abs(obj.point_index))
        );
        return maxValue;
      },
      getCalMax4(rateList, type, currentType) {
        rateList = rateList.filter((res) => {
          return res.id > 0 && res.id < 9999999;
        });
        let maxValue = 0;
        if (type == "sales_rate") {
          if (currentType == 1) {
            maxValue = Math.max(
              ...rateList.map((obj) => Math.abs(obj.sales))
            );
          } else {
            maxValue = Math.max(
              ...rateList.map((obj) => Math.abs(obj.avg_sales_trend))
            );
          }
        } else if (type == "price_rate") {
          if (currentType == 1) {
            maxValue = Math.max(
              ...rateList.map((obj) => Math.abs(obj.price))
            );
          } else {
            maxValue = Math.max(
              ...rateList.map((obj) => Math.abs(obj.avg_price_trend))
            );
          }
        } else if (type == "shop_num") {
          if (currentType == 1) {
            maxValue = Math.max(
              ...rateList.map((obj) => Math.abs(obj.item_num_trend))
            );
          } else {
            maxValue = Math.max(
              ...rateList.map((obj) => Math.abs(obj.item_num_trend))
            );
          }
        } else {
          if (currentType == 1) {
            maxValue = Math.max(
              ...rateList.map((obj) => Math.abs(obj.avg_sales))
            );
          } else {
            maxValue = Math.max(
              ...rateList.map((obj) => Math.abs(obj.avg_sales_trend))
            );
          }
        }
        // console.log("maxValue",maxValue)
        return maxValue;
      },
      calPerWidth1(rankList, subItem, idx, calPerType = "") {
        let percent = 0;
        let sum = 0;
        let type = "";
        if (idx == 0) {
          type = "sales_rate";
        }
        if (idx == 1) {
          type = "price_rate";
        }
        if (idx == 2) {
          type = "shop_num";
        }
        let maxValue = 0;
        if (this.calMax) {
          if (this.currentType == "categoryIpPotentailRankType") {
            maxValue = this.getCalMax(rankList, "potential_index");
          } else {
            if (this.valueType == "analysisType") {
              maxValue = this.getCalMax(rankList, type);
            } else if (this.valueType == "analysisIpType") {
              if( this.currentType == "ipRankType") {
                maxValue = this.getCalMax4(rankList, type, 1);
              } else if(this.currentType == "categoryIpUpRankType") {
                
                maxValue = this.getCalMax1(rankList, type, 1);
              } else if (
                this.currentType == "categoryIpNewRankType"
              ) {
                maxValue = this.getCalMax2(rankList, type, 1);
              } else {
                maxValue = this.getCalMax2(rankList, type, 2);
              }
            } else if (this.valueType == "flowRankValue") {
              // console.log("rankList", rankList);
              maxValue = this.getCalMax3(rankList, type);
            } else {
              maxValue = this.getCalMax1(rankList, type);
            }
          }
        }
        // console.log("maxValue", maxValue);

        if((this.currentType == 'ipRankType' || this.currentType == 'categoryIpUpRankType' || this.currentType == 'categoryIpNewRankType') && this.pageParam.currentPage == 1) {
          if(idx == 0) {
            localStorage.setItem("firstMax_" + this.currentType, maxValue);
          } else if(idx == 1) {
            localStorage.setItem("secondMax_" + this.currentType, maxValue);
          } else if(idx == 2) {
            localStorage.setItem("threeMax_" + this.currentType, maxValue);
          }
        }

        if((this.currentType == 'ipRankType' || this.currentType == 'categoryIpUpRankType' || this.currentType == 'categoryIpNewRankType') && this.pageParam.currentPage > 1) {
          if(idx == 0) {
            maxValue = localStorage.getItem("firstMax_" + this.currentType);
          } else if(idx == 1) {
            maxValue = localStorage.getItem("secondMax_" + this.currentType);
          } else if(idx == 2) {
            maxValue = localStorage.getItem("threeMax_" + this.currentType);
          }
        }

        if (this.currentType == "flowRankType") {
          percent = subItem.point_index;
        } else if (this.currentType == "categoryIpPotentailRankType") {
          percent = subItem.potential_index;
        } else {
          if (this.valueType == "analysisType") {
            if (idx == 0) {
              percent = Math.abs(subItem.sales_rate);
            }

            if (idx == 1) {
              percent = Math.abs(subItem.price_rate);
            }

            if (idx == 2) {
              percent = Math.abs(subItem.shop_num_rate);
            }
          }
          if (this.valueType == "upDownType") {
            if (idx == 0) {
              percent = Math.abs(subItem.sales_trend);
            }
            if (idx == 1) {
              percent = Math.abs(subItem.price_trend);
            }

            if (idx == 2) {
              percent = Math.abs(subItem.shop_num_rate_trend);
            }
          }
          if (this.valueType == "analysisIpType") {
            if(this.currentType == "ipRankType") {
              if (idx == 0) {
                percent = Math.abs(subItem.sales);
              }
              if (idx == 1) {
                percent = Math.abs(subItem.price);
              }

              if (idx == 2) {
                percent = Math.abs(subItem.item_num_trend);
              }
            } else if(this.currentType == 'categoryIpUpRankType') {
              if (idx == 0) {
                percent = subItem.sales_trend;
              }
              if (idx == 1) {
                percent = subItem.price_trend;
              }

              if (idx == 2) {
                percent = subItem.item_num_trend;
              }
            } else if (
              this.currentType == "categoryIpNewRankType"
            ) {
              if (idx == 0) {
                percent = subItem.sales;
              }
              if (idx == 1) {
                percent = subItem.price;
              }

              if (idx == 2) {
                percent = subItem.unit_price;
              }
            } else {
              if (idx == 0) {
                percent = Math.abs(subItem.avg_sales_trend);
              }
              if (idx == 1) {
                percent = Math.abs(subItem.avg_price_trend);
              }

              if (idx == 2) {
                percent = Math.abs(subItem.unit_price_trend);
              }
            }
          }
        }
        if (
          this.currentType == "flowRankType" ||
          this.currentType == "categoryIpPotentailRankType" ||
          this.valueType == "upDownType" ||
          this.valueType == "analysisIpType" ||
          (idx == 2 &&
            this.currentType !== "flowRankType" &&
            this.currentType !== "categoryIpPotentailRankType") ||
          ((idx == 0 || idx == 1) && this.valueType == "analysisType")
        ) {
          for (let i = 0, j = rankList.length; i < j; i++) {
            // if (rankList[i].sales_rate) {
            //   sum += Number(rankList[i].sales_rate);
            // }
            if (rankList[i].rate) {
              sum += Number(rankList[i].rate);
            } else if (this.currentType == "flowRankType") {
              // sum += Number(rankList[i].point_index);
              sum = maxValue;
            } else if (this.currentType == "categoryIpPotentailRankType") {
              // sum += Number(rankList[i].potential_index);
              sum = maxValue;
            } else {
              if (this.calType == "calPercentMax") {
                // if (idx == 0 || idx == 1) {
                //   sum = maxValue;
                // }
                // if (idx == 2) {
                //   sum += Number(rankList[i].shop_num_rate);
                // }

                if (!calPerType) {
                  sum = maxValue;
                  // console.log("sum",sum)
                } else if (idx == 2 && calPerType == "calAddSum") {
                  // console.log("calAddSum");
                  sum += Number(rankList[i].shop_num_rate);
                }
              } else {
                if (this.valueType == "analysisType") {
                  if (idx == 0) {
                    sum += Number(rankList[i].sales_rate);
                  }
                  if (idx == 1) {
                    sum += Number(rankList[i].price_rate);
                  }

                  if (idx == 2) {
                    sum += Number(rankList[i].shop_num_rate);
                  }
                }
                if (this.valueType == "analysisIpType") {
                  if (idx == 0) {
                    sum += Number(rankList[i].avg_sales);
                  }
                  if (idx == 1) {
                    sum += Number(rankList[i].avg_price);
                  }

                  if (idx == 2) {
                    sum += Number(rankList[i].unit_price);
                  }
                }
              }
            }
          }
          if (percent == 0) {
            return 0;
          }
          if (sum == 0) {
            return 0;
          } else {
            // if(this.currentType == "categoryIpDownRankType") {
            //   console.log("sum",sum,"percent",percent,"name",subItem)
            // }
            let per = parseFloat((Number(percent) / sum) * 100).toFixed(2);
            if(per > 100) {
              return 100;
            } else {
              return per;
            }
            // return parseFloat((Number(percent) / sum) * 100).toFixed(2);
          }
        } else {
          return percent.toFixed(2);
        }
      },
      calPerWidth(rankList, subItem, idx) {
        let sum = 0;
        let percent = 0;
        if (this.currentType == "flowRankType") {
          percent = subItem.point_index;
        } else if (this.currentType == "categoryIpPotentailRankType") {
          percent = subItem.potential_index;
        } else {
          if (this.valueType == "analysisType") {
            if (idx == 0) {
              percent = subItem.sales_rate;
            }
            if (idx == 1) {
              percent = subItem.price_rate;
            }
            if (idx == 2) {
              percent = subItem.shop_num;
            }
          }
        }

        for (let i = 0, j = rankList.length; i < j; i++) {
          // if (rankList[i].sales_rate) {
          //   sum += Number(rankList[i].sales_rate);
          // }
          if (rankList[i].rate) {
            sum += Number(rankList[i].rate);
          }
          // if (rankList[i].point_index) {
          //   sum += Number(rankList[i].point_index);
          // }
          else if (this.currentType == "flowRankType") {
            sum += Number(rankList[i].point_index);
          } else if (this.currentType == "categoryIpPotentailRankType") {
            sum += Number(rankList[i].potential_index);
          } else {
            if (this.valueType == "analysisType") {
              if (idx == 0) {
                sum += Number(rankList[i].sales_rate);
              }
              if (idx == 1) {
                sum += Number(rankList[i].price_rate);
              }
              if (idx == 2) {
                sum += Number(rankList[i].shop_num);
              }
            }
          }
        }
        if (percent == 0) {
          return 0;
        }

        if (sum == 0) {
          return 0;
        } else {
          return parseFloat((Number(percent) / sum) * 100).toFixed(2);
        }
      },
    },
  };
</script>
<style lang="less" scoped>
  @import url("@/assets/css/rankTitle.less");

  .rank-box {
    display: flex;
    width: 100%;
    // height: 206px;
    padding-bottom: 20px;
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #e7e8ed;
    margin-bottom: 20px;
    flex-direction: column;

    .analysis-info {
      display: flex;
      justify-content: space-between;
      width: 98%;
      margin: 0 auto;
      margin-top: 20px;

      .analysis-item {
        width: 48%;
        display: flex;
        background: #e0deff;
        border-radius: 10px;
        padding: 14px 30px;

        .name {
          font-weight: 400;
          font-size: 24px;
          color: #1a232f;
          text-align: left;
        }

        .item-left {
          flex: 2;
          display: flex;
          flex-direction: column;



          .count {
            font-weight: bold;
            font-size: 40px;
            color: #1a232f;
            // line-height: 47px;
            text-align: left;
            justify-content: center;
            display: flex;
            margin-top: 4px;

            .unit {
              font-weight: bold;
              font-size: 24px;
              color: #1a232f;
              text-align: left;
              display: flex;
              align-items: flex-end;
            }

            .unit-puls {
              font-weight: bold;
              font-size: 17px;
              color: #1a232f;
              text-align: left;
            }
          }
        }

        .item-right {
          display: flex;
          /* align-items: flex-end; */
          flex: 1;
          flex-direction: column;

          .right-info {
            display: flex;
            align-items: center;
            font-size: 40px;
            justify-content: center;
          }

          .up {
            font-weight: 400;
            font-size: 40px;
            color: #e6182e;
            text-align: left;
          }

          .down {
            font-weight: 400;
            font-size: 40px;
            color: #13b918;
            text-align: left;
          }

          img {
            width: 16px;
            height: 20px;
            margin-left: 6px;
          }
        }
      }
    }

    .rank-title {
      .right-opt {
        margin-right: 10px;
      }

      ::v-deep {
        .el-cascader {
          line-height: 30px;
          margin-left: 10px;

          input {
            height: 30px;
            line-height: 30px;
          }

          .el-input__icon {
            line-height: 30px;
          }
        }
      }

      ::v-deep {
        .el-select {
          line-height: 30px;
          margin-left: 10px;

          input {
            height: 30px;
            line-height: 30px;
          }

          .el-input__icon {
            line-height: 30px;
          }
        }
      }
    }

    .rank-list {
      display: flex;
      width: 100%;

      .rank-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        position: relative;

        ::v-deep .el-empty {
          padding: 20px 0;

          .el-empty__image {
            width: 40px;
            // display: none;
          }

          .el-empty__description {
            p {
              font-size: 14px;
              color: #626a83;
            }
          }
        }

        .title {
          font-weight: 400;
          font-size: 16px;
          color: #626a83;
          line-height: 20px;
          text-align: center;
          padding-top: 8px;
          margin-bottom: 20px;
        }

        .rank-h {
          font-weight: 400;
          font-size: 16px;
          color: #979db1;
          line-height: 20px;
          position: absolute;
          right: 100px;
          top: 30px;
        }

        .zhanbi {
          font-weight: 400;
          font-size: 16px;
          color: #979db1;
          line-height: 20px;
          position: absolute;
          right: 30px;
          top: 30px;
        }
        .zhanbi1 {
          right: 150px;
        }

        .rank-info-list {
          display: flex;
          flex-direction: column;
          position: relative;
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }

          &.itemHide {
            display: none;
          }
        }

        
      }
    }
    .rank-info {
          display: flex;
          padding: 0 6px 0 10px;
          align-items: center;

          .num {
            min-width: 24px;
            height: 24px;
            padding: 0 4px;
            background: #fbfbfc;
            box-shadow: 0px 2px 4px 0px #e7e8ed;
            border: 1px solid #e7e8ed;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;

            span {
              font-weight: bold;
              font-size: 12px;
              color: #1a232f;
            }

            &.no-num {
              box-shadow: none;
              border: none;
              background: none;
            }
          }


          .short-name {
            width: 116px;
            font-weight: 400;
            font-size: 16px;
            color: #1a232f;
            text-align: left;
            margin-left: 8px;
          }

          .name {
            width: 120px;
            font-weight: 400;
            font-size: 16px;
            color: #1a232f;
            text-align: left;
            margin-left: 8px;
          }

          .percent {
            flex: 1;
            height: 16px;
            background: #f3f3f8;
            border-radius: 4px;
            margin-left: 14px;
            position: relative;

            .active {
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              background: #da8ff5;
              border-radius: 4px;
            }
          }

          .rank-num {
            width: 60px;
            font-weight: 400;
            font-size: 16px;
            color: #626a83;
          }

          .percent-count {
            width: 100px;
            font-weight: 400;
            font-size: 16px;
            color: #626a83;
            margin-left: 6px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 12px;
              height: 15px;
              margin-left: 4px;
              margin-top: 2px;
            }
          }
        }
  }
</style>
<template>
  <div class="flex flex-column feedback-box">
    <i class="el-icon-circle-close icon-defalut-close cursor"
      @click="close"></i>
    <div class="default-title">问题反馈</div>
    <div class="default-content mt-20">
      <div
        class="font-14 text-default line-h-20 font-weight-bolder ml-20">
        请描述你的问题</div>
      <div class="text-area mt-20">
        <el-input type="textarea" resize="none"
          placeholder="请描述你的问题" v-model="problem">
        </el-input>
      </div>
      <div class="upload-box mt-38">
        <el-upload class="upload-demo" ref="imageUpload"
          :limit="limit" :on-error="handleUploadError"
          :on-exceed="handleExceed" :action="uploadImgUrl"
          :on-success="handleUploadSuccess"
          :before-upload="handleBeforeUpload"
          :headers="headers" :show-file-list="false"
          list-type="picture">
        <el-button type="primary" class="upload-btn ml-20" round>上传图片</el-button>
        </el-upload>
        <!-- <div class="upload-btn flex justify-center align-center font-23 text-primary" @click="uploadImg">上传图片</div> -->
        <div class="img-list flex flex-wrap mt-20">
          <div class="img-item"
            v-for="(item,idx) in imgUrlList" :key="idx">
            <img :src="item">
            <i class="icon-del el-icon-circle-close icon-defalut-close cursor"
              @click="delImg(item,idx)"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="feedback-bottom flex align-center">
      <div class="flex flex-1  justify-end">
        <div class="default-btn cursor mr-20">发送</div>
      </div>

    </div>
  </div>
</template>
      
  <script>
  import { getToken } from "@/utils/auth";
export default {
  data() {
    return {
      problem: "",
      imgUrlList: ["https://files.sssrise.com/files/uploads/static/icon_app.png","https://files.sssrise.com/files/uploads/static/icon_app.png"],
      uploadImgUrl: process.env.VUE_APP_BASE_API + "/file/upload", // 上传的图片服务器地址
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      fileType: ["png", "jpg", "jpeg"],
      fileSize: 1,
      limit: 1,
      uploadList: [],
    };
  },
  methods: {
    close() {
      this.$emit("closeHelp");
    },
    handleChange() {},
    uploadImg() {},
    delImg() {
        this.$confirm('此操作将永久删除该图片, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.$message({
            type: 'success',
            message: '删除成功!'
            });
        }).catch(() => {
            this.$message({
            type: 'info',
            message: '已取消删除'
            });          
        });
    },
    handleBeforeUpload(file) {
      let isImg = false;
      if (this.fileType.length) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        isImg = this.fileType.some((type) => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
      } else {
        isImg = file.type.indexOf("image") > -1;
      }

      if (!isImg) {
        this.$message.error(
          `文件格式不正确, 请上传${this.fileType.join("/")}图片格式文件!`
        );
        return false;
      }
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$message.error(`上传头像图片大小不能超过 ${this.fileSize} MB!`);
          return false;
        }
      }
      this.$message.error("正在上传图片，请稍候...");
    },
    handleUploadError() {
      this.$message.error("上传图片失败，请重试");
    },
    handleExceed() {
      this.$message.error(`上传文件数量不能超过 ${this.limit} 个!`);
    },
    handleUploadSuccess(res, file) {
      console.log("handleUploadSuccess", res, file);
      if (res.code === 200) {
        // this.uploadList.push({ name: res.data.url, url: res.data.url });
        // this.uploadedSuccessfully();
      } else {
        this.$message.error(res.msg);
        this.$refs.imageUpload.handleRemove(file);
        // this.uploadedSuccessfully();
      }
    },
  },
};
</script>
  <style lang="less" scoped>
.feedback-box {
  width: 380px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px #e7e8ed;
  border-radius: 20px;
  border: 1px solid #e7e8ed;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  z-index: 10001;

  .default-content {
    flex: 1;
    overflow-y: auto;
    text-align: left;
    padding-bottom: 70px;
    ::v-deep .el-collapse {
      border-top: none;
      border-bottom: none;
      width: 352px;
      margin: 0 auto;
      .el-collapse-item {
        width: 100%;
        background: #f8f8fa;
        border-radius: 10px;
        margin-bottom: 10px;
        position: relative;
        .el-collapse-item__header {
          background: #f8f8fa;
          border-radius: 10px;
          border-bottom: none;
          padding-left: 16px;
          font-weight: 400;
          font-size: 14px;
          color: #1a232f;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 320px;
          display: inline-block;
          .el-collapse-item__arrow {
            position: absolute;
            top: 20px;
            right: 0;
            display: flex;
            align-items: center;
          }
        }
        .el-collapse-item__wrap {
          border-bottom: none;
          background: #f8f8fa;
          border-radius: 10px;
          font-weight: 400;
          font-size: 12px;
          color: #626a83;
          line-height: 17px;
          padding: 0 16px 16px 16px;
        }
        .el-collapse-item__content {
          padding-bottom: 0;
        }
      }
    }
    .upload-btn {
        width: 88px;
        height: 28px;
        background: #D9E5FF;
        box-shadow: 0px 2px 4px 0px #D9E5FF;
        border-radius: 18px;
        border: 1px solid #3672FD;
        font-weight: 400;
        font-size: 12px;
        color: #3672FD;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .img-list {
        margin-left: 20px;
        .img-item {
            width: 64px;
            height: 64px;
            margin-right: 28px;
            margin-bottom: 10px;
            position: relative;
            &:nth-child(4n) {
                margin-right: 0;
            }
            img {
                width: 64px;
                height: 64px;
            }
            .icon-del {
                position: absolute;
                top: -5px;
                right: -5px;
                font-size: 16px;
                color: #FF5959;
            }
        }
    }
    .text-area {
      display: flex;
      justify-content: center;
      ::v-deep .el-textarea {
        width: 340px;
        height: 194px;
        background: #ffffff;
        border-radius: 5px;
        border: 1px solid #cccfda;
        margin: 0 auto;
        .el-textarea__inner {
          border: none;
          height: 190px;
        }
      }
    }
  }
  .feedback-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 64px;
    background: rgba(243, 243, 248, 0.8);
    box-shadow: inset 0px 1px 0px 0px #e7e8ed;
    border-radius: 0px 0px 20px 20px;
    backdrop-filter: blur(10px);
  }
}
</style>
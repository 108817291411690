<template>
  <div
    class="detail-bottom flex justify-between align-center">
    <div class="flex align-center">
      <div class="flex align-center cursor"
        @click="submitLike(1)">
        <img class="thumb-up" v-if="contentDetail && contentDetail.liked" src="@/assets/images/fav_check.png">
				<img class="thumb-up" v-else src="@/assets/images/fav_default.png">
        <span
          class="ml-10 font-23 text-normal">{{contentDetail && contentDetail.like || 0}}</span>
      </div>
      <div class="flex align-center ml-20 cursor"
        @click="submitLike(-1)">
          <img class="thumb-up" v-if="contentDetail && contentDetail.unliked" src="@/assets/images/unlike_check.png">
				<img class="thumb-up" v-else src="@/assets/images/unlike_default.png">
        <span
          class="ml-10 font-23 text-normal">{{contentDetail && contentDetail.unlike || 0}}</span>
      </div>
      <div class="flex align-center ml-20"
        @click="submitFav()">
        <i class="el-icon-star-off star-on cursor"
          v-if="contentDetail && contentDetail.faved"></i>
        <i class="el-icon-star-off star-off cursor"
          v-else></i>
      </div>
    </div>
    <div class="submit-normal-btn cursor" @click="goIndex">
      我也要问该问题</div>
  </div>
</template>

<script>
export default {
  props: ["contentDetail"],
  data() {
    return {
      hasFav: false,
    };
  },
  methods: {
    submitLike(type) {
      let cancel = false;
      if (this.contentDetail && this.contentDetail.liked && type == 1) {
        cancel = true;
      }
      if (this.contentDetail && this.contentDetail.unliked && type == -1) {
        cancel = true;
      }
      this.$emit("submitLike", {
        type: type,
        cancel: cancel,
      });
    },
    submitFav() {
      let fav = 1;
      if (this.contentDetail && this.contentDetail.faved) {
        fav = -1;
      }
      this.$emit("submitFav", fav);
    },
    goIndex() {
      this.$router.push({
        path: "/ai",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.detail-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 14px 30px 14px 30px;
  background: rgba(243, 243, 248, 0.8);
  box-shadow: inset 0px 1px 0px 0px #e7e8ed;
  border-radius: 0px 0px 20px 20px;
  backdrop-filter: blur(10px);
  img {
    width: 24px;
    height: 24px;
  }
  .thumb-up-down {
    width: 24px;
    height: 24px;
  }
  .submit-normal-btn {
    width: 140px;
    height: 36px;
    background: #3672fd;
    box-shadow: 0px 2px 4px 0px #d9e5ff;
    border-radius: 18px;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .star-on {
    color: #3672fd;
    font-size: 24px;
  }
  .star-off {
    color: #979db1;
    font-size: 24px;
  }
}
</style>

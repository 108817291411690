<template>
  <div class="zhuanfa-detail-box-wrap">
    <div class="detail-header">
      <i class="el-icon-back icon-back"
        @click="closeDetail"></i>
      转发
    </div>
    <div class="detail-content">
      <div class="title">
        【{{zhuanfaName}}】{{problemList && problemList.length > 0 && problemList[0].msg && problemList[0].msg.text ? problemList[0].msg.text.substring(0,9) : ""}}
      </div>
      <div class="problem-wrap mt-20"
        v-for="(item,idx) in problemList" :key="idx">
        <DetailLeft v-if="item.cat == 1" :problemItem="item"
          :problemIdx="idx"></DetailLeft>
        <DetailRight v-if="item.cat == 0"
          :problemItem="item" :problemIdx="idx">
        </DetailRight>
      </div>
      <div class="zhuanfa-bottom">
        <!-- <div class="title">选择发布频道</div> -->
        <!-- <div class="flex flex-wrap ">
        <div
          class="type-item flex align-center justify-center mt-20"
          :class="[typeIdx == idx ? 'active' : '']"
          v-for="(item,idx) in typeList" :key="idx"
          @click="chooseIdx(item,idx)">{{item.name}}</div>
      </div> -->
        <!-- <div class="mt-20  pt-10 pb-10 position-relative">
        <div class="flex justify-between">
          <div
            class="font-14 text-default font-weight-bolder">
            <span>设定查看权限</span><span
              class="font-12 font-weight-normal">（可选）</span>
          </div>
        </div>
        <div class="flex justify-between mt-10">
          <div class="flex align-center">
            <span
              class="font-14 text-default line-h-20 mr-10">积分</span>
            <div>
            <input placeholder="可填写范围0-100"
                v-model="point" :type="type">
            </div>
          </div>
          <div class="flex align-center">
            <span
              class="font-27 text-default line-h-38 mr-20">¥</span>
            <div>
                <input placeholder="可填写范围0-100"
                v-model="money" :type="type">
            </div>
          </div>
        </div>
        <div class="lock-box flex flex-column align-center">
          <img class="suo"
            src="https://files.sssrise.com/files/uploads/static/suo.png">
          <div class="font-12 line-h-16 text-white mt-10">
            达到100个赞即可开启查看权限模式</div>
        </div>
      </div> -->
        <div class="mt-10 flex justify-end">
          <div class="flex align-center">
            <span
              class="font-12 text-default mr-10">需消耗20积分</span>
            <div class="submit-normal-btn"
              @click="submitContent">发布</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DetailLeft from "../content/detailLeft.vue";
import DetailRight from "../content/detailRight.vue";
import { getMessageByIds } from "@/api/user";
import { postCreate } from "@/api/content";
import util from "@/utils/util";
import qs from "qs";
export default {
  props: ["zhuanfaMessageIds", "zhuanfaName", "currentChatId"],
  components: {
    DetailLeft,
    DetailRight,
  },
  data() {
    return {
      detailInfo: null,
      typeList: [
        {
          id: 1,
          name: "创意市场",
        },
        {
          id: 2,
          name: "文学巨匠",
        },
        {
          id: 3,
          name: "绘画天才",
        },
      ],
      typeIdx: 0,
      point: "",
      money: "",
      type: "number",
      userInfo: {
        id: "",
        nickname: "",
        mobile: "",
        avatar: "",
        gender: "",
        desc: "",
        real_name: "",
        character: "",
        vip_level: 0,
        vip_expires_in: "",
        coin: 0,
        income: 0,
        balance: 0,
        freeze: 0,
        token: "",
      },
      problemList: [],
    };
  },
  created() {
    util.onLogin().then((res) => {
      if (!res) {
        this.$bus.$emit("openLogin");
      } else {
        this.getUserInfo();
        this.getMessageByIdsReq(this.zhuanfaMessageIds);
      }
    });
  },
  methods: {
    getUserInfo() {
      this.$store
        .dispatch("GetInfo", "")
        .then((res) => {
          this.userInfo = res;
        })
        .catch((err) => {});
    },
    getMessageByIdsReq(ids) {
      this.problemList = [];
      let params = "";
      if (ids && ids.length > 0) {
        ids = ids.split(",");
        console.log("ids", ids);
        for (let i = 0, j = ids.length; i < j; i++) {
          params += "&ids=" + ids[i];
        }
        params = params.substring(1, params.length);
      }

      getMessageByIds(params)
        .then((res) => {
          if (res && res.error == 0) {
            let tempList = (res && res.data && res.data.list) || [];
            if (tempList && tempList.length > 0) {
              console.log("tempList", tempList);
              this.problemList = tempList;
            }
          } else {
            this.$message({
              message: (res && res.msg) || "服务器异常，请稍后重试",
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: (err && err.msg) || "服务器异常，请稍后重试",
            type: "error",
          });
        });
    },
    closeDetail() {
      this.$emit("closeZhuanfa");
    },
    submitContent() {
    //   if (this.userInfo.coin < 20) {
    //     this.$emit("showPayEmit");
    //     return;
    //   }

      let param = {
        message_ids: this.zhuanfaMessageIds.split(","),
        channel: "market",
        title:
          "【" +
          this.zhuanfaName +
          "】" +
          ((this.problemList &&
            this.problemList[0].msg &&
            this.problemList[0].msg.text &&
            this.problemList[0].msg.text.substring(0, 9)) ||
            ""),
        coin: 20,
      };

      let paramStr = qs.stringify(param, { indices: false });
      postCreate(paramStr)
        .then((res) => {
          console.log("res", res)
          if(res && res.error == 0) {
            this.$message({
                message: "发布成功",
                type: "success",
            });
            this.$router.replace({
                path: "creative",
            });
          } else {
            this.$message({
                message: (err && err.msg) || "服务器异常，请稍后重试",
                type: "error",
            });
          }
          
        })
        .catch((err) => {
          this.$message({
            message: (err && err.msg) || "服务器异常，请稍后重试",
            type: "error",
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.zhuanfa-detail-box-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  .detail-header {
    padding: 0 30px;
    height: 63px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: bold;
    font-size: 17px;
    color: #1a232f;
    .icon-back {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 30px;
      color: #626a83;
      font-size: 20px;
      cursor: pointer;
      font-weight: 400;
      display: flex;
      align-items: center;
    }
  }
  .detail-content {
    margin: 0 30px;
    background: #ffffff;
    border-radius: 10px;
    padding: 20px 0;
    flex: 1;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    .title {
      padding: 0 16px;
      font-weight: bold;
      font-size: 17px;
      color: #1a232f;
      line-height: 24px;
      text-align: left;
    }
    .user-info {
      margin: 0 27px;
      height: 58px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e7e8ed;
      .avatar {
        width: 24px;
        height: 24px;
        border-radius: 100%;
      }
      .user-name {
        font-weight: 400;
        font-size: 12px;
        color: #1a232f;
        line-height: 17px;
        margin-left: 6px;
      }
      .bade-img {
        width: 20px;
        height: 20px;
        margin-left: 2px;
      }
    }
    .problem-wrap-list {
      display: flex;
      flex: 1;
      overflow-y: auto;
      flex-direction: column;
      padding-bottom: 60px;
    }
  }
  .zhuanfa-bottom {
    background: rgba(243, 243, 248, 0.8);
    box-shadow: inset 0px 1px 0px 0px #e7e8ed;
    border-radius: 0px 0px 20px 20px;
    backdrop-filter: blur(10px);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px 96px;
    ::v-deep input {
      width: 195px;
      height: 32px;
      background: #ffffff;
      border-radius: 5px;
      border: 1px solid #cccfda;
      font-weight: 400;
      font-size: 12px;
      color: #979db1;
      padding-left: 8px;
      &:focus {
        outline: none;
      }
    }
    .title {
      font-weight: bold;
      font-size: 14px;
      color: #1a232f;
      line-height: 20px;
    }
    .type-item {
      width: 110px;
      height: 36px;
      background: #ffffff;
      box-shadow: 0 2px 4px 0 #e7e8ed;
      border-radius: 5px;
      border: 1px solid #e7e8ed;
      font-size: 12px;
      color: #626a83;
      margin-right: 12px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      &.active {
        color: #3672fd;
        font-weight: bolder;
        background: #d9e5ff;
        box-shadow: 0 2px 4px 0 #d9e5ff;
        border: 1px solid #3672fd;
      }
    }
    .lock-box {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: rgba(26, 35, 47, 0.7);
      border-radius: 20px;
      z-index: 99;
      .suo {
        width: 24px;
        height: 24px;
        margin-top: 18px;
      }
    }
  }
}
</style>
<template>
  <div class="data-ref-box">
    <div class="data-title mt-20">
      市场现状<el-tooltip  style="margin-left:4px;margin-top:2px;" class="item" effect="dark"
      placement="right">
      <div slot="content">
        <div v-html="calTopTip(1)"></div>
      </div>
      <i class="el-icon-question"></i>
    </el-tooltip>
    </div>
    <div class="price-dis-box">
      <div v-show="priceDisList && priceDisList.length > 0"
        class="price-dis-main" id="priceDisMain"></div>
      <el-empty
        v-show="!priceDisList || priceDisList.length == 0"></el-empty>
    </div>
    <div class="flex content-between">
      <div class="ip-influence">
        <div class="data-title">
          IP竞争力<el-tooltip  style="margin-left:4px;margin-top:2px;" class="item" effect="dark"
          placement="right">
          <div slot="content">
            <div v-html="calTopTip(2)"></div>
          </div>
          <i class="el-icon-question"></i>
        </el-tooltip>
        </div>
        <div class="bar-item" id="ipInfluence"></div>
      </div>
      <div class="ip-influence">
        <div class="data-title">
          爆款概率预测<el-tooltip style="margin-left:4px;margin-top:2px;" class="item" effect="dark"
          placement="right">
          <div slot="content">
            <div v-html="calTopTip(3)"></div>
          </div>
          <i class="el-icon-question"></i>
        </el-tooltip>
        </div>
        <div class="bar-item" id="probability"></div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  props: ["priceList","probabilityList","ipName","ipId","keywordAvgSales","keywordNoIpSale","keywordIpSale","priceDefaultList","topIpSale"],
  data() {
    return {
      priceDisList: [
        [10.0, 8.04],
        [8.07, 6.95],
        [13.0, 7.58],
        [9.05, 8.81],
        [11.0, 8.33],
        [14.0, 7.66],
        [13.4, 6.81],
        [10.0, 6.33],
        [14.0, 8.96],
        [12.5, 6.82],
        [9.15, 7.2],
        [11.5, 7.2],
        [3.03, 4.23],
        [12.2, 7.83],
        [2.02, 4.47],
        [1.05, 3.33],
        [4.05, 4.96],
        [6.03, 7.24],
        [12.0, 6.26],
        [12.0, 8.84],
        [7.08, 5.82],
        [5.02, 5.68],
      ],
    };
  },
  created() {
    this.initData();

    this.$bus.$off("refreshData");
    this.$bus.$on("refreshData",()=> {
      this.initData();
    })
  },
  methods: {
    calTopTip(type) {
      switch(type) {
        case 1: 
         return "统计分析满足当前条件的商品的销售情况，了解市场结构和消费者需求，<br>优化产品组合和定价策略，提升市场竞争力和盈利能力。";
         break;
        case 2: 
          return "分析IP对于所选品类的匹配度和提升度。";
          break;
        case 3: 
          return "预测当前条件下产品销量档位";
          break;
      }
    },
    initData() {
      this.$nextTick(() => {
        this.initPriceDis();
        this.initIpInfluence();
        this.initProbability();
      });
    },
    initPriceDis() {
      let self = this;
      var chartDom = document.getElementById("priceDisMain");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        // title: {
        //   text: "单品月销量",
        //   left: "center",
        //   textStyle: {},
        // },
        grid: {
          left: 100
        },
        tooltip: {
          position: 'top',
          show: true,
          formatter: function (params) {
            if(params && params.value.length > 0) {
              // return Math.floor(Math.pow(10,params.value[0] * Math.log10(5))) + "  " + params.value[1];
              return "价格：" + Math.floor(Math.pow(10,params.value[0] * Math.log10(5))) + "<br>销量：" + params.value[1];
            }
          },
        },
        xAxis: {
          name: "价格",
          nameLocation: "center",
          nameTextStyle: {
            fontSize: 18,
            lineHeight: 30,
            padding: [10, 0, 30, 0],
          },
          axisLabel: {
            formatter: function (value, index) {
                // return Math.floor(Math.pow(10,value * Math.log10(5)));
                let tempX = Math.pow(10,value * Math.log10(5));
                if(tempX.toString().indexOf(".")>=0) {
                  tempX = tempX.toFixed(1);
                }
                if(tempX && tempX.toString().indexOf(".") >= 0) {
                  let sec = tempX.toString().split(".")[1];
                  if(sec == 0) {
                    tempX = tempX.toString().split(".")[0];
                  }
                }
                return tempX;
            }
          }
          // data: self.priceDefaultList
        },
        yAxis: {
          name: "销量",
          nameLocation: "center",
          nameTextStyle: {
            fontSize: 18,
            lineHeight: 30,
            padding: [10, 0, 30, 0],
          },
          axisLabel: {
            show: true
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true
          }
        },
        series: [
          {
            symbolSize: 10,
            encode: { tooltip: [0, 1] },
            data: self.priceList,
            // data: self.priceDefaultList,
            type: "scatter",
          },
        ],
      };

      option && myChart.setOption(option);
    },
    initIpInfluence() {
      let self = this;
      var chartDom = document.getElementById("ipInfluence");
      var myChart = echarts.init(chartDom);
      var option;
      // let xData = ["品类平均","无ip平均"];
      let xData = [];
      let seriesData = [];
      let currentName = "";
      console.log("topIpSale",self.topIpSale)
      if(self.topIpSale && self.topIpSale.length > 0) {
        for(let i = 0,j = self.topIpSale.length;i<j;i++) {
          let tempItem = self.topIpSale[i];
          xData.push(tempItem.name);
          tempItem["value"] = tempItem.sales;
          if(tempItem.id == self.ipId) {
            tempItem["itemStyle"] = {
              color: '#a90000'
            }
            currentName = tempItem.name;
          }
          
          seriesData.push(tempItem)
        }
        
      }

      if(xData && xData.length > 0) {
        xData = xData.reverse();
        seriesData = seriesData.reverse()
      }
     
      // if(self.keywordAvgSales) {
      //   seriesData.push(self.keywordAvgSales.avg_sales || 0)
      // } else {
      //   seriesData.push(0)
      // }
      // if(self.keywordNoIpSale) {
      //   seriesData.push(self.keywordNoIpSale.avg_sales || 0)
      // } else {
      //   seriesData.push(0)
      // }

      // if(self.ipId && self.ipName) {
      //   xData.push(self.ipName)
      //   if(self.keywordIpSale) {
      //     seriesData.push(self.keywordIpSale.avg_sales || 0)
      //   } else {
      //     seriesData.push(0)
      //   }
      // } else {
      //   xData = ["品类平均","无ip平均"];
      // }
      if(seriesData.length == 0) {
        option = {
          title: {
            text: '数据挖掘中',
            x: "center",
            y: "center"
          }
        }

        option && myChart.setOption(option);
        return;
      }
      option = {
        title: {
          text: "月总销量（单位为万）",
          left: "center",
          textStyle: {},
        },
        grid: {
          left: 80
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
          formatter: function (params) {
            console.log("params",params)
            return params[0].name + "<br>月销量：" + (params[0].value ? params[0].value : "-") + "万<br>平均价格：" + params[0].data.unit_price + "<br>排名：" + params[0].data.rank
          }
        },
        xAxis: {
          type: "value",
          splitLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
        },
        yAxis: {
          type: "category",
          // interval:1000,
          data: xData,
          splitLine: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: function (value, index) {
                  return value == currentName ? '#a90000' : '#333';
              }
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
        },
        series: [
          {
            data: seriesData,
            type: "bar",
            label: {
              show: true, // 显示数据
              position: "right", // 数据显示的位置
              formatter: function (params) {
                // console.log("params",params)
                if(params.data.id == self.ipId) {
                  return '{redColor|' + params.value +'}' 
                } else {
                  return params.value 
                }
                  
              },
              "rich": {
                "redColor": {
                  color: "#a90000"
                }
              }
            }
          },
        ],
      };

      option && myChart.setOption(option);
    },
    initProbability() {
      let self = this;
      var chartDom = document.getElementById("probability");
      var myChart = echarts.init(chartDom);
      var option;
      
      option = {
        // title: {
        //   text: "销量概率预测",
        //   left: "center",
        //   textStyle: {},
        // },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
          formatter: function (params) {
            return params[0].name + "<br>" + params[0].value + "%"
          }
        },
        xAxis: {
          type: "category",
          data: ["爆款","高销量","中高销量","中等销量","中低销量","低销量"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: self.probabilityList,
            type: "bar",
            label: {
              show: true, // 显示数据
              position: "top", // 数据显示的位置
              formatter: function (params) {
                  return params.value + "%"
              }
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
};
</script>
<style lang="less" scoped>
.data-ref-box {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 0 20px;
  .data-title {
    font-size: 18px;
    color: #1a232f;
    font-weight: bold;
    text-align: left;
    padding-left: 10px;
  }
  .price-dis-box {
    padding: 20px 0;
    .price-dis-main {
      width: 100%;
      height: 500px;
    }
  }
  .ip-influence {
    width: 50%;
    
    .bar-item {
        width: 100%;
        height: 300px;
        margin-top: 20px;
    }
  }
}
</style>
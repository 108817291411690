<template>
    <div class="home-center-box">
        <!-- <div class="video-box">
            <img class="icon-bg" src="@/assets/images/home/shipin.png">
            <img class="icon-play" src="@/assets/images/home/icon_play.png">
        </div> -->
        <!-- <div class="desc-box">
            <div class="desc-list">
                <div class="desc-item" v-for="(item,idx) in descList" :key="idx">
                    <div class="desc-img pr-16" v-if="idx % 2 ==0">
                        <img :src="item.imgSrc">
                    </div>
                    <div class="desc-info">
                        <div class="name">{{item.name}}</div>
                        <div class="desc">{{item.desc}}</div>
                    </div>
                    <div class="desc-img pl-16" v-if="idx % 2 ==1">
                        <img :src="item.imgSrc">
                    </div>
                </div>
            </div>
        </div> -->
        <descCom></descCom>
    </div>
</template>
<script>
    import descCom from "./desc.vue";
export default {
    data() {
        return {
            descList: [{
                name: "主流AI大模型接入",
                desc: "我是您的育儿智能工具，随时准备为您提供专业的育儿建议和支持。我是您的育儿智能工具，随时准备为您提供专业的育儿建议和支持。我是您的育儿智能工具，随时准备为您提供专业的育儿建议和支持。我是您的育儿智能工具，随时准备为您提供专业的育儿建议和支持。我是您的育儿智能工具，随时准备为您提供专业的育儿建议和支持。我是您的育儿智能工具，随时准备为您提供专业的育儿建议和支持。",
                imgSrc: require("@/assets/images/home/ai_mx.png")
            },{
                name: "精细化行业适配",
                desc: "我是您的育儿智能工具，随时准备为您提供专业的育儿建议和支持。我是您的育儿智能工具，随时准备为您提供专业的育儿建议和支持。我是您的育儿智能工具，随时准备为您提供专业的育儿建议和支持。我是您的育儿智能工具，随时准备为您提供专业的育儿建议和支持。我是您的育儿智能工具，随时准备为您提供专业的育儿建议和支持。我是您的育儿智能工具，随时准备为您提供专业的育儿建议和支持。",
                imgSrc: require("@/assets/images/home/icon_sp.png")
            }]
        }
    },
    components: {
        descCom
    }
}
</script>
<style lang="less" scoped>
.home-center-box {
    padding: 30px;
    overflow-y: auto;
    .video-box {
        width: 100%;
        position: relative;
        .icon-bg {
            width: 100%;
        }
        .icon-play {
            width: 95px;
            height: 95px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: 2;
            cursor: pointer;
        }
    }
    .desc-box {
        .desc-list {
            display: flex;
            flex-direction: column;
            .desc-item {
                display: flex;
                margin-top: 30px;
                .desc-img {
                    display: flex;
                    align-items: flex-start;
                }
                img {
                    width: 120px;
                    height: 120px;
                }
                .desc-info {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    .name {
                        font-weight: bold;
                        font-size: 17px;
                        color: #3672FD;
                        line-height: 24px;
                        
                    }
                    .desc {
                        font-weight: 400;
                        font-size: 12px;
                        color: #626A83;
                        line-height: 17px;
                        margin-top: 6px;
                        text-align: left;
                    }
                }
            }
        }
    }
}
</style>
<template>
  <div class="market-pay-box">
    <div class="market-pay-wrap">
      <div class="market-pay-top">
        <i class="el-icon-back icon-back"
          @click="closeMarketPay" v-if="!hideBack"></i>
        <div class="go-order">
          <div @click="showMarketOrder = true">查看订单</div></div>
      </div>
      <div class="market-pay-content mb-30"
        v-for="(item,idx) in marketList" :key="idx">
        <div class="market-pay-h">
          <div class="name">{{item.cat_name}}</div>
          <div class="desc">{{item.cat_desc}}</div>
        </div>
        <div class="market-pay-list" v-if="item.products">
          <div class="market-pay-item"
            v-for="(subItem,subIdx) in item.products"
            :key="subIdx" @click="goPay(subItem)">
            <div class="name">{{subItem.name}}</div>
            <div class="remain-time">购买时间：{{subItem.days}}天
            </div>
            <div class="pay-info">
              <div class="discount"><span
                  class="unit">¥</span>{{subItem.price / 100}}
              </div>
              <div class="count" v-if="calShowPirce(subItem.price,subItem.origin_price)"><span
                  class="unit">¥</span>{{subItem.origin_price / 100}}
              </div>
              <div class="label" v-if="calShowPirce(subItem.price,subItem.origin_price)">
                {{calDiscount(subItem.price,subItem.origin_price)}}
              </div>
            </div>
            <div class="pay-btn">购买</div>
          </div>
        </div>
      </div>
    </div>

    <PayCom v-if="showPay" :payInfo="payInfo"
      @closePay="closePay" :isFromOrder="isFromOrder" :orderCurrentInfo="orderInfo"></PayCom>
    <div class="market-order-popup" v-if="showMarketOrder">
      <MarketOrder @closeMarketOrder="showMarketOrder = false" @goPopupPay="goPopupPay"></MarketOrder>
    </div>
  </div>
</template>
<script>
import PayCom from "./pay.vue";
import MarketOrder from "./marketOrder.vue";
export default {
  props: ["marketList","hideBack"],
  components: {
    PayCom,
    MarketOrder,
  },
  data() {
    return {
      showPay: false,
      payInfo: null,
      showMarketOrder: false,
      isFromOrder: false,
      orderInfo: null
    };
  },
  methods: {
    calShowPirce(discount, count) {
      if(discount != count) {
        return true;
      } else {
        return false;
      }
    },
    calDiscount(discount, count) {
      return (
        parseFloat((Number(discount) / Number(count)) * 10).toFixed(0) + "折"
      );
    },
    closePay() {
      this.showPay = false;
      this.isFromOrder = false;
    },
    closeMarketPay() {
      this.$emit("closeMarketPay");
    },
    goPay(subItem) {
      this.isFromOrder = false;
      this.orderInfo = null;
      this.payInfo = subItem;
      this.showPay = true;
    },
    goPopupPay(val) {
      this.isFromOrder = true;
      this.orderInfo = val;
      this.showPay = true;
    }
  },
};
</script>
<style lang="less" scoped>
.market-pay-box {
  width: 100%;
  overflow-y: hidden;
  padding-bottom: 30px;
  position: relative;
  .market-pay-wrap {
    overflow-y: auto;
    height: 100%;
  }
  .market-pay-top {
    height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    .icon-back {
      color: #626a83;
      font-size: 20px;
      cursor: pointer;
    }
    .go-order {
      font-weight: 400;
      font-size: 14px;
      color: #3672fd;
      text-align: center;
      font-style: normal;
      cursor: pointer;
      justify-content: flex-end;
      display: flex;
      flex: 1;
    }
  }
  .market-pay-content {
    padding: 0 24px;

    .market-pay-h {
      .name {
        font-weight: bold;
        font-size: 17px;
        color: #1a232f;
        line-height: 24px;
        text-align: left;
      }
      .desc {
        font-weight: 400;
        font-size: 14px;
        color: #626a83;
        line-height: 20px;
        text-align: left;
        margin-top: 4px;
      }
    }
    .market-pay-list {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      .market-pay-item {
        min-width: 200px;
        width: 30%;
        padding: 20px;
        background: #ffffff;
        border-radius: 10px;
        cursor: pointer;
        .name {
          font-weight: bold;
          font-size: 17px;
          color: #1a232f;
          line-height: 24px;
          text-align: left;
        }
        .remain-time {
          font-weight: 400;
          font-size: 14px;
          color: #979db1;
          line-height: 20px;
          text-align: left;
          margin-top: 4px;
        }
        .pay-info {
          display: flex;
          margin-top: 38px;
          align-items: center;
          .unit {
            font-size: 14px;
          }
          .discount {
            font-weight: bold;
            font-size: 20px;
            color: #3672fd;
            line-height: 20px;
            text-align: left;
          }
          .count {
            font-weight: 400;
            font-size: 14px;
            color: #979db1;
            line-height: 20px;
            text-align: left;
            text-decoration-line: line-through;
            margin-left: 12px;
          }

          .label {
            padding: 0 8px;
            height: 16px;
            background: #ffd8d8;
            border-radius: 8px 8px 8px 0px;
            border: 1px solid #ff5959;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 6px;
            font-size: 12px;
            color: #ff5959;
          }
        }
        .pay-btn {
          width: 112px;
          height: 28px;
          background: #3672fd;
          box-shadow: 0px 2px 4px 0px #d9e5ff;
          border-radius: 18px;
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          margin-top: 20px;
        }
      }
    }
  }
  .market-order-popup {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background:#f8f8fa;
    border-radius: 20px;
    z-index: 10;
  }
}
</style>
<template>
	<div class="prompt-popup">
		
		<div class="content">
			<i class="el-icon-circle-close icon-defalut-close cursor"
        @click="closePopup"></i>
			<div class="flex flex-column">
				<!-- 	<div class="mt-50 flex tab-list justify-center ">
					<div class="tab-item" :class="[tabIdx == idx && 'active']" v-for="(item,idx) in tabList" :key="idx" @click="tabChange(item,idx)">{{item.name}}</div>
				</div> -->
				<div class="title">提示词</div>
				<div class="content-list flex flex-column px-20 mt-20">
					<div class="content-item flex" v-for="(item,idx) in contentList" :key="idx" @click="submit(item)">
						<div class="flex flex-column flex-1">
							<div class="content-name title-ellipsis-two flex-1">{{item}}</div>
							<!-- <div v-if="item.desc" class="desc font-12 text-normal line-h-16">{{item.desc}}</div> -->
						</div>
						<div class="flex align-center pl-25">
							<div class="submit-use-btn" >使用</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getChatTipMessages
	} from "@/api/chat";
	export default {
		props: ["chatItem", "showPrompt"],
		data() {
			return {
				tabList: [{
					name: "知识询问"
				}, {
					name: "创意绘画"
				}, {
					name: "文学创作"
				}],
				tabIdx: 0,
				statusLoadmore: "loadmore",
				current: 1,
				isLoading: true,
				contentList: [],
				showPopup: false
			}
		},
		created() {
			this.showPopup = this.showPrompt;
			this.getChatTipMessagesReq();
		},
		methods: {
			tabChange(item, idx) {
				this.tabIdx = idx;
			},
			getChatTipMessagesReq() {
				if (this.chatItem && this.chatItem.id) {
					this.contentList = [];
					getChatTipMessages(this.chatItem.id).then((res) => {
						console.log("res", res)

						if (res && res.error == 0) {
							this.contentList = res && res.data && res.data.messages || [];
						} else {
							this.$message({
								message: err && err.msg || "获取推荐数据失败，请稍后重试",
								type: "error"
							});
						}
					}).catch(() => {
						this.$message({
							message: err && err.msg || "获取推荐数据失败，请稍后重试",
							type: "error"
						});
					})
				}

			},
			submit(tip) {
				this.$emit("sumbitTip", tip)
			},
			closePopup() {
				this.$emit("closePopup")
			}
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/css/tabList.less");

	.prompt-popup {

		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 999;
		background: transparent;

		.prompt-layer {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: transparent;
		}

		.content {
			width: 460px;
			height: 446px;
			background: #FFFFFF;
			box-shadow: 0px 4px 8px 0px #E7E8ED;
			border-radius: 20px;
			border: 1px solid #E7E8ED;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			
			.title {
				font-weight: bold;
				font-size: 17px;
				color: #1A232F;
				line-height: 24px;
				text-align: center;
				margin-top: 20px;
			}
		}
	}

	.content-list {

		.content-item {
			width: 400px;
			padding: 17px 20px 10px 20px;
			margin-bottom: 10px;
			background: #F8F8FA;
			border-radius: 10px;
			cursor: pointer;
			&:last-child {
				margin-bottom: 0;
			}

			.content-name {
				font-weight: bold;
				font-size: 14px;
				color: #1A232F;
				line-height: 20px;
				text-align: left;
			}

			.desc {
				margin: 6px 0 10px 0;
			}

			.submit-use-btn {
				width: 64px;
				height: 28px;
				background: #D9E5FF;
				box-shadow: 0px 2px 4px 0px #D9E5FF;
				border-radius: 18px;
				border: 1px solid #3672FD;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: 400;
				font-size: 12px;
				color: #3672FD;
			}
		}
	}
</style>
import request from "@/utils/request";


/**
 * 品类列表
 */
 export function getStatisticsCategoryList(params) {
    return request({
        url: '/statistics/category_list',
        method: 'get',
        params: params
    })
}

/**
 * Ip详情统计数据
 * ip_id: ip_id
 * limit: limit
 */
 export function getStatisticsIp(params) {
    return request({
        url: '/statistics/ip',
        method: 'get',
        params: params
    })
}

/**
 * 市场综合分析统计数据
 * ym: 年月 202406
 */
 export function getStatisticsMarket(params) {
    return request({
        url: '/statistics/market',
        method: 'get',
        params: params
    })
}

/**
 * 产品策略统计数据
 * category_id : 品类id
 * ip_id: ip_id
 */
 export function getStatisticsProduct(params) {
    return request({
        url: '/statistics/product',
        method: 'get',
        params: params
    })
}

/**
 * 查找ip，模糊查询
 * category_id :category_id 
 * q : 查询参数
 * offset:offset
 * limit:limit
 */
 export function getStatisticsSearchIp(params) {
    return request({
        url: '/statistics/search_ip',
        method: 'get',
        params: params
    })
}

/**
 * 热门品类
 * category_id  :品类id
 * ym: 年月 202406
 */
 export function getStatisticsMarketCategory(params) {
    return request({
        url: '/statistics/market_category',
        method: 'get',
        params: params
    })
}


/**
 * 品类同类ip
 * category_id  :品类id
 * ip_id : ip_id 
 * limit: limit
 */
 export function getStatisticsCategoryCompeteIp(params) {
    return request({
        url: '/statistics/category_compete_ip',
        method: 'get',
        params: params
    })
}

/**
 * 二级品类同类ip
 * keyword_id  :品类id
 * ip_id : ip_id 
 * limit: limit
 */
 export function getStatisticsKeywordCompeteIp(params) {
    return request({
        url: '/statistics/keyword_compete_ip',
        method: 'get',
        params: params
    })
}

/**
 * 品类下同类ip价格分布排行
 * category_id  :品类id
 * ip_id : ip_id 
 */
 export function getStatisticsCategoryCompeteIpDistribution(params) {
    return request({
        url: '/statistics/category_compete_ip_distribution',
        method: 'get',
        params: params
    })
}

/**
 * 品类下ip价格分布排行
 * category_id  :品类id
 * ip_id : ip_id 
 */
 export function getStatisticsCategoryIpDistribution(params) {
    return request({
        url: '/statistics/category_ip_distribution',
        method: 'get',
        params: params
    })
}

/**
 * 同类ip价格分布排行
 * category_id  :品类id
 * ip_id : ip_id 
 */
 export function getStatisticsCompeteIpDistribution(params) {
    return request({
        url: '/statistics/compete_ip_distribution',
        method: 'get',
        params: params
    })
}

/**
 * 二级品类下同类ip价格分布排行
 * ip_id   :ip_id 
 * keyword_id  : 品类id
 */
 export function getStatisticsKeywordCompeteIpDistribution(params) {
    return request({
        url: '/statistics/keyword_compete_ip_distribution',
        method: 'get',
        params: params
    })
}

/**
 * 二级品类下ip价格分布排行
 * ip_id   :ip_id 
 * keyword_id  : 品类id
 */
 export function getStatisticsKeywordIpDistribution(params) {
    return request({
        url: '/statistics/keyword_ip_distribution',
        method: 'get',
        params: params
    })
}

/**
 * 二级品类列表
 * ym    :ym  
 */
 export function getStatisticsKeywordList(params) {
    return request({
        url: '/statistics/keyword_list',
        method: 'get',
        params: params
    })
}

/**
 * 品类下二级品类蓝海指数
 * category_id : 品类id 
 * ym    :ym  
 */
 export function getStatisticsMarketCategoryBlueOcean(params) {
    return request({
        url: '/statistics/market_category_blue_ocean',
        method: 'get',
        params: params
    })
}

/**
 * 品类价格分布排行
 * category_id : 品类id 
 * ym    :ym  
 */
 export function getStatisticsMarketCategoryDistribution(params) {
    return request({
        url: '/statistics/market_category_distribution',
        method: 'get',
        params: params
    })
}

/**
 * 品类降温榜
 * category_id : 品类id 
 * ym    :ym  
 */
 export function getStatisticsMarketCategoryDown(params) {
    return request({
        url: '/statistics/market_category_down',
        method: 'get',
        params: params
    })
}

/**
 * 新晋品类ip
 * category_id : 品类id 
 * limit: limit
 * ym    :ym  
 */
 export function getStatisticsMarketCategoryIp(params) {
    return request({
        url: '/statistics/market_category_ip',
        method: 'get',
        params: params
    })
}

/**
 * 热门ip
 * limit: limit
 * offset: offset
 * ym    :ym  
 */
export function getStatisticsMarketIp(params) {
    return request({
        url: '/statistics/market_ip',
        method: 'get',
        params: params
    })
}

/**
 * 飙升IP
 * limit: limit
 * offset: offset
 * ym    :ym  
 */
export function getStatisticsMarketIpUp(params) {
    return request({
        url: '/statistics/market_ip_up',
        method: 'get',
        params: params
    })
}

/**
 * 新晋ip
 * limit: limit
 * offset: offset
 * ym    :ym  
 */
export function getStatisticsMarketIpNew(params) {
    return request({
        url: '/statistics/market_ip_new',
        method: 'get',
        params: params
    })
}

/**
 * 品类下降ip
 * category_id : 品类id 
 * limit: limit
 * ym    :ym  
 */
 export function getStatisticsMarketCategoryIpDown(params) {
    return request({
        url: '/statistics/market_category_ip_down',
        method: 'get',
        params: params
    })
}

/**
 * 品类飙升ip
 * category_id : 品类id 
 * limit: limit
 * ym    :ym  
 */
 export function getStatisticsMarketCategoryIpUp(params) {
    return request({
        url: '/statistics/market_category_ip_up',
        method: 'get',
        params: params
    })
}

/**
 * 新晋ip
 * category_id : 品类id 
 * limit: limit
 * ym    :ym  
 */
 export function getStatisticsMarketCategoryIpNew(params) {
    return request({
        url: '/statistics/market_category_ip_new',
        method: 'get',
        params: params
    })
}

/**
 * 潜力ip
 * category_id : 品类id 
 * limit: limit
 * ym    :ym  
 */
export function getStatisticsMarketCategoryIpPotential(params) {
    return request({
        url: '/statistics/market_category_ip_potential',
        method: 'get',
        params: params
    })
}

/**
 * 品类升温榜
 * category_id : 品类id 
 * ym    :ym  
 */
 export function getStatisticsMarketCategoryUp(params) {
    return request({
        url: '/statistics/market_category_up',
        method: 'get',
        params: params
    })
}

/**
 * 二级品类价格分布排行
 * keyword_id  : 品类id 
 * ym    :ym  
 */
 export function getStatisticsMarketKeyrowdDistribution(params) {
    return request({
        url: '/statistics/market_keyword_distribution',
        method: 'get',
        params: params
    })
}


/**
 * 热门二级品类ip
 * keyword_id  : 二级品类id 
 * ym    :ym  
 */
 export function getStatisticsMarketKeywordIp(params) {
    return request({
        url: '/statistics/market_keyword_ip',
        method: 'get',
        params: params
    })
}


/**
 * 二级品类下降ip
 * keyword_id  : 二级品类id 
 * ym    :ym  
 */
 export function getStatisticsMarketKeywordIpDown(params) {
    return request({
        url: '/statistics/market_keyword_ip_down',
        method: 'get',
        params: params
    })
}

/**
 * 二级品类飙升ip
 * keyword_id  : 二级品类id 
 * ym    :ym  
 */
 export function getStatisticsMarketKeywordIpUp(params) {
    return request({
        url: '/statistics/market_keyword_ip_up',
        method: 'get',
        params: params
    })
}

/**
 * 新晋二级品类ip
 * keyword_id  : 二级品类id 
 * ym    :ym  
 */
 export function getStatisticsMarketKeywordIpNew(params) {
    return request({
        url: '/statistics/market_keyword_ip_new',
        method: 'get',
        params: params
    })
}

/**
 * 品类下二级品类指数
 * category_id  : 二级品类id 
 * limit
 * ym    :ym  
 */
 export function getStatisticsMarketCategoryIndex(params) {
    return request({
        url: '/statistics/market_category_index',
        method: 'get',
        params: params
    })
}

/**
 * 数据区域
 * keyword_id   : 二级品类id 
 * ip_id
 * price    :price  
 */
export function getStatisticsDistribution(params) {
    return request({
        url: '/statistics/distribution',
        method: 'get',
        params: params
    })
}

/**
 * ip对应二级品类列表
 * ip_id
 */
export function getStatisticsIpKeywordList(params) {
    return request({
        url: '/statistics/ip_keyword_list',
        method: 'get',
        params: params
    })
}
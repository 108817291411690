import axios from 'axios'
import { Notification, Message,MessageBox } from 'element-ui'
import { getToken,removeToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import { tansParams } from "@/utils/common";
import cache from '@/plugins/cache'
import Vue from 'vue'
import VueRouter from "vue-router";
import { EventBus } from './eventBus.js';
import env from "./env";
const _Message = Message;
Vue.use(VueRouter);
// 是否显示重新登录
export let isRelogin = { show: false };

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 100000
})

let isAlert = false;

// request拦截器
service.interceptors.request.use(config => {
  // console.log("config",config)
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false;
  config.headers["X-SCENE"] = "";
  config.headers["X-APP"] = env.defaultConfig.App;
  config.headers["X-P"] = env.defaultConfig.Os;
  config.headers["X-UUID"] = env.defaultConfig.Uuid;
  config.headers["X-V"] = env.defaultConfig.AppVersion;
  config.headers["X-NV"] = env.defaultConfig.NativeVersion;
  config.headers["X-TS"] = new Date().getTime();
  config.headers["X-S"] = "";
  if (config["ContentType"]) {
    config.headers["Content-Type"] = config["ContentType"];
  }
  if(config.method == "post") {
    if(config.url.indexOf("default/upload") >= 0) {
      config.headers["Content-Type"] = "multipart/form-data";
      
    } else {
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    }
    
  }
  if (getToken() && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    }
    const sessionObj = cache.session.getJSON('sessionObj')
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.session.setJSON('sessionObj', requestObj)
    } else {
      const s_url = sessionObj.url;                  // 请求地址
      const s_data = sessionObj.data;                // 请求数据
      const s_time = sessionObj.time;                // 请求时间
      const interval = 1000;                         // 间隔时间(ms)，小于此时间视为重复提交
      if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
        const message = '数据正在处理，请勿重复提交';
        console.warn(`[${s_url}]: ` + message)
        return Promise.reject(new Error(message))
      } else {
        cache.session.setJSON('sessionObj', requestObj)
      }
    }
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  let code = res.data.error || 200;
  // 获取错误信息
  let msg = errorCode[code] || res.data.msg || errorCode['default']
  // 二进制数据则直接返回
  if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
    return res.data
  }

  if (code == 20001) {
    removeToken();
    if(isAlert) {
      return;
    }
    isAlert = true;
    MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
      EventBus.$emit('openLogin');
      isAlert = false;
      // return res.data
    }).catch(() => {
      isAlert = false;
      // return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    });
    
    return res.data;
  } else if (code === 500) {
    _Message({ message: msg, type: 'error' })
    return Promise.reject(new Error(msg))
  } else if (code === 601) {
    _Message({ message: msg, type: 'warning' })
    return Promise.reject('error')
  } else if (code !== 200) {
    // Notification.error({ title: msg })
    return Promise.reject(res.data)
    // return "";
  } else {
    
    return res.data
  }
},
  error => {
    console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    _Message({ message: message, type: 'error', duration: 5 * 1000 })
    return Promise.reject(error)
  }
)


export default service

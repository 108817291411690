<template>
    <div class="ask-content">
        <div class="flex  current-img-box">
            <!-- <view class="content-type-list flex" v-if="currentTypeItem && currentTypeItem.name">
                <view class="content-type-item flex justify-center align-center active">
                    {{currentTypeItem && currentTypeItem.name || ""}}
                </view>
            </view> -->
            <div>
                <div class="current-img-item position-relative" v-if="defaultImg">
                    <el-image style="height: 40px;" :src="defaultImg" :preview-src-list="[defaultImg]">
                    </el-image>
                    <img class="icon-del" src="https://files.sssrise.com/files/uploads/static/icon_del.png"
                        @click="del">
                </div>
            </div>
        </div>
        <div class="ask-box">
            <div class="ask-input">
                <input v-model="value" placeholder="在这里输入你的问题" type="text" />
            </div>
            <div class="ask-opt">
                <img v-if="chatItem && (chatItem.type == 3 || chatItem.type == 4 || chatItem.type == 5)" class="cursor"
                    src="https://files.sssrise.com/files/uploads/static/pic.png" @click="showImgBox = !showImgBox">
                <!-- <img class="cursor" src="https://files.sssrise.com/files/uploads/static/yuyin.png" > -->
                <img class="cursor" src="https://files.sssrise.com/files/uploads/static/fasong.png"
                    @click="showContentEditFun">
            </div>

        </div>
        <div class="img-list flex flex-wrap" v-if="showImgBox">
            <!-- <el-upload class="upload-demo" action="#" accept=".png,.jpg" :on-preview="handlePreview"
                :on-remove="handleRemove" :before-upload="beforeAvatarUpload" :before-remove="beforeRemove" :limit="1"
                :on-success="fileSuccess" :show-file-list="false" :on-exceed="handleExceed" :file-list="fileList"
                :http-request="handleFileUpload">
                <div class="img-item first-item flex align-center justify-center cursor">
                    <img src="https://files.sssrise.com/files/uploads/static/icon_paizhao.png">
                </div>

            </el-upload> -->
            <el-upload class="upload-demo" ref="fileUpload" :action="action" accept=".png,.jpg" :limit="1"
                :show-file-list="false"
                :on-change="changeImgFile" :http-request="handleFileUpload" :auto-upload="false">
                <div class="img-item first-item flex align-center justify-center cursor">
                    <img src="https://files.sssrise.com/files/uploads/static/icon_paizhao.png">
                </div>

            </el-upload>

            <div class="img-item position-relative" :class="[currentIdx == idx ? 'active' : '']"
                v-for="(item,idx) in imgList" :key="idx" @click="chooseImg(item,idx)">
                <img class="img-src" :src="item.url">
                <img class="img-check position-absolute" v-if="currentIdx == idx"
                    src="https://files.sssrise.com/files/uploads/static/img_check.png">
            </div>
        </div>
        <CropperCom v-if="showCropper" :dialog-visible="showCropper" :cropper-img="cropperImg"
            @update-cropper="updateCropper" @colse-dialog="closeDialog" @upload-img="uploadImg" />
    </div>

</template>
<script>
    import { uploadFiles } from "@/api/user";
    const baseURL = process.env.VUE_APP_BASE_API;
    import { getToken } from "@/utils/auth";
    import env from "@/utils/env";
    import CryptoJS from 'crypto-js';
    import CropperCom from './cropper.vue';
    export default {
        props: ["chatItem", "currentTypeItem"],
        components: {
            CropperCom
        },
        data() {
            return {
                showImgBox: true,
                value: "",
                imgList: [],
                defaultImg: "",
                currentIdx: -1,
                isRecording: false,
                currentHash: "",
                fileList: [],
                action: baseURL + "/default/upload",
                headers: {
                    "X-SCENE": "",
                    "X-APP": env.defaultConfig.App,
                    "X-P": env.defaultConfig.Os,
                    "X-UUID": env.defaultConfig.Uuid,
                    "X-V": env.defaultConfig.AppVersion,
                    "X-NV": env.defaultConfig.NativeVersion,
                    "X-TS": new Date().getTime(),
                    Authorization: `Bearer ${getToken()}`,
                    "X-S": "",
                },
                cropperImg: '', // 需要裁剪的图片
                showCropper: false, // 是否显示裁剪框
                uploadFile: '', // 裁剪后的文件
                resultImg: '' // 上传成功，后台返回的路径
            }
        },
        methods: {
            changeImgFile(file) {
                const { raw } = file;
                this.openCropper(raw);
            },
            openCropper(file) {
                this.fileList = [];
                var files = file;
                let isLt5M = files.size > (1 << 20);
                if (isLt5M) {
                    this.$message.error('请上传1M内的图片');
                    return false;
                }
                var reader = new FileReader();
                reader.onload = e => {
                    let data;
                    if (typeof e.target.result === 'object') {
                        // 把Array Buffer转化为blob 如果是base64不需要
                        data = window.URL.createObjectURL(new Blob([e.target.result]));
                    } else {
                        data = e.target.result;
                    }
                    this.cropperImg = data;
                };
                // 转化为base64
                // reader.readAsDataURL(file)
                // 转化为blob
                reader.readAsArrayBuffer(files);
                this.showCropper = true;
            },
            updateCropper() {
                this.$refs.fileUpload.$children[0].$el.click();
            },
            uploadImg(file) {
                this.uploadFile = file;
                this.$refs.fileUpload.submit();
            },
            closeDialog() {
                this.showCropper = false;
            },
            handleCrop() {

            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
            },
            handlePreview(file) {
                console.log(file);
            },
            beforeAvatarUpload(file) {
                const isLt1M = file.size / 1024 / 1024 < 1;

                if (!isLt1M) {
                    this.$message.error("上传头像图片大小不能超过 1MB!");
                }
                console.log("file", file)
                return isLt1M;
            },
            handleExceed(files, fileList) {
                this.$message.warning(
                    `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
                    } 个文件`
                );
            },
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${file.name}？`);
            },
            fileSuccess(file, fileList) {
                console.log("success", file, fileList)
            },
            handleFileUpload(file) {
                let self = this;
                console.log("file", file)
                let imgType = file.file.name.substring(file.file.name.lastIndexOf(".") + 1, file.file.name.length);
                let reader = new FileReader();
                let currentFile = new File([self.uploadFile], file.file.name, { type: self.uploadFile.type })
                console.log("currentFile",currentFile)
                reader.onload = function (e) {
                    let binaryString = e.target.result;
                    let md5 = CryptoJS.MD5(binaryString).toString();
                    console.log('MD5 Hash:', md5);
                    // let wordArray = CryptoJS.lib.WordArray.create(file.file);
                    // let hash = CryptoJS.MD5(wordArray).toString();
                    // console.log("wordArray", wordArray, 'File MD5 Hash:', hash);//上传文件和哈希值
                    let param = {
                        file: currentFile,
                        hash: md5,
                        scene: "chat"
                    }
                    uploadFiles(param).then((res) => {
                        self.fileList = [];
                        if (res && res.error == 0) {
                            if (res && res.data && res.data.url) {
                                self.imgList.push(res.data);
                                self.defaultImg = res.data.url;
                                self.currentHash = res.data.hash + "." + imgType;
                                self.currentIdx = self.imgList.length - 1;
                            }
                            self.showCropper = false;
                        } else {
                            self.$message.error(err && err.msg || "上传文件失败，请稍后重试");
                        }
                    }).catch((err) => {
                        self.fileList = [];
                        self.$message.error(err && err.msg || "上传文件失败，请稍后重试");
                    })
                };
                reader.readAsBinaryString(currentFile);

            },
            closePopup() {
                this.$emit("closePopup");
            },
            del() {
                this.defaultImg = "";
                this.currentHash = "";
                this.currentIdx = -1;
            },
            chooseImg(item, idx) {
                this.currentIdx = idx;
                this.currentHash = item.hash;
                this.defaultImg = item.url;
            },
            showContentEditFun() {
                if (!this.value) {
                    this.$message({
                        message: `请输入问题`,
                        type: "error"
                    })
                    return;
                }
                if (!this.currentHash && this.chatItem && (this.chatItem.type == 3 || this.chatItem.type == 4 || this.chatItem.type == 5)) {
                    this.$message({
                        message: `请上传图片`,
                        type: "error"
                    })
                    return;
                }
                let param = {

                }
                param["text"] = this.value;
                param["image"] = this.currentHash;
                param["imageSrc"] = this.defaultImg;
                console.log("param", param)

                this.value = "";
                this.currentHash = "";
                this.defaultImg = "";
                this.currentIdx = -1;
                this.$emit("hideContentEditFun", param)
            }
        }
    }
</script>
<style lang="less" scoped>
    .ask-content {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 0 auto;
        flex-direction: column;
        padding: 10px 0;
        background: rgba(243, 243, 248, 0.8);
        box-shadow: inset 0px 1px 0px 0px #E7E8ED;
        backdrop-filter: blur(10px);
    }

    .current-img-box {
        justify-content: flex-end;
        width: 100%;
        padding-right: 20px;
        margin-bottom: 10px;
    }

    .current-img-item {
        .current-img {
            height: 30px;
        }

        .icon-del {
            width: 24px;
            height: 24px;
            position: absolute;
            top: -10px;
            right: -10px;
        }
    }

    .ask-box {
        width: 100%;
        display: flex;
        padding: 0 20px;

        .ask-input {
            flex: 1;

            input {
                height: 46px;
                font-weight: 400;
                font-size: 14px;
                color: #626A83;
                padding: 0;
                padding-left: 24px;
                width: 100%;
                border-radius: 36px;
                border-width: 0;
            }

            input:focus {
                outline: none;
            }
        }

        .ask-opt {
            display: flex;
            align-items: center;
            padding-left: 20px;

            .ask-icon {
                margin-right: 20px;
            }

            img {
                width: 24px;
                height: 24px;
                margin-right: 20px;
            }
        }


    }

    .img-list {
        width: 100%;
        padding: 0 20px;

        .img-item {
            margin-top: 10px;
            width: 60px;
            height: 60px;
            margin-right: 30px;
            border-radius: 5px;

            &:nth-child(4n) {
                margin-right: 0;
            }

            &.first-item {
                background: #E7E8ED;
                border: 1px solid #CCCFDA;

                img {
                    width: 24px;
                    height: 24px;
                    border-radius: inherit;
                }
            }

            &.active {
                border: 2px solid #3672FD;
            }

            .img-src {
                width: 100%;
                height: 100%;

            }

            .img-check {
                width: 18px;
                height: 18px;
                top: -2px;
                right: -2px;
            }
        }
    }
</style>
<template>
  <div class="flex flex-column about-box">
    <i class="el-icon-circle-close icon-defalut-close cursor"
      @click="close"></i>
    <div class="default-title">个人信息保护规则</div>
    <div class="default-content">

      <div
        class="text-normal font-12 line-h-18 mb-20 mt-20 px-30">
        本协议（以下简称“隐私协议”）规定了您（以下简称“用户”）在使用我们提供的服务时，我们如何收集、使用、存储、共享和保护您的个人信息。请您仔细阅读本隐私协议，并确保您充分理解其内容。如果您不同意本隐私协议中的任何条款，请不要使用我们的服务。
      </div>
      <div
        class="text-normal font-12 line-h-18 mb-20 px-30">
        一、个人信息的收集</div>
      <div
        class="text-normal font-12 line-h-18 mb-20 px-30">
        1.1 我们可能收集的个人信息包括但不限于<span
          class="text-btn-submit">您的姓名、电子邮件地址、电话号码、地理位置、设备信息、浏览记录、使用习惯</span>等。这些信息将帮助我们更好地了解您的需求，以便提供更优质的服务。
      </div>
      <div
        class="text-normal font-12 line-h-18 mb-20  px-30">
        二、个人信息的使用</div>
      <div
        class="text-normal font-12 line-h-18 mb-20  px-30">
        2.1 我们将使用您的个人信息来提供和改进我们的服务，包括但不限于<span
          class="text-btn-submit">个性化推荐、AI服务、市场调研</span>等。
      </div>
      <div
        class="text-normal font-12 line-h-18 mb-20  px-30">
        2.2 我们将严格遵守法律法规，不会将您的个人信息用于任何非法或未经您授权的目的。</div>
      <div
        class="text-normal font-12 line-h-18 mb-20  px-30">
        三、个人信息的存储和保护</div>
      <div
        class="text-normal font-12 line-h-18 mb-20  px-30">
        3.1 我们将采取合理的技术和管理措施，确保您的个人信息在传输和存储过程中的安全。</div>
      <div
        class="text-normal font-12 line-h-18 mb-20  px-30">
        3.2 我们将根据您的个人信息的重要性和敏感程度，采取适当的加密、脱敏等安全措施。</div>
      <div
        class="text-normal font-12 line-h-18 mb-20  px-30">
        3.3 我们将定期备份您的个人信息，以防止数据丢失或损坏。</div>
      <div
        class="text-normal font-12 line-h-18 mb-20  px-30">
        四、个人信息的共享</div>
      <div
        class="text-normal font-12 line-h-18 mb-20  px-30">
        4.1 我们不会将您的个人信息共享给任何第三方，除非事先获得您的明确同意或法律法规另有规定。
      </div>
      <div
        class="text-normal font-12 line-h-18 mb-20  px-30">
        4.2 在以下情况下，我们可能需要共享您的个人信息：</div>
      <div
        class="text-normal font-12 line-h-18 mb-20  px-30">
        （1）与我们的关联公司共享，以便提供更优质的服务；</div>
      <div
        class="text-normal font-12 line-h-18 mb-20  px-30">
        （2）与我们的合作伙伴共享，以便提供更丰富的服务内容；</div>
      <div
        class="text-normal font-12 line-h-18 mb-20  px-30">
        （3）在法律法规要求或政府主管部门要求的情况下共享。</div>
      <div
        class="text-normal font-12 line-h-18 mb-20  px-30">
        五、您的权利</div>
      <div
        class="text-normal font-12 line-h-18 mb-20  px-30">
        5.1 如果您认为我们的个人信息处理行为违反了法律法规或侵犯了您的合法权益，您可以向有关部门投诉或举报。
      </div>
      <div
        class="text-normal font-12 line-h-18 mb-20  px-30">
        六、隐私政策的变更</div>
      <div
        class="text-normal font-12 line-h-18 mb-20  px-30">
        6.1
        我们可能会根据法律法规的变化、业务发展的需要或其他原因对本隐私政策进行不定期更新。更新后的隐私政策将通过适当方式向您告知。请您定期查阅本隐私政策，以了解最新的个人信息保护措施。
      </div>
      <div
        class="text-normal font-12 line-h-18 mb-20  px-30">
        七、法律适用和争议解决</div>
      <div
        class="text-normal font-12 line-h-18 mb-20  px-30">
        7.1
        本隐私协议适用中华人民共和国法律。如双方因本隐私协议产生任何争议，应首先通过友好协商解决；协商不成的，任何一方均有权向有管辖权的人民法院提起诉讼。
      </div>
      <div
        class="text-normal font-12 line-h-18 mb-20  px-30">
        7.2 本隐私协议的解释权归我们所有。</div>
      <div
        class="text-normal font-12 line-h-18 mb-20  px-30">
        请您仔细阅读本隐私协议，并确保充分理解其内容和含义。如果您有任何问题或疑虑，请随时联系我们。我们将竭诚为您提供服务。
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("closeHelp");
    },
  },
};
</script>
  
  <style lang="less" scoped>
.about-box {
  width: 380px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px #e7e8ed;
  border-radius: 20px;
  border: 1px solid #e7e8ed;
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  margin-left: 10px;
  display: flex;
  .default-content {
    flex: 1;
    overflow-y: auto;
    text-align: left;
  }
}
</style>
<template>
    <div class="vip-warp-popup">
        <div class="vip-popup-content">
            <i class="el-icon-circle-close icon-defalut-close cursor" @click="close"></i>
            <div class="default-title">会员中心</div>
            <div class="flex pl-30 mt-10 align-center">
                <div class="text-border"></div>
                <div class="normal-title">会员权益</div>
            </div>
            <div class="level-h font-12 flex align-center text-default font-weight-bolder mt-20">
                <div class="level-name flex-1">VIP等级</div>
                <div class="condition text-center">获取条件</div>
                <div class="sign-in text-center">每日签到</div>
                <div class="discount text-center">消费折扣</div>
                <div class="give text-center">赠送玩具</div>
            </div>
            <div class="level-list">
                <div class="level-item flex align-center"
                    :class="[userInfo && userInfo.vip_level == item.vipLevel ? 'active' : '']"
                    v-for="(item,idx) in levelList" :key="idx">
                    <div class="level-name flex-1">
                        <div class="vip-top" v-if="idx <= 2">VIP {{item.vipLevel}}</div>
                        <div class="vip" v-else>VIP {{item.vipLevel}}</div>
                    </div>

                    <div class="condition font-12 text-normal text-center">{{item.desc}}</div>
                    <div class="sign-in font-12 text-normal text-center">{{item.signin}}</div>
                    <div class="discount font-12 text-normal text-center">{{item.discount || "/"}}</div>
                    <div class="give font-12 text-normal text-center">{{item.give || "/"}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ["userInfo"],
        data() {
            return {
                levelList: [{
                    vipLevel: 1,
                    desc: "实名认证",
                    signin: 10,
                    discount: "",
                    give: ""
                }, {
                    vipLevel: 2,
                    desc: "30",
                    signin: 20,
                    discount: "",
                    give: ""
                }, {
                    vipLevel: 3,
                    desc: "90",
                    signin: 30,
                    discount: "",
                    give: ""
                }, {
                    vipLevel: 4,
                    desc: "180",
                    signin: 50,
                    discount: "9.5",
                    give: "1"
                }, {
                    vipLevel: 5,
                    desc: "588",
                    signin: 100,
                    discount: "9.3",
                    give: "2"
                }, {
                    vipLevel: 6,
                    desc: "1888",
                    signin: 100,
                    discount: "9.2",
                    give: "3"
                }, {
                    vipLevel: 7,
                    desc: "5888",
                    signin: 100,
                    discount: "9.1",
                    give: "6"
                }, {
                    vipLevel: 8,
                    desc: "18888",
                    signin: 110,
                    discount: "9",
                    give: "8"
                }, {
                    vipLevel: 9,
                    desc: "58888",
                    signin: 100,
                    discount: "8.9",
                    give: "10"
                }, {
                    vipLevel: 10,
                    desc: "88888",
                    signin: 100,
                    discount: "8.8",
                    give: "12"
                }]
            }
        },
        methods: {
            close() {
                this.$emit("closeVip")
            }
        }
    }
</script>
<style lang="less" scoped>
    .vip-warp-popup {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: transparent;
        z-index: 100001;

        .vip-popup-content {
            width: 380px;
            background: #FFFFFF;
            box-shadow: 0px 4px 8px 0px #E7E8ED;
            border-radius: 20px;
            border: 1px solid #E7E8ED;
            left: 132px;
            bottom: 20px;
            top: 128px;
            position: absolute;
            overflow-y: auto;

            .level-h {
                margin: 0 auto;
                width: 340px;
                height: 36px;
                background: #F8F8FA;
                border-radius: 10px;
            }

            .level-list {
                .level-item {
                    width: 340px;
                    height: 36px;

                    border-radius: 10px;
                    margin: 0 auto;
                    padding: 0 10px;

                    &:nth-child(2n) {
                        background: #FFFFFF;
                        border-radius: 10px;
                    }

                    &.active {
                        background: #D9E5FF;
                        box-shadow: 0px 2px 4px 0px #D9E5FF;
                        border-radius: 10px;
                        border: 1px solid #3672FD;
                    }
                }
            }

            .level-name {}

            .condition {
                width: 70px;
            }

            .sign-in {
                width: 60px;
            }

            .discount {
                width: 70px;
            }

            .give {
                width: 70px;
            }
        }
    }
</style>
<template>
    <div class="model-box">
        <div class="model-list">
            <div class="model-item cursor" :class="[modelChooseIdx == item.id ? 'active': '']" v-for="(item,idx) in modelsList" :key="idx" @click="chooseModel(item,idx)">
                <div class="model-img">
                    <img v-if="modelChooseIdx == item.id" :src="item.check_image">
                    <img v-else :src="item.default_image">
                </div>
                
                <div class="name" >{{item.name}}</div>
            </div>
        </div>
    </div>
    
</template>
<script>
export default {
    props: ["modelsList","modelChooseIdx"],
    data() {
        return {
        }
    },
    methods: {
        chooseModel(item,idx) {
            console.log("item",item)
            this.$emit("chooseModel",{
                item: item,
                problemIdx: 1,
                idx: idx
            })
        }
    }
}
</script>
<style lang="less" scoped>
.model-box {
    width: 100%;
    overflow-y: auto;
    // height: 100%;
    margin-bottom: 20px;
}
.model-list {
    display: flex;
    padding: 0 30px 0 128px;
    flex-wrap: wrap;
    overflow-y: auto;

    .model-item {
        width: 140px;
        height: 150px;
        background: #FFFFFF;
        border-radius: 20px;
        border: 1px solid #E7E8ED;
        margin-right: 10px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &:nth-child(2n) {
            margin-right: 0;
        }
        .model-img {
            width: 72px;
            height: 72px;
            background: #F3F3F8;
            border-radius: 100%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        img {
            width: 32px;
            height: 32px;
            
            margin: 0 auto;
        }
        .name {
            font-weight: 500;
            font-size: 14px;
            color: #1A232F;
            line-height: 20px;
            margin-top: 20px;
        }
        &.active {
            background: #D9E5FF;
            box-shadow: 0px 2px 4px 0px #D9E5FF;
            border: 1px solid #3672FD;
            .model-img {
                background: #fff;
            }
            .name {
                color: #3672FD;
                font-weight: bold;
            }
        }
        
    }
}
</style>